import {createTheme, CssBaseline, responsiveFontSizes, ThemeProvider} from '@mui/material';
import React from 'react';
import '@mui/material/styles/createPalette';

interface IThemeProps {
  children: React.ReactNode;
}

export enum themePalette {
  BG_DEFAULT = '#f7f8fc',
  BG_PAPER = '#FFF',
}

export enum themeFonts {
  FONT_GLOBAL = 'Mulish, sans-serif',
}

export enum paletteColors {
  PRIMARY_MAIN = '#3751FF',
  SUCCESS_MAIN = '#66BF0D',
  WARNING_MAIN = '#FDAF3A',
  INFO_MAIN = '#F178B6',
  GRAY_MAIN = '#3E4660',
  GRAY_LIGHT = '#A4A6B3',
  GRAY_DARK = '#263238',
  GRAY_CONTRAST_TEXT = '#FFFFFF',
}

export const theme = responsiveFontSizes(
  createTheme({
    palette: {
      primary: {
        main: paletteColors.PRIMARY_MAIN,
      },
      success: {
        main: paletteColors.SUCCESS_MAIN,
      },
      warning: {
        main: paletteColors.WARNING_MAIN,
      },
      info: {
        main: paletteColors.INFO_MAIN,
      },
      gray: {
        main: paletteColors.GRAY_MAIN,
        light: paletteColors.GRAY_LIGHT,
        dark: paletteColors.GRAY_DARK,
        contrastText: paletteColors.GRAY_CONTRAST_TEXT,
      },
      background: {default: '#f7f8fc', paper: '#FFF'},
    },
    typography: {
      fontFamily: themeFonts.FONT_GLOBAL,
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            scrollbarColor: '#6b6b6b #2b2b2b',
            '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
              backgroundColor: '#8e8b8b',
            },
            '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
              borderRadius: 2,
              backgroundColor: '#454651',
              minHeight: 24,
              border: '1px solid #2b2b2b',
            },
            '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
              backgroundColor: '#959595',
            },
            '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
              backgroundColor: '#959595',
            },
            '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#616370',
            },
            '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
              backgroundColor: '#2b2b2b',
            },
          },
        },
      },
      MuiButton: {
        defaultProps: {
          style: {
            textTransform: 'none',
            boxShadow: 'none',
            borderRadius: '0.5em',
          },
        },
      },
    },
  }),
);

export const ThemeConfig: React.FC<IThemeProps> = ({children}) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};
