import styled from '@emotion/styled';
import type {Theme} from '@mui/material';
import React from 'react';

interface IDrawerHeaderProps {
  sx: any;
  children: React.ReactNode;
}

const DrawerHeader: React.FC<IDrawerHeaderProps> = ({sx, children}) => {
  interface IDrawerHeaderDivProps {
    sx: Record<string, any>;
    theme?: Theme;
  }
  const DrawerHeaderDiv = styled('div')<IDrawerHeaderDivProps>(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 2.5),
    ...sx,
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  return <DrawerHeaderDiv sx={sx}>{children}</DrawerHeaderDiv>;
};

export default DrawerHeader;
