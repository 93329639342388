import {Button, Stack, Typography} from '@mui/material';
import React from 'react';
import {useRootStore} from '../context';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

function LogoutButton() {
  const {t} = useTranslation();
  const {accountStore} = useRootStore();
  const navigate = useNavigate();

  const handleLogout = (e: React.SyntheticEvent) => {
    e.preventDefault();
    accountStore.logout();
    navigate('/', {replace: true});
  };

  return (
    <Button
      onClick={handleLogout}
      sx={{
        paddingLeft: 1.5,
      }}
    >
      <Stack
        flexDirection="row"
        gap={3}
        sx={{
          marginLeft: '5px',
          '&:hover p': {
            color: '#FFF',
          },
        }}
      >
        <img src="/icons/logout.svg" width="24" />
        <Typography variant="body1" color="#FFFFFF40" sx={{transition: 'all 0.35s'}}>
          {t('common:logout')}
        </Typography>
      </Stack>
    </Button>
  );
}

export default LogoutButton;
