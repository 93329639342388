import {useTranslation} from 'react-i18next';

class LinenOrderStep {
  static get ORDER_DETAILS() {
    return 'order_details';
  }

  static get PREPARE_ORDER() {
    return 'prepare_order';
  }

  static get orderSteps() {
    return [
      {label: readableStep(LinenOrderStep.ORDER_DETAILS)},
      {label: readableStep(LinenOrderStep.PREPARE_ORDER)},
    ];
  }
}

export const readableStep = (step: string) => {
  const {t} = useTranslation();
  if (step === LinenOrderStep.ORDER_DETAILS) {
    return t('common:order_details');
  }
  return t('common:prepare_order');
};

export default LinenOrderStep;
