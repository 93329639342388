import {makeObservable, action, observable} from 'mobx';
import {StatusCodes} from 'http-status-codes';
import Api, {IGetGuestsResponse, IGuestResult} from '../api/guests';
import {toast} from 'react-toastify';
import type RootStore from './rootStore';
import {handleUnauthorizedResponse} from '../libs/utils';

interface IGuest {
  rootStore: RootStore;
  guests: Array<IGuestResult>;
  next: string | null;
  previous: string | null;
  count: number;
  selectedGuest: IGuestResult | null;
  isLoading: boolean;

  setGuests: (guests: Array<IGuestResult>) => void;
  setSelectedGuest: (guest: IGuestResult | null) => void;
  setIsLoading: (isLoading: boolean) => void;
  getGuests: (nextPreviousUrl?: string | null) => Promise<void>;
  getSelectedGuest: (guestId: string) => Promise<void>;
  setNext: (next: string | null) => void;
  setPrevious: (previous: string | null) => void;
  reset: () => void;
}

class Guest implements IGuest {
  rootStore: RootStore;
  guests: Array<IGuestResult> = [];
  next: string | null = null;
  previous: string | null = null;
  count = 0;
  selectedGuest: IGuestResult | null = null;
  isLoading = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      guests: observable,
      selectedGuest: observable,
      next: observable,
      previous: observable,
      count: observable,
      isLoading: observable,
      setGuests: action,
      setSelectedGuest: action,
      setIsLoading: action,
      getGuests: action,
      getSelectedGuest: action,
      setNext: action,
      setPrevious: action,
      reset: action,
    });
  }

  reset() {
    this.guests = [];
    this.selectedGuest = null;
    this.next = null;
    this.previous = null;
    this.count = 0;
  }

  setGuests(guests: Array<IGuestResult>) {
    this.guests = guests;
  }

  setSelectedGuest(guest: IGuestResult | null) {
    this.selectedGuest = guest;
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  async getSelectedGuest(guestId: string) {
    const token = this.rootStore.accountStore.user.token;

    if (token) {
      try {
        this.setIsLoading(true);

        const response = await Api.getGuest(token, guestId);
        if (response.ok) {
          const data: IGuestResult = await response.body;

          this.setSelectedGuest(data);
        }
      } catch (error: any) {
        toast.error(error.message);

        if (error.status === StatusCodes.UNAUTHORIZED) {
          handleUnauthorizedResponse();
          return;
        }
      } finally {
        this.setIsLoading(false);
      }
    }
  }

  async getGuests(nextPreviousUrl: string | null = null) {
    const token = this.rootStore.accountStore.user.token;

    if (token) {
      try {
        this.setIsLoading(true);

        const response = await Api.getGuests(token, nextPreviousUrl);

        if (response.ok) {
          const data: IGetGuestsResponse = await response.body;

          this.setGuests(data.results);
          this.setCount(data.count);
          this.setNext(data.next);
          this.setPrevious(data.previous);
        }
      } catch (error: any) {
        this.reset();
        toast.error(error.message);

        if (error.status === StatusCodes.UNAUTHORIZED) {
          handleUnauthorizedResponse();
          return;
        }
      } finally {
        this.setIsLoading(false);
      }
    }
  }

  setNext(next: string | null) {
    this.next = next;
  }

  setPrevious(previous: string | null) {
    this.previous = previous;
  }

  setCount(count: number) {
    this.count = count;
  }
}

export default Guest;
