import DeleteIcon from '@mui/icons-material/Delete';
import {useFormik} from 'formik';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useState} from 'react';
import * as Yup from 'yup';
import {toast} from 'react-toastify';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {useRootStore} from '../../context';
import {useTranslation} from 'react-i18next';
import type {TProductLevel} from '../../stores/inventory/product/product';
import type {IUnit} from '../../api/common';

interface IStorageLVProps {
  lv: TProductLevel;
}
const StorageLV: React.FC<IStorageLVProps> = observer(({lv}) => {
  const {t} = useTranslation();
  const {productStore, inventoryStore} = useRootStore();
  const [blob, setBlob] = useState<string>('');
  const [unit, setUnit] = useState<IUnit>({id: 0, name: ''});

  useEffect(() => {
    setBlob(productStore.getLevel(lv).image);
  }, [productStore.getLevel(lv).image]);

  useEffect(() => {
    if (inventoryStore.units.length === 0) {
      inventoryStore.getUnits();
    } else {
      const storageUnit = inventoryStore.units.find((obj) => {
        return obj.id === parseInt(productStore.amenity.unit);
      });

      if (storageUnit) setUnit(storageUnit);
    }
  }, [inventoryStore.units]);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files && e.currentTarget.files.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(e.currentTarget.files[0]);
      reader.onloadend = function () {
        setBlob(reader.result as string);
      };
    } else {
      setBlob('');
    }
    formik.handleChange(e);
  };

  const handleDeleteImage = () => {
    formik.setFieldValue('image', '');
    setBlob('');
  };

  const YupSchema = Yup.object().shape({
    storage: Yup.number()
      .positive(t('common:storage_type_required'))
      .integer()
      .required(t('common:storage_type_required')),
    quantity: Yup.number()
      .positive(t('storageLV:units_per_single_level_required', {lv: lv.toUpperCase()}))
      .integer()
      .required(t('storageLV:units_per_single_level_required', {lv: lv.toUpperCase()})),
    image: Yup.mixed().notRequired(),
  });

  const formik = useFormik({
    initialValues: {
      storage: productStore.getLevel(lv).storage,
      quantity: productStore.getLevel(lv).quantity,
      image: '',
    },
    enableReinitialize: true,
    validationSchema: YupSchema,
    onSubmit: (values) => {
      const level = {
        amenity_id: 0,
        storage: values.storage,
        quantity: values.quantity,
        image: blob,
      };
      productStore.setLevel(lv, level);
      toast.success(t('storageLV:level_storage_saved', {lv: lv[2]}));
    },
  });

  return (
    <>
      <Typography
        variant="h5"
        marginBottom={5}
        sx={{color: lv.includes('2') ? 'success.light' : 'warning.light'}}
      >
        {t('storageLV:level_storage_details', {lv: lv[2]})}
      </Typography>
      <Stack component="form" gap={3} noValidate onSubmit={formik.handleSubmit} marginBottom={5}>
        <Grid container width={{md: '75%'}}>
          {/* --------------------------- Storage -------------------------- */}
          <Grid item xs={12} md={6} marginBottom={3} component="div">
            <Typography variant="body1" display="inline">
              {t('storageLV:how_is_it_stored')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} marginBottom={5}>
            <TextField
              name="storage"
              value={formik.values.storage}
              onChange={formik.handleChange}
              label={t('storageLV:choose_level_storage', {lv: lv.toUpperCase()})}
              error={formik.touched.storage && Boolean(formik.errors.storage)}
              helperText={formik.touched.storage && formik.errors.storage}
              select
              required
              fullWidth
            >
              {inventoryStore.storages.map((obj, index) => (
                <MenuItem key={index} value={obj.id}>
                  {obj.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {/* --------------------------- Storage -------------------------- */}
          {/* --------------------------- Unit Qty ------------------------- */}
          <Grid item xs={12} md={6} marginBottom={3}>
            <Typography variant="body1">{t('storageLV:how_many_units_contain')}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name="quantity"
              value={formik.values.quantity}
              onChange={formik.handleChange}
              type="number"
              error={formik.touched.quantity && Boolean(formik.errors.quantity)}
              helperText={formik.touched.quantity && formik.errors.quantity}
              fullWidth
              required
              label={t('storageLV:unit_quantity')}
              InputProps={{
                inputProps: {min: 1},
                endAdornment: <InputAdornment position="end">{unit.name}</InputAdornment>,
              }}
            />
          </Grid>
          {/* --------------------------- Unit Qty ------------------------- */}
        </Grid>
        {/* ----------------------- If there IS an image ------------------- */}
        {blob === '' && (
          <FormControl>
            <Typography variant="body1" component="label" marginBottom={1}>
              {t('storageLV:upload_image_optional').toUpperCase()}
            </Typography>
            <input
              type="file"
              name="image"
              accept="image/*"
              onChange={handleImageChange}
              value={formik.values.image}
            />
            <FormHelperText error={formik.touched.image && Boolean(formik.errors.image)}>
              {formik.touched.image && formik.errors.image}
            </FormHelperText>
          </FormControl>
        )}
        {/* ----------------------- If there IS an image ------------------- */}
        {/* ---------------------- If there's NOT an image ----------------- */}
        {blob !== '' && (
          <Box position="relative">
            <img src={blob as string} alt={t('common:product_image')} />
            <IconButton
              onClick={handleDeleteImage}
              sx={{
                position: 'absolute',
                top: '0.5em',
                right: '0.5em',
                bgcolor: '#535050',
                '&:hover': {bgcolor: '#adaaaa', '*': {color: 'error.main'}},
              }}
            >
              <DeleteIcon sx={{color: '#FFF', transition: 'all 0.35s'}} />
            </IconButton>
          </Box>
        )}
        {/* ---------------------- If there's NOT an image ----------------- */}
        <Button
          type="submit"
          variant="contained"
          color="success"
          sx={{alignSelf: {md: 'center'}, minWidth: '200px', color: '#FFF'}}
        >
          {t('storageLV:save_level', {lv: lv.toUpperCase()})}
        </Button>
      </Stack>
    </>
  );
});

export default StorageLV;
