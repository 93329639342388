import {useState, useEffect} from 'react';
import {toJS} from 'mobx';
import {useRootStore} from '../context';
import type {IGetHotelsResult} from '../api/hotel';

const useProperties = () => {
  const {hotelStore} = useRootStore();
  const [all, setAll] = useState<Array<IGetHotelsResult>>([]);
  const [current, setCurrent] = useState({id: 0, short_name: '', long_name: ''});

  useEffect(() => {
    if (hotelStore.hotels.length === 0) {
      hotelStore.loadHotels();
    }
    const hs = hotelStore.hotels.slice();
    setAll(hs);
  }, [hotelStore.hotels]);

  useEffect(() => {
    if (hotelStore.currentHotel !== undefined) {
      setCurrent(toJS(hotelStore.currentHotel));
    }
  }, [hotelStore.currentHotel]);

  return {
    properties: all,
    selected: current,
  };
};

export default useProperties;
