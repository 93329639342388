import React from 'react';
import type {IReservationResult} from '../../api/reservations';
import {areConsecutiveDates, separateByRoom} from '../../libs/utils';
import ReservationCard from './ReservationCard';

const getPrevNextIndexes = (index: number, arr: Array<unknown>) => {
  const prevIndex = index - 1 < 0 ? 0 : index - 1;
  const nextIndex = index + 1 > arr.length - 1 ? arr.length - 1 : index + 1;
  return [prevIndex, nextIndex];
};

interface IReservationCardListProps {
  reservations: Array<IReservationResult>;
}

const ReservationCardList: React.FC<IReservationCardListProps> = ({reservations}) => {
  return (
    <>
      {reservations.map((guestReservations) => {
        const sameReservation = guestReservations.reservations?.every((reservationData, index) => {
          const [prevIndex, nextIndex] = getPrevNextIndexes(index, guestReservations.reservations);
          const previousReservation = guestReservations.reservations[prevIndex];
          const nextReservation = guestReservations.reservations[nextIndex];

          if (index === 0) {
            return areConsecutiveDates(reservationData.check_out, nextReservation.check_in);
          }
          return areConsecutiveDates(previousReservation.check_out, reservationData.check_in);
        });

        if (sameReservation) {
          return (
            <ReservationCard
              key={guestReservations.group_id}
              guestReservations={guestReservations}
              sameReservation={true}
              hasDuplicateRoom={guestReservations.reservations.length - 1}
            />
          );
        }

        const roomSeparatedReservations = separateByRoom(guestReservations);

        return Object.keys(roomSeparatedReservations).map((roomName) => {
          const guestReservationsForRoom: IReservationResult = {
            ...guestReservations,
            reservations: roomSeparatedReservations[roomName],
          };

          return (
            <ReservationCard
              key={`${guestReservations.group} ${roomName}`}
              guestReservations={guestReservationsForRoom}
              sameReservation={true}
              hasDuplicateRoom={guestReservationsForRoom.reservations.length - 1}
            />
          );
        });
      })}
    </>
  );
};

export default ReservationCardList;
