import {
  Card,
  CardContent,
  Container,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useState} from 'react';

import SelectHotel from '../../components/Navbar/SelectHotel';
import PageTitle from '../../components/PageTitle';
import {useRootStore} from '../../context';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import Header from '../../components/table/Header';
import CardRowItem from '../../components/table/CardRowItem';
import NoResultsFound from '../../components/general/NoResultsFound';
import '../../styles/main.css';
import SkeletonLoader from '../../components/general/SkeletonLoader';
import {t} from 'i18next';
import UpdateCodesModal from './UpdateCodesModal';
import type {IDoorCodes} from '../../api/hotel';

const DoorLocks = observer(() => {
  const {lockDevicesStore, hotelStore} = useRootStore();

  const [updateCodesModalOpen, setUpdateCodesModalOpen] = useState(false);
  const [selectedToUpdate, setSelectedToUpdate] = useState<IDoorCodes | null>(null);

  useEffect(() => {
    if (hotelStore.currentHotel !== undefined) {
      hotelStore.getHotelDoorLocks();
    }
  }, [hotelStore.currentHotel]);

  const isDesktop = useMediaQuery('(min-width: 1200px)');

  const updateCodesHandler = (selectedLock: IDoorCodes) => {
    setSelectedToUpdate(selectedLock);

    setUpdateCodesModalOpen(true);
  };

  return (
    <>
      {selectedToUpdate && (
        <UpdateCodesModal
          isOpen={updateCodesModalOpen}
          onClose={setUpdateCodesModalOpen.bind(this, false)}
          clearSelectedLock={setSelectedToUpdate.bind(this, null)}
          lock={selectedToUpdate}
        />
      )}
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <PageTitle>{'Door locks'}</PageTitle>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            gap={1}
          >
            <SelectHotel disabled={lockDevicesStore.isLoading} />
          </Grid>
        </Grid>

        <Card
          elevation={isDesktop ? 2 : 0}
          sx={{
            marginTop: 5,
            backgroundColor: {xs: 'transparent', lg: '#FFF'},
            overflow: 'visible',
          }}
        >
          <CardContent sx={{padding: {xs: 0, lg: 2}}}>
            <Typography variant="h5" component="h5" marginBottom={3}>
              {'Lock codes'}
            </Typography>

            {hotelStore.isLoading && <SkeletonLoader skeletonShape="table" />}

            {hotelStore.hotelDoorLocks &&
              hotelStore.hotelDoorLocks.length > 0 &&
              !hotelStore.isLoading && (
                <>
                  <Grid container display={{xs: 'none', lg: 'flex'}} mt={3}>
                    <Header lg={3}>{'Room number'}</Header>
                    <Header lg={2}>{'Code 1'}</Header>
                    <Header lg={2}>{'Code 2'}</Header>
                    <Header lg={2}>{'Code 3'}</Header>
                    <Header lg={2}>{'Actions'}</Header>
                  </Grid>
                  <Grid container gap={{xs: 5, lg: 0}}>
                    {hotelStore.hotelDoorLocks.map((lock, index) => {
                      const info = [
                        {
                          label: 'Room number',
                          value: lock.room_number,
                          lg: 3,
                          alignItems: 'center',
                        },
                        {
                          label: 'Code 1',
                          value: lock.code_1,
                          lg: 2,
                          alignItems: 'center',
                        },
                        {
                          label: 'Code 2',
                          value: lock.code_2,
                          lg: 2,
                          alignItems: 'center',
                        },
                        {
                          label: 'Code 3',
                          value: lock.code_3,
                          lg: 2,
                          alignItems: 'center',
                        },
                        {
                          label: t('common:actions'),
                          value: (
                            <Tooltip title="Edit codes">
                              <IconButton
                                aria-label="Edit codes"
                                color="primary"
                                onClick={updateCodesHandler.bind(this, lock)}
                              >
                                <ModeEditIcon />
                              </IconButton>
                            </Tooltip>
                          ),
                          lg: 2,
                          alignItems: 'center',
                        },
                      ];
                      return <CardRowItem key={index} item={info} />;
                    })}
                  </Grid>
                </>
              )}

            {hotelStore.hotelDoorLocks?.length === 0 && !hotelStore.isLoading && (
              <NoResultsFound message={'There are no door locks to show'} />
            )}
          </CardContent>
        </Card>
      </Container>
    </>
  );
});

export default DoorLocks;
