import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Box, IconButton, Stack, Typography} from '@mui/material';
import type Order from '../stores/order';
import type Guest from '../stores/guest';
import type Reservation from '../stores/reservation';
import type Orders from '../stores/inventory/orders/orders';
import type Products from '../stores/inventory/products';
import type StockCount from '../stores/inventory/stockCount';
import type LockDevices from '../stores/lockDevices';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {useRootStore} from '../context';

interface IPaginationProps {
  store: Order | Guest | Reservation | Orders | Products | StockCount | LockDevices;
  getter: (nextPreviousUrl: string | null) => void;
  watchers?: Array<unknown>;
}

const ListPagination: React.FC<IPaginationProps> = observer(({getter, store, watchers = []}) => {
  const {hotelStore} = useRootStore();
  const [currentPage, setCurrentPage] = useState(1);
  const nextVisibility = store.next ? 'visible' : 'hidden';
  const previousVisibility = store.previous ? 'visible' : 'hidden';

  useEffect(() => {
    setCurrentPage(1);
  }, [hotelStore.currentHotel, ...watchers]);

  const paginationChangeHandler = (event: React.SyntheticEvent) => {
    const id = event.currentTarget.id;

    if (id === 'next') {
      setCurrentPage((prevState) => prevState + 1);
      getter(store.next);
      return;
    }

    setCurrentPage((prevState) => prevState - 1);
    getter(store.previous);
  };

  return (
    <>
      <Box
        display={'flex'}
        flexDirection="column"
        justifyContent={'center'}
        alignItems="center"
        marginBottom={2}
      >
        <Stack display={'flex'} flexDirection="row" alignItems={'center'} marginY={1} gap={1}>
          <IconButton
            onClick={paginationChangeHandler}
            id="previous"
            sx={{visibility: previousVisibility}}
            disabled={store.isLoading}
          >
            <ChevronLeftIcon />
          </IconButton>

          <Typography
            sx={{
              backgroundColor: 'primary.main',
              borderRadius: 25,
              width: 40,
              height: 40,

              color: 'white',
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            {currentPage}
          </Typography>

          <IconButton
            onClick={paginationChangeHandler}
            id="next"
            sx={{visibility: nextVisibility}}
            disabled={store.isLoading}
          >
            <ChevronRightIcon />
          </IconButton>
        </Stack>
        <Typography>{`${store.count} results found`}</Typography>
      </Box>
    </>
  );
});

export default ListPagination;
