import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {toJS} from 'mobx';
import {toast} from 'react-toastify';
import * as Yup from 'yup';
import {FormikProps, useFormik} from 'formik';
import {useNavigate} from 'react-router-dom';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import {useRootStore} from '../../context';
import {
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  Grid,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import PageTitle from '../../components/PageTitle';
import SelectHotel from '../../components/Navbar/SelectHotel';
import Header from '../../components/table/Header';
import CardRowItem from '../../components/table/CardRowItem';
import {LoadingButton} from '@mui/lab';
import {useTranslation} from 'react-i18next';
import type {IGetStockResult} from '../../api/inventory/stock';
import type {IStorageResponseData} from '../../api/common';
import SkeletonLoader from '../../components/general/SkeletonLoader';
import NoResultsFound from '../../components/general/NoResultsFound';

export const MAX_QUANTITY = 300;

interface ITotals {
  lv1: number;
  lv2: number;
  lv3: number;
}
type TTotals = Record<string, ITotals>;

const StockCount = observer(() => {
  const {t} = useTranslation();
  const {stockStore, hotelStore, inventoryStore} = useRootStore();
  const navigate = useNavigate();
  const [yp, setYp] = useState({});
  const [total, setTotal] = useState<TTotals>({});

  const stockCountExists = () => {
    const d = new Date();
    const today = `${d.getUTCFullYear()}-${('0' + (d.getUTCMonth() + 1)).slice(-2)}-${(
      '0' + d.getUTCDate()
    ).slice(-2)}`;
    return today === inventoryStore.latestCount;
  };

  useEffect(() => {
    if (hotelStore.currentHotel !== undefined) {
      if (stockCountExists()) {
        navigate(`/inventory/edit-count/${inventoryStore.latestCount}`, {replace: true});
      } else {
        stockStore.getStock();
      }
    }
  }, [hotelStore.currentHotel]);

  useEffect(() => {
    if (stockStore.deleteSuccess) {
      stockStore.reset();
      navigate('/inventory/stock', {replace: true});
    }
  }, [stockStore.deleteSuccess]);

  useEffect(() => {
    if (stockStore.saveSuccess) {
      stockStore.reset();
      toast.success(t('stockCount:stock_saved_successfully'));
      navigate('/inventory/stock', {replace: true});
    }
  }, [stockStore.saveSuccess]);

  useEffect(() => {
    if (inventoryStore.units.length === 0) {
      inventoryStore.getUnits();
    }
  }, [inventoryStore.units]);

  useEffect(() => {
    if (inventoryStore.storages.length === 0) {
      inventoryStore.getStorages();
    }
  }, [inventoryStore.storages]);

  useEffect(() => {
    if (stockStore.stock.length > 0) {
      const stockData = toJS(stockStore.stock);
      const totals: TTotals = {};
      stockData.forEach((obj) => {
        totals[`total_${obj.id}`] = {lv1: 0, lv2: 0, lv3: 0};
        obj.levels.forEach((level) => {
          formik.initialValues[`${level.level.toLowerCase()}_${obj.id}`] = '';
          formik.setFieldValue(`${level.level.toLowerCase()}_${obj.id}`, '');
        });
      });
      setTotal(totals);

      const validate: Record<string, any> = {};
      stockData.forEach((obj) => {
        obj.levels.forEach((level) => {
          validate[`${level.level.toLowerCase()}_${obj.id}`] = Yup.number()
            .min(0, t('stockCount:minimum_0'))
            .max(MAX_QUANTITY, t('maximum_qty', {maxQty: MAX_QUANTITY}))
            .typeError(t('stockCount:please_enter_number'))
            .required(t('stockCount:number_required'));
        });
      });
      setYp(validate);
    }
  }, [stockStore.stock]);

  useEffect(() => {
    if (stockStore.conflict === true) {
      stockStore.reset();
      navigate('/inventory/stock', {replace: true});
    }
  }, [stockStore.conflict]);

  const updateTotal = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const tot = {...total};
    const value =
      parseInt(e.target.value) < 0
        ? 0
        : parseInt(e.target.value) > MAX_QUANTITY
        ? MAX_QUANTITY
        : parseInt(e.target.value);
    const segs = e.target.name.split('_');
    const amenity = stockStore.stock.find((obj) => {
      return obj.id === parseInt(segs[1]);
    });

    if (amenity) {
      let level = amenity.levels.find((obj) => {
        return obj.level === segs[0].toUpperCase();
      });
      level = toJS(level);

      if (level) {
        setTotal({
          ...tot,
          [`total_${segs[1]}`]: {
            ...tot[`total_${segs[1]}`],
            [segs[0]]: value * level.quantity,
          },
        });
        formik.handleChange(e);
      }
    }
  };

  const getTotal = (key: string) => {
    if (key in total) {
      return total[key].lv1 + total[key].lv2 + total[key].lv3;
    }
    return 0;
  };

  const handleDelete = (e: React.SyntheticEvent) => {
    e.preventDefault();
    stockStore.deleteStock();
  };

  const formik = useFormik({
    initialValues: {} as Record<string, any>,
    enableReinitialize: false,
    validationSchema: Yup.object().shape(yp),
    onSubmit: (values) => {
      const payload = Object.keys(total).map((key) => {
        const segs = key.split('_');
        return {
          id: parseInt(segs[1]),
          total: total[key].lv1 + total[key].lv2 + total[key].lv3,
          saved: true,
          storage_levels: {
            LV1: values[`lv1_${segs[1]}`],
            LV2: values[`lv2_${segs[1]}`],
            LV3: values[`lv3_${segs[1]}`],
          },
        };
      });
      stockStore.updateStock(payload);
    },
  });

  const isDesktop = useMediaQuery('(min-width: 1200px)');

  return (
    <Container>
      <PageTitle>{t('common:inventory_and_supplies')}</PageTitle>
      <SelectHotel />
      <Card
        elevation={isDesktop ? 2 : 0}
        sx={{
          backgroundColor: {xs: 'transparent', lg: 'background.paper'},
          marginTop: 5,
          overflow: 'visible',
        }}
      >
        <CardContent sx={{padding: {xs: 0, lg: 2}}}>
          <Stack gap={3} alignItems="start" marginY={2}>
            <Button
              variant="text"
              color="gray"
              onClick={() => {
                stockStore.deleteStock();
              }}
              startIcon={<ArrowBackOutlinedIcon />}
            >
              <Typography variant="h6" component="h2" fontWeight={600}>
                {t('stockCount:stock_count')}
              </Typography>
            </Button>
          </Stack>

          {stockStore.isLoading && <SkeletonLoader skeletonShape="table" />}
          {stockStore.stock.length > 0 && !stockStore.isLoading && (
            <FormControl component="form" onSubmit={formik.handleSubmit} sx={{display: 'block'}}>
              <Grid container display={{xs: 'none', lg: 'flex'}} className={'mt-4'}>
                {/* ------------------------ Headers ------------------------- */}
                <Header justifyContent="center" lg={1.25}>
                  {t('common:code')}
                </Header>
                <Header lg={1}>{t('common:unit')}</Header>
                <Header lg={1}>{t('common:est_stock')}</Header>
                <Header justifyContent="center" color="primary.main" lg={2}>
                  {t('common:LV1')}
                </Header>
                <Header justifyContent="center" color="success.main" lg={2}>
                  {t('common:LV2')}
                </Header>
                <Header justifyContent="center" color="warning.main" lg={2}>
                  {t('common:LV3')}
                </Header>
                <Header lg={1}>{t('common:total')}</Header>
                <Header justifyContent="center" lg={1.75}>
                  {t('stockCount:previous_base_consumption')}
                </Header>
              </Grid>
              {/* ------------------------ Headers ------------------------- */}
              <Grid container item xs={12} paddingX={1} gap={{xs: 5, lg: 0}}>
                {stockStore.stock.map((obj, index) => {
                  const info = [
                    {
                      label: isDesktop ? t('common:code') : obj.amenity.sku,
                      value: isDesktop ? obj.amenity.sku : obj.amenity.name,
                      lg: 1.25,
                      alignItems: 'center',
                    },
                    {
                      label: t('common:unit'),
                      value: obj.amenity.unit.name,
                      lg: 1,
                    },
                    {
                      label: t('common:est_stock'),
                      value: obj.est_stock,
                      lg: 1,
                    },
                    {
                      label: t('common:LV1'),
                      value: (
                        <LevelInput obj={obj} lv="LV1" updateTotal={updateTotal} formik={formik} />
                      ),
                      lg: 2,
                    },
                    {
                      label: t('common:LV2'),
                      value: (
                        <LevelInput obj={obj} lv="LV2" updateTotal={updateTotal} formik={formik} />
                      ),
                      lg: 2,
                    },
                    {
                      label: t('common:LV3'),
                      value: (
                        <LevelInput obj={obj} lv="LV3" updateTotal={updateTotal} formik={formik} />
                      ),
                      lg: 2,
                    },
                    {
                      label: t('common:total'),
                      value: getTotal(`total_${obj.id}`),
                      lg: 0.75,
                    },
                    {
                      label: t('stockCount:previous_base_consumption'),
                      value: obj.delta_count,
                      lg: 1.75,
                      alignItems: 'center',
                    },
                  ];

                  return <CardRowItem key={index} item={info} />;
                })}
              </Grid>
              <Stack flexDirection="row" justifyContent="space-between" marginTop={3}>
                <Button variant="contained" color="error" onClick={handleDelete}>
                  {t('common:delete')}
                </Button>

                {/*
              <Button variant="contained" color="warning" onClick={() => {formik.setTouched({}, false);}}>
                {t('Reset Errors')}
              </Button>
              */}

                <LoadingButton type="submit" loading={stockStore.isSaving} variant="contained">
                  {t('common:save')}
                </LoadingButton>
              </Stack>
            </FormControl>
          )}

          {stockStore.stock.length === 0 && !stockStore.isLoading && (
            <NoResultsFound message={t('common:there_are_no_items')} />
          )}
        </CardContent>
      </Card>
    </Container>
  );
});

export default StockCount;

interface ILevelInputProps {
  obj: IGetStockResult;
  lv: string;
  formik: FormikProps<Record<string, any>>;
  updateTotal: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
}

export const LevelInput: React.FC<ILevelInputProps> = ({obj, lv, formik, updateTotal}) => {
  const getFieldValue = (value: any) => {
    if (value === undefined || value === null) {
      return '';
    }
    return value;
  };

  const hasLevel = (levels: Array<IStorageResponseData>, level: string) => {
    return (
      undefined !==
      levels.find((obj) => {
        return obj.level === level;
      })
    );
  };

  const getStorage = (levels: Array<IStorageResponseData>, level: string) => {
    const l = levels.find((obj) => {
      return obj.level === level;
    });
    if (l !== undefined) {
      return l.storage.name;
    }
    return '';
  };

  const styles = {
    maxWidth: '75%',
    '& legend, & fieldset legend': {display: 'none !important'},
    '& .MuiFilledInput-input': {paddingTop: 0.5},
    '& .MuiFilledInput-root': {borderRadius: '4px'},
  };

  return (
    <TextField
      type="number"
      variant="filled"
      size="small"
      disabled={!hasLevel(obj.levels, lv)}
      name={`${lv.toLowerCase()}_${obj.id}`}
      placeholder={getStorage(obj.levels, lv)}
      value={getFieldValue(formik.values[`${lv.toLowerCase()}_${obj.id}`])}
      onChange={updateTotal}
      inputProps={{min: 0}}
      error={
        formik.touched[`${lv.toLowerCase()}_${obj.id}`] &&
        Boolean(formik.errors[`${lv.toLowerCase()}_${obj.id}`])
      }
      sx={styles}
    />
  );
};
