import React, {useEffect} from 'react';
import {BrowserRouter} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import type {GlobalStylesProps as StyledGlobalStylesProps} from '@mui/system';

import Navigation from './Navigation';
import {GlobalStyles, Theme} from '@mui/material';

const App: React.FC = observer(() => {
  const {t} = useTranslation();

  useEffect(() => {
    document.title = t('common:wayfarer_ops');
  });

  const styles: StyledGlobalStylesProps<Theme>['styles'] = {
    'html, body': {
      overflowX: 'hidden',
    },
    '*': {
      maxWidth: '100%',
    },
  };

  const globalStyles = <GlobalStyles styles={styles} />;

  return (
    <React.Fragment>
      {globalStyles}
      <HelmetProvider>
        <BrowserRouter>
          <Helmet prioritizeSeoTags={true}>
            <meta name={'description'} content={t('common:wayfarer_ops')} />
          </Helmet>
          <Navigation />
        </BrowserRouter>
      </HelmetProvider>
    </React.Fragment>
  );
});

export default App;
