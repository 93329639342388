import {Box, Typography, Stack, TextField, InputAdornment, IconButton} from '@mui/material';
import type {FormikProps} from 'formik';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import type {IFormikInitValues} from './Settings';

interface IUpdatePasswordProps {
  formik: FormikProps<IFormikInitValues>;
}

const UpdatePassword: React.FC<IUpdatePasswordProps> = ({formik}) => {
  const {t} = useTranslation();
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const passwordFields = [
    {
      name: 'password',
      show: showCurrentPassword,
      setVisibility: setShowCurrentPassword,
      label: t('settings:current_password'),
    },
    {
      name: 'new_password',
      show: showNewPassword,
      setVisibility: setShowNewPassword,
      label: t('settings:new_password'),
    },
    {
      name: 'confirm_password',
      show: showConfirmPassword,
      setVisibility: setShowConfirmPassword,
      label: t('settings:confirm_password'),
    },
  ];

  return (
    <Box display={'flex'} flexDirection="column">
      <Typography variant="h6">{t('settings:update_password')}</Typography>
      <Stack alignItems="start" gap={2} marginTop={1}>
        {passwordFields.map((field) => {
          const formikValueKey = field.name as keyof typeof formik.values;

          return (
            <TextField
              key={field.name}
              name={field.name}
              type={field.show ? 'text' : 'password'}
              autoComplete={field.name.replace('_', '-')}
              onChange={formik.handleChange}
              value={formik.values[formikValueKey]}
              label={field.label}
              fullWidth
              error={formik.touched[formikValueKey] && Boolean(formik.errors[formikValueKey])}
              helperText={formik.touched[formikValueKey] && formik.errors[formikValueKey]}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => field.setVisibility(!field.show)}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {field.show ? (
                        <VisibilityOff fontSize="large" />
                      ) : (
                        <Visibility fontSize="large" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          );
        })}
      </Stack>
    </Box>
  );
};

export default UpdatePassword;
