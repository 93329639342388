import request from 'superagent';
import Config from '../config';
import Common from './common';

export default {
  login: function (username: string, password: string) {
    const url = `${Config.API_URL}/account/login/`;

    const payload = JSON.stringify({
      username: username,
      password: password,
    });

    return request
      .post(url)
      .type(Common.MEDIA_TYPE)
      .accept(Common.MEDIA_TYPE)
      .send(payload);
  },

  logout: function (token: string) {
    const url = `${Config.API_URL}/account/logout/`;

    return request
      .delete(url)
      .type(Common.MEDIA_TYPE)
      .accept(Common.MEDIA_TYPE)
      .auth(token, {type: 'bearer'})
      .send();
  },

  getUser: function (token: string) {
    const url = `${Config.API_URL}/account/user/`;

    return request
      .get(url)
      .accept(Common.MEDIA_TYPE)
      .auth(token, {type: 'bearer'})
      .send();
  },
};
