import {makeObservable, action, observable, autorun, IReactionDisposer} from 'mobx';
import {StatusCodes} from 'http-status-codes';
import Api, {
  IDoorCodes,
  IGetHotelDoorLocksResponse,
  IGetHotelsResult,
  TCodes,
  TGetHotelsResponse,
} from '../api/hotel';
import type {IRootStore} from './rootStore';
import {toast} from 'react-toastify';
import {handleUnauthorizedResponse} from '../libs/utils';

export interface IHotel {
  rootStore: IRootStore;
  hotels: TGetHotelsResponse;
  currentHotel: IGetHotelsResult | undefined;
  hotelDoorLocks: Array<IDoorCodes> | null;
  isLoading: boolean;

  disposers: Array<IReactionDisposer> | IReactionDisposer;
  setHotels: (hotels: TGetHotelsResponse) => void;
  setCurrentHotel: (hotel: IGetHotelsResult) => void;
  setHotelDoorLocks: (doorLocks: Array<IDoorCodes>) => void;
  getHotelDoorLocks: () => void;
  loadCurrentHotel: () => void;
  setIsLoading: (isLoading: boolean) => void;
  loadHotels: () => void;
  updateDoorLocks: (lockId: number, codes: TCodes) => void;
}

class Hotel implements IHotel {
  rootStore;
  hotels: TGetHotelsResponse = [];
  currentHotel: IGetHotelsResult | undefined = undefined;
  hotelDoorLocks: Array<IDoorCodes> | null = null;
  isLoading = false;

  disposers: IReactionDisposer | IReactionDisposer[];

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      hotels: observable,
      currentHotel: observable,
      hotelDoorLocks: observable,
      isLoading: observable,

      loadHotels: action,
      setHotels: action,
      setCurrentHotel: action,
      setIsLoading: action,
      setHotelDoorLocks: action,
      loadCurrentHotel: action,
      getHotelDoorLocks: action,
      updateDoorLocks: action,
    });

    this.disposers = [
      autorun(() => {
        if (this.currentHotel === undefined) {
          this.loadCurrentHotel();
        }
      }),
    ];
  }

  setHotels(hotels: TGetHotelsResponse) {
    this.hotels = hotels;
  }

  setCurrentHotel(hotel: IGetHotelsResult) {
    window.sessionStorage.setItem('currentHotel', JSON.stringify(hotel));
    this.currentHotel = hotel;
  }

  loadCurrentHotel() {
    const currentHotel = window.sessionStorage.getItem('currentHotel');
    if (currentHotel !== null) {
      this.setCurrentHotel(JSON.parse(currentHotel));
    }
  }
  setHotelDoorLocks(doorLocks: Array<IDoorCodes>) {
    this.hotelDoorLocks = doorLocks;
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  // Async functions

  async loadHotels() {
    const token = this.rootStore.accountStore.user.token;
    if (token) {
      try {
        const response = await Api.getHotels(token);

        if (response.ok) {
          const data = await response;

          this.setHotels(data.body);
          if (this.currentHotel === undefined) {
            this.setCurrentHotel(data.body[0]);
          }
        }
      } catch (error: any) {
        if (error.status === StatusCodes.UNAUTHORIZED) {
          handleUnauthorizedResponse();
          return;
        }
      }
    }
  }

  async saveBuildingCode(buildingCode: string) {
    const currentHotel = this.rootStore.hotelStore.currentHotel;
    const token = this.rootStore.accountStore.user.token;

    if (token && currentHotel) {
      try {
        const response = await Api.saveBuildingCode(token, currentHotel.id, buildingCode);

        if (response.ok) {
          buildingCode === ''
            ? toast.success('Building code deleted!')
            : toast.success('Building code updated!');

          await this.loadHotels();

          const updatedHotel = this.hotels.find((hotel) => hotel.id === currentHotel.id);

          updatedHotel && this.setCurrentHotel(updatedHotel);
        }
      } catch (error: any) {
        toast.error(error.message);

        if (error.status === StatusCodes.UNAUTHORIZED) {
          handleUnauthorizedResponse();
          return;
        }
      }
    }
  }

  async getHotelDoorLocks() {
    const token = this.rootStore.accountStore.user.token;
    const hotelId = this.currentHotel?.id;

    if (token && hotelId) {
      try {
        this.setIsLoading(true);

        const response = await Api.getHotelDoorLocks(token, hotelId);

        if (response.ok) {
          const data: IGetHotelDoorLocksResponse = await response.body;

          this.setHotelDoorLocks(data.results);
        }
      } catch (error: any) {
        if (error.status === StatusCodes.UNAUTHORIZED) {
          handleUnauthorizedResponse();
          return;
        }
      } finally {
        this.setIsLoading(false);
      }
    }
  }

  async updateDoorLocks(lockId: number, codes: TCodes) {
    const token = this.rootStore.accountStore.user.token;
    const hotelId = this.currentHotel?.id;

    if (token && hotelId && lockId && codes) {
      try {
        this.setIsLoading(true);

        const response = await Api.updateHotelDoorLocks(token, lockId, codes);

        if (response.ok) {
          this.getHotelDoorLocks();
          return response;
        }
      } catch (error: any) {
        if (error.status === StatusCodes.UNAUTHORIZED) {
          handleUnauthorizedResponse();
          return;
        }
      } finally {
        this.setIsLoading(false);
      }
    }
  }
}

export default Hotel;
