import React, {useState, useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {saveAs} from 'file-saver';
import {useRootStore} from '../../context';
import LinenAction from '../../libs/linenAction';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import LoadingButton from '@mui/lab/LoadingButton';
import {Divider, useTheme} from '@mui/material';
import {useTranslation} from 'react-i18next';
import type {TransitionProps} from '@mui/material/transitions';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SuccessModal = observer(() => {
  const {t} = useTranslation();
  const {orderStore, linenStore, hotelStore} = useRootStore();
  const [showModal, setShowModal] = useState(false);
  const theme = useTheme();

  const handleCloseModal = (e: React.SyntheticEvent) => {
    e.preventDefault();
    orderStore.reset();
    linenStore.setCurrentAction(LinenAction.VIEW_ORDERS);
  };

  const handleDownload = (e: React.MouseEvent) => {
    e.preventDefault();
    orderStore.downloadOrder(orderStore.downloadURL ?? '');
  };

  useEffect(() => {
    if (orderStore.blob !== undefined) {
      const created = new Date(orderStore.details.order_date);
      const month = created.getMonth() + 1;
      const day = created.getDate();
      saveAs(
        orderStore.blob,
        `リネン発注の件${month}月${day}日 - ${hotelStore.currentHotel?.short_name}.pdf`,
      );
      orderStore.reset();
      linenStore.setCurrentAction(LinenAction.VIEW_ORDERS);
    }
  }, [orderStore.blob]);

  useEffect(() => {
    if (orderStore.downloadURL !== undefined) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [orderStore.downloadURL]);

  return (
    <>
      <Dialog
        open={showModal}
        keepMounted
        TransitionComponent={Transition}
        onClose={handleCloseModal}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle textAlign="center">{t('common:order_completed')}</DialogTitle>
        <Divider></Divider>
        <DialogActions>
          <Button
            onClick={handleCloseModal}
            disabled={orderStore.isDownloading}
            color="gray"
            variant="contained"
          >
            {t('common:close')}
          </Button>
          <LoadingButton
            loadingIndicator="Downloading..."
            onClick={handleDownload}
            loading={orderStore.isDownloading}
            variant="contained"
            sx={{background: theme.palette.gray.dark}}
          >
            {t('common:download_order')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
});

export default SuccessModal;
