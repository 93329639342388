import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {ToastContainer} from 'react-toastify';
import reportWebVitals from './reportWebVitals';
import {RootStateProvider} from './context';
import 'react-toastify/dist/ReactToastify.css';
import {ThemeConfig} from './config/theme.config';
import './i18n';

ReactDOM.render(
  <React.StrictMode>
    <RootStateProvider>
      <ThemeConfig>
        <Suspense fallback={<div>Loading...</div>}>
          <App />
        </Suspense>
        <ToastContainer />
      </ThemeConfig>
    </RootStateProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
