import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {useRootStore} from '../../context';
import {
  Box,
  Card,
  CardContent,
  Container,
  FormControl,
  FormHelperText,
  Stack,
  useMediaQuery,
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import PageTitle from '../../components/PageTitle';
import ChangeLanguageSwitch from '../../components/ChangeLanguage';
import ManageProfileImage from './ManageProfileImage';
import SubmitButton from './SubmitButton';
import UpdatePassword from './UpdatePassword';
import UpdateField from './UpdateField';

export interface IFormikInitValues {
  password: string;
  new_password: string;
  confirm_password: string;
  username: string;
  email: string;
  first_name: string;
  last_name: string;
}

const Settings = observer(() => {
  const {t} = useTranslation();
  const {settingsStore, accountStore} = useRootStore();
  const [displayError, setDisplayError] = useState('d-none');
  const [timeoutID, setTimeoutID] = useState<NodeJS.Timeout>();

  useEffect(() => {
    formik.setSubmitting(settingsStore.inProgress);
  }, [settingsStore.inProgress]);

  useEffect(() => {
    if (settingsStore.error) {
      setDisplayError('d-flex');
      setTimeoutID(
        setTimeout(() => {
          settingsStore.setError(undefined);
        }, 5000),
      );
    } else {
      setDisplayError('d-none');
    }
  }, [settingsStore.error]);

  useEffect(() => {
    if (settingsStore.success) {
      formik.setFieldValue('password', '', false);
      formik.setFieldValue('new_password', '', false);
      formik.setFieldValue('confirm_password', '', false);
      if (accountStore.user.username !== undefined) {
        formik.setFieldValue('username', accountStore.user.username, false);
      }
      if (accountStore.user.email !== undefined) {
        formik.setFieldValue('email', accountStore.user.email, false);
      }
      if (accountStore.user.first_name !== undefined) {
        formik.setFieldValue('first_name', accountStore.user.first_name, false);
      }
      if (accountStore.user.last_name !== undefined) {
        formik.setFieldValue('last_name', accountStore.user.last_name, false);
      }
    }
  }, [settingsStore.success]);

  const SettingsSchema = Yup.object().shape(
    {
      password: Yup.string()
        .ensure()
        .when(['new_password', 'confirm_password'], {
          is: (new_password: string, confirm_password: string) =>
            new_password !== '' || confirm_password !== '',
          then: (SettingsSchema) => SettingsSchema.required(t('settings:password_required')),
        }),
      new_password: Yup.string()
        .ensure()
        .when(['password', 'confirm_password'], {
          is: (password: string, confirm_password: string) =>
            password !== '' || confirm_password !== '',
          then: (SettingsSchema) =>
            SettingsSchema.required(t('settings:new_password_required'))
              .min(8, t('settings:password_cannot_be_shorter_than'))
              .notOneOf([Yup.ref('password')], t('settings:new_password_cannot_be_same_as')),
        }),
      confirm_password: Yup.string()
        .ensure()
        .when(['password', 'new_password'], {
          is: (password: string, new_password: string) => password !== '' || new_password !== '',
          then: (SettingsSchema) =>
            SettingsSchema.required(t('settings:confirm_password_required')).oneOf(
              [Yup.ref('new_password')],
              t('settings:new_password_confirm_password_must_match'),
            ),
        }),
      username: Yup.string()
        .trim()
        .ensure()
        .required(t('settings:username_required'))
        .min(3, t('settings:username_cannot_be_shorter_than')),
      email: Yup.string()
        .trim()
        .ensure()
        .required(t('settings:email_required'))
        .email(t('settings:invalid_email')),
      first_name: Yup.string().trim().ensure(),
      last_name: Yup.string().trim().ensure(),
    },
    [
      ['password', 'new_password'],
      ['password', 'confirm_password'],
      ['new_password', 'confirm_password'],
    ],
  );

  const formik = useFormik({
    initialValues: {
      password: '',
      new_password: '',
      confirm_password: '',
      username: '',
      email: '',
      first_name: '',
      last_name: '',
    },
    validationSchema: SettingsSchema,
    onSubmit: (values) => {
      if (timeoutID !== undefined) {
        clearTimeout(timeoutID);
        setTimeoutID(undefined);
      }
      settingsStore.setError(undefined);
      if (
        values.password === '' &&
        values.new_password === '' &&
        values.confirm_password === '' &&
        values.username === accountStore.user.username &&
        values.email === accountStore.user.email &&
        values.first_name === accountStore.user.first_name &&
        values.last_name === accountStore.user.last_name
      ) {
        settingsStore.setChanged(false);
      } else {
        settingsStore.updateSettings(values);
      }
    },
  });

  useEffect(() => {
    if (accountStore.user.username !== undefined) {
      formik.setFieldValue('username', accountStore.user.username, false);
    }
    if (accountStore.user.email !== undefined) {
      formik.setFieldValue('email', accountStore.user.email, false);
    }
    if (accountStore.user.first_name !== undefined) {
      formik.setFieldValue('first_name', accountStore.user.first_name, false);
    }
    if (accountStore.user.last_name !== undefined) {
      formik.setFieldValue('last_name', accountStore.user.last_name, false);
    }
  }, [
    accountStore.user.username,
    accountStore.user.email,
    accountStore.user.first_name,
    accountStore.user.last_name,
  ]);

  // If no field has changed, disable update button by setting change to false
  useEffect(() => {
    if (
      formik.values.password === '' &&
      formik.values.new_password === '' &&
      formik.values.confirm_password === '' &&
      formik.values.username === accountStore.user.username &&
      formik.values.email === accountStore.user.email &&
      formik.values.first_name === accountStore.user.first_name &&
      formik.values.last_name === accountStore.user.last_name
    ) {
      settingsStore.setChanged(false);
    } else {
      settingsStore.setChanged(true);
    }
  }, [formik.values]);

  const handleUploadProfileImage = (event: any) => {
    if (event.target.files.length) {
      if (accountStore.user.profile_image === undefined) {
        if (accountStore.token)
          settingsStore.createProfileImage(event.target.name, event.target.files[0]);
      } else {
        if (accountStore.token)
          settingsStore.updateProfileImage(event.target.name, event.target.files[0]);
      }

      event.target.value = null;
    }
  };

  const handleDeleteProfileImage = (event: React.MouseEvent) => {
    event.preventDefault();
    if (accountStore.user.token) settingsStore.deleteProfileImage();
  };

  const isMobile = useMediaQuery('(max-width: 600px)');

  return (
    <Container>
      <PageTitle>{t('settings:user_settings')}</PageTitle>
      <Card>
        <CardContent>
          <form onSubmit={formik.handleSubmit}>
            {/* --------------------- Update image ----------------------- */}
            <FormControl fullWidth>
              <ManageProfileImage
                handleDelete={handleDeleteProfileImage}
                handleUpload={handleUploadProfileImage}
              />
              <FormHelperText
                error={true}
                sx={{textAlign: {xs: 'start'}, fontSize: '1rem', marginBlock: 3}}
              >
                {settingsStore.error}
              </FormHelperText>
            </FormControl>
            {/* --------------------- Update image ----------------------- */}
            {/* --------------------- Update Name ----------------------- */}
            <Stack gap={5} sx={{width: {xs: '100%', md: '75%'}}}>
              <ChangeLanguageSwitch />
              <UpdatePassword formik={formik} />
              <Stack flexDirection={isMobile ? 'column' : 'row'} gap={{xs: 3}}>
                <UpdateField
                  name="first_name"
                  label={t('settings:update_first_name')}
                  placeholder={t('settings:first_name')}
                  formik={formik}
                />
                <UpdateField
                  name="last_name"
                  label={t('settings:update_last_name')}
                  placeholder={t('settings:last_name')}
                  formik={formik}
                />
              </Stack>
              {/* --------------------- Update Name ----------------------- */}
              {/* ------------------ Update user/email --------------------- */}
              <Stack flexDirection={isMobile ? 'column' : 'row'} gap={{xs: 3}}>
                <UpdateField
                  name="username"
                  label={t('settings:update_username')}
                  placeholder={t('settings:username')}
                  formik={formik}
                />
                <UpdateField
                  name="email"
                  label={t('settings:update_email')}
                  placeholder={t('settings:email')}
                  formik={formik}
                />
              </Stack>
              {/* ------------------ Update user/email --------------------- */}
              <Box sx={{alignSelf: 'end'}}>
                <SubmitButton />
              </Box>
            </Stack>
          </form>
        </CardContent>
      </Card>
    </Container>
  );
});

export default Settings;
