import {StatusCodes} from 'http-status-codes';
import {makeObservable, action, observable, autorun, toJS, IReactionDisposer} from 'mobx';
import {toast} from 'react-toastify';
import i18n from '../../../i18n';
import Api, {ICreateProductPayload} from '../../../api/inventory/product';
import type RootStore from '../../rootStore';
import Edit from './editProduct';
import {handleUnauthorizedResponse} from '../../../libs/utils';

export const ADD_ITEM_STEP_1 = 'step1';
export const ADD_ITEM_STEP_2 = 'step2';
export const ADD_ITEM_STEP_3 = 'step3';
export const ADD_ITEM_STEPS = [ADD_ITEM_STEP_1, ADD_ITEM_STEP_2, ADD_ITEM_STEP_3];

interface IProductLevelSessionStorage {
  amenity_id: number;
  storage: number;
  quantity: number;
  image: string;
}

export interface IProductPurchaseSessionStorage {
  vendor: number;
  price: number;
  reference: string;
  delivery: number;
  info: string;
}

export interface IAmenitySessionStorage {
  sku: string;
  consumption_tax: number;
  name: string;
  brand: number;
  category: number;
  unit: string;
  hotel: Array<number>;
}

export type TProductLevel = 'lv1' | 'lv2' | 'lv3';

type TLevelsSessionStorage = Array<TProductLevel>;

interface IProduct {
  rootStore: RootStore;
  edit: Edit;
  steps: Record<string, boolean>;
  levels: TLevelsSessionStorage;
  isSubmitting: boolean;
  success: boolean | undefined;
  amenity: IAmenitySessionStorage;
  purchase: IProductPurchaseSessionStorage;
  lv1: IProductLevelSessionStorage;
  lv2: IProductLevelSessionStorage;
  lv3: IProductLevelSessionStorage;
  disposers: IReactionDisposer | IReactionDisposer[];

  enableStep: (step: string) => void;
  currentStep: () => void;
  setStep: (step: string, completed: boolean) => void;
  setSteps: (steps: Record<string, boolean>, save: boolean) => void;
  resetSteps: () => void;
  setIsSubmitting: (isSubmitting: boolean) => void;
  setSuccess: (isSuccess: boolean | undefined) => void;
  setLevels: (levels: TLevelsSessionStorage, save: boolean) => void;
  setAmenity: (amenity: IAmenitySessionStorage, save: boolean) => void;
  setPurchase: (purchase: IProductPurchaseSessionStorage, save: boolean) => void;
  setLV1: (lv1Data: IProductLevelSessionStorage, save: boolean) => void;
  setLV2: (lv2Data: IProductLevelSessionStorage, save: boolean) => void;
  setLV3: (lv3Data: IProductLevelSessionStorage, save: boolean) => void;
  resetLevels: () => void;
  resetAmenity: () => void;
  resetPurchase: () => void;
  resetLV1: () => void;
  resetLV2: () => void;
  resetLV3: () => void;
  reset: () => void;
  getLevel: (level: TProductLevel) => IProductLevelSessionStorage;
  setLevel: (level: TProductLevel, value: IProductLevelSessionStorage) => void;
  createProduct: () => void;
}

class Product implements IProduct {
  rootStore;
  edit;
  steps: Record<string, boolean> = {
    [ADD_ITEM_STEP_1]: false,
    [ADD_ITEM_STEP_2]: false,
    [ADD_ITEM_STEP_3]: false,
  };
  levels: TLevelsSessionStorage = [];
  isSubmitting = false;
  success: boolean | undefined = undefined;
  amenity: IAmenitySessionStorage = {
    sku: '',
    consumption_tax: 10,
    name: '',
    brand: 0,
    category: 0,
    unit: '',
    hotel: [],
  };
  purchase: IProductPurchaseSessionStorage = {
    vendor: 0,
    price: 0,
    reference: '',
    delivery: 0,
    info: '',
  };
  lv1: IProductLevelSessionStorage = {
    amenity_id: 0,
    storage: 0,
    quantity: 0,
    image: '',
  };
  lv2: IProductLevelSessionStorage = {
    amenity_id: 0,
    storage: 0,
    quantity: 0,
    image: '',
  };
  lv3: IProductLevelSessionStorage = {
    amenity_id: 0,
    storage: 0,
    quantity: 0,
    image: '',
  };
  disposers: IReactionDisposer | IReactionDisposer[];

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.edit = new Edit(rootStore);

    makeObservable(this, {
      edit: observable,
      steps: observable,
      levels: observable,
      isSubmitting: observable,
      success: observable,
      amenity: observable,
      purchase: observable,
      lv1: observable,
      lv2: observable,
      lv3: observable,
      enableStep: action,
      currentStep: action,
      setStep: action,
      setSteps: action,
      resetSteps: action,
      setIsSubmitting: action,
      setSuccess: action,
      setLevels: action,
      setAmenity: action,
      setPurchase: action,
      setLV1: action,
      setLV2: action,
      setLV3: action,
      resetLevels: action,
      resetAmenity: action,
      resetPurchase: action,
      resetLV1: action,
      resetLV2: action,
      resetLV3: action,
      getLevel: action,
      setLevel: action,
      reset: action,
      createProduct: action,
    });

    this.disposers = [
      autorun(() => {
        this.loadSteps();
      }),

      autorun(() => {
        this.loadLevels();
      }),

      autorun(() => {
        if (this.amenity.sku === '') {
          this.loadAmenity();
        }
      }),

      autorun(() => {
        if (this.purchase.price === 0) {
          this.loadPurchase();
        }
      }),

      autorun(() => {
        if (this.lv1.storage === 0) {
          this.loadLV1();
        }
      }),

      autorun(() => {
        if (this.lv2.storage === 0) {
          this.loadLV2();
        }
      }),

      autorun(() => {
        if (this.lv3.storage === 0) {
          this.loadLV3();
        }
      }),
    ];
  }

  enableStep(step: string) {
    if (ADD_ITEM_STEPS.includes(step) === false) {
      return false;
    }
    if (step === ADD_ITEM_STEP_1) return true;

    const steps = toJS(this.steps);
    if (step === ADD_ITEM_STEP_2 && steps[ADD_ITEM_STEP_1] === true) return true;
    return !!(step === ADD_ITEM_STEP_3 && steps[ADD_ITEM_STEP_2] === true);
  }

  currentStep() {
    const steps = toJS(this.steps);
    if (steps[ADD_ITEM_STEP_2] === true) {
      return ADD_ITEM_STEP_3;
    }
    if (steps[ADD_ITEM_STEP_1] === true) {
      return ADD_ITEM_STEP_2;
    }
    return ADD_ITEM_STEP_1;
  }

  setStep(step: string, completed: boolean) {
    const steps = toJS(this.steps);
    if (Object.keys(steps).includes(step)) {
      steps[step] = completed;
    }
    this.setSteps(steps);
  }

  setSteps(steps: Record<string, boolean>, save = true) {
    this.steps = steps;
    if (save) {
      window.sessionStorage.setItem('steps', JSON.stringify(steps));
    }
  }

  loadSteps() {
    const data = window.sessionStorage.getItem('steps');
    if (data) {
      try {
        const steps = JSON.parse(data);
        this.setSteps(steps, false);
      } catch (e) {
        // do nothing
      }
    }
  }

  resetSteps() {
    window.sessionStorage.removeItem('steps');
    this.setSteps(
      {
        [ADD_ITEM_STEP_1]: false,
        [ADD_ITEM_STEP_2]: false,
        [ADD_ITEM_STEP_3]: false,
      },
      false,
    );
  }

  setIsSubmitting(isSubmitting: boolean) {
    this.isSubmitting = isSubmitting;
  }

  setSuccess(isSuccess: boolean | undefined) {
    this.success = isSuccess;
  }

  setLevels(levels: TLevelsSessionStorage, save = true) {
    this.levels = levels;
    if (save) {
      window.sessionStorage.setItem('levels', JSON.stringify(levels));
    }
  }

  loadLevels() {
    const data = window.sessionStorage.getItem('levels');
    if (data) {
      try {
        const levels = JSON.parse(data);
        this.setLevels(levels, false);
      } catch (e) {
        // do nothing
      }
    }
  }

  resetLevels() {
    window.sessionStorage.removeItem('levels');
    this.setLevels([], false);
  }

  setAmenity(amenity: IAmenitySessionStorage, save = true) {
    this.amenity = amenity;
    if (save) {
      window.sessionStorage.setItem('amenity', JSON.stringify(amenity));
    }
  }

  loadAmenity() {
    const data = window.sessionStorage.getItem('amenity');
    if (data) {
      try {
        const amenity = JSON.parse(data);
        this.setAmenity(amenity, false);
      } catch (e) {
        // do nothing
      }
    }
  }

  resetAmenity() {
    window.sessionStorage.removeItem('amenity');
    this.setAmenity(
      {
        sku: '',
        consumption_tax: 10,
        name: '',
        brand: 0,
        category: 0,
        unit: '',
        hotel: [],
      },
      false,
    );
  }

  setPurchase(purchase: IProductPurchaseSessionStorage, save = true) {
    this.purchase = purchase;
    if (save) {
      window.sessionStorage.setItem('purchase', JSON.stringify(purchase));
    }
  }

  loadPurchase() {
    const data = window.sessionStorage.getItem('purchase');
    if (data) {
      try {
        const purchase = JSON.parse(data);
        this.setPurchase(purchase, false);
      } catch (e) {
        // do nothing
      }
    }
  }

  resetPurchase() {
    window.sessionStorage.removeItem('purchase');
    this.setPurchase(
      {
        vendor: 0,
        price: 0,
        reference: '',
        delivery: 0,
        info: '',
      },
      false,
    );
  }

  setLV1(lv1Data: IProductLevelSessionStorage, save = true) {
    this.lv1 = lv1Data;
    if (save) {
      window.sessionStorage.setItem('lv1', JSON.stringify(lv1Data));
    }
  }

  loadLV1() {
    const data = window.sessionStorage.getItem('lv1');
    if (data) {
      try {
        const lv1 = JSON.parse(data);
        this.setLV1(lv1, false);
      } catch (e) {
        // do nothing
      }
    }
  }

  resetLV1() {
    window.sessionStorage.removeItem('lv1');
    this.setLV1(
      {
        amenity_id: 0,
        storage: 0,
        quantity: 0,
        image: '',
      },
      false,
    );
  }

  setLV2(lv2Data: IProductLevelSessionStorage, save = true) {
    this.lv2 = lv2Data;
    if (save) {
      window.sessionStorage.setItem('lv2', JSON.stringify(lv2Data));
    }
  }

  loadLV2() {
    const data = window.sessionStorage.getItem('lv2');
    if (data) {
      try {
        const lv2 = JSON.parse(data);
        this.setLV2(lv2, false);
      } catch (e) {
        // do nothing
      }
    }
  }

  resetLV2() {
    window.sessionStorage.removeItem('lv2');
    this.setLV2(
      {
        amenity_id: 0,
        storage: 0,
        quantity: 0,
        image: '',
      },
      false,
    );
  }

  setLV3(lv3Data: IProductLevelSessionStorage, save = true) {
    this.lv3 = lv3Data;
    if (save) {
      window.sessionStorage.setItem('lv3', JSON.stringify(lv3Data));
    }
  }

  loadLV3() {
    const data = window.sessionStorage.getItem('lv3');
    if (data) {
      try {
        const lv3 = JSON.parse(data);
        this.setLV3(lv3, false);
      } catch (e) {
        // do nothing
      }
    }
  }

  resetLV3() {
    window.sessionStorage.removeItem('lv3');
    this.setLV3(
      {
        amenity_id: 0,
        storage: 0,
        quantity: 0,
        image: '',
      },
      false,
    );
  }

  getLevel(level: TProductLevel) {
    return this[level];
  }

  setLevel(level: TProductLevel, value: IProductLevelSessionStorage) {
    switch (level) {
      case 'lv1':
        this.setLV1(value);
        break;
      case 'lv2':
        this.setLV2(value);
        break;
      case 'lv3':
        this.setLV3(value);
        break;
      default:
        break;
    }
  }

  reset() {
    this.resetAmenity();
    this.resetPurchase();
    this.resetLV1();
    this.resetLV2();
    this.resetLV3();
    this.resetLevels();
    this.setSuccess(undefined);
    this.resetSteps();
  }

  async createProduct() {
    const product: ICreateProductPayload = {
      amenity: toJS(this.amenity),
      purchase: toJS(this.purchase),
      lv1: {
        level: 'LV1',
        ...toJS(this.lv1),
      },
    };

    if (this.levels.includes('lv2')) {
      product['lv2'] = {
        level: 'LV2',
        ...toJS(this.lv2),
      };
    }

    if (this.levels.includes('lv3')) {
      product['lv3'] = {
        level: 'LV3',
        ...toJS(this.lv3),
      };
    }

    if (!product.lv1.image) {
      delete product.lv1.image;
    }

    this.levels.forEach((level: 'lv1' | 'lv2' | 'lv3') => {
      if (!product[level]?.image) {
        delete product[level]?.image;
      }
    });

    const token = this.rootStore.accountStore.user.token;

    if (token) {
      try {
        this.setIsSubmitting(true);

        const response = await Api.createProduct(token, product);

        if (response.ok) {
          toast.success(i18n.t('Product Saved.') as string);
          this.setSuccess(true);
        }
      } catch (error: any) {
        if (error.status === StatusCodes.UNAUTHORIZED) {
          handleUnauthorizedResponse();
          return;
        }
        let errors = [];
        for (const key in error.response.body) {
          errors = error.response.body[key];
        }
        if (errors.length > 0) {
          toast.error(errors[0]);
        } else {
          toast.error(error.message);
        }
      } finally {
        this.setIsSubmitting(false);
      }
    }
  }
}

export default Product;
