import Account from './account';
import Settings from './settings';
import Hotel from './hotel';
import Order from './order';
import Linen from './linen';
import Vendor from './inventory/vendor';
import Inventory from './inventory/inventory';
import Product from './inventory/product/product';
import Products from './inventory/products';
import Stock from './inventory/stock';
import StockCount from './inventory/stockCount';
import Orders from './inventory/orders/orders';
import Reservation from './reservation';
import LockDevices from './lockDevices';
import Guest from './guest';

export interface IRootStore {
  accountStore: Account;
  settingsStore: Settings;
  hotelStore: Hotel;
  orderStore: Order;
  linenStore: Linen;
  vendorStore: Vendor;
  inventoryStore: Inventory;
  productStore: Product;
  productsStore: Products;
  stockStore: Stock;
  ordersStore: Orders;
  reservationsStore: Reservation;
  lockDevicesStore: LockDevices;
  stockCountStore: StockCount;
  guestStore: Guest;
}

class RootStore implements IRootStore {
  accountStore: Account;
  settingsStore: Settings;
  hotelStore: Hotel;
  orderStore: Order;
  linenStore: Linen;
  vendorStore: Vendor;
  inventoryStore: Inventory;
  productStore: Product;
  productsStore: Products;
  stockStore: Stock;
  ordersStore: Orders;
  reservationsStore: Reservation;
  lockDevicesStore: LockDevices;
  stockCountStore: StockCount;
  guestStore: Guest;

  constructor() {
    this.accountStore = new Account(this);
    this.settingsStore = new Settings(this);
    this.hotelStore = new Hotel(this);
    this.orderStore = new Order(this);
    this.linenStore = new Linen(this);
    this.vendorStore = new Vendor(this);
    this.inventoryStore = new Inventory(this);
    this.productStore = new Product(this);
    this.productsStore = new Products(this);
    this.stockStore = new Stock(this);
    this.ordersStore = new Orders(this);
    this.reservationsStore = new Reservation(this);
    this.lockDevicesStore = new LockDevices(this);
    this.stockCountStore = new StockCount(this);
    this.guestStore = new Guest(this);
  }
}

export default RootStore;
