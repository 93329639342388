import React, {useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import '../../styles/main.css';
import Header from '../table/Header';
import CardRowItem from '../table/CardRowItem';
import {getML, getPrice} from '../../libs/utils';
import {useRootStore} from '../../context';
import {toast} from 'react-toastify';
import {
  PAGE_CREATE_ORDER,
  ORDER_STATUS_FAILED,
  ORDER_STATUS_PROGRESS,
  ORDER_STATUS_COMPLETED,
} from '../../stores/inventory/orders/orders';
import SelectHotel from '../Navbar/SelectHotel';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import DatePicker from '../DatePicker';
import {DateTime} from 'luxon';
import {saveAs} from 'file-saver';
import {LoadingButton} from '@mui/lab';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import {Box} from '@mui/system';
import {useTranslation} from 'react-i18next';
import NoResultsFound from '../general/NoResultsFound';
import SkeletonLoader from '../general/SkeletonLoader';
import ListPagination from '../ListPagination';

interface IStatusSelectProps {
  value: string;
  handleChange: (e: SelectChangeEvent<string>) => void;
  isFilter?: boolean;
}

const StatusSelect: React.FC<IStatusSelectProps> = ({handleChange, value, isFilter}) => {
  const {t} = useTranslation();

  const selectStyles = {
    width: {xs: '80%', sm: '30%', md: '50%', lg: '100%'},
    marginLeft: 'auto',
    '& .MuiSelect-select': {padding: '0.2rem 1rem 0.25rem'},
    '& legend': {display: 'none'},
  };

  const filterStyles = {
    '& legend': {width: '6rem'},
  };

  return (
    <FormControl fullWidth>
      {isFilter && (
        <InputLabel id="select-label" size="small">
          Filter by Status
        </InputLabel>
      )}

      <Select
        labelId="select-label"
        value={value}
        label={t('common:status')}
        onChange={handleChange}
        sx={isFilter ? filterStyles : selectStyles}
        size="small"
      >
        {isFilter && <MenuItem value={''}>{t('listOrders:no_filter')}</MenuItem>}
        <MenuItem value={ORDER_STATUS_PROGRESS}>{t('listOrders:in_progress')}</MenuItem>
        <MenuItem value={ORDER_STATUS_COMPLETED}>{t('listOrders:completed')}</MenuItem>
        <MenuItem value={ORDER_STATUS_FAILED}>{t('listOrders:failed')}</MenuItem>
      </Select>
    </FormControl>
  );
};

const ListOrders = observer(() => {
  const {t} = useTranslation();
  const {hotelStore, ordersStore} = useRootStore();

  useEffect(() => {
    if (hotelStore.currentHotel !== undefined) {
      ordersStore.getOrders();
    }
  }, [
    hotelStore.currentHotel,
    ordersStore.updateSuccess,
    ordersStore.cancelSuccess,
    ordersStore.dateTo,
    ordersStore.dateFrom,
    ordersStore.filterState,
  ]);

  useEffect(() => {
    if (ordersStore.amenities.length > 0) {
      ordersStore.orderOrders();
    }
  }, [ordersStore.order]);

  useEffect(() => {
    if (ordersStore.updateSuccess) {
      toast.success(t('listOrders:order_updated'));
      ordersStore.setUpdateSuccess(false);
    }
  }, [ordersStore.updateSuccess]);

  useEffect(() => {
    if (ordersStore.cancelSuccess) {
      toast.success(t('listOrders:order_canceled'));
      ordersStore.setCancelSuccess(false);
    }
  }, [ordersStore.cancelSuccess]);

  useEffect(() => {
    if (ordersStore.exportBlob !== undefined) {
      const todayDate = DateTime.now().toFormat('yyyy-MM-dd');
      const filename = `${hotelStore.currentHotel?.short_name} previous-orders-counts-${todayDate}.xlsx`;
      saveAs(ordersStore.exportBlob, filename);
      ordersStore.setExportBlob(undefined);
    }
  }, [ordersStore.exportBlob]);

  const handleStatusChange = (e: SelectChangeEvent<string>, id: number) => {
    e.preventDefault();
    ordersStore.updateOrder({
      id: id,
      state: e.target.value,
    });
  };

  const handleCancelOrder = async (e: React.SyntheticEvent, id: number) => {
    e.preventDefault();
    ordersStore.cancelOrder(id);
  };

  const handleClearDates = (event: React.SyntheticEvent) => {
    event.preventDefault();
    ordersStore.setDateFrom(null);
    ordersStore.setDateTo(null);
  };

  const handleExport = (event: React.SyntheticEvent) => {
    event.preventDefault();
    ordersStore.exportOrders();
  };

  const handleSetAscendingOrder = (event: React.SyntheticEvent) => {
    event.preventDefault();
    ordersStore.setOrder('asc');
    toast.success(t('common:updated_list_ascending_order'));
  };

  const handleSetDescendingOrder = (event: React.SyntheticEvent) => {
    event.preventDefault();
    ordersStore.setOrder('desc');
    toast.success(t('common:updated_list_descending_order'));
  };

  const handleFilterByStatus = (event: SelectChangeEvent<string>) => {
    event.preventDefault();

    ordersStore.setFilterState(event.target.value);
  };

  const isDesktop = useMediaQuery('(min-width: 1200px)');

  const createOrderClickHandler = () => {
    const previousOrderProducts = window.sessionStorage.getItem('order-products');

    if (previousOrderProducts) {
      window.sessionStorage.removeItem('order-products');
      toast.warn(t('createOrder:previous_order_items_deleted'));
    }

    ordersStore.setPage(PAGE_CREATE_ORDER);
  };

  return (
    <>
      <Grid container alignItems="center" spacing={3}>
        {/* ----------------------------- Top ------------------------------ */}
        <Grid item xs={12} sm={8} md={6} lg={4}>
          <SelectHotel disabled={false} />
        </Grid>
        <Grid item xs={12} sm={4} md={6}>
          <Button variant="contained" size="large" onClick={createOrderClickHandler}>
            {t('common:create_order')}
          </Button>
        </Grid>
        {/* ----------------------------- Top ------------------------------ */}
      </Grid>
      <Card
        elevation={isDesktop ? 2 : 0}
        sx={{
          backgroundColor: {xs: 'transparent', lg: 'background.paper'},
          marginTop: 5,
          overflow: 'visible',
          '& > div': {
            padding: {xs: '0 0 1rem', lg: 3},
          },
        }}
      >
        <CardHeader title={t('listOrders:orders_and_deliveries')} />

        <CardContent>
          <Grid
            container
            spacing={2}
            justifyContent={{xs: 'center'}}
            direction={{xs: 'row'}}
            alignItems="center"
            marginBottom={5}
          >
            <Grid item xs={12} lg={5}>
              <Stack
                flexDirection={{xs: 'column', md: 'row'}}
                alignItems={{xs: 'center'}}
                justifyContent="center"
                spacing={1}
                gap={1}
              >
                <Typography textAlign={'center'}>{t('common:filter_between_dates')}</Typography>
                <DatePicker
                  label={t('common:date_from')}
                  value={ordersStore.dateFrom}
                  onChange={(date) => {
                    ordersStore.setDateFrom(date);
                  }}
                  fullWidth={false}
                  disableFuture
                />
                <DatePicker
                  label={t('common:date_to')}
                  value={ordersStore.dateTo}
                  onChange={(date) => {
                    ordersStore.setDateTo(date);
                  }}
                  fullWidth={false}
                  margin={{marginLeft: 2}}
                  minDate={ordersStore.dateFrom}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Stack
                flexDirection={{xs: 'row', lg: 'row-reverse'}}
                alignItems={{xs: 'center'}}
                justifyContent={{
                  xs: 'space-between',
                  lg: 'space-evenly',
                }}
              >
                <Stack
                  flexDirection={'column'}
                  alignItems={{xs: 'center'}}
                  justifyContent={{xs: 'flex-start'}}
                >
                  <Typography>{t('common:date_sort')}</Typography>
                  <Stack flexDirection={'row'}>
                    <Tooltip title={t('common:descending_order')}>
                      <IconButton
                        aria-label={t('common:descending_order')}
                        color="primary"
                        onClick={handleSetDescendingOrder}
                      >
                        <ArrowDownwardIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t('common:ascending_order')}>
                      <IconButton
                        aria-label={t('common:ascending_order')}
                        color="primary"
                        onClick={handleSetAscendingOrder}
                      >
                        <ArrowUpwardIcon />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Stack>
                <Stack
                  flexDirection={'row'}
                  alignItems={{xs: 'center'}}
                  justifyContent={{xs: 'flex-end'}}
                  spacing={1}
                >
                  <LoadingButton
                    sx={{marginLeft: 1}}
                    onClick={handleExport}
                    variant="contained"
                    loading={ordersStore.isExporting}
                    disabled={ordersStore.count === 0}
                  >
                    {t('common:export')}
                  </LoadingButton>
                  <Button sx={{marginLeft: 1}} onClick={handleClearDates}>
                    {t('common:clear_dates')}
                  </Button>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Stack display={'flex'} justifyContent="flex-end">
                <Box>
                  <StatusSelect
                    value={ordersStore.filterState}
                    isFilter
                    handleChange={handleFilterByStatus}
                  />
                </Box>
              </Stack>
            </Grid>
          </Grid>

          {ordersStore.isLoading && <SkeletonLoader skeletonShape="table" />}

          {ordersStore.count > 0 && !ordersStore.isLoading && (
            <>
              <Grid container sx={{display: {xs: 'none', lg: 'flex'}}}>
                <Header justifyContent="start" lg={1}>
                  {t('common:code')}
                </Header>
                <Header justifyContent="start" lg={1}>
                  {t('common:brand')}
                </Header>
                <Header justifyContent="start" lg={1}>
                  {t('common:vendor')}
                </Header>
                <Header lg={1.25}>{t('common:date')}</Header>
                <Header lg={2}>{t('common:status')}</Header>
                <Header lg={0.75}>{t('common:quantity')}</Header>
                <Header lg={1.25}>{t('listOrders:amount')}</Header>
                <Header lg={1.25}>{t('common:cost_without_tax')}</Header>
                <Header lg={1}>{t('common:unit_price')}</Header>
              </Grid>
              {/* -------------------------- Items ------------------------- */}
              <Grid container gap={{xs: 5, lg: 0}}>
                {ordersStore.amenities.map((obj, index) => {
                  const date1 = new Date(obj.ctime);
                  const date2 = new Date();

                  const differenceInTime = date2.getTime() - date1.getTime();
                  const differenceInDays = differenceInTime / (1000 * 3600 * 24);

                  const info = [
                    {
                      label: t('common:code'),
                      value: obj.amenity.sku,
                      lg: 1,
                      alignItems: 'start',
                    },
                    {
                      label: t('common:brand'),
                      value: obj.amenity.brand.name,
                      lg: 1,
                      alignItems: 'start',
                    },
                    {
                      label: t('common:vendor'),
                      value: obj.purchase.vendor.name,
                      lg: 1,
                      alignItems: 'start',
                    },
                    {
                      label: t('common:date'),
                      value: obj.ctime,
                      lg: 1.25,
                    },
                    {
                      label: t('common:status'),
                      value: (
                        <StatusSelect
                          value={obj.state}
                          handleChange={(e) => {
                            handleStatusChange(e, obj.id);
                          }}
                        />
                      ),
                      lg: 2,
                    },
                    {
                      label: t('common:quantity'),
                      value: obj.total,
                      lg: 0.75,
                    },
                    {
                      label: t('listOrders:amount'),
                      value: `${getML(obj.total * obj.storage.quantity)} ${obj.amenity.unit.name}`,
                      lg: 1.25,
                    },
                    {
                      label: t('common:cost_without_tax'),
                      value: getPrice(Number(obj.price)),
                      lg: 1.25,
                    },
                    {
                      label: t('common:unit_price'),
                      value: getPrice(Number(obj.purchase.unit_price)),
                      lg: 1,
                    },
                    {
                      label: t('listOrders:cancel_order'),
                      value: (
                        <Tooltip title={t('listOrders:cancel_order')}>
                          <IconButton
                            aria-label="delete"
                            color="error"
                            onClick={(e) => {
                              handleCancelOrder(e, obj.id);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      ),
                      lg: 1,
                    },
                    differenceInDays >= obj.purchase.delivery &&
                      obj.state !== 'completed' && {
                        label: t('listOrders:delivery_date_passed'),
                        value: (
                          <Tooltip title={t('listOrders:delivery_date_passed')}>
                            <PriorityHighIcon color="error" />
                          </Tooltip>
                        ),
                        lg: 0.5,
                      },
                  ];

                  return <CardRowItem key={index} item={info} />;
                })}
              </Grid>
              {/* -------------------------- Items ------------------------- */}
            </>
          )}

          {ordersStore.count > 0 && (
            <Stack
              display="flex"
              flexDirection={'row'}
              width="100%"
              justifyContent={'center'}
              marginTop={3}
            >
              <ListPagination
                getter={ordersStore.getOrders.bind(ordersStore)}
                store={ordersStore}
                watchers={[ordersStore.dateTo, ordersStore.dateFrom]}
              />
            </Stack>
          )}

          {ordersStore.count === 0 && !ordersStore.isLoading && (
            <NoResultsFound message={t('common:there_are_currently_no_orders')} />
          )}
        </CardContent>
      </Card>
    </>
  );
});

export default ListOrders;
