import {Card, CardContent, Container, Grid, Stack, Typography, useMediaQuery} from '@mui/material';
import {observer} from 'mobx-react-lite';
import React, {useEffect} from 'react';
import PageTitle from '../../components/PageTitle';
import {useRootStore} from '../../context';
import '../../styles/main.css';
import {useTranslation} from 'react-i18next';
import Skeleton from '@mui/material/Skeleton';
import GuestCardList from './GuestCardList';
import NoResultsFound from '../../components/general/NoResultsFound';
import ListPagination from '../../components/ListPagination';

const SkeletonLoader = (
  <Grid container gap={{xs: 1}} marginY={5} justifyContent="center">
    <Grid item sx={{width: {xs: '100%', sm: '49%', md: '30%'}}}>
      <Skeleton variant="rounded" height={320} />
    </Grid>
    <Grid item sx={{width: {xs: '100%', sm: '49%', md: '30%'}}}>
      <Skeleton variant="rounded" height={320} />
    </Grid>
    <Grid item sx={{width: {xs: '100%', sm: '49%', md: '30%'}}}>
      <Skeleton variant="rounded" height={320} />
    </Grid>
    <Grid item sx={{width: {xs: '100%', sm: '49%', md: '30%'}}}>
      <Skeleton variant="rounded" height={320} />
    </Grid>
    <Grid item sx={{width: {xs: '100%', sm: '49%', md: '30%'}}}>
      <Skeleton variant="rounded" height={320} />
    </Grid>
    <Grid item sx={{width: {xs: '100%', sm: '49%', md: '30%'}}}>
      <Skeleton variant="rounded" height={320} />
    </Grid>
  </Grid>
);

const Guests = observer(() => {
  const {t} = useTranslation();
  const {guestStore, hotelStore} = useRootStore();

  useEffect(() => {
    if (hotelStore.currentHotel !== undefined) {
      guestStore.getGuests();
      guestStore.setSelectedGuest(null);
    }
  }, [hotelStore.currentHotel]);

  const isDesktop = useMediaQuery('(min-width: 1200px)');

  return (
    <>
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <PageTitle>{t('common:navbar_travelers')}</PageTitle>
          </Grid>
        </Grid>
        <Card
          elevation={isDesktop ? 2 : 0}
          sx={{
            marginTop: 5,
            backgroundColor: {xs: 'transparent', lg: '#FFF'},
            overflow: 'visible',
          }}
        >
          <CardContent sx={{padding: {xs: 0, lg: 2}}}>
            <Typography variant="h5" component="h5" marginBottom={3}>
              {t('guests:all_guests')}
            </Typography>

            {guestStore.isLoading && SkeletonLoader}

            {guestStore.count > 0 && !guestStore.isLoading && (
              <Grid container gap={{xs: 1}} marginY={5} justifyContent="center">
                <GuestCardList guests={guestStore.guests} />
              </Grid>
            )}

            {guestStore.count > 0 && (
              <Stack
                display="flex"
                flexDirection={'row'}
                width="100%"
                justifyContent={'center'}
                marginTop={3}
              >
                <ListPagination getter={guestStore.getGuests.bind(guestStore)} store={guestStore} />
              </Stack>
            )}

            {guestStore.count === 0 && !guestStore.isLoading && (
              <NoResultsFound message={t('guests:currently_no_guests')} />
            )}
          </CardContent>
        </Card>
      </Container>
    </>
  );
});

export default Guests;
