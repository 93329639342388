import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  IconButton,
  Input,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {toJS} from 'mobx';
import {useState} from 'react';
import GroupIcon from '@mui/icons-material/Group';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import BusinessIcon from '@mui/icons-material/Business';
import TranslateIcon from '@mui/icons-material/Translate';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PhoneIcon from '@mui/icons-material/Phone';
import Divider from '@mui/material/Divider';
import {observer} from 'mobx-react-lite';
import {useParams} from 'react-router-dom';
import SelectHotel from '../Navbar/SelectHotel';
import PageTitle from '../PageTitle';
import {useRootStore} from '../../context';
import {capitalize, localizedDate} from '../../libs/utils';
import {toast} from 'react-toastify';
import Api, {
  IReservationResult,
  TSendCodeResponse,
  reservationStatusColor,
} from '../../api/reservations';
import '../../styles/main.css';
import {useTranslation} from 'react-i18next';
import SkeletonLoader from '../general/SkeletonLoader';
import InfoText from '../general/InfoText';
import SendCodeModal from './SendCodeModal';
import SendCodeButton from './SendCodeButton';
import useProperties from '../../hooks/properties';
import validateEmail from '../../libs/EmailValidator';
import QRCodeButton from './QRCodeButton';

const ReservationDetails = observer(() => {
  const {t} = useTranslation();
  const [smsSent, setSmsSent] = useState<boolean | null>(null);
  const [emailSent, setEmailSent] = useState<boolean | null>(null);
  const [sendCodeResponse, setSendCodeResponse] = useState<TSendCodeResponse | null>(null);
  const {reservationsStore, accountStore, hotelStore} = useRootStore();
  const {reservationGroup, checkInDate} = useParams();
  const [reservation, setReservation] = useState<IReservationResult | null>(null);
  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const [emailInput, setEmailInput] = useState('');
  const [emailInputError, setEmailInputError] = useState(false);

  const [isEditingPhone, setIsEditingPhone] = useState(false);
  const [phoneInput, setPhoneInput] = useState('');
  const [phoneInputError, setPhoneInputError] = useState(false);

  const [sendCodeSms, setSendCodeSms] = useState(false);

  const properties = useProperties();

  const [reservationGroupId, setReservationGroupId] = useState<string | null>(null);
  const [modalOpen, setModalOpen] = useState(false);

  const capitalizedFirstName = reservation && capitalize(reservation.guest.first_name);
  const capitalizedLastName = reservation && capitalize(reservation.guest.last_name);

  const phoneHasIncorrectFormat =
    reservation && reservation.guest.phone_number && !reservation.guest.is_valid_phone_number;

  const checkInInProgress = reservation?.reservations.some(
    (reserv) => reserv.check_in_completed === true,
  );

  const checkInCompleted = reservation?.reservations.every(
    (reserv) => reserv.check_in_completed === true,
  );

  useEffect(() => {
    if (reservationGroup && checkInDate) {
      reservationsStore.getSelectedReservation(reservationGroup, checkInDate);
    }
  }, [reservationGroup]);

  useEffect(() => {
    if (
      reservation === null &&
      reservationsStore.selectedReservation !== null &&
      properties.properties.length > 0
    ) {
      const hotelId = reservationsStore.selectedReservation.hotel_id;
      const hotelData = properties.properties.find((hotel) => hotel.id === hotelId);

      hotelStore.setCurrentHotel(hotelData!);

      setReservation(toJS(reservationsStore.selectedReservation));
    }
  }, [reservationsStore.selectedReservation, properties.properties]);

  const handleSendCode = async (groupId: string, isSms?: boolean) => {
    const token = accountStore.token;

    if (token) {
      try {
        const response = await Api.sendAccessCode(accountStore.token, groupId, isSms);

        if (response.ok) {
          const data: TSendCodeResponse = await response.body;

          const emailSent = data.every((reserv) => reserv.email_sent === true);
          const smsSent = data.every((reserv) => reserv.sms_sent === true);
          setSendCodeResponse(data);

          setSmsSent(smsSent);
          setEmailSent(emailSent);
          toast.success(t('reservations:access_code_sent'));
        }
      } catch (error) {
        setSmsSent(null);
        setEmailSent(null);
        toast.error(t('reservations:error_sending_code'));
      }

      setModalOpen(false);
    }
  };

  const closeModalHandler = () => {
    setModalOpen(false);
  };

  const sendCodeClickHandler = (isSms: boolean) => {
    if (reservation) {
      setReservationGroupId(reservation.group_id);
      setSendCodeSms(isSms);
      setModalOpen(true);
    }
  };

  const sameReservation = reservation?.reservations?.every(
    (reservationData) => reservationData.room_number === reservation.reservations[0].room_number,
  );

  const emailCodeSent =
    emailSent !== null
      ? emailSent
      : reservation?.reservations?.every((reservationData) => reservationData.email_sent === true);

  const smsCodeSent =
    smsSent !== null
      ? smsSent
      : reservation?.reservations?.every((reservationData) => reservationData.sms_sent === true);

  const codeExists = reservation?.reservations?.every(
    (reservationData) => reservationData.code !== null,
  );

  const lockExists = reservation?.reservations?.every(
    (reservationData) => reservationData.lock !== null,
  );

  const infoText = sameReservation
    ? t('reservations:this_is_single_reservation')
    : t('reservations:different_reservations_same_guest');

  const emailFieldEditHandler = () => {
    if (reservation) setEmailInput(reservation.guest.email);

    setIsEditingEmail(true);
  };

  const emailFieldKeyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Escape') {
      setIsEditingEmail(false);
      setEmailInput('');
      setEmailInputError(false);
    }
  };

  const emailFieldChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailInput(event.target.value);
    setEmailInputError(false);
  };

  const phoneFieldEditHandler = () => {
    if (reservation) setPhoneInput(reservation.guest.phone_number);

    setIsEditingPhone(true);
  };

  const phoneFieldKeyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Escape') {
      setIsEditingPhone(false);
      setPhoneInput('');
      setPhoneInputError(false);
    }
  };

  const phoneFieldChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const sanitizedPhoneNumber: string = event.target.value.replace(/[^0-9\+]/g, '');

    setPhoneInput(sanitizedPhoneNumber);
    setPhoneInputError(false);
  };

  const cancelClickHandler = () => {
    setIsEditingPhone(false);
    setPhoneInput('');
    setPhoneInputError(false);

    setIsEditingEmail(false);
    setEmailInput('');
    setEmailInputError(false);
  };

  const submitInputHandler = async () => {
    if (!reservation) return;

    const guestId = reservation.guest.id;
    let phoneNumber = reservation.guest.phone_number;
    let email = reservation.guest.email;

    if (isEditingEmail) {
      const isEmailValid = validateEmail(emailInput);

      if (isEmailValid) {
        email = emailInput;
      } else {
        setEmailInputError(true);
        return;
      }
    }

    if (isEditingPhone) {
      const isPhoneValid = phoneInput.length > 0 && phoneInput.includes('+');

      if (isPhoneValid) {
        phoneNumber = phoneInput;
      } else {
        setPhoneInputError(true);
        return;
      }
    }

    // submit to API
    const success = await reservationsStore.updateGuestContactInfo(guestId, email, phoneNumber);

    if (success === true) location.reload();
  };

  return (
    <>
      {reservationsStore.isLoading && <SkeletonLoader skeletonShape="page" />}
      {reservation && (
        <>
          {reservationGroupId && (
            <SendCodeModal
              onSendCode={handleSendCode.bind(this, reservationGroupId, sendCodeSms)}
              isOpen={modalOpen}
              onClose={closeModalHandler}
            />
          )}
          <Container>
            <Grid container>
              <Grid item xs={12}>
                <PageTitle>{t('common:navbar_travelers')}</PageTitle>
              </Grid>
              <Grid item xs={12} md={4}>
                <SelectHotel disabled={true} />
              </Grid>
            </Grid>

            <Card sx={{marginY: 1}}>
              <CardContent>
                <Link to={'../..'} relative="path" style={{textDecoration: 'none'}}>
                  <Button
                    variant="text"
                    startIcon={<ChevronLeftIcon fontSize="large" />}
                    size="large"
                  >
                    {t('reservations:all_reservations')}
                  </Button>
                </Link>

                <Typography variant="h5" component="h5" marginBottom={2}>
                  {t('reservations:reservation_details')}
                </Typography>
                <>
                  <Stack
                    display={'flex'}
                    flexDirection="row"
                    alignItems={'center'}
                    justifyContent="space-between"
                    gap={1}
                  >
                    <InfoText text={infoText} hide={reservation.reservations.length === 1} />

                    <Paper sx={{border: '1px solid #CCCCCC', padding: 1.5, boxShadow: 'none'}}>
                      <Stack display={'flex'} flexDirection={{sm: 'column', md: 'row'}} gap={1}>
                        <SendCodeButton
                          codeExists={codeExists ?? false}
                          lockExists={lockExists ?? false}
                          hasValidPhone={reservation.guest.is_valid_phone_number}
                          hasRegisteredEmail={reservation.guest.email !== null}
                          sendCodeHandler={sendCodeClickHandler.bind(this, false)}
                          title="Send code to email"
                          type="email"
                        />
                        <SendCodeButton
                          codeExists={codeExists ?? false}
                          lockExists={lockExists ?? false}
                          hasValidPhone={reservation.guest.is_valid_phone_number}
                          hasRegisteredEmail={reservation.guest.email !== null}
                          sendCodeHandler={sendCodeClickHandler.bind(this, true)}
                          title="Send code to phone"
                          type="sms"
                        />

                        <Stack
                          display={'flex'}
                          flexDirection={'row'}
                          gap={0.5}
                          alignItems={'center'}
                          sx={{cursor: 'default'}}
                        >
                          {smsCodeSent ? (
                            <CheckCircleIcon color="success" />
                          ) : (
                            <HighlightOffIcon color="error" />
                          )}
                          SMS
                        </Stack>
                        <Stack
                          display={'flex'}
                          flexDirection={'row'}
                          gap={0.5}
                          alignItems={'center'}
                          sx={{cursor: 'default'}}
                        >
                          {emailCodeSent ? (
                            <CheckCircleIcon color="success" />
                          ) : (
                            <HighlightOffIcon color="error" />
                          )}
                          Email
                        </Stack>
                      </Stack>
                    </Paper>
                  </Stack>

                  <Stack display={'flex'} marginY={1} alignItems="flex-end"></Stack>

                  <Paper sx={{border: '2px solid #A4A6B3'}}>
                    <Stack flexDirection={'row'} alignItems="center">
                      <Typography
                        variant="h5"
                        margin={2}
                      >{`${capitalizedFirstName} ${capitalizedLastName}`}</Typography>
                    </Stack>

                    <Grid
                      container
                      gap={{xs: 5}}
                      margin={0}
                      padding={1}
                      justifyContent="flex-start"
                    >
                      <Grid item display={'flex'} flexDirection="column" justifyContent={'center'}>
                        <Stack display="flex" flexDirection="row" alignItems={'center'} gap={0.5}>
                          <Typography variant="body1" fontWeight={'bold'}>
                            {t('reservations:group')}
                          </Typography>
                          <Typography variant="body1">{reservation.group}</Typography>
                        </Stack>
                      </Grid>
                      <Grid item display={'flex'} flexDirection="column" justifyContent={'center'}>
                        <Stack display="flex" flexDirection="row" alignItems={'center'} gap={0.5}>
                          <AlternateEmailIcon fontSize="small" />

                          {isEditingEmail ? (
                            <Stack
                              display="flex"
                              flexDirection="column"
                              alignItems={'flex-start'}
                              gap={0.5}
                            >
                              <Input
                                autoFocus
                                type="email"
                                value={emailInput}
                                onChange={emailFieldChangeHandler}
                                onKeyDown={emailFieldKeyDownHandler}
                                error={emailInputError}
                              />
                              {emailInputError && (
                                <Typography color={'error'} fontSize={'12px'}>
                                  Please enter a valid email
                                </Typography>
                              )}
                            </Stack>
                          ) : (
                            <Button
                              onClick={emailFieldEditHandler}
                              sx={{padding: 0, color: 'black'}}
                            >
                              <Typography variant="button">
                                {reservation.guest.email ?? '-'}
                              </Typography>
                            </Button>
                          )}
                        </Stack>
                      </Grid>
                      <Grid item display={'flex'} flexDirection="column" justifyContent={'center'}>
                        <Stack display="flex" flexDirection="row" alignItems={'center'} gap={0.5}>
                          <PhoneIcon
                            fontSize="small"
                            color={!reservation.guest.is_valid_phone_number ? 'error' : 'inherit'}
                          />

                          {isEditingPhone ? (
                            <Stack
                              display="flex"
                              flexDirection="column"
                              alignItems={'flex-start'}
                              gap={0.5}
                            >
                              <Input
                                autoFocus
                                type="tel"
                                value={phoneInput}
                                onChange={phoneFieldChangeHandler}
                                onKeyDown={phoneFieldKeyDownHandler}
                                error={phoneInputError}
                              />
                              {phoneInputError && (
                                <Typography color={'error'} fontSize={'12px'}>
                                  Please enter a valid phone number
                                </Typography>
                              )}
                            </Stack>
                          ) : (
                            <Button
                              onClick={phoneFieldEditHandler}
                              sx={{padding: 0, color: 'black'}}
                            >
                              <Typography variant="body2">
                                {reservation.guest.phone_number ?? '-'}
                              </Typography>
                            </Button>
                          )}

                          {phoneHasIncorrectFormat && (
                            <Tooltip
                              title={t('common:phone_number_incorrect_format')}
                              color="error"
                            >
                              <IconButton color="error" sx={{margin: 0, padding: 0}}>
                                <ErrorOutlineIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          )}
                        </Stack>
                      </Grid>
                      <Grid item display={'flex'} flexDirection="column" justifyContent={'center'}>
                        <Stack display="flex" flexDirection="row" alignItems={'center'} gap={0.5}>
                          <TranslateIcon fontSize="small" />

                          <Typography textAlign="center" variant="button">
                            {reservation.guest.language.toUpperCase()}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item display={'flex'} flexDirection="column" justifyContent={'center'}>
                        <Stack display={'flex'} flexDirection="row" alignItems={'center'} gap={0.5}>
                          <BusinessIcon fontSize="small" />
                          <Typography variant="button">
                            {hotelStore.currentHotel?.short_name}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid
                        item
                        display={'flex'}
                        flexDirection="column"
                        alignItems={'flex-end'}
                        flex={1}
                        width={'fit-content'}
                      >
                        {(isEditingPhone || isEditingEmail) && (
                          <Stack display={'flex'} flexDirection="row" gap={1}>
                            <Button variant="contained" onClick={submitInputHandler}>
                              Update
                            </Button>
                            <Button variant="contained" color="gray" onClick={cancelClickHandler}>
                              Cancel
                            </Button>
                          </Stack>
                        )}
                      </Grid>
                    </Grid>
                  </Paper>
                  {reservation.reservations.map((reservationData) => {
                    const roomNumber = `${reservationData.room_number} (${reservationData.room_state})`;
                    const localizedCheckIn = localizedDate(reservationData.check_in);
                    const localizedCheckOut = localizedDate(reservationData.check_out);
                    const statusColor = reservationStatusColor[reservationData.state];
                    const emailSent = sendCodeResponse?.find(
                      (element) => element.code === reservationData.code,
                    )?.email_sent;

                    const smsSent = sendCodeResponse?.find(
                      (element) => element.code === reservationData.code,
                    )?.sms_sent;

                    return (
                      <Box key={reservationData.id} marginY={2}>
                        <Card>
                          <CardContent>
                            <Grid
                              container
                              gap={{xs: 1}}
                              justifyContent={{
                                xs: 'space-between',
                                sm: 'space-evenly',
                              }}
                              textAlign="center"
                            >
                              <Grid item xs={5.5} sm="auto">
                                <Typography
                                  variant="body2"
                                  sx={{fontWeight: 'bold'}}
                                  flexDirection="row"
                                >
                                  {t('common:id')}
                                </Typography>

                                <Typography variant="body2">{reservationData.id}</Typography>
                              </Grid>

                              <Grid item xs={5.5} sm="auto">
                                <Typography
                                  variant="body2"
                                  sx={{fontWeight: 'bold'}}
                                  flexDirection="row"
                                >
                                  {t('reservations:state')}
                                </Typography>

                                <Typography variant="body2" fontWeight={'700'} color={statusColor}>
                                  {reservationData.state.length > 0 ? reservationData.state : 'N/A'}
                                </Typography>
                              </Grid>
                              <Grid item xs={5.5} sm="auto">
                                <Typography
                                  variant="body2"
                                  sx={{fontWeight: 'bold'}}
                                  flexDirection="row"
                                >
                                  PreCheck-in
                                </Typography>

                                <Typography
                                  variant="body2"
                                  fontWeight={'700'}
                                  color={
                                    checkInInProgress && !checkInCompleted
                                      ? 'primary'
                                      : checkInCompleted
                                      ? 'green'
                                      : 'GrayText'
                                  }
                                >
                                  {checkInInProgress && !checkInCompleted
                                    ? 'In Progress'
                                    : checkInCompleted
                                    ? 'Completed'
                                    : 'Not started'}
                                </Typography>
                              </Grid>

                              <Grid
                                item
                                display={'flex'}
                                flexDirection="column"
                                alignItems={'center'}
                                xs={5.5}
                                sm="auto"
                              >
                                <GroupIcon fontSize="small" />
                                <Typography variant="body2">
                                  {reservationData.guest_count}
                                </Typography>
                              </Grid>
                              <Grid item xs={5.5} sm="auto">
                                <Typography
                                  variant="body2"
                                  sx={{fontWeight: 'bold'}}
                                  flexDirection="row"
                                >
                                  {t('reservations:room_number')}
                                </Typography>
                                <Typography variant="body2">{roomNumber}</Typography>
                              </Grid>
                              <Grid item xs={5.5} sm="auto">
                                <Typography
                                  variant="body2"
                                  sx={{fontWeight: 'bold'}}
                                  flexDirection="row"
                                >
                                  {t('reservations:code_start')}
                                </Typography>
                                <Typography variant="body2">{localizedCheckIn}</Typography>
                              </Grid>
                              <Grid item xs={5.5} sm="auto">
                                <Typography
                                  variant="body2"
                                  sx={{fontWeight: 'bold'}}
                                  flexDirection="row"
                                >
                                  {t('reservations:code_end')}
                                </Typography>
                                <Typography variant="body2">{localizedCheckOut}</Typography>
                              </Grid>

                              <Grid item width="100%" display={{md: 'none'}}>
                                <Divider light />
                              </Grid>
                              <Grid item>
                                <Typography
                                  variant="body2"
                                  sx={{fontWeight: 'bold'}}
                                  flexDirection="row"
                                >
                                  {t('common:code')}
                                </Typography>
                                <Typography variant="body2">
                                  {reservationData.code ?? '-'}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography
                                  variant="body2"
                                  sx={{fontWeight: 'bold'}}
                                  flexDirection="row"
                                >
                                  {t('reservations:code_sent')}
                                </Typography>
                                <Stack display={'flex'} flexDirection={'row'} gap={1}>
                                  <Stack
                                    display={'flex'}
                                    flexDirection={'row'}
                                    gap={0.5}
                                    alignItems={'center'}
                                    sx={{cursor: 'default'}}
                                  >
                                    {smsSent || reservationData.sms_sent ? (
                                      <CheckCircleIcon color="success" fontSize="small" />
                                    ) : (
                                      <HighlightOffIcon color="error" fontSize="small" />
                                    )}
                                    <Typography variant="body2">SMS</Typography>
                                  </Stack>
                                  <Stack
                                    display={'flex'}
                                    flexDirection={'row'}
                                    gap={0.5}
                                    alignItems={'center'}
                                    sx={{cursor: 'default'}}
                                  >
                                    {emailSent || reservationData.email_sent ? (
                                      <CheckCircleIcon color="success" fontSize="small" />
                                    ) : (
                                      <HighlightOffIcon color="error" fontSize="small" />
                                    )}
                                    <Typography variant="body2">Email</Typography>
                                  </Stack>
                                </Stack>
                              </Grid>
                              <Grid item>
                                <Typography
                                  variant="body2"
                                  sx={{fontWeight: 'bold'}}
                                  flexDirection="row"
                                >
                                  {t('reservations:ota')}
                                </Typography>
                                <Typography variant="body2">{reservationData.ota_name}</Typography>
                              </Grid>
                              <Grid
                                item
                                display={'flex'}
                                justifyContent={'center'}
                                width={{xs: '100%', sm: 'fit-content'}}
                                marginTop={1}
                              >
                                <QRCodeButton
                                  reservationId={reservationData.id}
                                  disabled={reservation.guest.email === null}
                                />
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Box>
                    );
                  })}
                </>
              </CardContent>
            </Card>
          </Container>
        </>
      )}
    </>
  );
});

export default ReservationDetails;
