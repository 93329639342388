import {Card, CardContent, Container, Grid, Stack, Typography} from '@mui/material';
import React, {useEffect} from 'react';
import type {ISelectedProductAction} from '../../stores/reservation';
import {useRootStore} from '../../context';
import PageTitle from '../../components/PageTitle';
import SelectHotel from '../../components/Navbar/SelectHotel';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react-lite';
import NoResultsFound from '../../components/general/NoResultsFound';
import ReservationProductAccordion from './ReservationProductAccordion';

export interface IGuestProducts {
  roomId: string;
  approved: Array<ISelectedProductAction>;
  rejected: Array<ISelectedProductAction>;
}

const ReservationProducts = () => {
  const {reservationsStore, hotelStore} = useRootStore();
  const {t} = useTranslation();

  const reservationProducts = reservationsStore.reservationProducts;
  const hasNoGuests = reservationProducts.some((element) => element.guest === null);

  useEffect(() => {
    if (hotelStore.currentHotel !== undefined) {
      reservationsStore.getReservationProducts();
    }
  }, [hotelStore.currentHotel]);

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PageTitle>{t('reservations:products_pending_for_approval')}</PageTitle>
        </Grid>
        <Grid item xs={12} md={4}>
          <SelectHotel />
        </Grid>
      </Grid>
      <Card
        sx={{
          marginTop: 5,
        }}
      >
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            border: 'none',
            boxShadow: 'none',
          }}
        >
          <Stack flexDirection={'row'} alignItems="center">
            <Typography variant="h5" margin={2}>
              {t('common:navbar_guests')}
            </Typography>
          </Stack>

          {reservationsStore.reservationProducts &&
            reservationsStore.reservationProducts
              .filter((element) => element.room_number !== null)
              .map((element) => {
                return (
                  <ReservationProductAccordion key={element.room_number} reservation={element} />
                );
              })}

          {(reservationProducts.length === 0 || hasNoGuests) && (
            <NoResultsFound message={t('common:there_are_no_items')} />
          )}
        </CardContent>
      </Card>
    </Container>
  );
};

export default observer(ReservationProducts);
