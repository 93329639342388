import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import HelpIcon from '@mui/icons-material/Help';
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {observer} from 'mobx-react-lite';
import {useLocation} from 'react-router-dom';
import SectionTitle from './SectionTitle';
import {useTranslation} from 'react-i18next';
import {useRootStore} from '../../../context';
import type {FormikProps} from 'formik';
import type {IFormikInitValues} from '../AddItemStep1';

interface IStorageDetailsSectionProps {
  formik: FormikProps<IFormikInitValues>;
  lv: string;
  tooltip: string;
  unit: string;
  setBlob: React.Dispatch<React.SetStateAction<string | null>>;
}

const StorageDetailsSection: React.FC<IStorageDetailsSectionProps> = observer(
  ({formik, lv, tooltip, unit, setBlob}) => {
    const {inventoryStore} = useRootStore();
    const {t} = useTranslation();

    let titleColor;
    switch (lv) {
      case 'LV1':
        titleColor = 'primary';
        break;
      case 'LV2':
        titleColor = 'warning.main';
        break;
      case 'LV3':
        titleColor = 'success.dark';
        break;
      default:
        titleColor = '#000';
    }

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
      const files = e.target.files;

      if (files && files.length > 0) {
        const reader = new FileReader();
        const file = files[0];
        reader.readAsDataURL(file);
        reader.onloadend = function () {
          setBlob(reader.result as string);
        };
      } else {
        setBlob('');
        formik.setFieldValue(field, '');
      }
      formik.handleChange(e);
    };

    const handleDeleteImage = (field: string) => {
      formik.setFieldValue(field, '');
      setBlob('');
    };

    const isMobile = useMediaQuery('(max-width: 900px)');
    const location = useLocation();
    const {pathname} = location;
    const isAddingItem = pathname.includes('add');

    const storageFieldName = isAddingItem
      ? 'storage'
      : (`${lv.toLowerCase()}_storage` as keyof typeof formik.values);

    const quantityFieldName = isAddingItem
      ? 'quantity'
      : (`${lv.toLowerCase()}_quantity` as keyof typeof formik.values);

    const imageFieldName = isAddingItem
      ? 'image'
      : (`${lv.toLowerCase()}_image` as keyof typeof formik.values);

    return (
      <Grid container gap={3} marginBottom={3}>
        <Grid item xs={12}>
          <SectionTitle color={titleColor}>
            {t(`storageDetailsSection:storage_level_details`, {lv: lv})}
          </SectionTitle>
        </Grid>
        {/* -------------------------- LV Storage ------------------------ */}
        <Grid item xs={12} md={4}>
          <TextField
            select
            name={storageFieldName}
            value={formik.values[storageFieldName]}
            label={t('storageDetailsSection:level_storage', {lv: lv})}
            required
            fullWidth
            onChange={formik.handleChange}
            error={formik.touched[storageFieldName] && Boolean(formik.errors[storageFieldName])}
            helperText={
              formik.touched[storageFieldName] && (formik.errors[storageFieldName] as string)
            }
          >
            {inventoryStore.storages.map((obj, index) => (
              <MenuItem key={index} value={obj.id}>
                {obj.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        {/* -------------------------- LV Storage ------------------------ */}
        {/* -------------------------- Units p/ LV ----------------------- */}
        <Grid item xs={12} md={6}>
          <TextField
            name={quantityFieldName}
            label={t('storageDetailsSection:units_per_level_storage', {lv: lv})}
            value={formik.values[quantityFieldName]}
            onChange={formik.handleChange}
            type="number"
            required
            fullWidth={isMobile}
            helperText={
              formik.touched[quantityFieldName] && (formik.errors[quantityFieldName] as string)
            }
            error={formik.touched[quantityFieldName] && Boolean(formik.errors[quantityFieldName])}
            InputProps={{
              inputProps: {min: 1},
              endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
            }}
          />
          <Tooltip title={tooltip} placement="top">
            <HelpIcon />
          </Tooltip>
        </Grid>
        {/* -------------------------- Units p/ LV ----------------------- */}
        <Grid item>
          {/* -------------------- If there's NO image ------------------- */}
          {!formik.values[imageFieldName] && (
            <FormControl
              error={formik.touched[imageFieldName] && Boolean(formik.errors[imageFieldName])}
            >
              <Typography variant="body2" color="GrayText" marginBottom={1}>
                {t('common:product_image_optional')}
              </Typography>
              <input
                type="file"
                accept="image/*"
                name="image"
                onChange={(e) => handleImageChange(e, imageFieldName)}
                value={formik.values[imageFieldName]}
              />
              <FormHelperText
                error={formik.touched[imageFieldName] && Boolean(formik.errors[imageFieldName])}
              >
                <>{formik.touched[imageFieldName] && formik.errors[imageFieldName]}</>
              </FormHelperText>
            </FormControl>
          )}
          {/* -------------------- If there's NO image ------------------- */}
          {/* -------------------- If there IS an image ------------------ */}
          {formik.values[imageFieldName] && (
            <Box position="relative">
              <IconButton
                onClick={() => handleDeleteImage(imageFieldName)}
                sx={{
                  position: 'absolute',
                  top: '0.5em',
                  right: '0.5em',
                  bgcolor: '#535050',
                  '&:hover': {bgcolor: '#adaaaa', '*': {color: 'error.main'}},
                }}
              >
                <DeleteIcon sx={{color: '#FFF', transition: 'all 0.35s'}} />
              </IconButton>
              <img src={formik.values[imageFieldName]} alt={t('common:product_image')} />
            </Box>
          )}
          {/* -------------------- If there IS an image ------------------ */}
        </Grid>
      </Grid>
    );
  },
);

export default StorageDetailsSection;
