import React from 'react';
import {IconButton} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

interface INavButtonProps {
  onClick: () => void;
  open: boolean;
}

const NavButton: React.FC<INavButtonProps> = ({onClick, open}) => {
  return (
    <IconButton
      color="inherit"
      aria-label="open drawer"
      onClick={onClick}
      edge="start"
      sx={{
        marginRight: 2,
      }}
    >
      <MenuIcon
        sx={{
          '&.MuiSvgIcon-root': {
            transition: 'all 0.35s ease-in-out',
            color: {xs: open ? '#FFF' : '#000', sm: '#FFF'},
          },
        }}
      />
    </IconButton>
  );
};

export default NavButton;
