import {DateTime} from 'luxon';
import request from 'superagent';
import Config from '../../config';
import Common, {IAmenityResponse, IPurchaseResponse, IStorageResponseData} from '../common';

export interface IGetOrdersResult {
  id: number;
  amenity: IAmenityResponse;
  purchase: IPurchaseResponse;
  storage: IStorageResponseData;
  est_demand: number;
  suggested_stock: number;
  buffer: number;
  total: number;
  price_currency: string;
  price: string;
  state: string;
  ctime: string;
  mtime: string;
  hotel: number;
}

export interface IGetOrdersResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: Array<IGetOrdersResult>;
}

export interface IUpdateOrderPayload {
  id: number;
  state: string;
}
interface IOrderProductItem {
  amenity: number;
  hotel: number;
  total: number;
  price: number;
}
export interface ISaveProductResponse {
  id: number;
  items: Array<IOrderProductItem>;
  price_currency: string;
  // TODO CHECK IF THIS COULD BE A NUMBER
  price: string;
  ctime: string;
  mtime: string;
  vendor: number;
}

export interface IOrderProductsPayload {
  items: Array<IOrderProductItem>;
  vendor: number;
  price: number;
}

export default {
  getOrders: function (
    token: string,
    hotelId: number,
    dateFrom: string | null,
    dateTo: string | null,
    // TODO define filter state in a single place to provide typing
    filterState: string,
    nextPreviousUrl: string | null,
  ) {
    const url = nextPreviousUrl || `${Config.API_URL}/inventory/previous_orders/${hotelId}/`;

    if (nextPreviousUrl && nextPreviousUrl.length > 0) {
      return request.get(url).auth(token, {type: 'bearer'}).accept(Common.MEDIA_TYPE).send();
    }
    return request
      .get(url)
      .query({start: dateFrom, end: dateTo, state: filterState})
      .auth(token, {type: 'bearer'})
      .accept(Common.MEDIA_TYPE)
      .send();
  },

  updateOrder(token: string, hotel: number, payload: IUpdateOrderPayload) {
    const url = `${Config.API_URL}/inventory/orders/${hotel}/`;

    return request
      .put(url)
      .auth(token, {type: 'bearer'})
      .type(Common.MEDIA_TYPE)
      .accept(Common.MEDIA_TYPE)
      .send(JSON.stringify(payload));
  },

  cancelOrder(token: string, id: number) {
    const url = `${Config.API_URL}/inventory/order/delete/${id}/`;

    return request
      .delete(url)
      .auth(token, {type: 'bearer'})
      .type(Common.MEDIA_TYPE)
      .accept(Common.MEDIA_TYPE)
      .send();
  },

  saveOrder(token: string, hotel: number, products: IOrderProductsPayload) {
    const url = `${Config.API_URL}/inventory/orders/${hotel}/`;

    return request
      .post(url)
      .auth(token, {type: 'bearer'})
      .type(Common.MEDIA_TYPE)
      .accept(Common.MEDIA_TYPE)
      .send(JSON.stringify(products));
  },
  exportOrders: function (token: string, hotelId: number, dateTo: string | null) {
    const url = `${Config.API_URL}/inventory/previous_orders/export/${hotelId}/`;

    if (!dateTo) {
      dateTo = DateTime.now().toFormat('yyyy-MM-dd');
    }

    return request.get(url).query({end: dateTo}).auth(token, {type: 'bearer'}).responseType('blob');
  },
  updateBuffer: function (
    token: string,
    hotelId: number,
    amenityId: number,
    buffer: number) {
    const url = `${Config.API_URL}/inventory/amenity/buffer/${amenityId}/${hotelId}/`;
    const payload = {buffer};
    return request
      .put(url)
      .auth(token, {type: 'bearer'})
      .type(Common.MEDIA_TYPE)
      .accept(Common.MEDIA_TYPE)
      .send(payload);
  },
};
