import {TextField, TextFieldProps} from '@mui/material';
import React from 'react';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {DatePicker as MuiDatePicker} from '@mui/x-date-pickers';
import type {FilterDate} from '../stores/inventory/stockCount';

interface IDatePickerProps {
  value: FilterDate;
  onChange: (value: Date | null, keyboardInputValue?: string | undefined) => void;
  label?: React.ReactNode;
  noLegend?: boolean;
  fullWidth?: boolean;
  margin?: Record<string, string | number>;
  minDate?: any;
  disableFuture?: boolean;
  error?: boolean;
  helperText?: string;
  renderInput?: (
    params: TextFieldProps,
  ) => React.ReactElement<any, string | React.JSXElementConstructor<any>>;
}

const DatePicker: React.FC<IDatePickerProps> = ({
  value,
  onChange,
  label,
  noLegend,
  fullWidth = true,
  margin,
  minDate,
  disableFuture,
  error,
  helperText,
  renderInput,
}) => {
  const inputStyle = {
    backgroundColor: '#FFF !important',
    '& + .MuiInputAdornment-root': {
      width: '50px !important',
    },
    '& fieldset': {
      borderColor: '#c4c4c4 !important',
    },
  };

  const paperStyle = {
    '& .MuiCalendarPicker-root': {
      backgroundColor: 'white',
    },
  };

  const labelStyle = {
    '& .MuiInputLabel-root.Mui-error': {
      color: 'gray !important',
    },
  };

  const dialogStyle = {
    '& .MuiDialog-paper': {
      backgroundColor: 'white',
    },
  };

  const defaultRenderInput = (params: TextFieldProps) => (
    <TextField
      {...params}
      error={error}
      helperText={helperText}
      sx={{
        ...margin,
        ...labelStyle,
        '& legend, label': {display: noLegend ? 'none' : 'block'},
        '& .MuiInputAdornment-root': {
          // Calendar icon
          width: '50px !important',
        },
      }}
      fullWidth={fullWidth}
    />
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MuiDatePicker
        label={label}
        inputFormat="yyyy/MM/dd"
        value={value}
        onChange={onChange}
        closeOnSelect
        minDate={minDate}
        disableFuture={disableFuture}
        renderInput={renderInput ?? defaultRenderInput}
        InputProps={{sx: inputStyle, id: 'please'}}
        DialogProps={{sx: dialogStyle}}
        PaperProps={{sx: paperStyle}}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
