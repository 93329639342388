import {Card, CardContent, Grid, useMediaQuery} from '@mui/material';
import React from 'react';
import Item from './Item';

interface ICardRowItemProps {
  item: Array<any>;
}

const CardRowItem: React.FC<ICardRowItemProps> = ({item}) => {
  const isDesktop = useMediaQuery('(min-width: 1200px)');

  // * Display each item as cards when mobile and 'table rows' when desktop
  return (
    <Card
      elevation={isDesktop ? 0 : 5}
      sx={{
        width: '100%',
        paddingBlock: {lg: 1},
        marginBlock: {xs: 2},
        borderRadius: {lg: 0},

        '&:not(:last-child)': {
          borderBottom: '1px solid #babac1',
        },
      }}
    >
      <CardContent sx={{paddingBottom: '0 !important', padding: {lg: '0 !important'}}}>
        <Grid container item xs={12} spacing={2}>
          {item.map((info, index) => {
            const {label, value, lg, alignItems, justifyContent} = info;

            return (
              <Item
                key={index}
                label={label}
                lg={lg}
                alignItems={{lg: alignItems || 'center'}}
                justifyContent={justifyContent}
                fontWeight={700}
              >
                {value}
              </Item>
            );
          })}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CardRowItem;
