import React, {useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {useNavigate} from 'react-router-dom';

import {useRootStore} from '../../context';
import {ADD_ITEM_STEP_3} from '../../stores/inventory/product/product';
import ItemSummary from '../../components/inventory/ItemSummary';
import AddItemStepLayout from '../../components/inventory/AddItemStepLayout';
import ConfirmStorageDetails from '../../components/inventory/ConfirmStorageDetails';
import {Stack} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {useTranslation} from 'react-i18next';

const Confirm = observer(() => {
  const {t} = useTranslation();
  const {productStore} = useRootStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (productStore.success) {
      productStore.reset();
      navigate('/inventory/items', {replace: true});
    }
  }, [productStore.success]);

  const handleSaveItem = () => {
    productStore.createProduct();
  };

  return (
    <AddItemStepLayout back={ADD_ITEM_STEP_3}>
      <ItemSummary />
      <Stack gap={3}>
        {productStore.levels.includes('lv2') && <ConfirmStorageDetails lv="lv2" />}
        {productStore.levels.includes('lv3') && <ConfirmStorageDetails lv="lv3" />}
        <LoadingButton
          onClick={handleSaveItem}
          loading={productStore.isSubmitting}
          variant="contained"
          size="large"
          sx={{alignSelf: {md: 'center'}}}
        >
          {t('common:save')}
        </LoadingButton>
      </Stack>
    </AddItemStepLayout>
  );
});

export default Confirm;
