import {Grid, GridSize, Typography} from '@mui/material';
import React from 'react';

interface IHeaderProps {
  justifyContent?: string;
  lg?: boolean | GridSize | undefined;
  children?: string;
  symbol?: string;
  color?: string;
}

const Header: React.FC<IHeaderProps> = ({justifyContent, lg, children, symbol, color}) => {
  return (
    <Grid
      item
      lg={lg}
      textAlign="center"
      display="flex"
      alignItems="center"
      justifyContent={justifyContent || 'center'}
      style={{wordWrap: 'normal'}}
    >
      <Typography variant="body1" component="span" color={color} fontWeight="bold">
        {symbol}
        {children}
      </Typography>
    </Grid>
  );
};

export default Header;
