import LoadingButton from '@mui/lab/LoadingButton';
import {Box, Button, Stack, useMediaQuery} from '@mui/material';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {saveAs} from 'file-saver';
import {DateTime} from 'luxon';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import LinenAction from '../../libs/linenAction';
import {useRootStore} from '../../context';
import DatePicker from '../DatePicker';

const OrderFilter = observer(() => {
  const {t} = useTranslation();
  const {orderStore, linenStore, hotelStore} = useRootStore();
  const [startDate, setStartDate] = useState<string | null>('');
  const [endDate, setEndDate] = useState<string | null>('');

  const handleStartDate = (e: any) => {
    if (e) {
      orderStore.setStartDate(e.toLocaleDateString('ja-JP').replace(/\//g, '-'));
    } else {
      orderStore.setStartDate(null);
    }
  };

  const handleEndDate = (e: any) => {
    if (e) {
      orderStore.setEndDate(e.toLocaleDateString('ja-JP').replace(/\//g, '-'));
    } else {
      orderStore.setEndDate(null);
    }
  };

  const handleExportOrders = (e: React.SyntheticEvent) => {
    e.preventDefault();
    orderStore.exportOrders();
  };

  const handleClearDates = (event: React.SyntheticEvent) => {
    event.preventDefault();
    orderStore.setStartDate(null);
    orderStore.setEndDate(null);
  };

  useEffect(() => {
    if (orderStore.exportBlob !== undefined) {
      const todayDate = DateTime.now().toFormat('yyyy-MM-dd');
      const filename = `${hotelStore.currentHotel?.short_name} orders-${todayDate}.xlsx`;
      saveAs(orderStore.exportBlob, filename);
      orderStore.setExportBlob(undefined);
    }
  }, [orderStore.exportBlob]);

  useEffect(() => {
    if (orderStore.startDate !== undefined) {
      setStartDate(orderStore.startDate);
    } else {
      setStartDate('');
    }
    if (orderStore.endDate !== undefined) {
      setEndDate(orderStore.endDate);
    } else {
      setEndDate('');
    }
    if (hotelStore.currentHotel !== undefined) {
      orderStore.getOrders();
    }
  }, [orderStore.startDate, orderStore.endDate, hotelStore.currentHotel]);

  useEffect(() => {
    linenStore.getLinenCosts(orderStore.startDate);
  }, [orderStore.startDate]);

  const isMobile = useMediaQuery('(max-width:600px)');

  if (linenStore.currentAction === LinenAction.VIEW_ORDERS) {
    return (
      <Box width={{xs: '100%', md: '50%'}}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Stack flexDirection={{sm: 'row'}} gap={3} width="100%">
            <Stack flexDirection="row" gap={3} width="100%">
              <DatePicker
                label={t('common:start_date')}
                value={startDate}
                onChange={handleStartDate}
                disableFuture
              />
              <DatePicker
                label={t('common:end_date')}
                value={endDate}
                onChange={handleEndDate}
                disableFuture
              />
              <Button onClick={handleClearDates}>{t('common:clear_dates')}</Button>
            </Stack>
            <LoadingButton
              variant="contained"
              color="primary"
              loading={orderStore.isExporting}
              onClick={handleExportOrders}
              loadingIndicator={t('common:exporting')}
              fullWidth={isMobile}
              sx={{paddingBlock: '1rem', alignSelf: {sm: 'end'}}}
              disabled={orderStore.count === 0}
            >
              {t('common:export')}
            </LoadingButton>
          </Stack>
        </LocalizationProvider>
      </Box>
    );
  }
  return null;
});

export default OrderFilter;
