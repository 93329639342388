import React, {useEffect} from 'react';
import {Skeleton, Stack} from '@mui/material';
import LinenInfoCard from './LinenInfoCard';
import {useRootStore} from '../../context';
import {getPrice} from '../../libs/utils';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react-lite';

const SkeletonLoader = (
  <Stack flexDirection="row" alignItems={'center'} justifyContent="space-evenly">
    <Skeleton variant="rectangular" width="30%" height={140} />
    <Skeleton variant="rectangular" width="30%" height={140} />
    <Skeleton variant="rectangular" width="30%" height={140} />
  </Stack>
);

const LinenInfoCards: React.FC = observer(() => {
  const {linenStore, hotelStore} = useRootStore();
  const {t} = useTranslation();

  useEffect(() => {
    if (hotelStore.currentHotel) {
      linenStore.getLinenCosts();
    }
  }, [hotelStore.currentHotel]);

  const defaultValue = '￥0';

  const mtdCost = linenStore.linenCosts && getPrice(linenStore.linenCosts.month_to_date_cost);
  const forecastMonthCost =
    linenStore.linenCosts && getPrice(linenStore.linenCosts.forecasted_monthly_cost);
  const perTravelerCost =
    linenStore.linenCosts && getPrice(linenStore.linenCosts.per_traveler_cost);

  return (
    <>
      {linenStore.isLoading && SkeletonLoader}
      {!linenStore.isLoading && linenStore.linenCosts && (
        <Stack flexDirection={{sm: 'row'}} justifyContent="space-between" gap={3} width="100%">
          <LinenInfoCard title={t('common:mtd_cost_price')} value={mtdCost || defaultValue} />
          <LinenInfoCard
            title={t('common:forecast_month_cost')}
            value={forecastMonthCost || defaultValue}
          />
          <LinenInfoCard
            title={t('common:per_traveler_cost')}
            value={perTravelerCost || defaultValue}
          />
        </Stack>
      )}
    </>
  );
});

export default LinenInfoCards;
