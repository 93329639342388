import request from 'superagent';
import Config from '../config';
import Common from './common';

// CHECKED INTERFACE
export interface IOrderParams {
  delivery_company: number;
  orderer: string;
  order_date: string;
  order_from: string;
  delivery_date: string;
  days: number;
  hotel: number;
}

// CHECKED INTERFACE
export type IPrepareOrderParams = IOrderParams;

// CHECKED INTERFACE
export interface IPrepareOrderResponseResult {
  item: string;
  price: number; // TODO check if this could ever by a string like the other prices
  spec: string;
  bundle: number;
  demand: number;
  to_order: number;
  stock: number;
  linen_id: number;
  surplus: number;
}

// CHECKED TYPE
export type TPrepareOrderResponse = Array<IPrepareOrderResponseResult>;

// CHECKED INTERFACE
interface ILinenItem {
  linen_id: number;
  quantity: number;
}

// CHECKED INTERFACE
export interface ICreateOrderParams extends IOrderParams {
  to_order: Array<ILinenItem>;
}

// CHECKED INTERFACE
export interface ICreateOrderResponse {
  download_url: string;
}

// CHECKED INTERFACE
export interface IGetLinenOrdersResult {
  id: number;
  hotel_name: string;
  download_url: string;
  unique_id: string;
  name: string;
  from_date: string;
  days: number;
  price_currency: string;
  price: string;
  delivery_date: string;
  note: string;
  created: string;
  updated: string;
  delivery_company: number;
  hotel: number;
}

// CHECKED INTERFACE
export interface IGetLinenOrdersResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: Array<IGetLinenOrdersResult>;
}

export default {
  prepareOrder: function (params: IPrepareOrderParams, token: string) {
    const url = `${Config.API_URL}/linen/order/prepare/`;
    const payload = JSON.stringify(params);

    return request
      .post(url)
      .auth(token, {type: 'bearer'})
      .type(Common.MEDIA_TYPE)
      .accept(Common.MEDIA_TYPE)
      .send(payload);
  },
  createOrder: function (params: ICreateOrderParams, token: string) {
    const url = `${Config.API_URL}/linen/order/create/`;
    const payload = JSON.stringify(params);

    return request
      .post(url)
      .auth(token, {type: 'bearer'})
      .type(Common.MEDIA_TYPE)
      .accept(Common.MEDIA_TYPE)
      .send(payload);
  },
  getOrders: function (
    token: string,
    hotelId: number,
    startDate: string | null,
    endDate: string | null,
    nextPreviousUrl: string | null,
  ) {
    const url = nextPreviousUrl || `${Config.API_URL}/linen/orders/${hotelId}/`;

    if (nextPreviousUrl && nextPreviousUrl.length > 0) {
      return request.get(url).auth(token, {type: 'bearer'}).accept(Common.MEDIA_TYPE).send();
    }
    return request
      .get(url)
      .query({start: startDate, end: endDate})
      .auth(token, {type: 'bearer'})
      .accept(Common.MEDIA_TYPE)
      .send();
  },
  downloadOrder: function (url: string) {
    return request.get(url).responseType('blob');
  },
  exportOrders: function (
    token: string,
    hotelId: number,
    startDate: string | null,
    endDate: string | null,
  ) {
    const url = `${Config.API_URL}/linen/orders/export/${hotelId}/`;

    return request
      .get(url)
      .query({start: startDate, end: endDate})
      .auth(token, {type: 'bearer'})
      .responseType('blob');
  },
};
