import React, {useEffect, useState} from 'react';
import {toJS} from 'mobx';
import {observer} from 'mobx-react-lite';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import type {SelectChangeEvent} from '@mui/material/Select';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {useRootStore} from '../../context';
import {PAGE_CREATE_ORDER} from '../../stores/inventory/orders/orders';
import SelectHotel from '../Navbar/SelectHotel';
import {getML, getPrice} from '../../libs/utils';
import {useTranslation} from 'react-i18next';
import type {ISessionStorageProduct} from '../../stores/inventory/orders/cart';
import {DateTime} from 'luxon';

const AddAmenity: React.FC = observer(() => {
  const {ordersStore} = useRootStore();
  const {t} = useTranslation();

  const [product, setProduct] = useState<ISessionStorageProduct>();
  const [amount, setAmount] = useState(0);
  const [stockSuggest, setStockSuggest] = useState(0);
  const [bufferValue, setBufferValue]  = useState(0);
  const [price, setPrice] = useState(0);
  const [stock, setStock] = useState(0);

  useEffect(() => {
    if (ordersStore.cart.product === null) {
      ordersStore.cart.loadProduct();
    }
  }, [ordersStore.cart.product]);

  useEffect(() => {
    if (ordersStore.cart.product !== null) {
      const p = toJS(ordersStore.cart.product);

      setProduct(p);
      setAmount(p.amenity.storage.quantity * p.quantity);
      setPrice(parseInt(p.amenity.purchase.price) * p.quantity);
      setStock(p.amenity.est_stock + p.amenity.storage.quantity * p.quantity);
      setStockSuggest(p.amenity.suggested_stock);
      setBufferValue(p.amenity.buffer > 0 ? p.amenity.buffer : 0);
    }
  }, [ordersStore.cart.product]);

  const handleQuantityChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const value = parseInt(e.target.value);

    if (value >= 0 && value <= 100 && product) {
      const quantity = product.amenity.storage.quantity;
      const cost = parseInt(product.amenity.purchase.price) * value;

      setAmount(quantity * value);
      setPrice(cost);
      setStock(product.amenity.est_stock + quantity * value);
    } else {
      setStock(product ? product.amenity.est_stock : 0);
    }
    formik.handleChange(e);
  };

  const handleBufferChange = async (event: SelectChangeEvent<number>) => {
    if (product) {
      const buffer = event.target.value as number;
      setBufferValue(buffer);
      await ordersStore.updateBuffer(product.amenity.id, buffer);
      setStockSuggest(ordersStore.suggestedStock);
    }
  };

  const getEstArrival = (days: number) => {
    return DateTime.now().plus({days: days}).toFormat('dd/MM/yyyy');
  };

  const YupSchema = Yup.object().shape({
    quantity: Yup.number()
      .min(0, t('addAmenity:quantity_required'))
      .max(300, t('addAmenity:maximum_quantity', {maxQuantity: 300}))
      .required(t('addAmenity:quantity_required')),
  });

  const formik = useFormik({
    initialValues: {
      quantity: product && product.quantity <= 0 ? '' : product && product.quantity,
      buffer: product && product.amenity.buffer <= 0 ? '' : product && product.amenity.buffer,
    },
    enableReinitialize: true,
    validationSchema: YupSchema,
    onSubmit: (values) => {
      product!.quantity = Number(values.quantity);
      ordersStore.cart.updateProducts(product!);
      ordersStore.setPage(PAGE_CREATE_ORDER);
    },
  });

  if (product && product.amenity === null) {
    return (
      <Stack minHeight="100vh" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Stack>
    );
  }

  const cardStyles = {
    elevation: 0,
    sx: {borderRadius: 0.5, border: '1px solid #dbdbdb', height: '100%'},
  };
  const estimatedDemand =
    product && product.amenity.est_demand > 0 ? getML(product.amenity.est_demand) : 0;
  return (
    <>
      <Grid container marginBottom={5}>
        <Grid item xs={12} sm={4}>
          <SelectHotel disabled />
        </Grid>
      </Grid>

      {product && (
        <Card>
          <CardContent>
            <Button
              variant="text"
              onClick={() => ordersStore.setPage(PAGE_CREATE_ORDER)}
              startIcon={<ChevronLeftIcon fontSize="large" />}
              size="large"
              color="gray"
              sx={{fontSize: '1.25rem'}}
            >
              {t('addAmenity:order_form')}
            </Button>
            <form noValidate onSubmit={formik.handleSubmit} >
              <Stack flexDirection="row" marginX={3}>
                <Typography
                  variant="body1"
                  textTransform="uppercase"
                  marginRight={3}
                  color="GrayText"
                >
                  {t('addAmenity:category')}
                </Typography>
                <Typography variant="body1" fontWeight={700} color="GrayText">
                  {product.amenity.category}
                </Typography>
              </Stack>
              <ItemInfo product={product} />
              {/* -------------------------- Cards --------------------------- */}
              <Grid container marginTop={1.5} rowSpacing={3} columnSpacing={{md: 3}}>
                <Grid item xs={12} md={4}>
                  <InfoCard
                    title={t('addAmenity:current_supply')}
                    value={getML(product.amenity.est_stock ?? 0)}
                    unit={product.amenity.unit.name ?? ''}
                    {...cardStyles}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <InfoCard
                    title={t('addAmenity:stock_after_order')}
                    value={getML(stock)}
                    unit={product.amenity.unit.name ?? ''}
                    color="success.main"
                    {...cardStyles}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <InfoCard
                    title={t('addAmenity:est_demand')}
                    value={estimatedDemand}
                    unit={product.amenity.unit.name ?? ''}
                    {...cardStyles}
                  />
                </Grid>
                {/* --------------------- Buffer Percentage % -------------------- */}
                <Grid item xs={12} md={4}>
                  <Card {...cardStyles}>
                    <CardContent>
                      <Typography variant="body1" textTransform="uppercase" marginBottom={2}>
                        {`${t('addAmenity:buffer')} %`}
                      </Typography>
                      <Select
                      labelId="property-select-buffer"
                      id="select-buffer"
                      label="Select Buffer"
                      onChange={handleBufferChange}
                      value={bufferValue}
                      disabled={false}
                      size="medium"
                      aria-label={t('common:select_buffer')}
                    >
                      <MenuItem key={0} value={0.0}>No Buffer</MenuItem>
                      <MenuItem key={1} value={0.05}>5%</MenuItem>
                      <MenuItem key={2} value={0.10}>10%</MenuItem>
                      <MenuItem key={3} value={0.20}>20%</MenuItem>
                      <MenuItem key={4} value={0.30}>30%</MenuItem>
                    </Select>
                    </CardContent>
                  </Card>
                </Grid>
                {/* --------------------- Buffer Percentage % -------------------- */}
                <Grid item xs={12} md={4}>
                  <InfoCard
                    title={t('addAmenity:suggested_stock')}
                    value={stockSuggest}
                    unit={product.amenity.unit.name ?? ''}
                    {...cardStyles}
                  />
                </Grid>
                {/* --------------------- Order QTY (Box) -------------------- */}
                <Grid item xs={12} md={6}>
                  <Card {...cardStyles}>
                    <CardContent>
                      <Typography variant="h6" textTransform="uppercase" marginBottom={2}>
                        {`${t('addAmenity:order_qty')} (${product.amenity.storage.storage.name})`}
                      </Typography>
                      <TextField
                        type="number"
                        name="quantity"
                        onChange={handleQuantityChange}
                        value={formik.values.quantity}
                        placeholder={t('addAmenity:input_qty')}
                        label={t('addAmenity:order_qty')}
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {product.amenity.storage.storage.name}
                            </InputAdornment>
                          ),
                        }}
                        inputProps={{min: 0}}
                        helperText={formik.touched.quantity && formik.errors.quantity}
                        error={formik.touched.quantity && Boolean(formik.errors.quantity)}
                      />
                    </CardContent>
                  </Card>
                </Grid>
                {/* --------------------- Order QTY (Box) -------------------- */}
                {/* --------------------- Order QTY (UOM) -------------------- */}
                <Grid item xs={12} md={6}>
                  <Card {...cardStyles}>
                    <CardContent>
                      <Typography variant="h6" textTransform="uppercase" marginBottom={2}>
                        {t('addAmenity:order_qty_uom')}
                      </Typography>
                      <Typography variant="h4">{getML(amount)}</Typography>
                    </CardContent>
                  </Card>
                </Grid>
                {/* --------------------- Order QTY (UOM) -------------------- */}
                {/* -------------------------- Cost -------------------------- */}
                <Grid item xs={12} md={6}>
                  <Card {...cardStyles}>
                    <CardContent>
                      <Typography variant="h6" textTransform="uppercase" marginBottom={2}>
                        {t('addAmenity:cost')}
                      </Typography>
                      <Typography variant="h4" textTransform="uppercase">
                        {getPrice(price)}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                {/* -------------------------- Cost -------------------------- */}
                {/* ---------------------- Est. Arrival ---------------------- */}
                <Grid item xs={12} md={6}>
                  <Card {...cardStyles}>
                    <CardContent>
                      <Typography variant="h6" textTransform="uppercase" marginBottom={2}>
                        {t('addAmenity:est_arrival')}
                      </Typography>
                      <Typography variant="h4" textTransform="uppercase">
                        {getEstArrival(product.amenity.purchase.delivery)}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                {/* ---------------------- Est. Arrival ---------------------- */}
                <Grid item xs={12} marginTop={3}>
                  <Button variant="contained" type="submit" fullWidth>
                    {t('addAmenity:update_order')}
                  </Button>
                </Grid>
              </Grid>
              {/* -------------------------- Cards --------------------------- */}
            </form>
          </CardContent>
        </Card>
      )}
    </>
  );
});

export default AddAmenity;

interface IItemInfoProps {
  product: ISessionStorageProduct;
}

const ItemInfo: React.FC<IItemInfoProps> = observer(({product}) => {
  const {t} = useTranslation();

  {
    return product ? (
      <Grid container spacing={{xs: 2}}>
        {/* ---------------------- Product SKU / Name ------------------------ */}
        <Grid item xs={12} sm={6} md={3} lg>
          <Typography variant="h5" component="p" textTransform="uppercase" fontWeight={600}>
            {product.amenity.sku}
          </Typography>
          <Typography variant="body1" color="primary" fontWeight={600}>
            {product.amenity.name}
          </Typography>
        </Grid>
        {/* ---------------------- Product SKU / Name ------------------------ */}
        {/* -------------------------- Unit (UOM) ---------------------------- */}
        <Grid item xs={12} sm={6} md={3} lg>
          <Info title={t('addAmenity:unit_uom')} value={product.amenity.unit.name} />
        </Grid>
        {/* -------------------------- Unit (UOM) ---------------------------- */}
        {/* -------------------------- Price / box --------------------------- */}
        <Grid item xs={12} sm={6} md={3} lg>
          <Info
            title={t('addAmenity:price_per_box')}
            value={
              //eg. ¥12.320 (¥15.4 per ea)
              `${getPrice(Number(product.amenity.purchase.price))}
            (${getPrice(Number(product.amenity.purchase.unit_price))}
            ${t('addAmenity:per')}
            ${product.amenity.unit.name}
            )`
            }
          />
        </Grid>
        {/* -------------------------- Price / box --------------------------- */}
        {/* ----------------------------- Brand ------------------------------ */}
        <Grid item xs={12} sm={6} md={3} lg>
          <Info title={t('common:brand')} value={product.amenity.brand.name} />
        </Grid>
        {/* ----------------------------- Brand ------------------------------ */}
        {/* ----------------------------- Vendor ----------------------------- */}
        <Grid item xs={12} sm={6} md={3} lg>
          <Info title={t('common:vendor')} value={product.amenity.purchase.vendor.name} />
        </Grid>
        {/* ----------------------------- Vendor ----------------------------- */}
      </Grid>
    ) : (
      <></>
    );
  }
});

interface IInfoProps {
  title: string;
  value: string;
}

const Info: React.FC<IInfoProps> = observer(({title, value}) => {
  return (
    <Stack gap={1}>
      <Typography variant="body1" textTransform="uppercase" fontWeight={600}>
        {title}
      </Typography>
      <Typography variant="body2" fontWeight={700} color="GrayText">
        {value}
      </Typography>
    </Stack>
  );
});

interface IInfoCardProps {
  title: string;
  value: string | number;
  unit: string;
  color?: string;
}

const InfoCard: React.FC<IInfoCardProps> = observer(({title, value, unit, color}) => {
  return (
    <Card>
      <CardContent>
        <Stack alignItems="center" gap={1}>
          <Typography variant="h5" color="GrayText">
            {title}
          </Typography>
          <Stack flexDirection="row" gap={1} alignItems="baseline">
            <Typography variant="h4" color={color || 'inherit'} fontWeight={500}>
              {value}
            </Typography>
            {unit && (
              <Typography variant="body1" color="GrayText">
                {unit}
              </Typography>
            )}
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
});
