import {Stack, TextField, Typography} from '@mui/material';
import type {FormikProps} from 'formik';
import React from 'react';
import type {IFormikInitValues} from './Settings';

interface IUpdateFieldProps {
  label: string;
  placeholder: string;
  name: string;
  formik: FormikProps<IFormikInitValues>;
}

const UpdateField: React.FC<IUpdateFieldProps> = ({label, placeholder, name, formik}) => {
  const formikValueKey = name as keyof typeof formik.values;

  return (
    <Stack width="100%">
      <Typography variant="h6" component="h3" mb={2}>
        {label}
      </Typography>
      <TextField
        name={name}
        value={formik.values[formikValueKey]}
        onChange={formik.handleChange}
        label={placeholder}
        fullWidth
        placeholder={placeholder}
        error={formik.touched[formikValueKey] && Boolean(formik.errors[formikValueKey])}
        helperText={formik.touched[formikValueKey] && formik.errors[formikValueKey]}
      />
    </Stack>
  );
};

export default UpdateField;
