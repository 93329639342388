import {DateTime} from 'luxon';
import request from 'superagent';
import Config from '../../config';
import Common, {
  IAmenityResponse,
  IBrand,
  IPurchaseResponse,
  IStorageResponseData,
  IUnit,
} from '../common';

// CHECKED INTERFACE
export interface IGetItemsItem {
  id: number;
  brand: IBrand;
  unit: IUnit;
  purchase: IPurchaseResponse;
  storage: IStorageResponseData;
  stock: number;
  est_stock: number;
  est_demand: number;
  suggested_stock: number;
  buffer: number;
  status: string; // ok or low
  est_box: number;
  since_count: number;
  daily_usage: number;
  sku: string;
  name: string;
  consumption_tax: number;
  category: number;
  hotel: Array<number>;
}

// CHECKED INTERFACE
interface IGetItemsResult {
  name: string;
  item: Array<IGetItemsItem>;
}

// CHECKED TYPE
export type TGetItemsResponse = Array<IGetItemsResult>;

// CHECKED INTERFACE
export interface IGetStockResult {
  id: number;
  amenity: IAmenityResponse;
  levels: Array<IStorageResponseData>;
  est_stock: number;
  delta_count: number;
  total: number;
  saved: boolean;
  ctime: string;
  creator: string;
  storage_levels: {
    LV1: number;
    LV2?: number;
    LV3?: number;
  };
  hotel: number;
}

// CHECKED TYPE
export type TGetStockResponse = Array<IGetStockResult>;

// CHECKED INTERFACE
interface IUpdateStockPayloadItem {
  id: number;
  total: number;
  saved: boolean;
  storage_levels: {
    LV1: number;
    LV2?: number;
    LV3?: number;
  };
}

// CHECKED TYPE
export type TUpdateStockPayload = Array<IUpdateStockPayloadItem>;

// CHECKED INTERFACE
export interface IGetStockHistoryResult {
  hotel_name: string;
  amenity: string;
  ctime: string;
  total: number;
  creator: string;
  storage_levels: {
    LV1: 0;
    LV2?: 0;
    LV3?: 0;
  };
}

// CHECKED INTERFACE
export interface IGetStockHistoryResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: Array<IGetStockHistoryResult>;
}

// CHECKED INTERFACE
export interface IGetPreviousStockCountResult {
  id: number;
  amenity: IAmenityResponse;
  levels: Array<IStorageResponseData>;
  est_stock: number;
  delta_count: number;
  total: number;
  saved: boolean;
  ctime: string;
  creator: string;
  storage_levels: {
    LV1: number;
    LV2: number;
    LV3: number;
  };
  hotel: number;
}

export type TGetPreviousStockCountResponse = Array<IGetPreviousStockCountResult>;

export interface IUpdatePreviousStockCountPayloadItem {
  id: number;
  total: number | bigint;
  saved: boolean;
  storage_levels: {
    LV1: number;
    LV2?: number;
    LV3?: number;
  };
}

export type TUpdatePreviousStockCountPayload = Array<IUpdatePreviousStockCountPayloadItem>;

export default {
  getItems: function (token: string, hotelId: number) {
    const url = `${Config.API_URL}/inventory/amenity/list/${hotelId}/`;

    return request.get(url).accept(Common.MEDIA_TYPE).auth(token, {type: 'bearer'}).send();
  },

  getStock: function (token: string, hotelId: number) {
    const url = `${Config.API_URL}/inventory/stock/count/${hotelId}/`;

    return request.post(url).accept(Common.MEDIA_TYPE).auth(token, {type: 'bearer'}).send();
  },

  getPreviousStockCount: function (token: string, hotelId: number, countId: string) {
    const url = `${Config.API_URL}/inventory/stock/${countId}/${hotelId}/`;
    return request.get(url).auth(token, {type: 'bearer'}).accept(Common.MEDIA_TYPE).send();
  },

  // NO RESPONSE INTERFACE
  updatePreviousStockCount: function (
    token: string,
    hotelId: number,
    countId: string,
    payload: TUpdatePreviousStockCountPayload,
  ) {
    const url = `${Config.API_URL}/inventory/stock/${countId}/${hotelId}/`;

    return request.put(url).auth(token, {type: 'bearer'}).accept(Common.MEDIA_TYPE).send(payload);
  },

  deletePreviousStockCount: function (token: string, hotelId: number, countId: string) {
    const url = `${Config.API_URL}/inventory/stock/${countId}/${hotelId}/`;

    return request.delete(url).auth(token, {type: 'bearer'}).accept(Common.MEDIA_TYPE).send();
  },

  deleteStock: function (token: string, hotelId: number) {
    const url = `${Config.API_URL}/inventory/stock/count/${hotelId}/`;

    return request.delete(url).accept(Common.MEDIA_TYPE).auth(token, {type: 'bearer'}).send();
  },

  // NO RESPONSE INTERFACE
  updateStock: function (token: string, hotelId: number, payload: TUpdateStockPayload) {
    const url = `${Config.API_URL}/inventory/stock/count/${hotelId}/`;

    return request
      .put(url)
      .auth(token, {type: 'bearer'})
      .type(Common.MEDIA_TYPE)
      .accept(Common.MEDIA_TYPE)
      .send(JSON.stringify(payload));
  },

  getStockHistory: function (
    token: string,
    hotelId: number,
    dateFrom: string | null,
    dateTo: string | null,
    nextPreviousUrl: string | null,
  ) {
    const url = nextPreviousUrl || `${Config.API_URL}/inventory/previous_stock/${hotelId}/`;

    if (nextPreviousUrl && nextPreviousUrl.length > 0) {
      return request.get(url).auth(token, {type: 'bearer'}).accept(Common.MEDIA_TYPE).send();
    }
    return request
      .get(url)
      .query({start: dateFrom, end: dateTo})
      .auth(token, {type: 'bearer'})
      .accept(Common.MEDIA_TYPE)
      .send();
  },
  exportStockHistory: function (
    token: string,
    hotelId: number,
    dateFrom: string | null,
    dateTo: string | null,
  ) {
    const url = `${Config.API_URL}/inventory/previous_stock/export/${hotelId}/`;

    if (!dateTo) {
      dateTo = DateTime.now().toFormat('yyyy-MM-dd');
    }

    return request
      .get(url)
      .query({start: dateFrom, end: dateTo})
      .auth(token, {type: 'bearer'})
      .responseType('blob');
  },
};
