import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {toJS} from 'mobx';
import {observer} from 'mobx-react-lite';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import NoResultsFound from '../../components/general/NoResultsFound';
import SkeletonLoader from '../../components/general/SkeletonLoader';
import StockItemsList from '../../components/inventory/StockItemsList';
import SelectHotel from '../../components/Navbar/SelectHotel';
import PageTitle from '../../components/PageTitle';
import {useRootStore} from '../../context';
import '../../styles/main.css';

const Stock = observer(() => {
  const {t} = useTranslation();
  const {stockStore, hotelStore, inventoryStore} = useRootStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (hotelStore.currentHotel !== undefined) {
      stockStore.getItems();
    }
  }, [hotelStore.currentHotel]);

  useEffect(() => {
    if (inventoryStore.units.length === 0) {
      inventoryStore.getUnits();
    }
  }, [inventoryStore.units]);

  useEffect(() => {
    if (inventoryStore.brands.length === 0) {
      inventoryStore.getBrands();
    }
  }, [inventoryStore.brands]);

  useEffect(() => {
    if (hotelStore.currentHotel !== undefined && inventoryStore.latestCount === '') {
      inventoryStore.getLatestCount();
    }
  }, [hotelStore.currentHotel, inventoryStore.latestCount]);

  const isDesktop = useMediaQuery('(min-width: 1200px)');

  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <PageTitle>{t('common:inventory_and_supplies')}</PageTitle>
        </Grid>
        <Grid item xs={12} md={4}>
          <SelectHotel />
        </Grid>
      </Grid>
      {/* ----------------------- Main Card - Start ------------------------ */}
      <Card
        elevation={isDesktop ? 2 : 0}
        sx={{
          marginTop: 5,
          backgroundColor: {xs: 'transparent', lg: '#FFF'},
          overflow: 'visible',
        }}
      >
        <CardContent sx={{padding: {xs: 0, lg: 2}}}>
          {/* ---------------------- Top - Start ----------------------  */}
          <Stack flexDirection="row" justifyContent="space-between">
            <Typography variant="h5" component="h2">
              {t('items:items_list')}
            </Typography>
            <div>
              <Button variant={'text'} href={'/inventory/previous/stock-counts'} className={'me-5'}>
                {t('stockCount:view_previous_counts')}
              </Button>
              <Button variant={'contained'} onClick={() => navigate('/inventory/stock-count')}>
                {t('stockCount:new_stock_count')}
              </Button>
            </div>
          </Stack>
          <Typography variant="body1" color="GrayText" marginBottom={3}>
            {t('stockCount:last_count', {
              latestCount: inventoryStore.latestCount || '...',
            })}
          </Typography>
          {/* ----------------------- Top - End ------------------------ */}

          <Grid container gap={{xs: 5, lg: 0}}>
            <Grid item width="100%">
              {stockStore.isLoading && <SkeletonLoader skeletonShape="table" amount={5} />}

              {stockStore.items.length > 0 && !stockStore.isLoading && (
                <StockItemsList categories={toJS(stockStore.items)} />
              )}

              {stockStore.items.length === 0 && !stockStore.isLoading && (
                <NoResultsFound message={t('common:there_are_no_items')} />
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {/* ------------------------ Main Card - End ------------------------- */}
    </Container>
  );
});

export default Stock;
