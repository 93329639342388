import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {useParams, useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import Api, {
  TGetPreviousStockCountResponse,
  TUpdatePreviousStockCountPayload,
} from '../../api/inventory/stock';
import {toast} from 'react-toastify';
import {
  Modal,
  Paper,
  Button,
  Divider,
  Card,
  CardContent,
  Container,
  FormControl,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import {useRootStore} from '../../context';
import PageTitle from '../../components/PageTitle';
import Header from '../../components/table/Header';
import {LevelInput, MAX_QUANTITY} from './StockCount';
import CardRowItem from '../../components/table/CardRowItem';
import SelectHotel from '../../components/Navbar/SelectHotel';
import {useTranslation} from 'react-i18next';

interface ITotals {
  lv1: number;
  lv2: number;
  lv3: number;
}
type TTotals = Record<string, ITotals>;

const EditCount = observer(() => {
  const {t} = useTranslation();
  const {hotelStore, accountStore} = useRootStore();
  const isDesktop = useMediaQuery('(min-width: 1200px)');
  const navigate = useNavigate();
  const {countId} = useParams();
  const [yp, setYp] = useState({});
  const [stock, setStock] = useState<TGetPreviousStockCountResponse>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState<TTotals>({});
  const [modalOpen, setModalOpen] = useState(false);
  const modalStyles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  const getPreviousStockCount = async () => {
    const token = accountStore.user.token;
    const currentHotel = hotelStore.currentHotel?.id;

    if (token && currentHotel && countId) {
      try {
        setIsLoading(true);

        const response = await Api.getPreviousStockCount(token, currentHotel, countId);

        if (response.ok) {
          const data = await response;
          setStock(data.body);
        }
      } catch (error: any) {
        toast.error(error.message);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const updatePreviousStockCount = async (payload: TUpdatePreviousStockCountPayload) => {
    const token = accountStore.user.token;
    const currentHotel = hotelStore.currentHotel?.id;

    if (token && currentHotel && countId) {
      try {
        setIsLoading(true);

        const response = await Api.updatePreviousStockCount(token, currentHotel, countId, payload);

        if (response.ok) {
          toast.success(t('stockCount:stock_count_updated'));
          navigate(-1);
        }
      } catch (error: any) {
        toast.error(error.message);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const deletePreviousStockCount = async () => {
    const token = accountStore.user.token;
    const currentHotel = hotelStore.currentHotel?.id;

    if (token && currentHotel && countId) {
      try {
        setIsLoading(true);

        const response = await Api.deletePreviousStockCount(token, currentHotel, countId);

        if (response.ok) {
          toast.success(t('stockCount:stock_count_deleted'));
          navigate(-1);
        }
      } catch (error: any) {
        toast.error(error.message);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (hotelStore.currentHotel !== undefined && accountStore.token) {
      getPreviousStockCount();
    }
  }, [countId, hotelStore.currentHotel]);

  useEffect(() => {
    if (stock.length > 0) {
      const totals: TTotals = {};
      stock.forEach((obj) => {
        const key = `total_${obj.id}`;
        totals[key] = {lv1: 0, lv2: 0, lv3: 0};
        obj.levels.forEach((level) => {
          totals[key as string][level.level.toLowerCase() as keyof ITotals] =
            obj.storage_levels[level.level.toUpperCase() as keyof typeof obj.storage_levels] *
            level.quantity;

          const formikKey = `${level.level.toLowerCase()}_${obj.id}`;

          formik.initialValues[formikKey as keyof typeof formik.initialValues] =
            obj.storage_levels[level.level.toUpperCase() as keyof typeof obj.storage_levels];
          formik.setFieldValue(
            formikKey,
            obj.storage_levels[level.level.toUpperCase() as keyof typeof obj.storage_levels],
          );
        });
      });
      setTotal(totals);

      const validate: Record<string, any> = {};
      stock.forEach((obj) => {
        obj.levels.forEach((level) => {
          const formikKey = `${level.level.toLowerCase()}_${obj.id}`;

          validate[formikKey] = Yup.number()
            .min(0, t('stockCount:minimum_0'))
            .max(MAX_QUANTITY, t(`stockCount:maximum_qty`, {maxQty: MAX_QUANTITY}))
            .typeError(t('stockCount:please_enter_number'))
            .required(t('stockCount:number_required'));
        });
      });
      setYp(validate);
    }
  }, [stock]);

  const handleDelete = (e: React.MouseEvent) => {
    e.preventDefault();

    setModalOpen(false);

    deletePreviousStockCount();
  };

  const formik = useFormik({
    initialValues: {} as Record<string, number>,
    enableReinitialize: false,
    validationSchema: Yup.object().shape(yp),
    onSubmit: (values) => {
      const payload: TUpdatePreviousStockCountPayload = Object.keys(total).map((key) => {
        const segs = key.split('_');
        return {
          id: parseInt(segs[1]),
          total: total[key].lv1 + total[key].lv2 + total[key].lv3,
          saved: true,
          storage_levels: {
            LV1: values[`lv1_${segs[1]}`],
            LV2: values[`lv2_${segs[1]}`],
            LV3: values[`lv3_${segs[1]}`],
          },
        };
      });

      updatePreviousStockCount(payload);
    },
  });

  const updateTotal = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const tot = {...total};
    const value =
      parseInt(e.target.value) < 0
        ? 0
        : parseInt(e.target.value) > MAX_QUANTITY
        ? MAX_QUANTITY
        : parseInt(e.target.value);
    const segs = e.target.name.split('_');
    const amenity = stock.find((obj) => {
      return obj.id === parseInt(segs[1]);
    });

    if (amenity) {
      const level = amenity.levels.find((obj) => {
        return obj.level === segs[0].toUpperCase();
      });

      if (level) {
        setTotal({
          ...tot,
          [`total_${segs[1]}`]: {
            ...tot[`total_${segs[1]}`],
            [segs[0]]: value * level.quantity,
          },
        });
        formik.handleChange(e);
      }
    }
  };

  const getTotal = (key: string) => {
    if (key in total) {
      return total[key].lv1 + total[key].lv2 + total[key].lv3;
    }
    return 0;
  };

  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <PageTitle>{t('stockCount:edit_stock_count')}</PageTitle>
        </Grid>
        <Grid item xs={12} md={4}>
          <SelectHotel disabled={true} />
        </Grid>
      </Grid>
      <Card
        elevation={isDesktop ? 2 : 0}
        sx={{
          backgroundColor: {xs: 'transparent', lg: 'background.paper'},
          marginTop: 5,
          overflow: 'visible',
        }}
      >
        <CardContent sx={{padding: {xs: 0, lg: 2}}}>
          <Button
            variant="text"
            color="gray"
            href="/inventory/previous/stock-counts"
            startIcon={<ArrowBackOutlinedIcon />}
          >
            <Typography variant="h6" component="h2" fontWeight={600}>
              {t('stockCount:previous_stock_counts')}
            </Typography>
          </Button>
          <FormControl component="form" onSubmit={formik.handleSubmit} sx={{display: 'block'}}>
            <Grid container display={{xs: 'none', lg: 'flex'}} className={'mt-5'}>
              {/* ------------------------ Headers ------------------------- */}
              <Header justifyContent="center" lg={1.25}>
                {t('common:code')}
              </Header>
              <Header lg={1}>{t('common:unit')}</Header>
              <Header lg={1}>{t('common:est_stock')}</Header>
              <Header justifyContent="center" color="primary.main" lg={2}>
                {t('common:LV1')}
              </Header>
              <Header justifyContent="center" color="success.main" lg={2}>
                {t('common:LV2')}
              </Header>
              <Header justifyContent="center" color="warning.main" lg={2}>
                {t('common:LV3')}
              </Header>
              <Header lg={1}>{t('common:total')}</Header>
              <Header justifyContent="center" lg={1.75}>
                {t('stockCount:previous_base_consumption')}
              </Header>
            </Grid>
            {/* ------------------------ Headers ------------------------- */}
            <Grid container item xs={12} paddingX={1} gap={{xs: 5, lg: 0}}>
              {stock.map((obj, index) => {
                const info = [
                  {
                    label: isDesktop ? t('common:code') : obj.amenity.sku,
                    value: isDesktop ? obj.amenity.sku : obj.amenity.name,
                    lg: 1.25,
                    alignItems: 'center',
                  },
                  {
                    label: t('common:unit'),
                    value: obj.amenity.unit.name,
                    lg: 1,
                  },
                  {
                    label: t('common:est_stock'),
                    value: obj.est_stock,
                    lg: 1,
                  },
                  {
                    label: t('common:LV1'),
                    value: (
                      <LevelInput obj={obj} lv="LV1" updateTotal={updateTotal} formik={formik} />
                    ),
                    lg: 2,
                  },
                  {
                    label: t('common:LV2'),
                    value: (
                      <LevelInput obj={obj} lv="LV2" updateTotal={updateTotal} formik={formik} />
                    ),
                    lg: 2,
                  },
                  {
                    label: t('common:LV3'),
                    value: (
                      <LevelInput obj={obj} lv="LV3" updateTotal={updateTotal} formik={formik} />
                    ),
                    lg: 2,
                  },
                  {
                    label: t('common:total'),
                    value: getTotal(`total_${obj.id}`),
                    lg: 0.75,
                  },
                  {
                    label: t('stockCount:previous_base_consumption'),
                    value: obj.delta_count,
                    lg: 1.75,
                    alignItems: 'center',
                  },
                ];

                return <CardRowItem key={index} item={info} />;
              })}
            </Grid>
            <Stack flexDirection="row" justifyContent="space-between" marginTop={3}>
              <Button variant="contained" color="error" onClick={() => setModalOpen(true)}>
                {t('common:delete')}
              </Button>

              {/*}
              <Button variant="contained" color="warning" onClick={() => {formik.setTouched({}, false);}}>
                {t('Reset Errors')}
              </Button>
              */}

              <LoadingButton type="submit" loading={isLoading} variant="contained">
                {t('common:update')}
              </LoadingButton>
            </Stack>
          </FormControl>
        </CardContent>
      </Card>
      <Modal open={modalOpen}>
        <Paper sx={modalStyles}>
          <Typography variant="h4" component="h3" fontWeight={600} mb={1}>
            {t('stockCount:delete_stock_count_question')}
          </Typography>
          <Divider />
          <Typography variant="body1" fontWeight={600} gutterBottom color="GrayText" mt={2}>
            {t('stockCount:cannot_be_undone')}
          </Typography>
          <Divider />
          <Stack flexDirection="row" mt={2} justifyContent="end" gap={2}>
            <Button variant="outlined" onClick={() => setModalOpen(false)} color="gray">
              {t('common:close')}
            </Button>
            <Button variant="contained" onClick={handleDelete} color="error">
              {t('common:delete')}
            </Button>
          </Stack>
        </Paper>
      </Modal>
    </Container>
  );
});

export default EditCount;
