import {StatusCodes} from 'http-status-codes';
import {makeObservable, action, observable, autorun, IReactionDisposer} from 'mobx';
import {toast} from 'react-toastify';
import Api, {TGetVendorResponse} from '../../api/inventory/vendor';
import i18n from '../../i18n';
import {handleUnauthorizedResponse} from '../../libs/utils';
import type RootStore from '../rootStore';

interface IVendor {
  rootStore: RootStore;
  submitting: boolean;
  vendors: TGetVendorResponse;
  getVendors: () => void;
  getVendor: (vendorId: number) => void;
  setVendors: (vendors: TGetVendorResponse) => void;
  setSubmitting: (isSubmitting: boolean) => void;
  disposer: IReactionDisposer;
}

class Vendor implements IVendor {
  rootStore;
  submitting = false;
  vendors: TGetVendorResponse = [];
  disposer: IReactionDisposer;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      vendors: observable,
      submitting: observable,
      getVendors: action,
      getVendor: action,
      setVendors: action,
      setSubmitting: action,
    });

    this.disposer = autorun(() => {
      this.getVendors();
    });
  }

  setSubmitting(isSubmitting: boolean) {
    this.submitting = isSubmitting;
  }

  setVendors(vendors: TGetVendorResponse) {
    this.vendors = vendors;
  }

  getVendor(vendorId: number) {
    const vendor = this.vendors.find((vendor) => {
      return vendor.id === vendorId;
    });
    if (vendor) return vendor;
    return {id: vendorId, name: ''};
  }

  async addVendor(name: string, type: string, info: string) {
    const token = this.rootStore.accountStore.user.token;

    if (token) {
      try {
        this.setSubmitting(true);

        const response = await Api.addVendor(token, name, type, info);

        if (response.ok) {
          this.getVendors();
          toast.success(i18n.t('vendor:vendor_added'));
        }
      } catch (error: any) {
        if (error.status === StatusCodes.UNAUTHORIZED) {
          handleUnauthorizedResponse();
          return;
        }
        toast.error(error.message);
      } finally {
        this.setSubmitting(false);
      }
    }
  }

  async deleteVendor(vendorId: number) {
    const token = this.rootStore.accountStore.user.token;

    if (token) {
      try {
        const response = await Api.deleteVendor(token, vendorId);

        if (response.ok) {
          this.getVendors();
        }
      } catch (error: any) {
        if (error.status === StatusCodes.UNAUTHORIZED) {
          handleUnauthorizedResponse();
          return;
        }
        toast.error(error.message);
      }
    }
  }

  async getVendors() {
    const token = this.rootStore.accountStore.user.token;

    if (token) {
      try {
        const response = await Api.getVendors(token);

        if (response.ok) {
          const data = await response;

          this.setVendors(data.body);
        }
      } catch (error: any) {
        if (error.status === StatusCodes.UNAUTHORIZED) {
          handleUnauthorizedResponse();
          return;
        }
      }
    }
  }
}

export default Vendor;
