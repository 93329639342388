import React from 'react';
import {Paper, Stack, Typography} from '@mui/material';

interface ILinenInfoCardProps {
  title: string;
  value: string;
}

const LinenInfoCard: React.FC<ILinenInfoCardProps> = ({title, value}) => {
  return (
    <Paper sx={{width: '100%', backgroundColor: '#FFF', display: {xs: 'none', md: 'block'}}}>
      <Stack gap={2} alignItems="center" padding={3}>
        <Typography variant="h6" component="h2" color="GrayText">
          {title}
        </Typography>
        <Typography variant="h4" component="p" fontWeight="600">
          {value}
        </Typography>
      </Stack>
    </Paper>
  );
};
export default LinenInfoCard;
