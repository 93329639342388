import {Grid, InputLabel, TextField, Typography} from '@mui/material';
import type {FormikProps} from 'formik';
import React from 'react';

interface ILinenStockItemProps {
  id: number;
  linenName: string;
  total: number;
  formik: FormikProps<Record<string, never>>;
}

const LinenStockItem: React.FC<ILinenStockItemProps> = ({id, linenName, total, formik}) => {
  const getFieldValue = (value: undefined | null | number) => {
    if (value === undefined || value === null) {
      return 0;
    }
    return value;
  };

  return (
    <Grid container alignItems="center">
      <Grid item xs={8.5} sm={8} md={6}>
        {/* --------------------------- Label ------------------------------ */}
        <InputLabel htmlFor={`total_${id}`} sx={{position: 'static', transform: 'unset'}}>
          <Typography fontWeight="500">{linenName}</Typography>
        </InputLabel>
        {/* --------------------------- Label ------------------------------ */}
      </Grid>
      <Grid item xs={3.5} sm={4} md={6}>
        {/* --------------------------- Input ------------------------------ */}
        <TextField
          id={`total_${id}`}
          type="number"
          inputProps={{min: 0}}
          error={formik.touched[`total_${id}`] && Boolean(formik.errors[`total_${id}`])}
          helperText={formik.errors[`total_${id}`]}
          onChange={(e) => {
            formik.handleChange(e);
            formik.setFieldTouched(`total_${id}`);
          }}
          name={`total_${id}`}
          value={getFieldValue(formik.values[`total_${id}`])}
          fullWidth
          sx={{'& legend': {display: 'none'}}}
        >
          {total}
        </TextField>
        {/* --------------------------- Input ------------------------------ */}
      </Grid>
    </Grid>
  );
};
export default LinenStockItem;
