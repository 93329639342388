import {useMediaQuery, Grid, Typography} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import Header from '../table/Header';

import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';
import {getPrice} from '../../libs/utils';
import CardRowItem from '../table/CardRowItem';
import type {TGetItemsResponse} from '../../api/inventory/stock';
import {useTranslation} from 'react-i18next';

interface IStockItemsListProps {
  categories: TGetItemsResponse;
}

const StockItemsList: React.FC<IStockItemsListProps> = ({categories}) => {
  const isDesktop = useMediaQuery('(min-width: 1200px)');

  const {t} = useTranslation();

  return (
    <>
      {categories.map((category) => {
        return (
          <Accordion key={category.name} sx={{width: '100%'}}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography fontWeight={'bold'}>{category.name}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container display={{xs: 'none', lg: 'flex'}}>
                <Header lg={1} justifyContent="start">
                  {t('common:item_name')}
                </Header>
                <Header lg={1}>{t('common:status')}</Header>
                <Header lg={1}>{t('common:unit')}</Header>
                <Header lg={2}>{t('common:brand')}</Header>
                <Header lg={2}>{t('common:vendor')}</Header>
                <Header lg={1}>{t('common:unit_price')}</Header>
                <Header lg={1}>{t('common:est_stock')}</Header>
                <Header lg={1}>{t('common:est_boxes')}</Header>
                <Header lg={1}>{t('common:used_since_count')}</Header>
                <Header lg={1}>{t('common:consumed_per_traveler')}</Header>
              </Grid>
              {category.item.map((item) => {
                const itemStatusColor = item.status === 'low' ? 'warning.light' : 'green';
                const itemStatus = (
                  <Typography
                    variant="body1"
                    component="p"
                    textTransform="uppercase"
                    fontWeight={700}
                    color={itemStatusColor}
                  >
                    {item.status}
                  </Typography>
                );

                const info = [
                  {
                    label: t('common:item_name'),
                    value: item.name,
                    lg: 1,
                    alignItems: 'start',
                  },
                  {
                    label: t('common:status'),
                    value: itemStatus,
                    lg: 1,
                  },
                  {label: t('common:unit'), value: item.unit.name, lg: 1},
                  {label: t('common:brand'), value: item.brand.name, lg: 2},
                  {label: t('common:vendor'), value: item.purchase.vendor.name, lg: 2},
                  {
                    label: t('common:price'),
                    value: getPrice(Number(item.purchase.unit_price)),
                    lg: 1,
                  },
                  {label: t('common:stock'), value: item.est_stock, lg: 1},
                  {label: t('common:boxes'), value: item.est_box, lg: 1},
                  {
                    label: t('stockCount:previous_base_consumption'),
                    value: item.since_count,
                    lg: 1,
                  },
                  {label: t('common:usage'), value: item.daily_usage, lg: 1},
                ];

                return <CardRowItem key={item.name} item={info} />;
              })}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
};

export default StockItemsList;
