import request from 'superagent';
import Config from '../config';
import Common from './common';

export type TCodes = {
  code_1: string;
  code_2: string;
  code_3: string;
};
export interface IDoorCodes {
  id: number;
  code_1: string;
  code_2: string;
  code_3: string;
  room_number: string;
  hotel_id: number;
}

export interface IGetHotelDoorLocksResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: Array<IDoorCodes>;
}

export interface IGetHotelsResult {
  id: number;
  short_name: string;
  long_name: string;
  hotel_door_lock: string;
}

export type TGetHotelsResponse = Array<IGetHotelsResult>;

export default {
  getHotels: function (token: string) {
    const url = `${Config.API_URL}/hotel/`;

    return request.get(url).auth(token, {type: 'bearer'}).accept(Common.MEDIA_TYPE).send();
  },
  getHotelDoorLocks: function (token: string, hotelId: number) {
    const url = `${Config.API_URL}/hotel/unmanaged-door-locks/`;

    return request
      .get(url)
      .auth(token, {type: 'bearer'})
      .query({hotel_id: hotelId})
      .accept(Common.MEDIA_TYPE)
      .send();
  },
  /* getHotelDoorLock: function (token: string, hotelId: number, lockId: number) {
    const url = `${Config.API_URL}/hotel/unmanaged-door-locks/${lockId}`;

    return request
      .get(url)
      .auth(token, {type: 'bearer'})
      .query({hotel_id: hotelId})
      .accept(Common.MEDIA_TYPE)
      .send();
  }, */
  updateHotelDoorLocks: function (token: string, lockId: number, codes: TCodes) {
    const url = `${Config.API_URL}/hotel/unmanaged-door-locks/${lockId}/`;

    return request.patch(url).auth(token, {type: 'bearer'}).accept(Common.MEDIA_TYPE).send(codes);
  },
  saveBuildingCode: function (token: string, hotelId: number, code: string) {
    const url = `${Config.API_URL}/hotel/update/door_lock/${hotelId}/`;
    const payload = {code};

    return request.put(url).auth(token, {type: 'bearer'}).accept(Common.MEDIA_TYPE).send(payload);
  },
};
