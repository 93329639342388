import React from 'react';
import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from '@mui/material';
import useProperties from '../../hooks/properties';
import {useRootStore} from '../../context';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import type {IGetHotelsResult} from '../../api/hotel';

interface ISelectHotelProps {
  disabled?: boolean;
}

const SelectHotel: React.FC<ISelectHotelProps> = observer(({disabled}) => {
  const properties = useProperties();

  const {t} = useTranslation();

  const {hotelStore} = useRootStore();

  const handleSelectHotel = (event: SelectChangeEvent<number>) => {
    hotelStore.setCurrentHotel(
      properties.properties.find(
        (property) => property.id === Number(event.target.value),
      ) as IGetHotelsResult,
    );
  };

  if (properties.properties.length > 0) {
    return (
      <FormControl fullWidth>
        <InputLabel id="property-select-label">{t('common:select_property')}</InputLabel>
        <Select
          labelId="property-select-label"
          id="property-select"
          value={properties.selected.id}
          label="Select Property"
          onChange={handleSelectHotel}
          disabled={disabled}
          size="medium"
          aria-label={t('common:select_property')}
          aria-describedby={'currentHotel'}
        >
          {properties.properties.map(({id, short_name}) => {
            return (
              <MenuItem key={id} value={id}>
                {short_name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  }

  return <></>;
});
export default SelectHotel;
