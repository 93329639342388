import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {Box, Button, Card, CardContent, Container, Stack} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import SelectHotel from '../Navbar/SelectHotel';
import PageTitle from '../PageTitle';

interface IAddItemStepLayoutProps {
  back?: string;
  children: React.ReactNode;
}

const AddItemStepLayout: React.FC<IAddItemStepLayoutProps> = ({back, children}) => {
  const {t} = useTranslation();

  const backButtonHref = back ? `/inventory/add-item#${back}` : '/inventory/items';

  return (
    <Container>
      <PageTitle>{t('common:inventory_and_supplies')}</PageTitle>
      <Box maxWidth="400px">
        <SelectHotel disabled />
      </Box>
      <Card sx={{mt: 5}}>
        <CardContent>
          <Stack gap={3} sx={{overflow: 'hidden'}} alignItems="start">
            <Button
              variant="text"
              color="inherit"
              startIcon={<ChevronLeftIcon fontSize="large" />}
              size="large"
              sx={{fontSize: '1.25rem', paddingLeft: 0, mb: 3}}
              href={backButtonHref}
            >
              {t('common:all_items')}
            </Button>
          </Stack>
          {children}
        </CardContent>
      </Card>
    </Container>
  );
};

export default AddItemStepLayout;
