import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import Header from '../../components/table/Header';
import CardRowItem from '../../components/table/CardRowItem';
import PageTitle from '../../components/PageTitle';
import DeleteIcon from '@mui/icons-material/Delete';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {useRootStore} from '../../context';
import SelectHotel from '../../components/Navbar/SelectHotel';
import {
  Button,
  Card,
  CardContent,
  Collapse,
  Container,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {useTranslation} from 'react-i18next';

const Vendors = observer(() => {
  const {t} = useTranslation();
  const {vendorStore} = useRootStore();
  const [show, setShow] = useState(false);
  const [vendorId, setVendorId] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (vendorStore.vendors.length === 0) vendorStore.getVendors();
  }, []);

  const showDeleteModal = (vendorId: number) => {
    setShow(true);
    setVendorId(vendorId);
  };

  const handleCancel = () => {
    setVendorId(undefined);
    setShow(false);
  };

  const handleDeleteVendor = () => {
    if (!vendorId) return;

    vendorStore.deleteVendor(vendorId);
    setVendorId(undefined);
    setShow(false);
  };

  if (vendorStore.vendors.length === 0) {
    return (
      <Typography variant="h4" component="h2" marginBottom={4}>
        {t('vendor:no_vendors_yet')}
      </Typography>
    );
  }

  const cardStyles = {
    padding: {xs: 0, lg: 1},
    bgcolor: {xs: 'transparent', lg: 'background.paper'},
    boxShadow: {
      xs: 'none',
      lg: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
    },
    overflow: 'visible',
  };

  return (
    <>
      <Modal
        open={show}
        onClose={handleCancel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{minHeight: '100vh', display: 'grid', placeItems: 'center'}}
      >
        <Card sx={{width: '85%', maxWidth: '350px'}}>
          <CardContent sx={{padding: 3}}>
            <Typography variant="h5" component="h2" marginBottom={1}>
              {t('vendor:delete_vendor_question')}
            </Typography>
            <Divider sx={{marginBottom: 3}} />
            <Stack flexDirection="row" justifyContent="end" gap={2}>
              <Button variant="text" color="inherit" onClick={handleCancel}>
                {t('common:cancel')}
              </Button>
              <Button variant="contained" color="error" onClick={handleDeleteVendor}>
                {t('vendor:delete_vendor')}
              </Button>
            </Stack>
          </CardContent>
        </Card>
      </Modal>
      <Card sx={cardStyles}>
        <CardContent sx={{padding: {xs: 0, lg: 2}}}>
          <Grid container display={{xs: 'none', lg: 'flex'}} sx={{borderBottom: '2px solid #000'}}>
            <Grid item xs={3}>
              <Header justifyContent="start">{t('vendor:vendor_name')}</Header>
            </Grid>
            <Grid item xs={3}>
              <Header justifyContent="start">{t('vendor:ordering_method')}</Header>
            </Grid>
            <Grid item xs={3}>
              <Header justifyContent="start">{t('vendor:url_phone_email')}</Header>
            </Grid>
            <Grid item xs={3}>
              <Header>&nbsp;</Header>
            </Grid>
          </Grid>
          <Grid container gap={{xs: 5, lg: 0}}>
            {vendorStore.vendors.map((storeVendor, index) => {
              const {id, name, type, info} = storeVendor;

              const obj = [
                {
                  label: t('vendor:vendor_name'),
                  value: name,
                  lg: 3,
                  alignItems: 'start',
                },
                {
                  label: t('vendor:ordering_method'),
                  value: type,
                  lg: 3,
                  alignItems: 'start',
                },
                {
                  label: t('vendor:url_phone_email'),
                  value: info,
                  lg: 3,
                  alignItems: 'start',
                },
                {
                  label: '',
                  value: (
                    <IconButton onClick={() => showDeleteModal(id)}>
                      <DeleteIcon color="error" aria-label={t('vendor:delete_vendor')} />
                    </IconButton>
                  ),
                  lg: 3,
                },
              ];

              return <CardRowItem key={index} item={obj} />;
            })}
          </Grid>
        </CardContent>
      </Card>
    </>
  );
});

const Vendor = observer(() => {
  const {t} = useTranslation();
  const {vendorStore} = useRootStore();
  const [open, setOpen] = useState(false);
  const [vendorType, setVendorType] = useState('');

  const handleVendorType = (e: SelectChangeEvent<string>) => {
    if (e.target.value === 'url') {
      setVendorType('URL');
    } else {
      if (e.target.value.length > 0) {
        const value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
        setVendorType(value);
      } else {
        setVendorType('');
      }
    }
    formik.handleChange(e);
  };

  const VendorSchema = Yup.object().shape({
    name: Yup.string().ensure().trim().required(t('vendor:vendor_name_required')),
    type: Yup.string()
      .ensure()
      .trim()
      .oneOf(['email', 'url', 'phone'])
      .required(t('vendor:ordering_method_required')),
    info: Yup.string()
      .ensure()
      .trim()
      .when('type', {
        is: (val: string) => val === 'url',
        then: (VendorSchema) => VendorSchema.url(t('vendor:not_valid_url')),
      })
      .when('type', {
        is: (val: string) => val === 'email',
        then: (VendorSchema) => VendorSchema.email(t('vendor:not_valid_email')),
      })
      .when('type', {
        is: (val: string) => val === 'phone',
        then: (VendorSchema) =>
          VendorSchema.matches(/^\+?[0-9]+$/, t('vendor:not_valid_phone')).min(
            9,
            t('vendor:not_enough_digits'),
          ),
      })
      .required(t('vendor:url_phone_email_required')),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      type: '',
      info: '',
    },
    validationSchema: VendorSchema,
    onSubmit: (values) => {
      vendorStore.addVendor(values.name, values.type, values.info);
      formik.resetForm();
      setOpen(false);
    },
  });

  const SelectMenuProps = {
    sx: {
      '& .MuiPaper-root': {
        width: {xs: '50%', md: '25%'},
      },
    },
  };

  return (
    <Container>
      <PageTitle>{t('common:inventory_and_supplies')}</PageTitle>
      <Grid container alignItems="center" gap={2} marginBottom={5}>
        <Grid item xs={12} md={4}>
          <SelectHotel disabled={true} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Button
            variant="contained"
            size="large"
            onClick={() => setOpen(!open)}
            aria-controls={'add-vendor-form'}
            aria-expanded={open}
          >
            {t('vendor:add_vendor')}
          </Button>
        </Grid>
      </Grid>
      <Collapse in={open} sx={{maxWidth: {md: '50%'}, marginBottom: open ? 5 : 0}}>
        <Card>
          <CardContent>
            <form noValidate onSubmit={formik.handleSubmit}>
              <Stack gap={3}>
                <TextField
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  label="Vendor Name"
                  placeholder="Vendor Name"
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
                <FormControl fullWidth error={formik.touched.type && Boolean(formik.errors.type)}>
                  <InputLabel id="vendor-select-label">{t('vendor:order_method')}</InputLabel>
                  <Select
                    name="type"
                    value={formik.values.type}
                    onChange={handleVendorType}
                    label={t('vendor:order_method')}
                    labelId="vendor-select-label"
                    id="vendor-select"
                    autoWidth
                    MenuProps={SelectMenuProps}
                    sx={{borderColor: 'red'}}
                  >
                    <MenuItem value="url">{t('common:url')}</MenuItem>
                    <MenuItem value="email">{t('common:email')}</MenuItem>
                    <MenuItem value="phone">{t('common:phone')}</MenuItem>
                  </Select>
                  <FormHelperText error={formik.touched.type && Boolean(formik.errors.type)}>
                    {formik.touched.type && formik.errors.type}
                  </FormHelperText>
                </FormControl>
                <TextField
                  name="info"
                  value={formik.values.info}
                  label={vendorType || t('vendor:order_method')}
                  placeholder={vendorType}
                  onChange={formik.handleChange}
                  error={formik.touched.info && Boolean(formik.errors.info)}
                  helperText={formik.touched.info && formik.errors.info}
                />
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={vendorStore.submitting}
                  color="gray"
                  sx={{alignSelf: 'end'}}
                >
                  {t('common:submit')}
                </LoadingButton>
              </Stack>
            </form>
          </CardContent>
        </Card>
      </Collapse>
      <Vendors />
    </Container>
  );
});

export default Vendor;
