import {makeObservable, toJS, action, observable} from 'mobx';
import {toast} from 'react-toastify';
import i18n from '../i18n';
import {StatusCodes} from 'http-status-codes';
import Api, {
  IGetLinenCostsResponse,
  IGetLinenInventoryResponse,
  TGetLinenDeliveryCompaniesResponse,
  TUpdateLinenInventoryParams,
} from '../api/linen';
import LinenAction from '../libs/linenAction';
import type {IRootStore} from './rootStore';
import type RootStore from './rootStore';
import {handleUnauthorizedResponse} from '../libs/utils';

interface ILinen {
  rootStore: IRootStore;
  currentAction: LinenAction;
  delivery_companies: TGetLinenDeliveryCompaniesResponse;
  linenCosts: IGetLinenCostsResponse | null;
  isSubmitting: boolean;
  isLoading: boolean;
  success: boolean;
  inventory: IGetLinenInventoryResponse | undefined;
  error: string | undefined;
  setCurrentAction: (currentAction: string, save: boolean) => void;
  loadCurrentAction: () => void;
  setDeliveryCompanies: (deliveryCompanies: TGetLinenDeliveryCompaniesResponse) => void;
  setLinenCosts: (linenCosts: IGetLinenCostsResponse) => void;
  setIsSubmitting: (isSubmitting: boolean) => void;
  setInventory: (inventory: IGetLinenInventoryResponse) => void;
  setSuccess: (isSuccess: boolean) => void;
  setError: (value: string) => void;
  setIsLoading: (isLoading: boolean) => void;
  getInventory: () => void;
  updateInventory: (params: TUpdateLinenInventoryParams) => void;
  getDeliveryCompanies: () => void;
}

class Linen implements ILinen {
  rootStore: RootStore;
  currentAction = LinenAction.VIEW_ORDERS;
  delivery_companies: TGetLinenDeliveryCompaniesResponse = [];
  linenCosts: IGetLinenCostsResponse | null = null;
  isSubmitting = false;
  success = false;
  inventory: IGetLinenInventoryResponse | undefined = undefined;
  error: string | undefined = undefined;
  isLoading = false;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      currentAction: observable,
      delivery_companies: observable,
      linenCosts: observable,
      isSubmitting: observable,
      isLoading: observable,
      inventory: observable,
      error: observable,

      setCurrentAction: action,
      getDeliveryCompanies: action,
      setDeliveryCompanies: action,
      setLinenCosts: action,
      loadCurrentAction: action,
      setIsSubmitting: action,
      setIsLoading: action,
      setInventory: action,
      getInventory: action,
      updateInventory: action,
      setError: action,
    });

    this.loadCurrentAction();
  }

  setCurrentAction(currentAction: string, save = true) {
    if (save) {
      window.sessionStorage.setItem('currentAction', currentAction);
    }
    this.currentAction = currentAction;
  }

  loadCurrentAction() {
    const currentAction = window.sessionStorage.getItem('currentAction');
    if (currentAction !== null) {
      this.setCurrentAction(currentAction, false);
    }
  }

  setDeliveryCompanies(deliveryCompanies: TGetLinenDeliveryCompaniesResponse) {
    this.delivery_companies = deliveryCompanies;
  }

  setLinenCosts(linenCosts: IGetLinenCostsResponse) {
    this.linenCosts = linenCosts;
  }

  setIsSubmitting(isSubmitting: boolean) {
    this.isSubmitting = isSubmitting;
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setInventory(inventory: IGetLinenInventoryResponse) {
    this.inventory = inventory;
  }

  setSuccess(isSuccess: boolean) {
    this.success = isSuccess;
  }

  setError(value: string) {
    this.error = value;
  }

  // api
  async getInventory() {
    const token = this.rootStore.accountStore.user.token;
    const currentHotel = this.rootStore.hotelStore.currentHotel?.id;

    if (token && currentHotel) {
      try {
        this.setIsLoading(true);

        const response = await Api.getInventory(token, currentHotel);

        if (response.ok) {
          const data = await response;

          this.setInventory(data.body);
        }
      } catch (error: any) {
        if (error.status === StatusCodes.UNAUTHORIZED) {
          handleUnauthorizedResponse();
          return;
        }
      } finally {
        this.setIsLoading(false);
      }
    }
  }

  async updateInventory(params: TUpdateLinenInventoryParams) {
    const token = this.rootStore.accountStore.user.token;
    const currentHotel = this.rootStore.hotelStore.currentHotel?.id;

    if (token && currentHotel) {
      try {
        this.setIsSubmitting(true);

        const response = await Api.updateInventory(token, currentHotel, params);

        if (response.ok) {
          const data = await response;

          let inventory = toJS(this.inventory);
          inventory = {
            ...inventory,
            ...data.body,
          };

          if (inventory) {
            this.setInventory(inventory);
            toast.success(i18n.t('Inventory Updated.') as string);
          }
        }
      } catch (error: any) {
        if (error.status === StatusCodes.UNAUTHORIZED) {
          handleUnauthorizedResponse();
          return;
        }
        this.setError(error.message);
      } finally {
        this.setIsSubmitting(false);
      }
    }
  }

  async getDeliveryCompanies() {
    const token = this.rootStore.accountStore.user.token;

    if (token) {
      try {
        const response = await Api.getDeliveryCompanies(token);

        if (response.ok) {
          const data = await response;

          this.setDeliveryCompanies(data.body);
        }
      } catch (error: any) {
        if (error.status === StatusCodes.UNAUTHORIZED) {
          handleUnauthorizedResponse();
          return;
        }
      }
    }
  }

  async getLinenCosts(startDate: string | null = null) {
    const token = this.rootStore.accountStore.user.token;
    const currentHotel = this.rootStore.hotelStore.currentHotel?.id;

    if (token && currentHotel) {
      this.setIsLoading(true);

      try {
        const response = await Api.getLinenCosts(token, currentHotel, startDate);

        if (response.ok) {
          const data = await response;

          this.setLinenCosts(data.body);
        }
      } catch (error: any) {
        if (error.status === StatusCodes.UNAUTHORIZED) {
          handleUnauthorizedResponse();
          return;
        }
      } finally {
        this.setIsLoading(false);
      }
    }
  }
}

export default Linen;
