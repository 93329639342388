import {StatusCodes} from 'http-status-codes';
import {makeObservable, action, observable, autorun, IReactionDisposer} from 'mobx';
import {toast} from 'react-toastify';
import Api, {IListProductResult, TGetLevelsResponse} from '../../api/inventory/product';
import {handleUnauthorizedResponse} from '../../libs/utils';
import type RootStore from '../rootStore';

interface IProducts {
  rootStore: RootStore;
  levels: TGetLevelsResponse;
  level: string;
  count: number;
  next: string | null;
  previous: string | null;
  isLoading: boolean;
  products: Array<IListProductResult>;
  disposers: IReactionDisposer | IReactionDisposer[];
  setLevels: (levels: TGetLevelsResponse) => void;
  setLevel: (level: string, save: boolean) => void;
  setCount: (count: number) => void;
  setNext: (value: string | null) => void;
  setPrevious: (value: string | null) => void;
  setIsLoading: (isLoading: boolean) => void;
  setProducts: (products: Array<IListProductResult>) => void;
  getProducts: (nextPreviousUrl: string | null) => void;
  deleteProduct: (id: number, hotel: number) => void;
  getLevels: () => void;
}

class Products implements IProducts {
  rootStore: RootStore;
  levels: TGetLevelsResponse = [];
  level = 'LV1';
  count = 0;
  next: string | null = null;
  previous: string | null = null;
  isLoading = false;
  products: Array<IListProductResult> = [];
  disposers: IReactionDisposer | IReactionDisposer[];

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      levels: observable,
      level: observable,
      count: observable,
      next: observable,
      previous: observable,
      isLoading: observable,
      products: observable,
      setLevels: action,
      setLevel: action,
      setCount: action,
      setNext: action,
      setPrevious: action,
      setIsLoading: action,
      setProducts: action,
      getProducts: action,
      deleteProduct: action,
      getLevels: action,
    });

    this.disposers = [
      autorun(() => {
        this.loadLevel();
      }),
    ];
  }

  setLevels(levels: TGetLevelsResponse) {
    this.levels = levels;
  }

  setLevel(level: string, save = true) {
    this.level = level;
    if (save === true) {
      window.sessionStorage.setItem('level', level);
    }
  }

  loadLevel() {
    const level = window.sessionStorage.getItem('level');
    if (level) {
      this.setLevel(level, false);
    }
  }

  setCount(count: number) {
    this.count = count;
  }

  setNext(value: string | null) {
    this.next = value;
  }

  setPrevious(value: string | null) {
    this.previous = value;
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setProducts(products: Array<IListProductResult>) {
    this.products = products;
  }

  async getLevels() {
    const token = this.rootStore.accountStore.user.token;
    const currentHotel = this.rootStore.hotelStore.currentHotel?.id;

    if (token && currentHotel) {
      try {
        const response = await Api.getLevels(token, currentHotel);

        if (response.ok) {
          const data = await response;

          this.setLevels(data.body);
        }
      } catch (error: any) {
        if (error.status === StatusCodes.UNAUTHORIZED) {
          handleUnauthorizedResponse();
          return;
        }
        toast.error(error.message);
      }
    }
  }

  async getProducts(nextPreviousUrl: string | null = null) {
    const token = this.rootStore.accountStore.user.token;
    const currentHotel = this.rootStore.hotelStore.currentHotel?.id;

    if (token && currentHotel) {
      try {
        this.setIsLoading(true);
        const response = await Api.listProducts(token, currentHotel, this.level, nextPreviousUrl);

        if (response.ok) {
          const data = await response;

          this.setCount(data.body.count);
          this.setNext(data.body.next);
          this.setPrevious(data.body.previous);
          this.setProducts(data.body.results);
        }
      } catch (error: any) {
        if (error.status === StatusCodes.UNAUTHORIZED) {
          handleUnauthorizedResponse();
          return;
        }
        toast.error(error.message);
      } finally {
        this.setIsLoading(false);
      }
    }
  }

  async deleteProduct(id: number) {
    const token = this.rootStore.accountStore.user.token;
    const currentHotelId = this.rootStore.hotelStore.currentHotel?.id;

    // TODO SEE IF AN AUTORUN MIGHT BE BETTER INSTEAD OF CALLING GET PRODUCTS HERE
    if (token && currentHotelId) {
      try {
        const response = await Api.deleteProduct(token, this.level, id, currentHotelId);

        if (response.ok) {
          this.getProducts();
        }
      } catch (error: any) {
        if (error.status === StatusCodes.UNAUTHORIZED) {
          handleUnauthorizedResponse();
          return;
        }
        toast.error(error.message);
      }
    }
  }
}

export default Products;
