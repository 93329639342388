/* eslint-disable indent */
import {useTranslation} from 'react-i18next';
import i18n from '../i18n';

class LinenAction {
  static get VIEW_ORDERS() {
    return 'view_orders';
  }

  static get INVENTORY() {
    return 'inventory';
  }

  static get PREPARE_ORDER() {
    return 'prepare_order';
  }

  static get action() {
    return [LinenAction.VIEW_ORDERS, LinenAction.INVENTORY, LinenAction.PREPARE_ORDER];
  }
}

export const readableAction = (action: string) => {
  const {t} = useTranslation();
  switch (action) {
    case LinenAction.VIEW_ORDERS:
      return t('common:view_orders');
      break;
    case LinenAction.INVENTORY:
      return t('common:inventory');
      break;
    case LinenAction.PREPARE_ORDER:
      return t('common:prepare_order');
      break;
    default:
      return t('common:view_orders');
      break;
  }
};

export default LinenAction;
