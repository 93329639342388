import {StatusCodes} from 'http-status-codes';
import {makeObservable, toJS, action, observable} from 'mobx';
import {toast} from 'react-toastify';
import {DateTime} from 'luxon';
import i18n from '../../../i18n';
import Api, {
  IGetOrdersResponse,
  IGetOrdersResult,
  IUpdateOrderPayload,
} from '../../../api/inventory/orders';
import type {IRootStore} from '../../rootStore';
import type RootStore from '../../rootStore';
import Cart from './cart';
import {handleUnauthorizedResponse} from '../../../libs/utils';

export const PAGE_LIST_ORDERS = 'list-orders';
export const PAGE_CREATE_ORDER = 'create-order';
export const PAGE_ADD_AMENITY = 'add-amenity';
export const PAGE_SAVE_ORDER = 'save-order';

export const ORDER_STATUS_PROGRESS = 'progress';
export const ORDER_STATUS_COMPLETED = 'completed';
export const ORDER_STATUS_FAILED = 'failed';

type FilterDate = string | null;

interface IOrders {
  rootStore: RootStore;
  cart: Cart;
  updateSuccess: boolean;
  cancelSuccess: boolean;
  page: string;
  count: number;
  next: string | null;
  previous: string | null;
  amenities: Array<IGetOrdersResult>;
  dateFrom: FilterDate;
  dateTo: FilterDate;
  isLoading: boolean;
  isExporting: boolean;
  suggestedStock: number;
  order: 'asc' | 'desc';
  filterState: string;
  // TODO CHECK IF FILE IS THE RIGHT TYPE
  exportBlob: any | undefined;
  setDateFrom: (dateFrom: string | null | Date) => void;
  setDateTo: (dateTo: string | null | Date) => void;
  setUpdateSuccess: (success: boolean) => void;
  setCancelSuccess: (value: boolean) => void;
  setPage: (page: string) => void;
  loadPage: () => void;
  setCount: (count: number) => void;
  setNext: (value: string | null) => void;
  setPrevious: (value: string | null) => void;
  setAmenities: (amenities: Array<IGetOrdersResult>) => void;
  setExportBlob: (blob: any | undefined) => void;
  setSuggestedStock: (suggestedStock: number) => void;
  getOrders: (nextPreviousUrl?: string | null) => Promise<void>;
  updateOrder: (payload: IUpdateOrderPayload) => void;
  setOrder: (orderDirection: 'asc' | 'desc') => void;
  setFilterState: (value: string) => void;
}

class Orders implements IOrders {
  rootStore: RootStore;
  cart;
  updateSuccess = false;
  cancelSuccess = false;
  page = PAGE_LIST_ORDERS;
  count = 0;
  next: string | null = null;
  previous: string | null = null;
  amenities: Array<IGetOrdersResult> = [];
  dateFrom: FilterDate = null;
  dateTo: FilterDate = null;
  isLoading = false;
  isExporting = false;
  order: 'asc' | 'desc' = 'desc';
  filterState = '';
  exportBlob: any | undefined = undefined;
  suggestedStock = 0;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;
    this.cart = new Cart(rootStore);
    makeObservable(this, {
      updateSuccess: observable,
      cancelSuccess: observable,
      page: observable,
      count: observable,
      next: observable,
      previous: observable,
      amenities: observable,
      dateFrom: observable,
      dateTo: observable,
      isLoading: observable,
      isExporting: observable,
      order: observable,
      filterState: observable,
      exportBlob: observable,
      suggestedStock: observable,
      setUpdateSuccess: action,
      setCancelSuccess: action,
      setPage: action,
      loadPage: action,
      setCount: action,
      setNext: action,
      setPrevious: action,
      setAmenities: action,
      setExportBlob: action,
      getOrders: action,
      setOrder: action,
      setFilterState: action,
      updateOrder: action,
      setIsExporting: action,
      setDateFrom: action,
      setDateTo: action,
      setIsLoading: action,
      orderOrders: action,
      exportOrders: action,
      setSuggestedStock: action,
    });
  }

  setDateFrom(dateFrom: string | null | Date) {
    const date =
      dateFrom instanceof Date
        ? DateTime.fromJSDate(dateFrom).toFormat('yyyy-MM-dd 06:00:00')
        : dateFrom;

    this.dateFrom = date;

    const message =
      dateFrom === null ? i18n.t('common:date_from_cleared') : i18n.t('common:date_from_updated');

    toast.success(i18n.t(message) as string);
  }

  setDateTo(dateTo: string | null | Date) {
    const date =
      dateTo instanceof Date ? DateTime.fromJSDate(dateTo).toFormat('yyyy-MM-dd 06:00:00') : dateTo;

    this.dateTo = date;

    const message = dateTo === null ? 'Date to cleared' : 'Date to updated';

    toast.success(i18n.t(message) as string);
  }

  setOrder(orderDirection: 'asc' | 'desc') {
    this.order = orderDirection;
  }

  setFilterState(value: string) {
    this.filterState = value;
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setUpdateSuccess(success: boolean) {
    this.updateSuccess = success;
  }

  setCancelSuccess(cancelSuccess: boolean) {
    this.cancelSuccess = cancelSuccess;
  }

  setPage(page: string) {
    window.sessionStorage.setItem('order-page', page);
    this.page = page;
  }

  loadPage() {
    const page = window.sessionStorage.getItem('order-page');
    if (page !== null) {
      this.page = page;
    }
  }

  setCount(count: number) {
    this.count = count;
  }

  setNext(nextUrl: string | null) {
    this.next = nextUrl;
  }

  setPrevious(previousUrl: string | null) {
    this.previous = previousUrl;
  }

  setAmenities(amenities: Array<IGetOrdersResult>) {
    this.amenities = amenities;
  }

  setExportBlob(blob: any | undefined) {
    this.exportBlob = blob;
  }

  setIsExporting(isExporting: boolean) {
    this.isExporting = isExporting;
  }

  setSuggestedStock(suggestedStock: number) {
    this.suggestedStock = suggestedStock;
  }

  // Api calls
  async getOrders(nextPreviousUrl: string | null = null) {
    let formattedDateFrom = null;
    let formattedDateTo = null;

    if (this.dateFrom !== null) {
      formattedDateFrom = DateTime.fromFormat(this.dateFrom, 'yyyy-MM-dd 06:00:00').toFormat(
        'yyyy-MM-dd',
      );
    }

    if (this.dateTo !== null) {
      formattedDateTo = DateTime.fromFormat(this.dateTo, 'yyyy-MM-dd 06:00:00').toFormat(
        'yyyy-MM-dd',
      );
    }

    const token = this.rootStore.accountStore.user.token;
    const currentHotelId = this.rootStore.hotelStore.currentHotel?.id;

    if (token && currentHotelId) {
      try {
        this.setIsLoading(true);

        const response = await Api.getOrders(
          token,
          currentHotelId,
          formattedDateFrom,
          formattedDateTo,
          this.filterState,
          nextPreviousUrl,
        );

        if (response.ok) {
          const data: IGetOrdersResponse = await response.body;

          this.setCount(data.count);
          this.setNext(data.next);
          this.setPrevious(data.previous);
          this.setAmenities(data.results);
          this.orderOrders();
        }
      } catch (error: any) {
        toast.error(error.message);
        if (error.status === StatusCodes.UNAUTHORIZED) {
          handleUnauthorizedResponse();
          return;
        }
      } finally {
        this.setIsLoading(false);
      }
    }
  }

  async updateOrder(payload: IUpdateOrderPayload) {
    const token = this.rootStore.accountStore.user.token;
    const currentHotelId = this.rootStore.hotelStore.currentHotel?.id;

    if (token && currentHotelId) {
      try {
        const response = await Api.updateOrder(token, currentHotelId, payload);

        if (response.ok) {
          this.setUpdateSuccess(true);
        }
      } catch (error: any) {
        if (error.status === StatusCodes.UNAUTHORIZED) {
          handleUnauthorizedResponse();
          return;
        }
        toast.error(error.message);
      }
    }
  }

  async cancelOrder(id: number) {
    const token = this.rootStore.accountStore.user.token;

    if (token) {
      try {
        const response = await Api.cancelOrder(token, id);

        if (response.ok) {
          this.setCancelSuccess(true);
        }
      } catch (error: any) {
        if (error.status === StatusCodes.UNAUTHORIZED) {
          handleUnauthorizedResponse();
          return;
        }
        toast.error(error.message);
      }
    }
  }

  async exportOrders() {
    let formattedDateTo = null;

    if (this.dateTo !== null) {
      formattedDateTo = DateTime.fromFormat(this.dateTo, 'yyyy-MM-dd 06:00:00').toFormat(
        'yyyy-MM-dd',
      );
    }

    const token = this.rootStore.accountStore.user.token;
    const currentHotelId = this.rootStore.hotelStore.currentHotel?.id;

    if (token && currentHotelId) {
      try {
        this.setIsExporting(true);

        const response = await Api.exportOrders(token, currentHotelId, formattedDateTo);

        if (response.ok) {
          const data = await response.body;
          this.setExportBlob(data);
        }
      } catch (error: any) {
        toast.error(error.message);

        if (error.status === StatusCodes.UNAUTHORIZED) {
          handleUnauthorizedResponse();
          return;
        }
      } finally {
        this.setIsExporting(false);
      }
    }
  }

  orderOrders() {
    const amenitiesOrders = toJS(this.amenities);

    const order = this.order;

    amenitiesOrders.sort(function (a: IGetOrdersResult, b: IGetOrdersResult) {
      const dateA = new Date(a.ctime).getTime();
      const dateB = new Date(b.ctime).getTime();
      if (order === 'asc') {
        return dateA - dateB;
      }
      return dateB - dateA;
    });

    this.setAmenities(amenitiesOrders);
  }

  async updateBuffer(amenityId: number, buffer: number) {
    const token = this.rootStore.accountStore.user.token;
    const currentHotelId = this.rootStore.hotelStore.currentHotel?.id;

    if (token && currentHotelId) {
      try {
        const response = await Api.updateBuffer(token, currentHotelId, amenityId, buffer);

        if (response.ok) {
          const data = await response.body;
          this.setSuggestedStock(data['suggested_stock']);
        }
      } catch (error: any) {
        if (error.status === StatusCodes.UNAUTHORIZED) {
          handleUnauthorizedResponse();
          return;
        }
        toast.error(error.message);
      }
    }
  }
}

export default Orders;
