import React, {useEffect, useRef, useState} from 'react';
import {useRootStore} from '../../context';
import {Tooltip, Button, Card, Modal, Typography} from '@mui/material';
import {Buffer} from 'buffer';
import {LoadingButton} from '@mui/lab';

interface IQRCodeButtonProps {
  reservationId: string;
  disabled: boolean;
}

const QRCodeButton: React.FC<IQRCodeButtonProps> = ({reservationId, disabled}) => {
  const [qrCode, setQrCode] = useState<string | undefined>(undefined);
  const [showQRCodeModal, setShowQRCodeModal] = useState(false);

  const divRef = useRef<HTMLDivElement | null>(null);

  const {reservationsStore} = useRootStore();

  const tooltipMessage = disabled
    ? 'To pay with QR code the guest must have a registered email address.'
    : 'Generate QR code to pay for this reservation';

  useEffect(() => {
    if (showQRCodeModal && divRef.current) {
      parseImage();
    }
  }, [showQRCodeModal, divRef.current]);

  const parseImage = () => {
    if (!qrCode) return;

    const imgElement = document.createElement('img');

    const buffer = Buffer.from(qrCode, 'binary').toString('base64');

    const blob = new Blob([qrCode], {type: 'text/plain'});

    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      const srcValue = 'data:image/png;base64,' + buffer;
      imgElement.src = srcValue;
    };

    imgElement.alt = 'QRcode';
    divRef.current?.appendChild(imgElement);
  };

  const generateQRCodeHandler = async () => {
    const qrCode = await reservationsStore.getPaymentQRCode(reservationId);

    setShowQRCodeModal(true);

    if (qrCode) {
      setQrCode(qrCode);
    }
  };

  return (
    <>
      <Modal
        open={showQRCodeModal}
        onClose={setShowQRCodeModal.bind(this, false)}
        sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
        }}
      >
        <Card sx={{display: 'flex', flexDirection: 'column', padding: 2, gap: 1}}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Scan the QR code to pay for your reservation
          </Typography>

          <div ref={divRef}></div>

          <Button variant="contained" onClick={setShowQRCodeModal.bind(this, false)}>
            Close
          </Button>
        </Card>
      </Modal>
      <Tooltip title={tooltipMessage}>
        <span>
          <LoadingButton
            variant="contained"
            onClick={generateQRCodeHandler}
            sx={{width: '100%'}}
            loading={reservationsStore.qrCodeLoading}
            disabled={disabled}
          >
            Generate QR Code
          </LoadingButton>
        </span>
      </Tooltip>
    </>
  );
};

export default QRCodeButton;
