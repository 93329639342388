import request from 'superagent';
import Config from '../config';
import Common from './common';

// CHECKED INTERFACE
export interface ILinenInventoryResult {
  id: number;
  location: string;
  linen_name: string;
  total: number;
  updated: string;
  linen_location: number;
  linen: number;
}

// CHECKED INTERFACE
export interface IGetLinenInventoryResponse {
  [key: string]: Array<ILinenInventoryResult>;
}

// CHECKED INTERFACE

export interface IUpdateLinenInventoryResponse {
  [key: string]: Array<ILinenInventoryResult>;
}

// CHECKED INTERFACE
export interface IGetLinenCostsResponse {
  month_to_date_cost: number;
  forecasted_monthly_cost: number;
  per_traveler_cost: number;
}

// CHECKED TYPE
export type TUpdateLinenInventoryParams = Array<{id: number; total: number}>; //  or []?

// CHECKED TYPE
export type TGetLinenDeliveryCompaniesResponse = Array<{id: number; name: string}>;

export default {
  getInventory: function (token: string, hotelId: number) {
    const url = `${Config.API_URL}/linen/inventory/${hotelId}/`;

    return request.get(url).accept(Common.MEDIA_TYPE).auth(token, {type: 'bearer'}).send();
  },
  updateInventory: function (token: string, hotelId: number, params: TUpdateLinenInventoryParams) {
    const url = `${Config.API_URL}/linen/inventory/${hotelId}/`;

    return request
      .put(url)
      .auth(token, {type: 'bearer'})
      .type(Common.MEDIA_TYPE)
      .accept(Common.MEDIA_TYPE)
      .send(JSON.stringify(params));
  },
  getDeliveryCompanies: function (token: string) {
    const url = `${Config.API_URL}/linen/delivery-company/`;

    return request.get(url).accept(Common.MEDIA_TYPE).auth(token, {type: 'bearer'}).send();
  },
  getLinenCosts: function (token: string, hotelId: number, startDate: string | null = null) {
    const url = `${Config.API_URL}/linen/cost/${hotelId}/`;

    return request
      .get(url)
      .query({start: startDate})
      .accept(Common.MEDIA_TYPE)
      .auth(token, {type: 'bearer'})
      .send();
  },
};
