import {Grid, Typography} from '@mui/material';
import type {GridSize} from '@mui/system';
import React from 'react';

interface IItemProps {
  alignItems: Record<string, string | number>;
  justifyContent: Record<string, string>;
  lg: boolean | GridSize | undefined;
  label: string;
  children: React.ReactNode | string;
  fontWeight: string | number;
}

const Item: React.FC<IItemProps> = ({
  alignItems,
  justifyContent,
  lg,
  label,
  children,
  fontWeight,
}) => {
  return (
    <Grid
      item
      xs={12}
      md={6}
      lg={lg}
      display="flex"
      alignItems={{xs: 'center', ...alignItems}}
      justifyContent={{xs: 'space-between', lg: 'center', ...justifyContent}}
      flexDirection={{xs: 'row', lg: 'column'}}
      gap={1}
      sx={{
        paddingBlock: {xs: 1, lg: 0},
        borderBottom: {xs: '1px solid #babac1', lg: 'none'},
        '&:last-child': {
          borderBottom: 'none',
        },
        '&:nth-of-type(odd)': {
          paddingRight: {md: 2, lg: 0},
          borderRight: {md: '1px solid #babac1', lg: 'none'},
        },
      }}
    >
      <Typography
        variant="body1"
        fontWeight={fontWeight || 'medium'}
        display={{lg: 'none'}}
        color={fontWeight ? '#000' : 'GrayText'}
      >
        {label}
      </Typography>
      {typeof children === 'string' && (
        <Typography variant="body1" style={{wordWrap: 'break-word', textAlign: 'center'}}>
          {children}
        </Typography>
      )}
      {typeof children !== 'string' && children}
    </Grid>
  );
};

export default Item;
