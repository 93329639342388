import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Stepper} from 'react-form-stepper';
import OrderDetails from './OrderDetails';
import {useRootStore} from '../../context';
import PrepareOrderMobile from './PrepareOrderMobile';
import LinenOrderStep from '../../libs/linenOrderStep';
import PrepareOrderDesktop from './PrepareOrderDesktop';
import {Paper} from '@mui/material';

const LinenOrder = observer(() => {
  const {orderStore} = useRootStore();
  const [currentStep, setCurrentStep] = useState(0);
  const mql = matchMedia('(max-width: 991px)');
  const [isMobile, setIsMobile] = useState(mql.matches);

  mql.addEventListener('change', (e) => {
    setIsMobile(e.matches);
  });

  useEffect(() => {
    setCurrentStep(orderStore.currentStep);
  }, [orderStore.currentStep]);

  const paperStyles = {
    backgroundColor: '#FFF',
    padding: {xs: 3, md: 5},
  };

  if (currentStep === 0) {
    return (
      <Paper sx={paperStyles}>
        <Stepper className={'pt-0'} steps={LinenOrderStep.orderSteps} activeStep={currentStep} />
        <OrderDetails />
      </Paper>
    );
  }
  if (currentStep === 1) {
    return (
      <Paper sx={paperStyles}>
        <Stepper className={'pt-0'} steps={LinenOrderStep.orderSteps} activeStep={currentStep} />
        {isMobile ? <PrepareOrderMobile /> : <PrepareOrderDesktop />}
      </Paper>
    );
  }
  return null;
});

export default LinenOrder;
