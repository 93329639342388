import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import {toJS} from 'mobx';
import {useState} from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import BusinessIcon from '@mui/icons-material/Business';
import TranslateIcon from '@mui/icons-material/Translate';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PhoneIcon from '@mui/icons-material/Phone';
import {observer} from 'mobx-react-lite';
import {useParams} from 'react-router-dom';
import SelectHotel from '../../components/Navbar/SelectHotel';
import PageTitle from '../../components/PageTitle';
import {useRootStore} from '../../context';
import {capitalize} from '../../libs/utils';
import '../../styles/main.css';
import {useTranslation} from 'react-i18next';
import type {IGuestResult} from '../../api/guests';
import SkeletonLoader from '../../components/general/SkeletonLoader';

const GuestDetails = observer(() => {
  const {t} = useTranslation();
  const {guestStore, hotelStore} = useRootStore();
  const {guestId} = useParams();
  const [guest, setGuest] = useState<IGuestResult | null>(null);

  const capitalizedFirstName = guest && capitalize(guest.first_name);
  const capitalizedLastName = guest && capitalize(guest.last_name);

  const phoneHasIncorrectFormat = guest && guest.phone_number && !guest.is_valid_phone_number;

  useEffect(() => {
    if (hotelStore.currentHotel !== undefined && guestId) {
      guestStore.getSelectedGuest(guestId);
    }
  }, [guestId]);

  useEffect(() => {
    if (guest === null && guestStore.selectedGuest !== null) {
      setGuest(toJS(guestStore.selectedGuest));
    }
  }, [guestStore.selectedGuest]);

  return (
    <>
      {guestStore.isLoading && <SkeletonLoader skeletonShape="page" />}
      {guest && !guestStore.isLoading && (
        <>
          <Container>
            <Grid container>
              <Grid item xs={12}>
                <PageTitle>{t('common:navbar_travelers')}</PageTitle>
              </Grid>
              <Grid item xs={12} md={4}>
                <SelectHotel disabled={true} />
              </Grid>
            </Grid>

            <Card sx={{marginY: 1}}>
              <CardContent>
                <Link to={'..'} relative="path" style={{textDecoration: 'none'}}>
                  <Button
                    variant="text"
                    startIcon={<ChevronLeftIcon fontSize="large" />}
                    size="large"
                  >
                    {t('guests:all_guests')}
                  </Button>
                </Link>

                <Typography variant="h5" component="h5" marginBottom={3}>
                  {t('guests:guest_details')}
                </Typography>
                <>
                  <Paper sx={{border: '2px solid #A4A6B3'}}>
                    <Stack flexDirection={'row'} alignItems="center">
                      <Typography
                        variant="h5"
                        margin={2}
                      >{`${capitalizedFirstName} ${capitalizedLastName}`}</Typography>
                    </Stack>

                    <Grid
                      container
                      gap={{xs: 5}}
                      margin={0}
                      padding={1}
                      justifyContent="flex-start"
                    >
                      <Grid item display={'flex'} flexDirection="column" justifyContent={'center'}>
                        <Stack display="flex" flexDirection="row" alignItems={'center'} gap={0.5}>
                          <AlternateEmailIcon fontSize="small" />

                          <Typography variant="button">{guest.email ?? '-'}</Typography>
                        </Stack>
                      </Grid>
                      <Grid item display={'flex'} flexDirection="column" justifyContent={'center'}>
                        <Stack display="flex" flexDirection="row" alignItems={'center'} gap={0.5}>
                          <PhoneIcon
                            fontSize="small"
                            color={!guest.is_valid_phone_number ? 'error' : 'inherit'}
                          />
                          <Typography variant="body2">{guest.phone_number ?? '-'}</Typography>

                          {phoneHasIncorrectFormat && (
                            <Tooltip
                              title={t('common:phone_number_incorrect_format')}
                              color="error"
                            >
                              <IconButton color="error" sx={{margin: 0, padding: 0}}>
                                <ErrorOutlineIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          )}
                        </Stack>
                      </Grid>
                      <Grid item display={'flex'} flexDirection="column" justifyContent={'center'}>
                        <Stack display="flex" flexDirection="row" alignItems={'center'} gap={0.5}>
                          <TranslateIcon fontSize="small" />

                          <Typography textAlign="center" variant="button">
                            {guest.language.toUpperCase()}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item display={'flex'} flexDirection="column" justifyContent={'center'}>
                        <Stack display={'flex'} flexDirection="row" alignItems={'center'} gap={0.5}>
                          <BusinessIcon fontSize="small" />
                          <Typography variant="button">
                            {hotelStore.currentHotel?.short_name}
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Paper>
                </>

                {/* <Typography variant="h5" component="h5" marginY={3} color="gray.light">
                  Guest photo - Future feature
                </Typography>
                <Typography variant="h5" component="h5" marginY={3} color="gray.light">
                  Guest current room and reservation - Future feature
                </Typography>
                <Typography variant="h5" component="h5" marginY={3} color="gray.light">
                  Guest last stay - Future feature
                </Typography>
                <Typography variant="h5" component="h5" marginY={3} color="gray.light">
                  Guest future reservations - Future feature
                </Typography>
                <Typography variant="h5" component="h5" marginY={3} color="gray.light">
                  Guest list of preferences - Future feature
                </Typography>
                <Typography variant="h5" component="h5" marginY={3} color="gray.light">
                  Guest expenses - Future feature
                </Typography>
                <Typography variant="h5" component="h5" marginY={3} color="gray.light">
                  Guest relatives - Future feature
                </Typography>
                <Typography variant="h5" component="h5" marginY={3} color="gray.light">
                  Guest social media - Future feature
                </Typography>
                <Typography variant="h5" component="h5" marginY={3} color="gray.light">
                  Guest nickname - Future feature
                </Typography>
                <Typography variant="h5" component="h5" marginY={3} color="gray.light">
                  Promotions available for guest - Future feature
                </Typography> */}
              </CardContent>
            </Card>
          </Container>
        </>
      )}
    </>
  );
});

export default GuestDetails;
