import React from 'react';
import type {IGuestResult} from '../../api/guests';
import GuestCard from './GuestCard';

interface IGuestCardListProps {
  guests: Array<IGuestResult>;
}

const GuestCardList: React.FC<IGuestCardListProps> = ({guests}) => {
  return (
    <>
      {guests.map((guestData) => {
        return <GuestCard key={guestData.id} guestData={guestData} />;
      })}
    </>
  );
};

export default GuestCardList;
