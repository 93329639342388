import React from 'react';
import {Typography} from '@mui/material';

interface IPageTitleProps {
  children: React.ReactNode;
}

const PageTitle: React.FC<IPageTitleProps> = ({children}) => {
  return (
    <Typography
      variant="h4"
      component="h1"
      fontWeight="500"
      marginBottom={{xs: 7}}
      marginTop={{xs: 6, lg: 3}}
    >
      {children}
    </Typography>
  );
};

export default PageTitle;
