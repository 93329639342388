import {Card, CardContent, Container, Grid, Skeleton} from '@mui/material';
import React from 'react';

interface ISkeletonLoaderProps {
  skeletonShape: 'table' | 'cards' | 'page' | 'avatar';
  amount?: number;
}

const SkeletonLoader: React.FC<ISkeletonLoaderProps> = ({skeletonShape, amount}) => {
  const shapes = {
    table: {
      item: Array.from({length: amount ?? 10}, (_, i) => (
        <Grid item key={i} sx={{width: {xs: '100%'}}}>
          <Skeleton variant="rounded" height={75} />
        </Grid>
      )),
      marginY: 0,
    },
    cards: {
      item: Array.from({length: amount ?? 6}, (_, i) => (
        <Grid item key={i} sx={{width: {xs: '100%', sm: '49%', md: '30%'}}}>
          <Skeleton variant="rounded" height={320} />
        </Grid>
      )),
      marginY: 5,
    },
    page: {
      item: Array.from({length: amount ?? 6}, (_, i) => (
        <Grid item key={i} sx={{width: {xs: '100%', sm: '49%', md: '30%'}}}>
          <Skeleton variant="rounded" width="100%" height={70} sx={{marginY: 2}} />
        </Grid>
      )),
      marginY: 0,
    },
    avatar: {
      item: Array.from({length: amount ?? 6}, (_, i) => (
        <Grid
          item
          key={i}
          display={'flex'}
          flexDirection="row"
          alignItems="center"
          sx={{width: {xs: '100%'}}}
        >
          <Skeleton variant="rounded" height={80} width={80} sx={{borderRadius: 35}} />
          <Skeleton variant="rounded" width={70} height={20} sx={{margin: 1}} />
        </Grid>
      )),
      marginY: 0,
    },
  };

  const shape = shapes[skeletonShape];

  return (
    <>
      {skeletonShape === 'page' && (
        <Container>
          <Card sx={{marginY: 1}}>
            <CardContent>
              <Skeleton variant="rounded" width="50%" height={40} />
              <Skeleton variant="rounded" width="50%" height={60} sx={{marginY: 5}} />
              <Skeleton variant="rounded" width="100%" height={100} />
              {Array.from({length: amount ?? 6}, (_, i) => (
                <>
                  <Skeleton variant="rounded" width="100%" height={70} sx={{marginY: 2}} />
                </>
              ))}
            </CardContent>
          </Card>
        </Container>
      )}

      {skeletonShape !== 'page' && (
        <Grid container gap={{xs: 1}} marginY={shape.marginY} justifyContent="center">
          {shape.item.map((skeleton) => skeleton)}
        </Grid>
      )}
    </>
  );
};

export default SkeletonLoader;
