import {
  InputAdornment,
  MenuItem,
  Stack,
  SxProps,
  TextField,
  Theme,
  useMediaQuery,
} from '@mui/material';
import type {FormikProps} from 'formik';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useRootStore} from '../../../context';
import type {IFormikInitValues} from '../AddItemStep1';
import SectionTitle from './SectionTitle';

interface IPurchaseInfoSectionProps {
  formik: FormikProps<IFormikInitValues>;
  selectStyles: SxProps<Theme> | undefined;
}

const PurchaseInfoSection: React.FC<IPurchaseInfoSectionProps> = observer(
  ({formik, selectStyles}) => {
    const {inventoryStore, vendorStore} = useRootStore();
    const {t} = useTranslation();

    const isMobile = useMediaQuery('(max-width: 900px)');

    return (
      <Stack gap={3} alignItems="start" width="100%" marginBottom={3}>
        <SectionTitle>{t('common:purchase_info')}</SectionTitle>
        <Stack
          flexDirection={{xs: 'column', md: 'row'}}
          gap={3}
          marginBottom={{xs: 3, md: 0}}
          width="100%"
        >
          {/* --------------------------- Brand ---------------------------- */}
          <TextField
            select
            name="brand"
            value={formik.values.brand}
            onChange={formik.handleChange}
            label={t('common:brand')}
            required
            fullWidth
            sx={selectStyles}
            error={formik.touched.brand && Boolean(formik.errors.brand)}
            helperText={formik.touched.brand && formik.errors.brand}
          >
            {inventoryStore.brands.map((obj, index) => (
              <MenuItem key={index} value={obj.id}>
                {obj.name}
              </MenuItem>
            ))}
          </TextField>
          {/* -------------------------- Brand ----------------------------- */}
          {/* -------------------------- Vendor ---------------------------- */}
          <TextField
            select
            name="vendor"
            onChange={formik.handleChange}
            value={formik.values.vendor}
            required
            fullWidth
            label={t('common:vendor')}
            error={formik.touched.vendor && Boolean(formik.errors.vendor)}
            helperText={formik.touched.vendor && formik.errors.vendor}
            sx={selectStyles}
          >
            {vendorStore.vendors.map((obj) => (
              <MenuItem key={obj.id} value={obj.id}>
                {obj.name}
              </MenuItem>
            ))}
          </TextField>
          {/* -------------------------- Vendor ---------------------------- */}
          {/* ------------------------ Vendor Ref -------------------------- */}
          <TextField
            name="reference"
            required
            label={t('common:vendor_ref')}
            onChange={formik.handleChange}
            value={formik.values.reference}
            error={formik.touched.reference && Boolean(formik.errors.reference)}
            helperText={formik.touched.reference && formik.errors.reference}
            fullWidth={isMobile}
          />
          {/* ------------------------ Vendor Ref -------------------------- */}
        </Stack>
        {/* -------------------------- Item URL ---------------------------- */}
        <TextField
          name="info"
          label={t('common:item_url_comment')}
          onChange={formik.handleChange}
          value={formik.values.info}
          error={formik.touched.info && Boolean(formik.errors.info)}
          helperText={formik.touched.info && formik.errors.info}
          fullWidth={isMobile}
          sx={{width: {xs: '100%', md: '50%'}}}
        />
        {/* -------------------------- Item URL ---------------------------- */}
        <Stack flexDirection={{xs: 'column', md: 'row'}} gap={3} width="100%">
          {/* ----------------------- Price p/ LV1 ------------------------- */}
          <TextField
            name="price"
            type="number"
            required
            label={t('common:price_per_lv1')}
            onChange={formik.handleChange}
            value={formik.values.price}
            error={formik.touched.price && Boolean(formik.errors.price)}
            helperText={formik.touched.price && formik.errors.price}
            fullWidth={isMobile}
            InputProps={{
              inputProps: {min: 1},
              endAdornment: <InputAdornment position="end">{'\u00A5'}</InputAdornment>,
            }}
          />
          <TextField
            select
            name="consumption_tax"
            value={formik.values.consumption_tax}
            onChange={formik.handleChange}
            label={t('common:consumption_tax')}
            fullWidth
            sx={selectStyles}
          >
            <MenuItem value={8}>8%</MenuItem>
            <MenuItem value={10}>10%</MenuItem>
          </TextField>
          {/* ----------------------- Price p/ LV1 ------------------------- */}
          {/* ----------------------- Delivery Est ------------------------- */}
          <TextField
            name="delivery"
            type="number"
            label={t('common:delivery_estimate')}
            onChange={formik.handleChange}
            value={formik.values.delivery}
            error={formik.touched.delivery && Boolean(formik.errors.delivery)}
            helperText={formik.touched.delivery && formik.errors.delivery}
            fullWidth={isMobile}
            InputLabelProps={{shrink: true}}
            InputProps={{
              inputProps: {min: 0},
              endAdornment: <InputAdornment position="end">{t('common:days')}</InputAdornment>,
            }}
          />
        </Stack>
      </Stack>
    );
  },
);

export default PurchaseInfoSection;
