import {Divider, Stack, Typography} from '@mui/material';
import React from 'react';

interface INoResultsFoundProps {
  message: string;
}

const NoResultsFound: React.FC<INoResultsFoundProps> = ({message}) => {
  return (
    <>
      <Divider light sx={{marginTop: 5}} />
      <Stack justifyContent="center" alignItems={'center'} flexDirection="column">
        <Typography variant="h6" margin={5}>
          {message}
        </Typography>
      </Stack>
    </>
  );
};

export default NoResultsFound;
