import {createUseStyles} from 'react-jss';

const maxWidth = 991;

const orderStyles = createUseStyles({
  item: {
    fontWeight: 600,
  },
  linen: {
    fontWeight: 200,
  },
  stock: {
    color: 'gray',
  },
  detail: {
    color: 'gray',
    fontSize: 'smaller',
  },
  surplus: {
    color: 'orange',
    fontWeight: 600,
  },
  order: {
    fontSize: '1.3rem',
    fontWeight: 600,
  },
  extra: {
    display: 'flex',
    color: 'gray',
    fontSize: '1.1rem',
    justifyContent: 'center',
    verticalAlign: 'baseline',
  },
  to_order: {
    color: '#5D5FEF',
    [`@media (max-width: ${maxWidth}px)`]: {
      fontSize: '1.5rem',
    },
    fontWeight: 700,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    borderStyle: 'solid',
    borderColor: 'lightgray',
    borderRadius: '8px',
    borderWidth: '1px',
    width: '120px',
  },
  plus_minus: {
    paddingTop: '8px',
    paddingLeft: '12px',
    paddingBottom: '8px',
    paddingRight: '12px',
    '&:hover': {
      cursor: 'pointer',
      color: '#5D5FEF',
    },
  },
  tr: {
    borderTopStyle: 'solid',
    borderTopColor: 'lightgray',
    borderTopWidth: '1px',
  },
  total: {
    fontWeight: 600,
  },
  price: {
    color: '#66BF0D',
    fontWeight: 600,
  },
  pagination: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
});

export default orderStyles;
