import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {observer} from 'mobx-react-lite';
import React, {useEffect} from 'react';
import SelectHotel from '../../components/Navbar/SelectHotel';
import PageTitle from '../../components/PageTitle';
import {useRootStore} from '../../context';
import DatePicker from '../../components/DatePicker';
import {useTranslation} from 'react-i18next';
import ReservationCardList from '../../components/reservations/ReservationCardList';
import NoResultsFound from '../../components/general/NoResultsFound';
import SkeletonLoader from '../../components/general/SkeletonLoader';
import ListPagination from '../../components/ListPagination';
import {DateTime} from 'luxon';
import {LoadingButton} from '@mui/lab';
import {saveAs} from 'file-saver';

const Reservations = observer(() => {
  const {t} = useTranslation();
  const {reservationsStore, hotelStore} = useRootStore();
  const isTodayDisabled =
    DateTime.utc().toLocal().toFormat('yyyy-MM-dd 06:00:00') === reservationsStore.checkInDate;

  useEffect(() => {
    if (hotelStore.currentHotel !== undefined) {
      reservationsStore.getReservations();
      reservationsStore.setSelectedReservation(null);
    }
  }, [hotelStore.currentHotel, reservationsStore.checkInDate]);

  const isMobile = useMediaQuery('(max-width:600px)');

  const handleExportReservations = (event: React.SyntheticEvent) => {
    event.preventDefault();
    reservationsStore.exportReservations();
  };

  useEffect(() => {
    if (reservationsStore.exportBlob !== undefined) {
      const selectedDate = DateTime.fromJSDate(new Date(reservationsStore.checkInDate)).toFormat(
        'yyyy-MM-dd',
      );

      const filename = `${hotelStore.currentHotel?.short_name} access-codes ${selectedDate}.xlsx`;
      saveAs(reservationsStore.exportBlob, filename);
      reservationsStore.setExportBlob(undefined);
    }
  }, [reservationsStore.exportBlob]);

  const isDesktop = useMediaQuery('(min-width: 1200px)');

  const setTodayDate = (event: React.SyntheticEvent) => {
    event.preventDefault();

    const currentDate = DateTime.utc().toLocal().toFormat('yyyy-MM-dd 06:00:00');
    reservationsStore.setCheckInDate(new Date(currentDate));
  };

  const changeDateHandler = (date: Date | string | null) => {
    if (date === null) {
      const currentDate = DateTime.utc().toLocal().toFormat('yyyy-MM-dd 06:00:00');

      reservationsStore.setCheckInDate(currentDate);
      return;
    }

    reservationsStore.setCheckInDate(date);
  };

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PageTitle>{t('common:navbar_travelers')}</PageTitle>
        </Grid>
        <Grid item xs={12} md={4}>
          <SelectHotel />
        </Grid>
        <Grid item xs={12} md={3}>
          <LoadingButton
            variant="contained"
            color="primary"
            loading={reservationsStore.isExporting}
            onClick={handleExportReservations}
            loadingIndicator={t('common:exporting')}
            fullWidth={isMobile}
            sx={{paddingBlock: '1rem', alignSelf: {sm: 'end'}}}
            disabled={reservationsStore.count === 0}
          >
            {t('common:export')}
          </LoadingButton>
        </Grid>
      </Grid>

      <Card
        elevation={isDesktop ? 2 : 0}
        sx={{
          marginTop: 5,
          backgroundColor: {xs: 'transparent', lg: '#FFF'},
          overflow: 'visible',
        }}
      >
        <CardContent sx={{padding: {xs: 0, lg: 2}}}>
          <Typography variant="h5" component="h5" marginBottom={3}>
            {t('reservations:available_reservations')}
          </Typography>
          <Stack flexDirection="row" alignItems={'center'} justifyContent="flex-end" width={'100%'}>
            <Typography variant="h6" component="h6" marginRight={2}>
              {t('reservations:show_reservations_of')}
            </Typography>
            <DatePicker
              value={reservationsStore.checkInDate}
              label={t('reservations:show_reservations_of')}
              onChange={changeDateHandler}
              fullWidth={false}
              noLegend
            />
            <Button
              variant="contained"
              sx={{marginLeft: 1}}
              onClick={setTodayDate}
              disabled={isTodayDisabled}
            >
              {t('Today')}
            </Button>
          </Stack>

          {reservationsStore.isLoading && <SkeletonLoader skeletonShape="cards" />}

          {reservationsStore.count > 0 && !reservationsStore.isLoading && (
            <Grid container gap={{xs: 1}} marginY={5} justifyContent="center">
              <ReservationCardList reservations={reservationsStore.reservations} />
            </Grid>
          )}

          {reservationsStore.count > 0 && (
            <Stack
              display="flex"
              flexDirection={'row'}
              width="100%"
              justifyContent={'center'}
              marginTop={3}
            >
              <ListPagination
                getter={reservationsStore.getReservations.bind(reservationsStore)}
                store={reservationsStore}
                watchers={[reservationsStore.checkInDate]}
              />
            </Stack>
          )}

          {reservationsStore.count === 0 && !reservationsStore.isLoading && (
            <NoResultsFound message={t('reservations:no_reservations_for_date')} />
          )}
        </CardContent>
      </Card>
    </Container>
  );
});

export default Reservations;
