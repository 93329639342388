import React, {FC} from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import {useRootStore} from './context';

interface IRequireAuthProps {
  children: React.ReactNode;
}

const RequireAuth: FC<IRequireAuthProps> = observer(
  ({children}: IRequireAuthProps): React.ReactElement<any, any> | null => {
    const location = useLocation();
    const {accountStore} = useRootStore();

    if (accountStore.isAuthenticated) {
      return <>{children}</>;
    }
    return <Navigate to={'/'} state={{path: location.pathname}} replace />;
  },
);

export default RequireAuth;
