import request from 'superagent';
import Config from '../config';
import Common from './common';

export interface ICreateUpdateProfileImageResponse {
  id: number;
  user: number;
  profile_image: string;
}

export interface IUpdateSettingsParams {
  password: string;
  new_password: string;
  confirm_password: string;
  username: string;
  email: string;
  first_name: string;
  last_name: string;
}

export interface IUpdateSettingsResponse {
  username: string;
  email: string;
  first_name: string;
  last_name: string;
}

export default {
  createProfileImage: function (field: string, file: any, token: string) {
    //TODO CHECK THAT THIS FILE TYPE WORKS PROPERLY
    const url = `${Config.API_URL}/account/profile_image/`;

    return request
      .post(url)
      .auth(token, {type: 'bearer'})
      .accept(Common.MEDIA_TYPE)
      .attach(field, file, file.name);
  },
  updateProfileImage: function (field: string, file: any, token: string) {
    const url = `${Config.API_URL}/account/profile_image/`;

    return request
      .put(url)
      .auth(token, {type: 'bearer'})
      .accept(Common.MEDIA_TYPE)
      .attach(field, file, file.name);
  },
  deleteProfileImage: function (token: string) {
    const url = `${Config.API_URL}/account/profile_image/`;

    return request.delete(url).auth(token, {type: 'bearer'}).accept(Common.MEDIA_TYPE).send();
  },
  getProfileImage: function (token: string) {
    const url = `${Config.API_URL}/account/profile_image/`;

    return request.get(url).auth(token, {type: 'bearer'}).accept(Common.MEDIA_TYPE).send();
  },
  updateSettings(params: IUpdateSettingsParams, token: string) {
    const url = `${Config.API_URL}/account/settings/`;
    const payload = JSON.stringify(params);

    return request
      .put(url)
      .auth(token, {type: 'bearer'})
      .type(Common.MEDIA_TYPE)
      .accept(Common.MEDIA_TYPE)
      .send(payload);
  },
};
