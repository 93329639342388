import styled from '@emotion/styled';
import {Box, Stack, useMediaQuery} from '@mui/material';
import React from 'react';
import MuiDrawer, {DrawerProps} from '@mui/material/Drawer';
import DrawerHeader from './DrawerHeader';
import NavLinks from './NavLinks';
import LogoutButton from '../LogoutButton';
import ProfileImage from '../ProfileImage';
import Logo from '../Logo';
import NavButton from './NavButton';

export const drawerWidth = 280;

// Functions for the mixins that are used to create the styles for the drawer.
const openedMixin = (theme: any) => ({
  ['width']: drawerWidth,
  ['transition']: theme.transitions.create('width', {
    ['easing']: theme.transitions.easing.sharp,
    ['duration']: theme.transitions.duration.enteringScreen,
  }),
  'background-color': '#363740',
  'overflow-x': 'hidden',
});

const closedMixin = (theme: any, isMobile: boolean) => {
  return {
    ['transition']: theme.transitions.create('width', {
      ['easing']: theme.transitions.easing.sharp,
      ['duration']: theme.transitions.duration.leavingScreen,
    }),
    'background-color': '#363740',
    'overflow-x': 'hidden',
    ['width']: isMobile ? 0 : `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      ['width']: isMobile ? 0 : `calc(${theme.spacing(8)} + 1px)`,
    },
  };
};

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})<DrawerProps>(({theme, open}) => {
  const isMobile = useMediaQuery(`(max-width: 600px)`);

  return {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    position: 'relative',
    right: '50px',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme, isMobile),
      '& .MuiDrawer-paper': closedMixin(theme, isMobile),
    }),
  };
});

interface INavbarProps {
  handleDrawerToggle: () => void;
  isOpen: boolean;
}

const Navbar: React.FC<INavbarProps> = ({handleDrawerToggle, isOpen}) => {
  const isDesktop = useMediaQuery(`(min-width: 1200px)`);

  return (
    <Drawer variant="permanent" open={isOpen}>
      {!isDesktop && (
        <DrawerHeader
          sx={{
            backgroundColor: '#363740',
          }}
        >
          <NavButton open={isOpen} onClick={handleDrawerToggle} />
          <Box sx={{display: {xs: isOpen ? 'block' : 'none'}}}>
            <Logo variant="light" />
          </Box>
        </DrawerHeader>
      )}
      <Stack justifyContent="space-between" sx={{height: '100%', paddingBottom: '2rem'}}>
        <NavLinks handleDrawerToggle={handleDrawerToggle} isOpen={isOpen} />
        <Box sx={{paddingLeft: 0}}>
          <LogoutButton />
          <ProfileImage sx={{marginTop: 1.75, marginLeft: 1}} textColor="white" />
        </Box>
      </Stack>
    </Drawer>
  );
};

export default Navbar;
