import React from 'react';
import {useMediaQuery} from '@mui/material';
import MuiAppBar, {AppBarProps} from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import {styled} from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import {observer} from 'mobx-react-lite';
import {useEffect, useState} from 'react';
import {Outlet} from 'react-router-dom';
import Navbar, {drawerWidth} from '../components/Navbar/Navbar';
import '../styles/main.css';
import NavButton from '../components/Navbar/NavButton';
import {useRootStore} from '../context';

interface IAppBarProps extends AppBarProps {
  open: boolean;
}

// Custom AppBar component to show only the icons when collapsed
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<IAppBarProps>(({theme, open}) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Layout = observer(() => {
  const isDesktop = useMediaQuery(`(min-width: 1200px)`);

  const [open, setOpen] = useState(isDesktop);
  const {accountStore} = useRootStore();

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  useEffect(() => {
    setOpen(isDesktop);
  }, [isDesktop]);

  return (
    <Box display="flex">
      {/* ---------------- Fixed header at the top of the page ------------- */}
      {accountStore?.user?.username && (
        <>
          <AppBar position="fixed" open={open} sx={{display: {lg: 'none'}, overflow: 'hidden'}}>
            {!open && (
              <Toolbar
                sx={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  right: 0,
                  paddingInline: '0 !important',
                  marginInline: {xs: '1rem', sm: '1.5rem'},
                }}
              >
                <NavButton open={open} onClick={handleDrawerToggle} />
              </Toolbar>
            )}
          </AppBar>
          <Navbar isOpen={open} handleDrawerToggle={handleDrawerToggle} />
        </>
      )}
      <Box component="main" sx={{flexGrow: 1, padding: {xs: 0, md: 3}, paddingTop: 1.5}}>
        <Outlet />
      </Box>
    </Box>
  );
});

export default Layout;
