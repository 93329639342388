import {StatusCodes} from 'http-status-codes';
import {makeObservable, action, observable, computed, autorun, IReactionDisposer} from 'mobx';
import {toast} from 'react-toastify';
import Api, {
  ICreateOrderParams,
  IGetLinenOrdersResult,
  IOrderParams,
  IPrepareOrderParams,
  TPrepareOrderResponse,
} from '../api/order';
import type RootStore from './rootStore';
import {handleUnauthorizedResponse} from '../libs/utils';

type IOrderDetails = IOrderParams;

interface IOrder {
  rootStore: RootStore;
  currentStep: number;
  isPreparing: boolean;
  isCreating: boolean;
  isDownloading: boolean;
  isExporting: boolean;
  isLoading: boolean;
  downloadURL: string | undefined;
  blob: any | undefined;
  exportBlob: any | undefined;
  details: IOrderDetails;
  order: TPrepareOrderResponse;
  orders: Array<IGetLinenOrdersResult>;
  next: string | null;
  previous: string | null;
  count: number;
  startDate: string | null;
  endDate: string | null;
  disposers: IReactionDisposer | IReactionDisposer[];

  setCurrentStep: (currentStep: number, save: boolean) => void;
  setIsPreparing: (isPreparing: boolean) => void;
  setIsCreating: (isCreating: boolean) => void;
  setIsLoading: (isLoading: boolean) => void;
  prepareOrder: (params: IPrepareOrderParams, token: string) => void;
  setDetails: (details: IOrderDetails, save: boolean) => void;
  reset: () => void;
  setOrder: (order: TPrepareOrderResponse, save: boolean) => void;
  updateOrder: (linen_id: number, to_order: number, surplus: number) => void;
  setDownloadURL: (url: string | undefined) => void;
  setOrders: (orders: Array<IGetLinenOrdersResult>) => void;
  getOrders: (nextPreviousUrl: string | null) => void;
  setNext: (next: string | null) => void;
  setPrevious: (previous: string | null) => void;
  setCount: (count: number) => void;
  setStartDate: (startDate: string | null) => void;
  setEndDate: (endDate: string | null) => void;
  setBlob: (blob: any | undefined) => void;
  setExportBlob: (exportBlob: any | undefined) => void;
  setIsDownloading: (isDownloading: boolean) => void;
  setIsExporting: (isExporting: boolean) => void;
}

class Order implements IOrder {
  rootStore: RootStore;
  currentStep = 0;
  isPreparing = false;
  isCreating = false;
  isDownloading = false;
  isLoading = false;
  isExporting = false;
  downloadURL: string | undefined = undefined;
  blob: any | undefined = undefined;
  exportBlob: any | undefined = undefined;
  details: IOrderDetails = {
    delivery_company: 0,
    order_date: '',
    order_from: '',
    delivery_date: '',
    orderer: '',
    days: 3,
    hotel: 0,
  };
  order: TPrepareOrderResponse = [];
  orders: Array<IGetLinenOrdersResult> = [];
  next: string | null = null;
  previous: string | null = null;
  count = 0;
  // order filtering
  startDate: string | null = null;
  endDate: string | null = null;
  disposers: IReactionDisposer | IReactionDisposer[];

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      currentStep: observable,
      details: observable,
      isPreparing: observable,
      isCreating: observable,
      isLoading: observable,
      order: observable,
      downloadURL: observable,
      orders: observable,
      next: observable,
      previous: observable,
      count: observable,
      startDate: observable,
      endDate: observable,
      blob: observable,
      exportBlob: observable,
      isDownloading: observable,
      isExporting: observable,
      setCurrentStep: action,
      setIsPreparing: action,
      setIsCreating: action,
      setDetails: action,
      reset: action,
      prepareOrder: action,
      setOrder: action,
      updateOrder: action,
      setDownloadURL: action,
      setOrders: action,
      setNext: action,
      setPrevious: action,
      setCount: action,
      setStartDate: action,
      setEndDate: action,
      setBlob: action,
      setExportBlob: action,
      setIsDownloading: action,
      setIsExporting: action,
      setIsLoading: action,
      pages: computed,
      total: computed,
    });

    this.disposers = [
      autorun(() => {
        this.loadCurrentStep();
      }),
      autorun(() => {
        if (this.currentStep === 0) {
          this.loadDetails();
        }
      }),
      autorun(() => {
        if (this.currentStep === 1) {
          this.loadDetails();
          this.loadOrder();
        }
      }),
    ];
  }

  setCurrentStep(currentStep: number, save = true) {
    if (save) {
      window.sessionStorage.setItem('currentStep', String(currentStep));
    }
    this.currentStep = currentStep;
  }

  setIsPreparing(isPreparing: boolean) {
    this.isPreparing = isPreparing;
  }

  setIsCreating(isCreating: boolean) {
    this.isCreating = isCreating;
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  loadCurrentStep() {
    const currentStep = window.sessionStorage.getItem('currentStep');
    if (currentStep !== null) {
      this.setCurrentStep(parseInt(currentStep), false);
    }
  }

  setDetails(details: IOrderDetails, save = true) {
    this.details = details;
    if (save) {
      window.sessionStorage.setItem('orderDetails', JSON.stringify(details));
    }
  }

  // TODO Implement luxon
  getFieldDate(d: Date, days = 0): string {
    if (days > 0) {
      const now = new Date(d);
      now.setDate(now.getDate() + days);
      return this.getFieldDate(now, 0);
    }
    return (
      d.getFullYear().toString() +
      '-' +
      (d.getMonth() + 1).toString().padStart(2, '0') +
      '-' +
      d.getDate().toString().padStart(2, '0')
    );
  }

  loadDetails() {
    const sessionDetails = window.sessionStorage.getItem('orderDetails');
    let details = null;
    if (sessionDetails === null) {
      const now = new Date();
      details = {
        delivery_company: 0,
        order_date: this.getFieldDate(now, 0),
        order_from: this.getFieldDate(now, 2),
        delivery_date: this.getFieldDate(now, 2),
        days: 3,
        hotel: this.rootStore.hotelStore.currentHotel?.id,
      };
    } else {
      details = JSON.parse(sessionDetails);
    }
    this.setDetails(details, false);
  }

  loadOrder() {
    const data = window.sessionStorage.getItem('order');
    if (data !== null) {
      const order = JSON.parse(data);
      this.setOrder(order, false);
    }
  }

  reset() {
    window.sessionStorage.removeItem('orderDetails');
    window.sessionStorage.removeItem('order');
    this.setDownloadURL(undefined);
    this.setBlob(undefined);
    this.setOrder([]);
    this.setDetails(
      {
        delivery_company: 0,
        order_date: '',
        order_from: '',
        orderer: '',
        delivery_date: '',
        days: 3,
        hotel: 0,
      },
      false,
    );
    this.setCurrentStep(0);
  }

  updateOrder(linen_id: number, to_order: number, surplus: number) {
    const order = this.order.map((orderItem) => {
      if (orderItem.linen_id === linen_id) {
        orderItem.to_order = to_order;
        orderItem.surplus = surplus;
      }
      return orderItem;
    });
    this.setOrder(order);
  }

  setOrder(order: TPrepareOrderResponse, save = true) {
    if (save) {
      window.sessionStorage.setItem('order', JSON.stringify(order));
    }
    this.order = order;
  }

  setDownloadURL(url: string | undefined) {
    this.downloadURL = url;
  }

  setOrders(orders: Array<IGetLinenOrdersResult>) {
    this.orders = orders;
  }

  setNext(next: string | null) {
    this.next = next;
  }

  setPrevious(previous: string | null) {
    this.previous = previous;
  }

  setCount(count: number) {
    this.count = count;
  }

  setStartDate(startDate: string | null) {
    this.startDate = startDate;
  }

  setEndDate(endDate: string | null) {
    this.endDate = endDate;
  }

  setBlob(blob: any | undefined) {
    this.blob = blob;
  }

  setExportBlob(exportBlob: any | undefined) {
    this.exportBlob = exportBlob;
  }

  setIsDownloading(isDownloading: boolean) {
    this.isDownloading = isDownloading;
  }

  setIsExporting(isExporting: boolean) {
    this.isExporting = isExporting;
  }

  get pages() {
    const limit = 20;
    if (this.count > 0) {
      return Math.ceil(this.count / limit);
    }
    return 0;
  }

  get total() {
    let total = 0;
    this.order.forEach((obj, i) => {
      total += obj.to_order * obj.price;
    });
    return new Intl.NumberFormat('ja-JP', {
      style: 'currency',
      currency: 'JPY',
    }).format(total);
  }

  // Api
  async prepareOrder(params: IPrepareOrderParams) {
    const token = this.rootStore.accountStore.user.token;

    if (token) {
      try {
        this.setIsPreparing(true);
        this.setIsLoading(true);

        const response = await Api.prepareOrder(params, token);

        if (response.ok) {
          const data = await response;

          this.setDetails(params);
          this.setOrder(data.body);
          this.setCurrentStep(1);
        }
      } catch (error: any) {
        if (error.status === StatusCodes.UNAUTHORIZED) {
          handleUnauthorizedResponse();
          return;
        }
        toast.error(error.message);
      } finally {
        this.setIsPreparing(false);
        this.setIsLoading(false);
      }
    }
  }

  async createOrder() {
    const token = this.rootStore.accountStore.user.token;
    const currentHotel = this.rootStore.hotelStore.currentHotel?.id;

    if (token && currentHotel) {
      const params: ICreateOrderParams = {
        delivery_company: this.details.delivery_company,
        hotel: currentHotel,
        orderer: this.details.orderer,
        order_date: this.details.order_date,
        order_from: this.details.order_from,
        days: this.details.days,
        delivery_date: this.details.delivery_date,
        to_order: this.order.map((obj) => {
          return {
            linen_id: obj.linen_id,
            quantity: obj.to_order,
          };
        }),
      };

      try {
        this.setIsCreating(true);
        this.setIsLoading(true);

        const response = await Api.createOrder(params, token);

        if (response.ok) {
          const data = await response;

          this.setDownloadURL(data.body.download_url);
        }
      } catch (error: any) {
        if (error.status === StatusCodes.UNAUTHORIZED) {
          handleUnauthorizedResponse();
          return;
        }
        toast.error(error.message);
      } finally {
        this.setIsCreating(false);
        this.setIsLoading(false);
      }
    }
  }

  //TODO CREATE PAGINATION COMPONENT FOR THIS FUNCTION
  async getOrders(nextPreviousUrl: string | null = null) {
    const token = this.rootStore.accountStore.user.token;
    const currentHotel = this.rootStore.hotelStore.currentHotel?.id;

    if (token && currentHotel) {
      try {
        this.setIsLoading(true);

        const response = await Api.getOrders(
          token,
          currentHotel,
          this.startDate,
          this.endDate,
          nextPreviousUrl,
        );

        if (response.ok) {
          const data = await response;

          this.setOrders(data.body.results);
          this.setCount(data.body.count);
          this.setNext(data.body.next);
          this.setPrevious(data.body.previous);
        }
      } catch (error: any) {
        if (error.status === StatusCodes.UNAUTHORIZED) {
          handleUnauthorizedResponse();
          return;
        }
        toast.error(error.message);
      } finally {
        this.setIsLoading(false);
      }
    }
  }

  async downloadOrder(url: string) {
    try {
      this.setIsDownloading(true);

      const response = await Api.downloadOrder(url);

      if (response.ok) {
        const data = await response;

        this.setBlob(data.body);
      }
    } catch (error: any) {
      if (error.status === StatusCodes.UNAUTHORIZED) {
        handleUnauthorizedResponse();
        return;
      }
      toast.error(error.message);
    } finally {
      this.setIsDownloading(false);
    }
  }

  async exportOrders() {
    const token = this.rootStore.accountStore.user.token;
    const currentHotel = this.rootStore.hotelStore.currentHotel?.id;

    if (token && currentHotel) {
      try {
        this.setIsExporting(true);

        const response = await Api.exportOrders(token, currentHotel, this.startDate, this.endDate);

        if (response.ok) {
          const data = await response;

          this.setExportBlob(data.body);
        }
      } catch (error: any) {
        if (error.status === StatusCodes.UNAUTHORIZED) {
          handleUnauthorizedResponse();
          return;
        }
        toast.error(error.message);
      } finally {
        this.setIsExporting(false);
      }
    }
  }
}

export default Order;
