import request from 'superagent';
import Config from '../config';
import Common from './common';

export interface IGuestResult {
  id: number;
  is_valid_phone_number: boolean;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  language: string;
}

export interface IGetGuestsResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: Array<IGuestResult>;
}

export default {
  getGuests: function (token: string, nextPreviousUrl: string | null) {
    const url = nextPreviousUrl || `${Config.API_URL}/guests/`;

    if (nextPreviousUrl && nextPreviousUrl.length > 0) {
      return request.get(url).auth(token, {type: 'bearer'}).accept(Common.MEDIA_TYPE).send();
    }
    return request.get(url).auth(token, {type: 'bearer'}).accept(Common.MEDIA_TYPE).send();
  },
  getGuest: function (token: string, guestId: string) {
    const url = `${Config.API_URL}/guest/${guestId}/`;

    return request.get(url).auth(token, {type: 'bearer'}).accept(Common.MEDIA_TYPE).send();
  },
};
