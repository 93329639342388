import {Box, Stack} from '@mui/system';
import React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import {Typography} from '@mui/material';

interface InfoTextProps {
  text: string;
  hide?: boolean;
}

const commonStyles = {
  bgcolor: 'primary.main',
  borderColor: 'text.primary',
  marginY: 2,
  border: 1,
  width: 'fit-content',
  color: 'white',
};

const InfoText: React.FC<InfoTextProps> = ({text, hide}) => {
  return (
    <Box
      sx={{...commonStyles, borderRadius: '20px', display: hide ? 'none' : 'flex'}}
      paddingY={1}
      paddingX={1.5}
    >
      <Stack display={'flex'} flexDirection="row" gap={1} alignItems="center">
        <InfoIcon fontSize="small" />
        <Typography variant="subtitle2">{text}</Typography>
      </Stack>
    </Box>
  );
};

export default InfoText;
