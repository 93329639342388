import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import Header from '../../components/table/Header';
import '../../styles/main.css';
import {getML, getPrice} from '../../libs/utils';
import {useRootStore} from '../../context';
import SelectHotel from '../../components/Navbar/SelectHotel';
import {
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Paper,
  Select,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import PageTitle from '../../components/PageTitle';
import CardRowItem from '../../components/table/CardRowItem';
import DeleteIcon from '@mui/icons-material/Delete';
import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import {useTranslation} from 'react-i18next';
import type {IListProductResult} from '../../api/inventory/product';
import SkeletonLoader from '../../components/general/SkeletonLoader';
import NoResultsFound from '../../components/general/NoResultsFound';
import ListPagination from '../../components/ListPagination';

const Products = observer(() => {
  const {t} = useTranslation();
  const {productsStore, hotelStore, inventoryStore} = useRootStore();

  useEffect(() => {
    if (hotelStore.currentHotel !== undefined) {
      productsStore.getLevels();
      productsStore.getProducts();
    }
  }, [hotelStore.currentHotel, productsStore.level]);

  useEffect(() => {
    if (inventoryStore.storages.length === 0) {
      inventoryStore.getStorages();
    }
    if (inventoryStore.units.length === 0) {
      inventoryStore.getUnits();
    }
  }, [inventoryStore.storages, inventoryStore.units]);

  useEffect(() => {
    if (productsStore.levels.length === 1) {
      productsStore.setLevel(productsStore.levels[0]);
    }
  }, [productsStore.levels]);

  // Had to change the paper elevation through the box shadow because using
  // useMediaQuery crashes the app causing 'Rendered more hooks than during the // previous render'
  const paperStyles = {
    padding: {xs: 0, lg: 3},
    bgcolor: {xs: 'transparent', lg: 'background.paper'},
    boxShadow: {
      xs: 'none',
      lg: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
    },
  };

  return (
    <Paper sx={paperStyles}>
      {/* ----------------------------- Top -------------------------------- */}
      <Stack flexDirection={{xs: 'column', lg: 'row'}} gap={3} mb={5}>
        <Stack>
          <Typography variant="h5" component="h2">
            {t('items:items_list')}
          </Typography>
          <Typography variant="body1" component="span" color="GrayText">
            {t('items:total_products_count', {products: productsStore.count})}
          </Typography>
        </Stack>
        <Select
          value={productsStore.level}
          onChange={(e) => productsStore.setLevel(e.target.value)}
          sx={{'& fieldset legend': {display: 'none'}}}
        >
          {productsStore.levels.map((lev, index) => (
            <MenuItem key={index} value={lev}>
              {t('items:viewing_storage')} {lev}
            </MenuItem>
          ))}
        </Select>
      </Stack>

      {productsStore.isLoading && <SkeletonLoader skeletonShape="table" />}

      {productsStore.count > 0 && !productsStore.isLoading && (
        <>
          {/* ---------------------------- Headers ----------------------------- */}
          <Grid container display={{xs: 'none', lg: 'flex'}} mb={1}>
            <Grid item lg={1.5} />
            <Grid item lg={1.5}>
              <Header justifyContent="start">{t('common:brand')}</Header>
            </Grid>
            <Grid item lg={1.5}>
              <Header justifyContent="start">{t('common:item_name')}</Header>
            </Grid>
            <Grid item lg={1.5}>
              <Header>{t('common:storage')}</Header>
            </Grid>
            <Grid item lg={1.5}>
              <Header>{t('common:unit')}</Header>
            </Grid>
            <Grid item lg={1.5}>
              <Header>{t('common:quantity')}</Header>
            </Grid>
            <Grid item lg={1.5}>
              <Header>{t('common:unit_price')}</Header>
            </Grid>
            <Grid item lg={1.5}>
              <Header>{t('common:actions')}</Header>
            </Grid>
          </Grid>

          {/* ----------------------------- Items ------------------------------ */}
          <Grid container gap={{xs: 5, lg: 2}}>
            {productsStore.products.map((obj, index) => {
              const info = [
                {
                  label: t('common:image'),
                  value: obj.storage?.image ? (
                    <img width={'75px'} src={obj.storage.image} />
                  ) : (
                    <PhotoSizeSelectActualIcon sx={{fontSize: '4rem', color: 'gray.light'}} />
                  ),
                  lg: 1.5,
                },
                {
                  label: t('common:brand'),
                  value: obj.brand.name,
                  lg: 1.5,
                  alignItems: 'start',
                },
                {
                  label: t('common:item_name'),
                  value: obj.name,
                  lg: 1.5,
                  alignItems: 'start',
                },
                {
                  label: t('items:storage_name'),
                  value: obj.storage?.storage.name ?? '',
                  lg: 1.5,
                },
                {
                  label: t('common:unit'),
                  value: obj.unit.name,
                  lg: 1.5,
                },
                {
                  label: t('common:quantity'),
                  value: getML(obj.storage?.quantity ?? 0),
                  lg: 1.5,
                },
                {
                  label: t('common:unit_price'),
                  value:
                    obj.purchase.unit_price &&
                    getPrice(Number(obj.purchase.unit_price), obj.purchase.unit_price_currency),
                  lg: 1.5,
                },
                {
                  label: t('common:actions'),
                  value: <ActionButtons obj={obj} />,
                  lg: 1.5,
                },
              ];

              return <CardRowItem item={info} key={index} />;
            })}
          </Grid>
        </>
      )}

      {productsStore.count > 0 && (
        <Stack
          display="flex"
          flexDirection={'row'}
          width="100%"
          justifyContent={'center'}
          marginTop={3}
        >
          <ListPagination
            getter={productsStore.getProducts.bind(productsStore)}
            store={productsStore}
          />
        </Stack>
      )}

      {productsStore.count === 0 && !productsStore.isLoading && (
        <NoResultsFound message={t('common:there_are_no_items')} />
      )}
    </Paper>
  );
});

const Items = observer(() => {
  const {t} = useTranslation();

  return (
    <Container>
      <PageTitle>{t('common:inventory_and_supplies')}</PageTitle>
      <Grid container alignItems="center" spacing={3} mb={5}>
        <Grid item xs={8} sm={4}>
          <SelectHotel />
        </Grid>
        <Grid item xs={4} sm={4}>
          <Link to={'/inventory/add-item'} style={{textDecoration: 'none'}}>
            <Button variant="contained">{t('items:add_an_item')}</Button>
          </Link>
        </Grid>
      </Grid>
      <Products />
    </Container>
  );
});

export default Items;

interface IActionButtonsProps {
  obj: IListProductResult;
}

const ActionButtons: React.FC<IActionButtonsProps> = ({obj}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {t} = useTranslation();
  const {productsStore} = useRootStore();

  const handleOpen = () => setIsModalOpen(true);
  const handleClose = () => setIsModalOpen(false);

  const handleDelete = () => {
    productsStore.deleteProduct(obj.id);
    handleClose();
  };

  const modalStyles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <Stack flexDirection="row">
        <Link to={`/inventory/edit-item/${obj.id}`} style={{textDecoration: 'none'}}>
          <Tooltip title={t('common:see_details')}>
            <IconButton aria-label={t('common:see_details')} color="primary">
              <FormatListBulletedIcon />
            </IconButton>
          </Tooltip>
        </Link>
        <Tooltip title={t('common:delete')}>
          <IconButton aria-label="delete" color="error" onClick={handleOpen}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Stack>
      <Modal open={isModalOpen} onClose={handleClose}>
        <Paper sx={modalStyles}>
          <Typography variant="h4" component="h3" fontWeight={600} mb={1}>
            {t(`items:delete_product_lv_storage`, {productsLevel: productsStore.level})}
          </Typography>
          <Divider />
          <Typography variant="body1" fontWeight={600} gutterBottom color="GrayText" mt={2}>
            {t('items:deleting_lv1_is_equivalent_to')}
          </Typography>
          <Typography variant="body1" fontWeight={600} color="GrayText" mb={2}>
            {t('items:to_delete_lv2_lv3_select')}
          </Typography>
          <Divider />
          <Stack flexDirection="row" mt={2} justifyContent="end" gap={2}>
            <Button variant="outlined" onClick={handleClose} color="gray">
              {t('common:close')}
            </Button>
            <Button variant="contained" onClick={handleDelete} color="error">
              {t('common:delete')}
            </Button>
          </Stack>
        </Paper>
      </Modal>
    </>
  );
};
