import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import '../../styles/main.css';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import {getML} from '../../libs/utils';
import {useRootStore} from '../../context';
import {
  PAGE_LIST_ORDERS,
  PAGE_ADD_AMENITY,
  PAGE_SAVE_ORDER,
} from '../../stores/inventory/orders/orders';
import SelectHotel from '../Navbar/SelectHotel';
import {
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  Typography,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Header from '../table/Header';
import CardRowItem from '../table/CardRowItem';
import {useTranslation} from 'react-i18next';
import SkeletonLoader from '../general/SkeletonLoader';

const CreateOrder = observer(() => {
  const {hotelStore, ordersStore, stockStore} = useRootStore();
  const {t} = useTranslation();
  // View order sheet button
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (hotelStore.currentHotel !== undefined) {
      stockStore.getItems();
    }
  }, [hotelStore.currentHotel]);

  useEffect(() => {
    setDisabled(ordersStore.cart.products.length === 0);
  }, ordersStore.cart.products);

  const handleAddAmenity = (e: React.SyntheticEvent, id: number) => {
    e.preventDefault();

    const product = ordersStore.cart.getProduct(id);

    if (product !== undefined) {
      ordersStore.cart.setProduct(product);
      ordersStore.setPage(PAGE_ADD_AMENITY);
    } else {
      const amenity = stockStore.getAmenity(id);

      if (amenity !== undefined) {
        ordersStore.cart.setProduct({
          amenity: amenity,
          quantity: 0,
        });
        ordersStore.setPage(PAGE_ADD_AMENITY);
      }
    }
  };

  interface IQuantityProps {
    itemId: number;
  }

  const Quantity: React.FC<IQuantityProps> = ({itemId}) => {
    const product = ordersStore.cart.products.find((obj) => {
      return obj.amenity.id === itemId;
    });
    if (product === undefined) {
      return <></>;
    }
    return <Chip label={product.quantity} color="error" />;
  };

  const isDesktop = useMediaQuery('(min-width: 1200px)');

  const categories = [...stockStore.items];

  return (
    <>
      <Grid container gap={2} alignItems="center">
        <Grid item xs={12} sm={6} md={4}>
          <SelectHotel disabled={!disabled} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button
            onClick={() => ordersStore.setPage(PAGE_SAVE_ORDER)}
            variant="contained"
            disabled={disabled}
          >
            {t('createOrder:view_order_sheet')}
          </Button>
        </Grid>
      </Grid>
      <Card
        elevation={isDesktop ? 2 : 0}
        sx={{
          mt: 5,
          bgcolor: {xs: 'transparent', lg: 'background.paper'},
          overflow: 'visible',
        }}
      >
        <CardContent sx={{padding: {xs: 0, lg: 2}}}>
          <Button
            variant="text"
            size="large"
            onClick={() => ordersStore.setPage(PAGE_LIST_ORDERS)}
            startIcon={<ChevronLeftIcon />}
            color="gray"
            sx={{mb: 5, fontSize: '1.25rem'}}
          >
            {t('createOrder:orders_list')}
          </Button>

          {stockStore.isLoading && <SkeletonLoader skeletonShape="table" amount={5} />}

          {stockStore.items.length > 0 &&
            !stockStore.isLoading &&
            categories.map((category) => {
              return (
                <Accordion key={category.name} sx={{width: '100%'}}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography fontWeight={'bold'}>{category.name}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container display={{xs: 'none', lg: 'flex'}}>
                      <Grid item lg={1.5}>
                        <Header />
                      </Grid>
                      <Grid item lg={1.5}>
                        <Header justifyContent="start">{t('common:code')}</Header>
                      </Grid>
                      <Grid item lg={1.5}>
                        <Header justifyContent="start">{t('common:brand')}</Header>
                      </Grid>
                      <Grid item lg={1.25}>
                        <Header justifyContent="start">{t('common:vendor')}</Header>
                      </Grid>
                      <Grid item lg={1}>
                        <Header>{t('common:storage')}</Header>
                      </Grid>
                      <Grid item lg={1.25}>
                        <Header>{t('common:unit')}</Header>
                      </Grid>
                      <Grid item lg={1.25}>
                        <Header>{t('createOrder:unit_storage')}</Header>
                      </Grid>
                      <Grid item lg={1.25}>
                        <Header>{t('common:status')}</Header>
                      </Grid>
                      <Grid item lg={1.5}>
                        <Header />
                      </Grid>
                    </Grid>
                    {category.item.map((item) => {
                      const info = [
                        {
                          label: t('common:image'),
                          value:
                            item.storage.image === '' ? (
                              <PhotoSizeSelectActualIcon
                                sx={{fontSize: '4rem', color: 'gray.light'}}
                              />
                            ) : (
                              <img src={item.storage.image} width="75px" />
                            ),
                          lg: 1.5,
                        },
                        {
                          label: t('common:code'),
                          value: item.sku,
                          lg: 1.5,
                          alignItems: 'start',
                        },
                        {
                          label: t('common:brand'),
                          value: item.brand.name,
                          lg: 1.5,
                          alignItems: 'start',
                        },
                        {
                          label: t('common:vendor'),
                          value: item.purchase.vendor.name,
                          lg: 1.25,
                          alignItems: 'start',
                        },
                        {
                          label: t('common:storage'),
                          value: item.storage.storage.name,
                          lg: 1,
                        },
                        {
                          label: t('common:unit'),
                          value: item.unit.name,
                          lg: 1.25,
                        },
                        {
                          label: t('createOrder:unit_storage'),
                          value: getML(item.storage.quantity, 0),
                          lg: 1.25,
                        },
                        {
                          label: t('common:status'),
                          value: item.status.toUpperCase(),
                          lg: 1.25,
                        },
                        {
                          label: '',
                          value: (
                            <Button onClick={(e) => handleAddAmenity(e, item.id)} variant="text">
                              <Quantity itemId={item.id} />
                              <ShoppingCartIcon
                                fontSize="large"
                                sx={{
                                  color: 'gray.light',
                                }}
                              />
                            </Button>
                          ),
                          lg: 1.5,
                        },
                      ];
                      return <CardRowItem key={item.name} item={info} />;
                    })}
                  </AccordionDetails>
                </Accordion>
              );
            })}

          {stockStore.items.length === 0 && !stockStore.isLoading && (
            <Typography variant="h5" component="h2">
              {t('common:there_are_no_items')}
            </Typography>
          )}
        </CardContent>
      </Card>
    </>
  );
});

export default CreateOrder;
