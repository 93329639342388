import React from 'react';

interface ILogoProps {
  variant: string;
}

const Logo: React.FC<ILogoProps> = ({variant}) => {
  return (
    <img src={`/images/wayfarer-logo${variant === 'light' ? '-light' : ''}.png`} width="146px" />
  );
};

export default Logo;
