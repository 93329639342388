import {StatusCodes} from 'http-status-codes';
import {makeObservable, observable, action} from 'mobx';
import {toast} from 'react-toastify';
import i18n from '../../i18n';
import Api, {
  IGetItemsItem,
  TGetItemsResponse,
  TGetStockResponse,
  TUpdateStockPayload,
} from '../../api/inventory/stock';
import type RootStore from '../rootStore';
import {handleUnauthorizedResponse} from '../../libs/utils';

interface IStock {
  rootStore: RootStore;
  isSaving: boolean;
  saveSuccess: boolean;
  deleteSuccess: boolean;
  conflict: boolean;
  isLoading: boolean;
  items: TGetItemsResponse;
  stock: TGetStockResponse;
  setIsSaving: (isSaving: boolean) => void;
  setSaveSuccess: (value: boolean) => void;
  setDeleteSuccess: (value: boolean) => void;
  setConflict: (value: boolean) => void;
  setIsLoading: (isLoading: boolean) => void;
  reset: () => void;
  getAmenity: (id: number) => IGetItemsItem | undefined;
  setItems: (items: TGetItemsResponse) => void;
  getItems: (hotel: number) => void;
  setStock: (stock: TGetStockResponse) => void;
  getStock: (hotel: number) => void;
  updateStock: (payload: TUpdateStockPayload) => void;
}

class Stock implements IStock {
  rootStore;
  isSaving = false;
  saveSuccess = false;
  deleteSuccess = false;
  conflict = false;
  isLoading = false;
  items: TGetItemsResponse = [];
  stock: TGetStockResponse = [];

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      isSaving: observable,
      saveSuccess: observable,
      deleteSuccess: observable,
      conflict: observable,
      isLoading: observable,
      items: observable,
      stock: observable,
      setIsSaving: action,
      setSaveSuccess: action,
      setDeleteSuccess: action,
      setConflict: action,
      setIsLoading: action,
      reset: action,
      getAmenity: action,
      setItems: action,
      getItems: action,
      setStock: action,
      getStock: action,
      updateStock: action,
    });
  }

  setIsSaving(isSaving: boolean) {
    this.isSaving = isSaving;
  }

  setSaveSuccess(value: boolean) {
    this.saveSuccess = value;
  }

  setDeleteSuccess(value: boolean) {
    this.deleteSuccess = value;
  }

  setConflict(value: boolean) {
    this.conflict = value;
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setItems(items: TGetItemsResponse) {
    this.items = items;
  }

  setStock(stock: TGetStockResponse) {
    this.stock = stock;
  }

  getAmenity(id: number) {
    for (const vendor of this.items) {
      const found = vendor.item.find((item) => {
        return item.id === id;
      });
      if (found) return found;
    }
  }

  // Api calls
  async getItems() {
    const token = this.rootStore.accountStore.user.token;
    const currentHotelId = this.rootStore.hotelStore.currentHotel?.id;

    if (token && currentHotelId) {
      try {
        this.setIsLoading(true);

        const response = await Api.getItems(token, currentHotelId);

        if (response.ok) {
          const data = await response;

          this.setItems(data.body);
        }
      } catch (error: any) {
        if (error.status === StatusCodes.UNAUTHORIZED) {
          handleUnauthorizedResponse();
          return;
        }
        toast.error(error.message);
      } finally {
        this.setIsLoading(false);
      }
    }
  }

  reset() {
    this.setItems([]);
    this.setStock([]);
    this.setIsSaving(false);
    this.setSaveSuccess(false);
    this.setDeleteSuccess(false);
    this.setConflict(false);
  }

  async getStock() {
    const token = this.rootStore.accountStore.user.token;
    const currentHotelId = this.rootStore.hotelStore.currentHotel?.id;

    if (token && currentHotelId) {
      try {
        this.setIsLoading(true);

        const response = await Api.getStock(token, currentHotelId);

        if (response.ok) {
          const data = await response;

          this.setStock(data.body);
        }
      } catch (error: any) {
        if (error.status === StatusCodes.UNAUTHORIZED) {
          handleUnauthorizedResponse();
          return;
        }
        if (error.status === StatusCodes.CONFLICT) {
          toast.warning(
            i18n.t(
              'A stock count for this hotel today already exists. Please view previous counts.',
            ) as string,
          );
          this.setConflict(true);
          return;
        }
        toast.error(error.message);
      } finally {
        this.setIsLoading(false);
      }
    }
  }

  async deleteStock() {
    const token = this.rootStore.accountStore.user.token;
    const currentHotelId = this.rootStore.hotelStore.currentHotel?.id;

    if (token && currentHotelId) {
      try {
        const response = await Api.deleteStock(token, currentHotelId);

        if (response.ok) {
          this.setDeleteSuccess(true);
        }
      } catch (error: any) {
        if (error.status === StatusCodes.UNAUTHORIZED) {
          handleUnauthorizedResponse();
          return;
        }
        toast.error(error.message);
      }
    }
  }

  // TODO CHECK THE BEHAVIOUR OF THIS FUNCTION SINCE IT DIDNT HAVE A IF RES OK
  async updateStock(payload: TUpdateStockPayload) {
    const token = this.rootStore.accountStore.user.token;
    const currentHotelId = this.rootStore.hotelStore.currentHotel?.id;

    if (token && currentHotelId) {
      try {
        this.setIsSaving(true);

        const response = await Api.updateStock(token, currentHotelId, payload);

        if (response.ok) {
          this.setSaveSuccess(true);
        }
      } catch (error: any) {
        if (error.status === StatusCodes.UNAUTHORIZED) {
          handleUnauthorizedResponse();
          return;
        }
        toast.error(error.message);
      } finally {
        this.setIsSaving(false);
      }
    }
  }
}

export default Stock;
