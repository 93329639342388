import React from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import GroupIcon from '@mui/icons-material/Group';
import TranslateIcon from '@mui/icons-material/Translate';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import BadgeRoundedIcon from '@mui/icons-material/BadgeRounded';
import PhoneIcon from '@mui/icons-material/Phone';
import {observer} from 'mobx-react-lite';
import {Link} from 'react-router-dom';
import type {IReservationData, IReservationResult} from '../../api/reservations';
import {reservationStatusColor} from '../../api/reservations';
import {capitalize, localizedDate} from '../../libs/utils';
import '../../styles/main.css';
import {useTranslation} from 'react-i18next';
import {useRootStore} from '../../context';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import {DateTime} from 'luxon';
import ToggleAutoSendCodeButton from './ToggleAutoSendCodeButton';

const getRoomList = (guestReservations: IReservationResult) => {
  const rooms: Array<string> = [];

  guestReservations.reservations.forEach((reservation) => {
    if (!rooms.includes(reservation.room_number))
      rooms.push(` ${reservation.room_number} (${reservation.room_state})`);
  });

  return rooms.join(',');
};

interface IReservationCardProps {
  guestReservations: IReservationResult;
  sameReservation: boolean;
  hasDuplicateRoom?: number;
}

const ReservationCard: React.FC<IReservationCardProps> = observer(
  ({guestReservations, sameReservation, hasDuplicateRoom}) => {
    const {reservations, guest, group_id, hotel_door_lock} = guestReservations;
    const {t} = useTranslation();

    const {hotelStore, reservationsStore} = useRootStore();

    const reservationCount = reservations.length - 1;

    const firstReservation: IReservationData = reservations[0];
    const lastReservation = reservations[reservationCount];
    const firstReservationCheckInDate = DateTime.fromJSDate(
      new Date(firstReservation.check_in),
    ).toFormat('yyyy-MM-dd');
    const checkInInProgress = reservations.some((reserv) => reserv.check_in_completed === true);

    const checkInCompleted = reservations.every((reserv) => reserv.check_in_completed === true);

    const showDuplicates = hasDuplicateRoom && hasDuplicateRoom > 0 ? true : false;

    const roomNumber = getRoomList(guestReservations);
    const localizedCheckIn = localizedDate(firstReservation.check_in);
    const localizedCheckOut = sameReservation
      ? localizedDate(lastReservation.check_out)
      : localizedDate(firstReservation.check_out);
    const capitalizedFirstName = capitalize(guest.first_name);
    const capitalizedLastName = capitalize(guest.last_name);

    const reservationDetailsButton = (
      <Link to={`${group_id}/${firstReservationCheckInDate}`} style={{textDecoration: 'none'}}>
        <Button
          variant="contained"
          endIcon={<FormatListBulletedIcon />}
          aria-label={'See details'}
          color={'primary'}
        >
          <Typography variant={'caption'}>{t('common:see_details')}</Typography>
        </Button>
      </Link>
    );

    const phoneHasIncorrectFormat = guest.phone_number && !guest.is_valid_phone_number;

    const statusColor = reservationStatusColor[firstReservation.state];

    const skipIdentityText = firstReservation.skip_identity ? 'Skipped' : '';

    const hasProductError = guestReservations.reservations.some((reservation) =>
      reservation.products.some((product) => product.error.length > 0),
    );

    const handleEnableDisableAutoSendCode = async (
      e: React.MouseEvent,
      groupId: string,
      checkInDate: string,
      currentAutoSendStatus: boolean,
      lock: string | null,
    ) => {
      e.preventDefault();

      reservationsStore.toggleAutomaticSendCode(groupId, checkInDate, currentAutoSendStatus, lock);
    };

    const cardContentStyles = {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    };

    return (
      firstReservation && (
        <>
          <Card sx={{width: {xs: '100%', sm: '49%', md: '30%'}}}>
            <CardContent sx={{...cardContentStyles}}>
              <Grid container gap={{xs: 1}}>
                <Grid item xs={12} marginBottom={1} display="flex" justifyContent={'space-between'}>
                  <Typography variant="h5">
                    {`${capitalizedFirstName} ${capitalizedLastName}`}{' '}
                  </Typography>
                  <Typography
                    variant="body2"
                    fontWeight={'700'}
                    color={statusColor}
                    display={'flex'}
                    alignItems={'flex-start'}
                    gap={0.5}
                  >
                    {firstReservation.state.length > 0 ? firstReservation.state : 'N/A'}
                    {hasProductError && (
                      <Tooltip title={t('reservations:approved_products_issue')} color="error">
                        <IconButton color="error" sx={{margin: 0, padding: 0}}>
                          <ErrorOutlineIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Stack>
                    <Box display={'flex'} alignItems="center" gap={1}>
                      <Typography variant="body2" sx={{fontWeight: 'bold'}} flexDirection="row">
                        {'Reservation ID'}
                      </Typography>
                      {showDuplicates && (
                        <Link
                          to={`${group_id}/${firstReservationCheckInDate}`}
                          style={{textDecoration: 'none'}}
                        >
                          <Tooltip
                            title="This room is booked with several reservation IDs"
                            placement="right"
                            arrow
                          >
                            <Button
                              variant="contained"
                              color="gray"
                              startIcon={<AddCircleOutlinedIcon />}
                              sx={{
                                width: 'fit-content',
                                height: 'fit-content',
                                paddingX: '5px',
                                paddingY: 0,
                                color: 'white',
                                minWidth: 0,
                              }}
                            >
                              {hasDuplicateRoom}
                            </Button>
                          </Tooltip>
                        </Link>
                      )}
                    </Box>

                    <Typography variant="body2">{firstReservation.id}</Typography>
                  </Stack>
                </Grid>
                <Grid item display={'flex'} flexDirection="column" alignItems={'center'}>
                  <GroupIcon fontSize="small" />

                  <Typography variant="body2">{firstReservation.guest_count}</Typography>
                </Grid>
                <Grid item marginLeft={2}>
                  <Stack>
                    <Typography variant="body2" sx={{fontWeight: 'bold'}} flexDirection="row">
                      {t('reservations:ota')}
                    </Typography>
                    <Typography variant="body2">{firstReservation.ota_name}</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack display="flex" flexDirection="row" alignItems={'center'} gap={0.5}>
                    <AlternateEmailIcon fontSize="small" />

                    <Typography noWrap variant="body2">
                      {guest.email ?? '-'}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item>
                  <Stack display="flex" flexDirection="row" alignItems={'center'} gap={0.5}>
                    <PhoneIcon
                      fontSize="small"
                      color={!guest.is_valid_phone_number ? 'error' : 'inherit'}
                    />
                    <Typography variant="body2">{guest.phone_number ?? '-'}</Typography>

                    {phoneHasIncorrectFormat && (
                      <Tooltip title={t('common:phone_number_incorrect_format')} color="error">
                        <IconButton color="error" sx={{margin: 0, padding: 0}}>
                          <ErrorOutlineIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Stack>
                </Grid>
                <Grid item display="flex" flexDirection="column" justifyContent={'center'}>
                  <Stack display="flex" flexDirection="row" alignItems={'center'} gap={0.5}>
                    <TranslateIcon fontSize="small" />

                    <Typography textAlign="center" variant="body2">
                      {guest.language.toUpperCase()}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item width="100%">
                  <Divider light />
                </Grid>
                <Grid item>
                  <Typography variant="body2" sx={{fontWeight: 'bold'}} flexDirection="row">
                    {t('reservations:hotel_and_room_number')}
                  </Typography>
                  <Typography variant="body2">
                    {`${hotelStore.currentHotel?.short_name} ${roomNumber}`}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2" sx={{fontWeight: 'bold'}} flexDirection="row">
                    PreCheck-in
                  </Typography>
                  <Typography
                    variant="body2"
                    fontWeight={'bold'}
                    color={
                      checkInInProgress && !checkInCompleted
                        ? 'primary'
                        : checkInCompleted
                        ? 'green'
                        : 'GrayText'
                    }
                  >
                    {checkInInProgress && !checkInCompleted
                      ? 'In Progress'
                      : checkInCompleted
                      ? 'Completed'
                      : 'Not started'}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" sx={{fontWeight: 'bold'}} flexDirection="row">
                    {t('reservations:code_start')}
                  </Typography>
                  <Typography variant="body2">{localizedCheckIn}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" sx={{fontWeight: 'bold'}} flexDirection="row">
                    {t('reservations:code_end')}
                  </Typography>
                  <Typography variant="body2">{localizedCheckOut}</Typography>
                </Grid>
                <Grid item width="100%">
                  <Divider light />
                </Grid>
                <Grid item>
                  <Typography variant="body2" sx={{fontWeight: 'bold'}}>
                    {t('common:building_code')}
                  </Typography>
                  <Typography variant="body2">
                      {(hotel_door_lock && hotel_door_lock.length !== 0) ? hotel_door_lock : '-'}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2" sx={{fontWeight: 'bold'}}>
                    {t('common:room_code')}
                  </Typography>
                  <Typography variant="body2">{firstReservation.code ?? '-'}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2" sx={{fontWeight: 'bold'}}>
                    {t('reservations:code_sent')}
                  </Typography>
                  <Typography variant="body2">
                    {firstReservation.email_sent || firstReservation.sms_sent
                      ? t('common:yes')
                      : t('common:no')}
                  </Typography>
                </Grid>

                <Grid
                  item
                  display={'flex'}
                  justifyContent={'flex-start'}
                  alignItems={'center'}
                  flexDirection={'column'}
                >
                  <Typography variant="body2" sx={{fontWeight: 'bold'}}>
                    ID Override
                  </Typography>
                  <Tooltip
                    title={
                      firstReservation.skip_identity
                        ? "Require guest's ID upload"
                        : "Skip guest's ID upload"
                    }
                  >
                    <IconButton
                      onClick={() =>
                        reservationsStore.setSkipIdentity(
                          firstReservation.id,
                          !firstReservation.skip_identity,
                        )
                      }
                      id="skipIdentity"
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: 0,
                        borderRadius: 1,
                      }}
                    >
                      {firstReservation.skip_identity ? (
                        <img src="/icons/skip_id.png" width={20} height={20} />
                      ) : (
                        <img src="/icons/id_required.png" width={20} height={20} />
                      )}

                      <Typography
                        variant="body2"
                        fontStyle={'normal'}
                        textTransform={'capitalize'}
                        fontSize={14}
                      >
                        {skipIdentityText}
                      </Typography>
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
              <Stack
                alignItems="center"
                marginTop={2}
                flexDirection="row"
                justifyContent={'space-evenly'}
                gap={1}
              >
                <ToggleAutoSendCodeButton
                  groupId={group_id}
                  onEnableDisable={handleEnableDisableAutoSendCode}
                  autoSendStatus={firstReservation.enabled}
                  checkInDate={firstReservation.check_in}
                  lock={firstReservation.lock}
                  reservationState={firstReservation.state}
                />
                {reservationDetailsButton}
              </Stack>
            </CardContent>
          </Card>
        </>
      )
    );
  },
);

export default ReservationCard;
