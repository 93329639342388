import {makeObservable, action, observable} from 'mobx';

import i18n from '../i18n';
import {StatusCodes} from 'http-status-codes';

import Api, {IUpdateSettingsParams} from '../api/settings';
import type RootStore from './rootStore';
import {handleUnauthorizedResponse} from '../libs/utils';
import {toast} from 'react-toastify';

interface ISettings {
  rootStore: RootStore;
  error: any;
  success: boolean | undefined;
  changed: boolean | undefined;
  inProgress: boolean;

  setChanged: (value: boolean | undefined) => void;
  setInProgress: (inProgress: boolean) => void;
  setError: (error: any) => void;
  setSuccess: (success: boolean | undefined) => void;
  setProfileImageURL: (profileImageURL: string | undefined) => void;
  createProfileImage: (field: string, file: any) => void;
  updateProfileImage: (field: string, file: any) => void;
  deleteProfileImage: () => void;
  getProfileImage: () => void;
  updateSettings: (params: IUpdateSettingsParams) => void;
}

class Settings implements ISettings {
  rootStore: RootStore;
  error = undefined;
  success: boolean | undefined = undefined;
  changed: boolean | undefined = undefined;
  inProgress = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      error: observable,
      success: observable,
      inProgress: observable,
      changed: observable,
      setChanged: action,
      setInProgress: action,
      setError: action,
      setSuccess: action,
      setProfileImageURL: action,
      createProfileImage: action,
      updateProfileImage: action,
      deleteProfileImage: action,
      getProfileImage: action,
      updateSettings: action,
    });
  }

  setInProgress(inProgress: boolean) {
    this.inProgress = inProgress;
  }

  setProfileImageURL(profileImageURL: string | undefined) {
    this.rootStore.accountStore.user.setProfileImage(profileImageURL);
  }

  setError(error: any) {
    this.error = error;
  }

  setSuccess(success: boolean | undefined) {
    this.success = success;
  }

  setChanged(value: boolean | undefined) {
    this.changed = value;
  }

  // API calls
  async createProfileImage(field: string, file: any) {
    const token = this.rootStore.accountStore.user.token;

    if (token) {
      try {
        this.setInProgress(true);

        const response = await Api.createProfileImage(field, file, token);

        if (response.ok) {
          const data = await response;

          this.setProfileImageURL(data.body.profile_image);
        }
      } catch (error: any) {
        if (error.status === StatusCodes.UNAUTHORIZED) {
          handleUnauthorizedResponse();
          return;
        }

        if (error.status === StatusCodes.BAD_REQUEST) {
          this.setError(i18n.t('Invalid image format'));
          return;
        }
        this.setError(error.message);
      } finally {
        this.setInProgress(false);
      }
    }
  }

  async updateProfileImage(field: string, file: any) {
    const token = this.rootStore.accountStore.user.token;

    if (token) {
      try {
        this.setInProgress(true);

        const response = await Api.updateProfileImage(field, file, token);

        if (response.ok) {
          const data = await response;

          this.setProfileImageURL(data.body.profile_image);
        }
      } catch (error: any) {
        if (error.status === StatusCodes.UNAUTHORIZED) {
          handleUnauthorizedResponse();
          return;
        }

        if (error.status === StatusCodes.BAD_REQUEST) {
          this.setError(i18n.t('Invalid image format'));
          return;
        }
        this.setError(error.message);
      } finally {
        this.setInProgress(false);
      }
    }
  }

  async deleteProfileImage() {
    const token = this.rootStore.accountStore.user.token;

    if (token) {
      try {
        this.setInProgress(true);

        const response = await Api.deleteProfileImage(token);

        if (response.ok) {
          this.setProfileImageURL(undefined);
        }
      } catch (error: any) {
        if (error.status === StatusCodes.UNAUTHORIZED) {
          handleUnauthorizedResponse();
          return;
        }
        this.setError(error.message);
      } finally {
        this.setInProgress(false);
      }
    }
  }

  async getProfileImage() {
    const token = this.rootStore.accountStore.user.token;

    if (token) {
      try {
        this.setInProgress(true);

        const response = await Api.getProfileImage(token);

        if (response.ok) {
          const data = await response;

          this.setProfileImageURL(data.body.profile_image);
        }
      } catch (error: any) {
        if (error.status === StatusCodes.UNAUTHORIZED) {
          handleUnauthorizedResponse();
          return;
        }
        this.setError(error.message);
      } finally {
        this.setInProgress(false);
      }
    }
  }

  async updateSettings(params: IUpdateSettingsParams) {
    const token = this.rootStore.accountStore.user.token;

    if (token) {
      try {
        this.setInProgress(true);

        const response = await Api.updateSettings(params, token);

        if (response.ok) {
          const data = await response;

          this.rootStore.accountStore.user.setUsername(data.body.username);
          this.rootStore.accountStore.user.setEmail(data.body.email);
          this.rootStore.accountStore.user.setFirstName(data.body.first_name);
          this.rootStore.accountStore.user.setLastName(data.body.last_name);
          this.setSuccess(true);

          toast.success(i18n.t('User settings were successfully updated'));
        }
      } catch (error: any) {
        // TODO improve this error handling
        if (error.status === StatusCodes.UNAUTHORIZED) {
          handleUnauthorizedResponse();
          return;
        }

        if (error.response.body?.password?.[0]) {
          this.setError(error.response.body?.password?.[0]);
        } else if (error.response.body?.new_password?.[0]) {
          this.setError(error.response.body?.new_password?.[0]);
        } else if (error.response.body?.non_field_errors?.[0]) {
          this.setError(error.response.body?.non_field_errors?.[0]);
        } else if (error.response.body?.username?.[0]) {
          this.setError(error.response.body?.username?.[0]);
        } else if (error.response.body?.email?.[0]) {
          this.setError(error.response.body?.email?.[0]);
        } else if (error.response.clientError) {
          this.setError(i18n.t('Invalid Request'));
        } else {
          this.setError(i18n.t('Server Error.'));
        }

        toast.error(this.error);
      } finally {
        this.setInProgress(false);
      }
    }
  }
}

export default Settings;
