import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {useRootStore} from '../../context';
import {Container, Grid, MenuItem, Select, Stack, TextField, Typography} from '@mui/material';
import DatePicker from '../DatePicker';
import {LoadingButton} from '@mui/lab';
import {useTranslation} from 'react-i18next';
import type {TGetLinenDeliveryCompaniesResponse} from '../../api/linen';

const OrderDetails = observer(() => {
  const {t} = useTranslation();
  const {accountStore, orderStore, linenStore, hotelStore} = useRootStore();
  const [deliveryCompanies, setDeliveryCompanies] = useState<TGetLinenDeliveryCompaniesResponse>(
    [],
  );
  const [details, setDetails] = useState({
    delivery_company: 0,
    order_date: orderStore.getFieldDate(new Date(), 0),
    order_from: orderStore.getFieldDate(new Date(), 2),
    delivery_date: orderStore.getFieldDate(new Date(), 2),
    days: 3,
  });

  useEffect(() => {
    if (linenStore.delivery_companies.length === 0) {
      linenStore.getDeliveryCompanies();
    } else {
      setDeliveryCompanies(linenStore.delivery_companies);
      if (orderStore.details.delivery_company === 0) {
        formik.setFieldValue('delivery_company', linenStore.delivery_companies[0].id, false);
      }
    }
  }, [linenStore.delivery_companies]);

  useEffect(() => {
    setDetails(orderStore.details);
    if (linenStore.delivery_companies.length > 0 && orderStore.details.delivery_company === 0) {
      formik.setFieldValue('delivery_company', linenStore.delivery_companies[0].id, false);
    } else {
      formik.setFieldValue('delivery_company', orderStore.details.delivery_company, false);
    }
    formik.setFieldValue('order_date', orderStore.details.order_date, false);
    formik.setFieldValue('order_from', orderStore.details.order_from, false);
    formik.setFieldValue('delivery_date', orderStore.details.delivery_date, false);
    formik.setFieldValue('days', orderStore.details.days, false);
  }, [orderStore.details]);

  useEffect(() => {
    if (accountStore.user.first_name !== undefined) {
      formik.setFieldValue('orderer', accountStore.user.first_name, false);
    } else if (accountStore.user.username !== undefined) {
      formik.setFieldValue('orderer', accountStore.user.username, false);
    }
  }, [accountStore.user.first_name, accountStore.user.username]);

  const OrderDetailsSchema = Yup.object().shape({
    orderer: Yup.string().trim().ensure().required(t('orderDetails:orderer_required')),
    order_date: Yup.date().required(t('orderDetails:order_date_required')),
    order_from: Yup.date().required(t('orderDetails:order_from_required')),
    delivery_date: Yup.date().required(t('orderDetails:delivery_date_required')),
    days: Yup.number()
      .required(t('orderDetails:supply_days_required'))
      .positive(t('orderDetails:supply_days_grater_0')),
  });

  const formik = useFormik({
    initialValues: {
      delivery_company: details.delivery_company,
      orderer: '',
      order_date: details.order_date,
      order_from: details.order_from,
      delivery_date: details.delivery_date,
      days: details.days,
    },
    validationSchema: OrderDetailsSchema,
    onSubmit: (values) => {
      const currentHotel = hotelStore.currentHotel?.id;

      if (currentHotel) {
        const params = {
          ...values,
          hotel: currentHotel,
        };
        orderStore.prepareOrder(params);
      }
    },
  });

  // Formats date object value from Datepicker and converts it to string
  const getFormattedDate = (date: Date | null) => {
    if (date) return date.toLocaleDateString('ja-JP').replace(/\//g, '-');
    return '';
  };

  return (
    <Container>
      <Typography variant="h5" component="h2" sx={{marginBottom: 4}}>
        {t('common:order_details')}
      </Typography>
      <form noValidate onSubmit={formik.handleSubmit}>
        <Stack gap={3}>
          {/* ------------------------- Vendor ----------------------------- */}
          <Grid container flexDirection="row" alignItems="center">
            <Grid item xs={12} sm={2}>
              <Label id="vendor-select-label">{t('common:vendor')}</Label>
            </Grid>
            <Grid item xs={12} sm={10}>
              <Select
                labelId="vendor-select-label"
                id="vendor-select"
                value={formik.values.delivery_company}
                aria-describedby={'delivery-company'}
                name={'delivery_company'}
                onChange={formik.handleChange}
                fullWidth
                MenuProps={{sx: {'& .MuiMenu-list': {backgroundColor: '#FFF'}}}}
                sx={{'& legend': {display: 'none'}}}
              >
                {deliveryCompanies.map((obj, i) => {
                  const {id, name} = obj;

                  return (
                    <MenuItem key={i} value={id}>
                      {name}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
          </Grid>
          {/* ------------------------- Vendor ----------------------------- */}

          {/* ------------------------- Orderer ---------------------------- */}
          <Grid container alignItems="center">
            <Grid item xs={12} sm={2}>
              <Label id="orderer-label">{t('orderDetails:orderer')}</Label>
            </Grid>
            <Grid item xs={12} sm={10}>
              <TextField
                name="orderer"
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.orderer}
                error={formik.touched.orderer && Boolean(formik.errors.orderer)}
                helperText={formik.touched.orderer && formik.errors.orderer}
                sx={{'& legend': {display: 'none'}}}
              />
            </Grid>
          </Grid>
          {/* ------------------------- Orderer ---------------------------- */}

          {/* ------------------------ Order Date -------------------------- */}
          <Grid container alignItems="center">
            <Grid item xs={12} sm={2}>
              <Label id="order_date">{t('common:order_date')}</Label>
            </Grid>
            <Grid item xs={12} sm={10}>
              <DatePicker
                label={t('common:order_date')}
                // Datepicker returns a Date object
                // That's why we need to convert it to string and manually set the field value
                onChange={(date) => {
                  formik.setFieldValue('order_date', getFormattedDate(date), true);
                }}
                value={formik.values.order_date}
                noLegend
                error={formik.touched.order_date && Boolean(formik.errors.order_date)}
                helperText={formik.errors.order_date ?? undefined}
              />
            </Grid>
          </Grid>
          {/* ------------------------ Order Date -------------------------- */}

          {/* ------------------------ Order From -------------------------- */}
          <Grid container alignItems="center">
            <Grid item xs={12} sm={2}>
              <Label id="order_from">{t('orderDetails:order_from')}</Label>
            </Grid>
            <Grid item xs={12} sm={10}>
              <DatePicker
                label={t('orderDetails:order_from')}
                // Datepicker returns a Date object
                // That's why we need to convert it to string and manually set the field value
                onChange={(date) => {
                  formik.setFieldValue('order_from', getFormattedDate(date), true);
                }}
                value={formik.values.order_from}
                noLegend
                error={formik.touched.order_from && Boolean(formik.errors.order_from)}
                helperText={formik.errors.order_from ?? undefined}
              />
            </Grid>
          </Grid>
          {/* ------------------------ Order From -------------------------- */}

          {/* ----------------------- Delivery Date ------------------------ */}
          <Grid container alignItems="center">
            <Grid item xs={12} sm={2}>
              <Label id="delivery_date">{t('common:delivery_date')}</Label>
            </Grid>
            <Grid item xs={12} sm={10}>
              <DatePicker
                label={t('common:delivery_date')}
                // Datepicker returns a Date object
                // That's why we need to convert it to string and manually set the field value
                onChange={(date) => {
                  formik.setFieldValue('delivery_date', getFormattedDate(date), true);
                }}
                value={formik.values.delivery_date}
                noLegend
                error={formik.touched.delivery_date && Boolean(formik.errors.delivery_date)}
                helperText={formik.errors.delivery_date ?? undefined}
              />
            </Grid>
          </Grid>
          {/* ----------------------- Delivery Date ------------------------ */}

          {/* -------------------------- Supply ---------------------------- */}
          <Grid container alignItems="center">
            <Grid item xs={12} sm={2}>
              <Label id="supply">{t('orderDetails:supply')}</Label>
            </Grid>
            <Grid item xs={12} sm={10}>
              <TextField
                name="days"
                type="number"
                onChange={formik.handleChange}
                value={formik.values.days}
                fullWidth
                error={formik.touched.days && Boolean(formik.errors.days)}
                helperText={formik.touched.days && formik.errors.days}
                sx={{'& legend': {display: 'none'}}}
                inputProps={{min: 0}}
              />
            </Grid>
          </Grid>
          {/* -------------------------- Supply ---------------------------- */}

          {/* -------------------------- Submit ---------------------------- */}
          <LoadingButton
            type="submit"
            loading={orderStore.isPreparing}
            variant="contained"
            sx={{width: '140px', alignSelf: 'end'}}
            color="gray"
          >
            {t('common:next')}
          </LoadingButton>
          {/* -------------------------- Submit ---------------------------- */}
        </Stack>
      </form>
    </Container>
  );
});

export default OrderDetails;

interface ILabelProps {
  id: string;
  children: React.ReactNode;
}

const Label: React.FC<ILabelProps> = ({id, children}) => {
  return (
    <Typography variant="body1" component="label" id={id} sx={{marginBottom: {xs: 1, sm: 0}}}>
      {children}
    </Typography>
  );
};
