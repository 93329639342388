/* eslint-disable @typescript-eslint/ban-types */
import {createContext} from 'react';
import React from 'react';
import RootStore from './stores/rootStore';

const RootStateContext = createContext<RootStore>({} as RootStore);

export const RootStateProvider: React.FC<React.PropsWithChildren<{}>> = ({children}) => {
  return <RootStateContext.Provider value={new RootStore()}>{children}</RootStateContext.Provider>;
};

export const useRootStore = () => React.useContext(RootStateContext);
