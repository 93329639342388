import {LoadingButton} from '@mui/lab';
import {
  Accordion,
  AccordionSummary,
  Stack,
  Typography,
  Grid,
  AccordionDetails,
  Tooltip,
  IconButton,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  capitalize,
} from '@mui/material';
import {t} from 'i18next';
import React, {useState} from 'react';
import {useRootStore} from '../../context';
import type {IGuestProducts} from './ReservationProducts';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import TopicOutlinedIcon from '@mui/icons-material/TopicOutlined';
import PhoneIcon from '@mui/icons-material/Phone';
import TranslateIcon from '@mui/icons-material/Translate';
import BusinessIcon from '@mui/icons-material/Business';
import DoorBackIcon from '@mui/icons-material/DoorBack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import type {IProductData, IProductElement} from '../../api/reservations';
import {observer} from 'mobx-react-lite';

interface IReservationProductAccordionProps {
  reservation: IProductElement;
}

const ReservationProductAccordion: React.FC<IReservationProductAccordionProps> = ({
  reservation,
}) => {
  const {reservationsStore, hotelStore} = useRootStore();
  const [guestsProducts, setGuestsProducts] = useState<Array<IGuestProducts>>([]);
  const [guestsWithProducts, setGuestsWithProducts] = useState<Array<string>>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const capitalizedFirstName = capitalize(reservation.guest.first_name);
  const capitalizedLastName = capitalize(reservation.guest.last_name);

  const shortCheckIn = reservation.check_in.split(' ')[0];
  const shortCheckOut = reservation.check_out.split(' ')[0];

  const submitProductsHandler = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    roomId: string,
  ) => {
    event.preventDefault();
    setIsSubmitting(true);

    // Find guest
    const guestIndex = guestsProducts.findIndex((guest) => guest.roomId === roomId);

    if (guestIndex === -1) {
      return;
    }

    const guest = guestsProducts[guestIndex];

    try {
      await reservationsStore.submitProducts(guest.approved, guest.rejected);
    } catch (error) {
      console.log('Error submitting products', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const getGuestsWithProducts = () => {
    const guestsWithProducts: Array<string> = [];

    guestsProducts.forEach((guest) => {
      if (guest.approved.length > 0 || guest.rejected.length > 0) {
        guestsWithProducts.push(guest.roomId);
      }
    });

    setGuestsWithProducts(guestsWithProducts);
  };

  const handleRadioButtonChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    roomId: string,
    product: IProductData,
  ) => {
    const action = event.target.value;

    // Find guest
    const guestIndex = guestsProducts.findIndex((guest) => guest.roomId === roomId);

    if (action === 'approve') {
      if (guestIndex > -1) {
        // Filter from rejected list
        const newRejectedProducts = guestsProducts[guestIndex].rejected.filter(
          (rejected) => rejected.id !== product.id,
        );

        //Add product to approved list
        const newApprovedProducts = [...guestsProducts[guestIndex].approved];
        newApprovedProducts.push({id: product.id, status: true});

        setGuestsProducts((prevGuestProducts) => {
          // Set new approved list
          prevGuestProducts[guestIndex].approved = newApprovedProducts;

          // Set new rejected list
          prevGuestProducts[guestIndex].rejected = newRejectedProducts;

          return prevGuestProducts;
        });
      } else {
        setGuestsProducts((prevGuestProducts) => {
          // Set new guest
          prevGuestProducts.push({
            roomId,
            approved: [{id: product.id, status: true}],
            rejected: [],
          });

          return prevGuestProducts;
        });
      }
    }

    if (action === 'reject') {
      if (guestIndex > -1) {
        // Filter from approved list
        const newApprovedProducts = guestsProducts[guestIndex].approved.filter(
          (approved) => approved.id !== product.id,
        );

        //Add product to rejected list
        const newRejectedProducts = [...guestsProducts[guestIndex].rejected];
        newRejectedProducts.push({id: product.id, status: false});

        setGuestsProducts((prevGuestProducts) => {
          // Set new approved list
          prevGuestProducts[guestIndex].approved = newApprovedProducts;

          // Set new rejected list
          prevGuestProducts[guestIndex].rejected = newRejectedProducts;

          return prevGuestProducts;
        });
      } else {
        setGuestsProducts((prevGuestProducts) => {
          // Set new guest
          prevGuestProducts.push({
            roomId,
            approved: [],
            rejected: [{id: product.id, status: false}],
          });

          return prevGuestProducts;
        });
      }
    }

    if (action === 'no action') {
      if (guestIndex > -1) {
        // Filter from approved list
        const newApprovedProducts = guestsProducts[guestIndex].approved.filter(
          (approved) => approved.id !== product.id,
        );

        // Filter from rejected list
        const newRejectedProducts = guestsProducts[guestIndex].rejected.filter(
          (rejected) => rejected.id !== product.id,
        );

        setGuestsProducts((prevGuestProducts) => {
          // Set new approved list
          prevGuestProducts[guestIndex].approved = newApprovedProducts;

          // Set new rejected list
          prevGuestProducts[guestIndex].rejected = newRejectedProducts;

          return prevGuestProducts;
        });
      }
    }

    getGuestsWithProducts();
  };

  return (
    <Accordion key={reservation.guest.id}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{backgroundColor: '#f7f7f4'}}>
        <Stack>
          <Stack flexDirection={'row'} alignItems="center">
            <Typography
              variant="h5"
              margin={2}
            >{`${capitalizedFirstName} ${capitalizedLastName}`}</Typography>
          </Stack>
          <Grid container gap={{xs: 5}} margin={0} padding={1} justifyContent="flex-start">
            <Grid item display={'flex'} flexDirection="column" justifyContent={'center'}>
              <Stack display="flex" flexDirection="row" alignItems={'center'} gap={0.5}>
                <TopicOutlinedIcon fontSize="small" />

                <Typography variant="button">{reservation.reservation}</Typography>
              </Stack>
            </Grid>
            <Grid item display={'flex'} flexDirection="column" justifyContent={'center'}>
              <Stack display="flex" flexDirection="row" alignItems={'center'} gap={0.5}>
                <AlternateEmailIcon fontSize="small" />

                <Typography variant="button">{reservation.guest.email ?? '-'}</Typography>
              </Stack>
            </Grid>
            <Grid item display={'flex'} flexDirection="column" justifyContent={'center'}>
              <Stack display="flex" flexDirection="row" alignItems={'center'} gap={0.5}>
                <PhoneIcon fontSize="small" />
                <Typography variant="body2">{reservation.guest.phone_number ?? '-'}</Typography>
              </Stack>
            </Grid>
            <Grid item display={'flex'} flexDirection="column" justifyContent={'center'}>
              <Stack display="flex" flexDirection="row" alignItems={'center'} gap={0.5}>
                <TranslateIcon fontSize="small" />

                <Typography textAlign="center" variant="button">
                  {reservation.guest.language.toUpperCase()}
                </Typography>
              </Stack>
            </Grid>
            <Grid item display={'flex'} flexDirection="column" justifyContent={'center'}>
              <Stack display={'flex'} flexDirection="row" alignItems={'center'} gap={0.5}>
                <BusinessIcon fontSize="small" />
                <Typography variant="button">{hotelStore.currentHotel?.short_name}</Typography>
              </Stack>
            </Grid>
            <Grid item display={'flex'} flexDirection="column" justifyContent={'center'}>
              <Stack display={'flex'} flexDirection="row" alignItems={'center'} gap={0.5}>
                <Typography variant="button">
                  <Typography fontWeight={'bold'} textTransform={'capitalize'}>
                    Check in:
                  </Typography>
                  {shortCheckIn}
                </Typography>
              </Stack>
            </Grid>
            <Grid item display={'flex'} flexDirection="column" justifyContent={'center'}>
              <Stack display={'flex'} flexDirection="row" alignItems={'center'} gap={0.5}>
                <Typography variant="button">
                  <Typography fontWeight={'bold'} textTransform={'capitalize'}>
                    Check out:
                  </Typography>
                  {shortCheckOut}
                </Typography>
              </Stack>
            </Grid>
            <Grid item display={'flex'} flexDirection="column" justifyContent={'center'}>
              <Stack display={'flex'} flexDirection="row" alignItems={'center'} gap={0.5}>
                <DoorBackIcon fontSize="small" />
                <Typography variant="button">{reservation.room_number ?? '-'}</Typography>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h6" component="h6">
          {t('common:reservation_products')}
        </Typography>

        {reservation.products.map((product) => {
          const shortRequestDate = product.requested.split(' ')[0];

          return (
            <Grid
              key={product.id}
              container
              gap={{xs: 5}}
              margin={0}
              padding={1}
              justifyContent="space-between"
              alignItems={'flex-start'}
              borderBottom={'1px solid #eff0f2'}
            >
              <Grid
                item
                display={'flex'}
                flexDirection="row"
                justifyContent={'center'}
                xs={12}
                sm={6}
                md={'auto'}
              >
                <Stack display="flex" flexDirection="column" alignItems={'center'} gap={0.5}>
                  <Typography variant="body1" fontWeight={'bold'}>
                    {t('common:name')}
                  </Typography>
                  <Tooltip title={product.product}>
                    <Typography variant="body1" noWrap width={200} textAlign={'center'}>
                      {product.product}
                    </Typography>
                  </Tooltip>
                </Stack>
              </Grid>
              <Grid
                item
                display={'flex'}
                flexDirection="column"
                justifyContent={'center'}
                xs={12}
                sm={'auto'}
              >
                <Stack display="flex" flexDirection="column" alignItems={'center'} gap={0.5}>
                  <Typography variant="body1" fontWeight={'bold'}>
                    {'Request date'}
                  </Typography>
                  <Typography variant="body1">{shortRequestDate}</Typography>
                </Stack>
              </Grid>
              <Grid
                item
                display={'flex'}
                flexDirection="column"
                justifyContent={'center'}
                xs={12}
                sm={'auto'}
              >
                <Stack display="flex" flexDirection="column" alignItems={'center'} gap={0.5}>
                  <Typography variant="body1" fontWeight={'bold'}>
                    {t('common:price')}
                  </Typography>
                  <Typography variant="body1">{product.price}</Typography>
                </Stack>
              </Grid>
              {product.error && (
                <Grid
                  item
                  display={'flex'}
                  flexDirection="column"
                  justifyContent={'center'}
                  xs={12}
                  md={'auto'}
                >
                  <Stack display="flex" flexDirection="column" alignItems={'center'} gap={0.5}>
                    <Typography variant="body1" fontWeight={'bold'} display={'flex'} gap={0.5}>
                      {t('common:error')}
                      {product.error.length > 0 && (
                        <Tooltip title={t('reservations:cannot_be_approved_error')} color="error">
                          <IconButton color="error" sx={{margin: 0, padding: 0}}>
                            <ErrorOutlineIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Typography>
                    <Typography variant="body1" color={'error'}>
                      {product.error}
                    </Typography>
                  </Stack>
                </Grid>
              )}
              {!product.error && (
                <Grid
                  item
                  display={'flex'}
                  flexDirection="column"
                  justifyContent={'center'}
                  xs={12}
                  md={'auto'}
                >
                  <FormControl disabled={product.error.length > 0}>
                    <FormLabel id="action-selection-label" sx={{textAlign: 'center'}}>
                      <Typography variant="body1" fontWeight={'bold'} color={'black'}>
                        {t('common:select_action')}
                      </Typography>
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="action-selection-label"
                      name="row-radio-buttons-group"
                      onChange={(event) =>
                        handleRadioButtonChange(event, reservation.room_number!, product)
                      }
                      defaultValue="no action"
                      sx={{display: 'flex', justifyContent: 'center', width: '100%'}}
                    >
                      <FormControlLabel
                        value={'approve'}
                        control={<Radio />}
                        label={t('common:approve')}
                      />
                      <FormControlLabel
                        value={'reject'}
                        control={<Radio />}
                        label={t('common:reject')}
                      />
                      <FormControlLabel
                        value={'no action'}
                        control={<Radio />}
                        label={t('common:no_action')}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              )}
            </Grid>
          );
        })}

        <LoadingButton
          loading={isSubmitting && guestsWithProducts.includes(reservation.room_number!)}
          disabled={!guestsWithProducts.includes(reservation.room_number!)}
          variant="contained"
          size="large"
          color="primary"
          sx={{fontSize: '1.1rem', alignSelf: 'center', marginY: 1}}
          onClick={(event) => submitProductsHandler(event, reservation.room_number!)}
        >
          {t('common:submit')}
        </LoadingButton>
      </AccordionDetails>
    </Accordion>
  );
};

export default observer(ReservationProductAccordion);
