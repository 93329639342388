import React from 'react';
import {Button, Stack, Typography, Box} from '@mui/material';
import Modal from '@mui/material/Modal';
import {observer} from 'mobx-react-lite';
import TextField from '@mui/material/TextField';
import '../../styles/main.css';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import type {IDoorCodes, TCodes} from '../../api/hotel';
import {useFormik} from 'formik';
import {useRootStore} from '../../context';

interface IUpdateCodesModalFormValues {
  code1: string;
  code2: string;
  code3: string;
}

interface IUpdateCodesModalProps {
  isOpen: boolean;
  onClose: () => void;
  clearSelectedLock: () => void;
  lock: IDoorCodes;
}

const UpdateCodesModal: React.FC<IUpdateCodesModalProps> = observer(
  ({isOpen, onClose, clearSelectedLock, lock}) => {
    const {t} = useTranslation();

    const {hotelStore} = useRootStore();

    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      bgcolor: 'background.default',
      border: '1px solid #000',
      boxShadow: 24,
      width: '500px',
      p: 4,
    };

    const closeHandler = () => {
      resetForm();
      clearSelectedLock();
      onClose();
    };

    const validationSchema = Yup.object().shape({
      code1: Yup.string()
        .required('Please enter a code')
        .min(4, 'Please enter a 4 characters code')
        .max(4, 'Code must be of 4 characters'),
      code2: Yup.string()
        .required('Please enter a code')
        .min(4, 'Please enter a 4 characters code')
        .max(4, 'Code must be of 4 characters'),
      code3: Yup.string()
        .required('Please enter a code')
        .min(4, 'Please enter a 4 characters code')
        .max(4, 'Code must be of 4 characters'),
    });

    const updateCodesHandler = async (values: IUpdateCodesModalFormValues) => {
      const {code1, code2, code3} = values;

      if (code1 === lock.code_1 && code2 === lock.code_2 && code3 === lock.code_3) {
        closeHandler();
        return;
      }

      const codes: TCodes = {
        code_1: code1,
        code_2: code2,
        code_3: code3,
      };

      const response = await hotelStore.updateDoorLocks(lock.id, codes);

      if (response && response.ok) {
        closeHandler();
      }
    };

    const {values, errors, handleChange, handleSubmit, resetForm, isValid} = useFormik({
      enableReinitialize: true,
      initialValues: {
        code1: lock.code_1,
        code2: lock.code_2,
        code3: lock.code_3,
      },
      validationSchema,
      validateOnBlur: true,
      validateOnChange: true,
      validateOnMount: false,
      onSubmit: updateCodesHandler,
    });

    return (
      <>
        <Modal
          open={isOpen}
          onClose={closeHandler}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={style}
            width={{xs: '70%', sm: 'auto'}}
            justifyContent="center"
            alignItems={'center'}
            display="flex"
            flexDirection={'column'}
          >
            <Stack style={{width: '100%'}}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {`Room ${lock.room_number} codes`}
              </Typography>

              <TextField
                name="code1"
                label="Code 1"
                variant="outlined"
                margin="normal"
                value={values.code1}
                onChange={handleChange}
                multiline={false}
                inputProps={{maxLength: 4}}
                fullWidth={true}
                error={!!errors.code1}
              />
              <Typography>{errors.code1}</Typography>

              <TextField
                name="code2"
                label="Code 2"
                variant="outlined"
                margin="normal"
                value={values.code2}
                onChange={handleChange}
                multiline={false}
                inputProps={{maxLength: 4}}
                fullWidth={true}
                error={!!errors.code2}
              />

              <Typography>{errors.code2}</Typography>

              <TextField
                name="code3"
                label="Code 3"
                variant="outlined"
                margin="normal"
                value={values.code3}
                onChange={handleChange}
                multiline={false}
                inputProps={{maxLength: 4}}
                fullWidth={true}
                error={!!errors.code3}
              />

              <Typography>{errors.code3}</Typography>
            </Stack>

            <Stack flexDirection="row" gap={1} justifyContent="flex-end" marginTop={3}>
              <Button
                variant="contained"
                onClick={() => handleSubmit()}
                color="primary"
                disabled={!isValid}
              >
                {'Update codes'}
              </Button>
              {/*   <Button variant="contained" color="gray" onClick={() => resetForm()}>
                {'Reset'}
              </Button> */}
              <Button variant="contained" color="error" onClick={closeHandler}>
                {t('common:cancel')}
              </Button>
            </Stack>
          </Box>
        </Modal>
      </>
    );
  },
);

export default UpdateCodesModal;
