import {LoadingButton} from '@mui/lab';
import {Button, Stack, Typography} from '@mui/material';
import React from 'react';
import {useRootStore} from '../../context';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react-lite';

const TotalCreateOrder = observer(() => {
  const {t} = useTranslation();
  const {orderStore} = useRootStore();

  const handleBack = (e: React.SyntheticEvent) => {
    e.preventDefault();
    orderStore.setCurrentStep(0, true);
  };

  const handleCreateOrder = (e: React.SyntheticEvent) => {
    e.preventDefault();
    orderStore.createOrder();
  };

  return (
    <>
      {/* ---------------------------- Total ------------------------------- */}
      <Stack flexDirection="row" justifyContent="end" alignItems="center" marginX={2}>
        <Typography variant="h5" marginRight={3}>
          {t('common:total')}
        </Typography>
        <Typography variant="h4" fontWeight="700" sx={{color: 'success.main'}}>
          {orderStore.total}
        </Typography>
      </Stack>
      {/* ---------------------------- Total ------------------------------- */}
      {/* --------------------------- Buttons ------------------------------ */}
      <Stack flexDirection="row" justifyContent="end" gap={2}>
        <Button variant="text" color="gray" onClick={handleBack}>
          {t('common:back')}
        </Button>
        <LoadingButton
          loading={orderStore.isCreating}
          variant="contained"
          color="primary"
          onClick={handleCreateOrder}
        >
          {t('common:create_order')}
        </LoadingButton>
      </Stack>
      {/* --------------------------- Buttons ------------------------------ */}
    </>
  );
});

export default TotalCreateOrder;
