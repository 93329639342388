const Common = {
  MEDIA_TYPE: 'application/json',
};

export interface IAPIErrorResponse {
  [key: string]: string[];
}

export interface ISuperAgentError extends Error {
  status?: number;
  response?: {
    body?: IAPIErrorResponse;
    text?: string;
    headers?: Record<string, string>;
    ok?: boolean;
    status?: number;
    statusText?: string;
  };
}

export interface IVendor {
  id: number;
  name: string;
  type: string;
  info: string;
}

export interface IBrand {
  id: number;
  name: string;
}

export interface IUnit {
  id: number;
  name: string | 'ml' | 'ea';
}

export interface ICategory {
  id: number;
  name: string | 'Amenity' | 'Promotion' | 'Supplies'; // amenity promotion supplies
}

export interface IStorageIDName {
  id: number;
  name: string;
}

export interface IStorageResponseData {
  id: number;
  image: string;
  storage: IStorageIDName;
  level: string | 'lv1' | 'lv2' | 'lv3';
  quantity: number;
  amenity: number;
}

export interface IPurchaseResponse {
  id: number;
  vendor: IVendor;
  price_currency: string;
  // TODO check if string or sometimes number
  price: string;
  unit_price_currency: string;
  // TODO check if string or sometimes number
  unit_price?: string;
  reference: string;
  delivery: number;
  info: string;
  amenity: number;
}

export interface IAmenityResponse {
  id: number;
  brand: IBrand;
  unit: IUnit;
  category: ICategory;
  sku: string;
  name: string;
  consumption_tax: number;
  hotel: Array<number>;
}

export default Common;
