import React from 'react';
import {Button, Typography} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import {theme} from '../../config/theme.config';

interface ISendCodeButtonProps {
  sendCodeHandler: () => void;
  codeExists: boolean;
  lockExists: boolean;
  hasValidPhone: boolean;
  hasRegisteredEmail: boolean;
  title: string;
  type: 'sms' | 'email';
}

const SendCodeButton: React.FC<ISendCodeButtonProps> = ({
  sendCodeHandler,
  codeExists,
  lockExists,
  hasValidPhone,
  hasRegisteredEmail,
  title,
  type,
}) => {
  function getButtonDisableState() {
    if (!codeExists || !lockExists) return true;

    if (type === 'email') {
      if (!hasRegisteredEmail) return true;
    }

    if (type === 'sms') {
      if (!hasValidPhone) return true;
    }

    return false;
  }

  const isDisabled = getButtonDisableState();

  return (
    <Button
      variant="contained"
      endIcon={<SendIcon />}
      aria-label={'Send Code'}
      sx={{backgroundColor: theme.palette.gray.dark}}
      onClick={sendCodeHandler}
      disabled={isDisabled}
    >
      <Typography variant={'caption'}>{title}</Typography>
    </Button>
  );
};

export default SendCodeButton;
