import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ListItemIcon,
  Typography,
} from '@mui/material';
import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NavLink from './NavLink';
import {useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

interface NavAccordionProps {
  icon: React.ReactNode;
  links: Array<{
    text: string;
    link: string;
  }>;
  name: string;
  isOpen: boolean;
}

const NavAccordion: React.FC<NavAccordionProps> = ({icon, links, name, isOpen}) => {
  const {t} = useTranslation();
  const location = useLocation();
  const pathname = location.pathname;
  const isInventoryPage = pathname.includes(`/${name.toLowerCase()}`);

  const leftBorderStyle = {
    position: 'relative',
    '&:before': {
      content: '""',
      width: '2px',
      position: 'absolute',
      top: isInventoryPage ? 0 : '50%',
      left: 0,
      height: isInventoryPage ? '100%' : '0%',
      transition: 'all 0.35s ease-in-out',
      backgroundColor: '#FFF',
    },
  };

  const styles = {
    ...leftBorderStyle,
    backgroundColor: '#363740',
    boxShadow: 'none',
    width: '279px',

    '.MuiSvgIcon-root': {
      color: isInventoryPage ? '#FFF' : '#FFFFFF40',
    },
  };

  return (
    <Accordion sx={styles} disabled={!isOpen}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{...leftBorderStyle, paddingLeft: 9}}
      >
        <ListItemIcon sx={{position: 'absolute', left: '1rem'}}>{icon}</ListItemIcon>

        <Typography sx={{color: isInventoryPage ? '#FFF' : '#FFFFFF40'}}>{name}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{paddingLeft: 7, backgroundColor: 'inherit'}}>
        {links.map((link) => {
          const {text} = link;

          return <NavLink key={text} link={link} />;
        })}
      </AccordionDetails>
    </Accordion>
  );
};

export default NavAccordion;
