import request from 'superagent';
import Config from '../config';
import Common from './common';

export interface IReservationGuestData {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  language: string;
  is_valid_phone_number: boolean;
}

export interface IProductData {
  id: number;
  product: string;
  price: string;
  error: string;
  requested: string;
}

export interface IProductElement {
  reservation: string;
  guest: {
    id: number;
    username: string;
    email: string;
    first_name: string;
    last_name: string;
    phone_number: string | null;
    nationality: string;
    language: string;
  };
  products: Array<IProductData>;
  room_number: string | null;
  check_in: string;
  check_out: string;
}

export type TGetProductsResponse = Array<IProductElement>;

export interface IReservationData {
  id: string;
  check_in: string; // date with 00:00:00
  check_out: string; // date with 00:00:00
  code: string | null;
  email_sent: boolean;
  sms_sent: boolean;
  room_number: string;
  guest_count: number;
  lock: string | null;
  ota_name: string;
  products: Array<IProductData>;
  state: 'Started' | 'Processed' | 'Confirmed' | 'Canceled';
  room_state: 'Dirty' | 'Clean' | 'Inspected' | 'OutOfService' | 'OutOfOrder';
  enabled: boolean;
  skip_identity: boolean;
  check_in_completed: boolean;
  guests: Array<any>;
}

export interface IReservationResult {
  guest: IReservationGuestData;
  group: string;
  group_id: string; // send this to retrieve single reservation
  hotel_id: number;
  hotel_door_lock: string;
  reservations: Array<IReservationData>;
}

export interface IGetReservationsResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: Array<IReservationResult>;
}

export type TGetReservationResponse = IReservationResult;

export const reservationStatusColor = {
  Started: 'success.dark',
  Processed: 'gray',
  Confirmed: 'primary',
  Canceled: 'warning',
};

export type TSendCodeResponse = Array<{
  code: string;
  email_sent: boolean;
  room_number: string;
  sms_sent: boolean;
}>;

export default {
  getReservations: function (
    token: string,
    currentHotel: number,
    checkInDate: string,
    nextPreviousUrl: string | null,
  ) {
    const url = nextPreviousUrl || `${Config.API_URL}/reservations/${currentHotel}/${checkInDate}/`;

    if (nextPreviousUrl && nextPreviousUrl.length > 0) {
      return request.get(url).auth(token, {type: 'bearer'}).accept(Common.MEDIA_TYPE).send();
    }
    return request.get(url).auth(token, {type: 'bearer'}).accept(Common.MEDIA_TYPE).send();
  },
  getReservation: function (token: string, groupId: string, date: string) {
    const url = `${Config.API_URL}/reservation/${groupId}/${date}/`;

    return request.get(url).auth(token, {type: 'bearer'}).accept(Common.MEDIA_TYPE).send();
  },
  sendAccessCode: function (token: string, groupId: string, is_sms = false) {
    const url = `${Config.API_URL}/reservation/send/code/${groupId}/`;

    return request
      .post(url)
      .auth(token, {type: 'bearer'})
      .accept(Common.MEDIA_TYPE)
      .send({is_sms});
  },
  enableDisableAccessCode: function (
    token: string,
    groupId: string,
    checkInDate: string,
    status: boolean,
    lock: string,
  ) {
    const url = `${Config.API_URL}/reservation/enable/code/${groupId}/${checkInDate}/${lock}/`;
    const payload = JSON.stringify({enabled: status});

    return request
      .put(url)
      .type(Common.MEDIA_TYPE)
      .auth(token, {type: 'bearer'})
      .accept(Common.MEDIA_TYPE)
      .send(payload);
  },
  exportReservations: function (token: string, currentHotel: number, checkInDate: string) {
    const url = `${Config.API_URL}/reservations/export/${currentHotel}/${checkInDate}/`;
    return request.get(url).auth(token, {type: 'bearer'}).responseType('blob');
  },
  getProducts: function (token: string, currentHotel: number) {
    const url = `${Config.API_URL}/reservations/products/${currentHotel}/`;

    return request.get(url).auth(token, {type: 'bearer'}).accept(Common.MEDIA_TYPE).send();
  },
  approveProducts: function (token: string, currentHotel: number, products: Array<number>) {
    const url = `${Config.API_URL}/reservation/products/approval/${currentHotel}/`;

    const payload = {products};

    return request
      .put(url)
      .type(Common.MEDIA_TYPE)
      .accept(Common.MEDIA_TYPE)
      .auth(token, {type: 'bearer'})
      .send(payload);
  },
  rejectProducts: function (token: string, currentHotel: number, products: Array<number>) {
    const url = `${Config.API_URL}/reservation/products/approval/${currentHotel}/`;

    const payload = {products};

    return request
      .delete(url)
      .type(Common.MEDIA_TYPE)
      .accept(Common.MEDIA_TYPE)
      .auth(token, {type: 'bearer'})
      .send(payload);
  },
  setSkipIdentity: function (token: string, reservationId: string, skip: boolean) {
    const url = `${Config.API_URL}/reservation/skip_identity/${reservationId}/`;

    const payload = {skip_identity: skip};

    return request
      .put(url)
      .type(Common.MEDIA_TYPE)
      .accept(Common.MEDIA_TYPE)
      .auth(token, {type: 'bearer'})
      .send(payload);
  },
  updateGuest: function (
    token: string,
    guestId: number,
    email: string | null,
    phoneNumber: string | null,
  ) {
    const url = `${Config.API_URL}/guest/${guestId}/`;

    const payload = {email, phone_number: phoneNumber};

    return request
      .patch(url)
      .type(Common.MEDIA_TYPE)
      .accept(Common.MEDIA_TYPE)
      .auth(token, {type: 'bearer'})
      .send(payload);
  },
  getPaymentQRCode: function (token: string, reservationId: string) {
    const url = `${Config.KABIN_APP_API_URL}/reservation/qr/${reservationId}/`;

    return request
      .get(url)
      .type(Common.MEDIA_TYPE)
      .accept(Common.MEDIA_TYPE)
      .query({source: 'materia'})
      .auth(token, {type: 'bearer'})
      .responseType('arraybuffer')
      .send();
  },
};
