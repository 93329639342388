import React, {useEffect, useState} from 'react';
import {toJS} from 'mobx';
import {observer} from 'mobx-react-lite';
import {useRootStore} from '../../context';
import {Divider, Grid, Stack, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import type {IVendorResponse} from '../../api/inventory/vendor';
import type {IBrand, IUnit} from '../../api/common';

const ItemSummary = observer(() => {
  const {inventoryStore, productStore, vendorStore} = useRootStore();
  const {t} = useTranslation();
  const [brand, setBrand] = useState<IBrand>({id: 0, name: ''});
  const [purchaseVendor, setPurchaseVendor] = useState<IVendorResponse>({
    name: '',
    id: 0,
    info: '',
    type: '',
  });
  const [unit, setUnit] = useState<IUnit>({id: 0, name: ''});

  useEffect(() => {
    if (inventoryStore.brands.length === 0) {
      inventoryStore.getBrands();
    } else {
      const itemBrand = inventoryStore.brands.find((obj) => {
        return obj.id === Number(productStore.amenity.brand);
      });

      if (itemBrand) setBrand(toJS(itemBrand));
    }
  }, [inventoryStore.brands]);

  useEffect(() => {
    if (vendorStore.vendors.length === 0) {
      vendorStore.getVendors();
    } else {
      const itemVendor = vendorStore.vendors.find((obj) => {
        return obj.id === Number(productStore.purchase.vendor);
      });

      if (itemVendor) setPurchaseVendor(itemVendor);
    }
  }, [vendorStore.vendors]);

  useEffect(() => {
    if (inventoryStore.units.length === 0) {
      inventoryStore.getUnits();
    } else {
      const itemUnit = inventoryStore.units.find((obj) => {
        return obj.id === parseInt(productStore.amenity.unit);
      });

      if (itemUnit) setUnit(itemUnit);
    }
  }, [inventoryStore.units]);

  const getPrice = (number: number | bigint) => {
    return new Intl.NumberFormat('ja-JP', {
      style: 'currency',
      currency: 'JPY',
    }).format(number);
  };

  const getUnitPrice = (quantity: number, price: number) => {
    const u = price / quantity;
    return new Intl.NumberFormat('ja-JP', {
      style: 'currency',
      currency: 'JPY',
      maximumSignificantDigits: 2,
    }).format(u);
  };

  return (
    <>
      <Grid container columnSpacing={5} rowSpacing={1.5} width={{md: '75%'}} marginBottom={4}>
        {/* ---------------------------- SKU ------------------------------- */}
        <Grid item xs={12} sm={6}>
          <Stack flexDirection="row" justifyContent="space-between" gap={3}>
            <Header>{t('common:sku')}</Header>
            <Item>{productStore.amenity.sku}</Item>
          </Stack>
        </Grid>
        {/* ---------------------------- SKU ------------------------------- */}
        {/* --------------------------- Brand ------------------------------ */}
        <Grid item xs={12} sm={6}>
          <Stack flexDirection="row" justifyContent="space-between" gap={3}>
            <Header>{t('common:brand')}</Header>
            <Item>{brand.name}</Item>
          </Stack>
        </Grid>
        {/* --------------------------- Brand ------------------------------ */}
        {/* ------------------------ Internal Name ------------------------- */}
        <Grid item xs={12} sm={6}>
          <Stack flexDirection="row" justifyContent="space-between" gap={3}>
            <Header>{t('common:internal_name')}</Header>
            <Item>{productStore.amenity.name}</Item>
          </Stack>
        </Grid>
        {/* ------------------------ Internal Name ------------------------- */}
        {/* ---------------------------- Vendor ---------------------------- */}
        <Grid item xs={12} sm={6}>
          <Stack flexDirection="row" justifyContent="space-between" gap={3}>
            <Header>{t('common:vendor')}</Header>
            <Item>{purchaseVendor.name}</Item>
          </Stack>
        </Grid>
        {/* ---------------------------- Vendor ---------------------------- */}
        {/* ---------------------------- Amount ---------------------------- */}
        <Grid item xs={12} sm={6}>
          <Stack flexDirection="row" justifyContent="space-between" gap={3}>
            <Header>{t('common:amount_per_lv1')}</Header>
            <Item>
              {new Intl.NumberFormat().format(productStore.lv1.quantity)}
              &nbsp;<span>{unit.name}</span>
            </Item>
          </Stack>
        </Grid>
        {/* ---------------------------- Amount ---------------------------- */}
        {/* ---------------------------- Price ----------------------------- */}
        <Grid item xs={12} sm={6}>
          <Stack flexDirection="row" justifyContent="space-between" gap={3}>
            <Header>{t('common:price_per_lv1')}</Header>
            <Item>
              {getPrice(productStore.purchase.price)}(
              {getUnitPrice(productStore.lv1.quantity, productStore.purchase.price)}
              &nbsp;{t('per')}&nbsp;
              {unit.name})
            </Item>
          </Stack>
        </Grid>
        {/* ---------------------------- Price ----------------------------- */}
      </Grid>
      <Divider sx={{marginBottom: 3}} />
    </>
  );
});

export default ItemSummary;

interface IHeaderProps {
  children: React.ReactNode;
}

const Header: React.FC<IHeaderProps> = ({children}) => {
  return (
    <Typography variant="body1" textTransform="uppercase" color="GrayText">
      {children}
    </Typography>
  );
};

interface IItemProps {
  children: React.ReactNode;
}

const Item: React.FC<IItemProps> = ({children}) => {
  return (
    <Typography variant="body1" color="primary">
      {children}
    </Typography>
  );
};
