import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import '../../styles/main.css';
import {useRootStore} from '../../context';
import SaveOrder from '../../components/inventory/SaveOrder';
import AddAmenity from '../../components/inventory/AddAmenity';
import ListOrders from '../../components/inventory/ListOrders';
import CreateOrder from '../../components/inventory/CreateOrder';
import {
  PAGE_LIST_ORDERS,
  PAGE_CREATE_ORDER,
  PAGE_ADD_AMENITY,
  PAGE_SAVE_ORDER,
} from '../../stores/inventory/orders/orders';
import PageTitle from '../../components/PageTitle';
import {Container} from '@mui/system';
import {useTranslation} from 'react-i18next';

const OrderPage = observer(() => {
  const {ordersStore} = useRootStore();
  const [page, setPage] = useState(PAGE_LIST_ORDERS);

  useEffect(() => {
    ordersStore.loadPage();
    ordersStore.cart.loadProducts();
  });

  useEffect(() => {
    setPage(ordersStore.page);
  }, [ordersStore.page]);

  if (page === PAGE_LIST_ORDERS) {
    return <ListOrders />;
  }
  if (page === PAGE_CREATE_ORDER) {
    return <CreateOrder />;
  }
  if (page === PAGE_ADD_AMENITY) {
    return <AddAmenity />;
  }
  if (page === PAGE_SAVE_ORDER) {
    return <SaveOrder />;
  }

  return <></>;
});

const DeliveriesAlerts = observer(() => {
  const {t} = useTranslation();

  return (
    <Container>
      <PageTitle>{t('common:inventory_and_supplies')}</PageTitle>
      <OrderPage />
    </Container>
  );
});

export default DeliveriesAlerts;
