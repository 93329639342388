import {Button, Stack} from '@mui/material';
import {useFormik} from 'formik';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import {useRootStore} from '../../context';
import {
  ADD_ITEM_STEP_1,
  ADD_ITEM_STEP_2,
  IAmenitySessionStorage,
  IProductPurchaseSessionStorage,
} from '../../stores/inventory/product/product';
import ItemInfoSection from './AddEditItem/ItemInfoSection';
import PurchaseInfoSection from './AddEditItem/PurchaseInfoSection';
import StorageDetailsSection from './AddEditItem/StorageDetailsSection';
import AddItemStepLayout from './AddItemStepLayout';

export interface IFormikInitValues extends IAmenitySessionStorage, IProductPurchaseSessionStorage {
  storage: number;
  quantity: number;
  hotel: any;
  image?: string;
  lv1_image?: string;
  lv2_image?: string;
  lv3_image?: string;
}

const AddItemStep1 = observer(() => {
  const {inventoryStore, productStore, hotelStore} = useRootStore();
  const {t} = useTranslation();

  const navigate = useNavigate();
  const [unit, setUnit] = useState('');
  const [blob, setBlob] = useState<string | null>('');

  useEffect(() => {
    if (inventoryStore.brands.length === 0) {
      inventoryStore.getBrands();
    }
    if (inventoryStore.categories.length === 0) {
      inventoryStore.getCategories();
    }
    if (inventoryStore.units.length === 0) {
      inventoryStore.getUnits();
    } else if (productStore.amenity.unit) {
      const u = inventoryStore.units.find((obj) => {
        return obj.id === parseInt(productStore.amenity.unit);
      });
      if (u !== undefined) {
        setUnit(u.name);
      }
    }
    if (inventoryStore.storages.length === 0) {
      inventoryStore.getStorages();
    } else {
      const s = inventoryStore.storages.find((obj) => {
        return obj.name.toLowerCase() === 'box';
      });
      if (s) formik.setFieldValue('storage', s.id);
    }
  }, [
    inventoryStore.brands,
    inventoryStore.categories,
    inventoryStore.storages,
    inventoryStore.units,
  ]);

  useEffect(() => {
    if (hotelStore.currentHotel !== undefined) {
      formik.setFieldValue('hotel', hotelStore.currentHotel.id);
    }
  }, [hotelStore.currentHotel]);

  useEffect(() => {
    setBlob(productStore.lv1.image);
    formik.setFieldValue('image', productStore.lv1.image);
  }, [productStore.lv1.image]);

  useEffect(() => {
    if (blob !== '') {
      formik.setFieldValue('image', blob);
    }
  }, [blob]);

  const handleUnitChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    formik.handleChange(e);
    const u = inventoryStore.units.find((obj) => {
      return obj.id === parseInt(e.target.value);
    });
    if (u !== undefined) {
      setUnit(u.name);
    }
  };

  const handleReset = () => {
    productStore.reset();
    navigate(-1);
  };

  /* ------------------------------ Form Schema ----------------------------- */
  const YupSchema = Yup.object().shape({
    category: Yup.number().required(t('addItemSteps:category_required')),
    sku: Yup.string().ensure().trim().required(t('addItemSteps:sku_required')),
    name: Yup.string().ensure().trim().required(t('addItemSteps:product_name_required')),
    unit: Yup.string().ensure().trim().required(t('addItemSteps:uom_required')),
    brand: Yup.string().ensure().trim().required(t('addItemSteps:brand_name_required')),
    vendor: Yup.number().positive().required(t('addItemSteps:vendor_required')),
    reference: Yup.string().ensure().trim().required(t('addItemSteps:reference_code_required')),
    price: Yup.number().positive().required(t('addItemSteps:product_price_required')),
    delivery: Yup.number().integer().min(0).notRequired().default(0),
    info: Yup.string().ensure().trim().notRequired(),
    storage: Yup.number().positive().integer().required(t('common:storage_type_required')),
    quantity: Yup.number()
      .positive()
      .integer()
      .required(t('addItemSteps:units_per_single_lv1_required')),
    image: Yup.mixed().notRequired(),
  });
  /* ------------------------------ Form Schema ----------------------------- */

  const formik = useFormik({
    initialValues: {
      sku: productStore.amenity.sku,
      name: productStore.amenity.name,
      brand: productStore.amenity.brand || 0,
      category: productStore.amenity.category || 0,
      unit: productStore.amenity.unit,
      hotel: [hotelStore.currentHotel?.id],
      vendor: productStore.purchase.vendor || '',
      price: productStore.purchase.price || '',
      consumption_tax: productStore.amenity.consumption_tax || 0,
      reference: productStore.purchase.reference,
      delivery: productStore.purchase.delivery || '',
      info: productStore.purchase.info,
      storage: productStore.lv1.storage || '',
      quantity: productStore.lv1.quantity || '',
      image: '',
    } as IFormikInitValues,
    enableReinitialize: true,
    validationSchema: YupSchema,
    onSubmit: (values) => {
      const amenity = {
        sku: values.sku,
        consumption_tax: Number(values.consumption_tax),
        name: values.name,
        brand: Number(values.brand),
        category: Number(values.category),
        unit: values.unit,
        hotel: [values.hotel],
      };
      const purchase = {
        vendor: Number(values.vendor),
        price: Number(values.price),
        reference: values.reference,
        delivery: Number(values.delivery) ?? 0,
        info: values.info,
      };
      const lv1 = {
        amenity_id: 0,
        storage: Number(values.storage),
        quantity: Number(values.quantity),
        image: blob ?? '',
      };
      productStore.setAmenity(amenity);
      productStore.setPurchase(purchase);
      productStore.setLV1(lv1);

      productStore.setStep(ADD_ITEM_STEP_1, true);
      navigate(`/inventory/add-item#${ADD_ITEM_STEP_2}`);
    },
  });

  const selectStyles = {
    width: '100%',
    maxWidth: '207px',
  };

  return (
    <AddItemStepLayout>
      <form noValidate onSubmit={formik.handleSubmit} style={{width: '100%'}}>
        <ItemInfoSection
          formik={formik}
          selectStyles={selectStyles}
          handleUnitChange={handleUnitChange}
        />
        <PurchaseInfoSection formik={formik} selectStyles={selectStyles} />
        <StorageDetailsSection
          formik={formik}
          lv="LV1"
          tooltip={t('addItemSteps:number_of_units_per_lv1')}
          unit={unit}
          setBlob={setBlob}
        />
        <Stack flexDirection="row" justifyContent="flex-end" gap={2} width="100%">
          <Button variant="contained" color="gray" onClick={handleReset}>
            {t('common:reset')}
          </Button>
          <Button variant="contained" type="submit">
            {t('common:next_step')}
          </Button>
        </Stack>
      </form>
    </AddItemStepLayout>
  );
});

export default AddItemStep1;
