import {
  makeAutoObservable,
  makeObservable,
  action,
  observable,
  computed,
  autorun,
  IReactionDisposer,
} from 'mobx';
import {StatusCodes} from 'http-status-codes';
import Api from '../api/account';
import type {IRootStore} from './rootStore';
import type RootStore from './rootStore';
import i18n from '../i18n';
import {handleUnauthorizedResponse} from '../libs/utils';

interface IUser {
  rootStore: RootStore;

  id: number | undefined;
  username: string | undefined;
  email: string | undefined;
  first_name: string | undefined;
  last_name: string | undefined;
  is_staff: boolean | undefined;
  profile_image: string | undefined;
  token: string | undefined;

  disposer: Array<IReactionDisposer> | IReactionDisposer;

  setId: (id: number) => void;
  setUsername: (username: string) => void;
  setEmail: (email: string) => void;
  setFirstName: (name: string) => void;
  setLastName: (name: string) => void;
  setIsStaff: (value: boolean) => void;
  setToken: (token: string | undefined) => void;
  setProfileImage: (profileImage: string | undefined) => void;
  isAuthenticated: boolean;
  reset: () => void;
  getUser: () => void;
}

class User implements IUser {
  rootStore: RootStore;
  id: number | undefined = undefined;
  username: string | undefined = undefined;
  email: string | undefined = undefined;
  first_name: string | undefined = undefined;
  last_name: string | undefined = undefined;
  is_staff: boolean | undefined = undefined;
  profile_image: string | undefined = undefined;
  token: string | undefined = undefined;

  disposer: Array<IReactionDisposer> | IReactionDisposer;

  constructor(rootStore: IRootStore) {
    makeObservable(this, {
      id: observable,
      username: observable,
      email: observable,
      first_name: observable,
      last_name: observable,
      is_staff: observable,
      profile_image: observable,
      token: observable,
      setId: action,
      setUsername: action,
      setEmail: action,
      setFirstName: action,
      setLastName: action,
      setIsStaff: action,
      setProfileImage: action,
      setToken: action,
      isAuthenticated: computed,
    });
    this.rootStore = rootStore;

    this.disposer = autorun(() => {
      this.getUser();
    });
  }

  setId(id: number) {
    this.id = id;
  }

  setUsername(username: string) {
    this.username = username;
  }

  setEmail(email: string) {
    this.email = email;
  }

  setFirstName(name: string) {
    this.first_name = name;
  }

  setLastName(name: string) {
    this.last_name = name;
  }

  setIsStaff(value: boolean) {
    this.is_staff = value;
  }

  setToken(token: string | undefined) {
    this.token = token;
  }

  setProfileImage(profileImage: string | undefined) {
    this.profile_image = profileImage;
  }

  get isAuthenticated() {
    return this.token !== undefined;
  }

  reset() {
    this.id = undefined;
    this.username = undefined;
    this.email = undefined;
    this.last_name = undefined;
    this.first_name = undefined;
    this.is_staff = undefined;
    this.token = undefined;
    this.profile_image = undefined;
  }

  async getUser() {
    if (this.isAuthenticated && this.token) {
      try {
        const response = await Api.getUser(this.token);

        if (response.ok) {
          const data = await response;

          this.setId(data.body.id);
          this.setUsername(data.body.username);
          this.setEmail(data.body.email);
          this.setFirstName(data.body.first_name);
          this.setLastName(data.body.last_name);
          this.setIsStaff(data.body.is_staff);
          this.setProfileImage(data.body.profile_image ? data.body.profile_image : undefined);
        }
      } catch (error: any) {
        if (error.status === StatusCodes.UNAUTHORIZED) {
          handleUnauthorizedResponse();
          return;
        }
      }
    }
  }
}

interface IAccount {
  user: IUser;
  inProgress: boolean;
  error: string | undefined;
  rememberMe: boolean;
  rootStore: IRootStore;
  disposers: Array<IReactionDisposer> | IReactionDisposer;
  loadToken: () => void;
  saveToken: () => void;
  deleteToken: () => void;
  token: string | undefined;
  setInProgress: (progress: boolean) => void;
  setError: (error: string | undefined) => void;
  setRememberMe: (value: boolean) => void;
  reset: () => void;
  isAuthenticated: boolean;
  login: (username: string, password: string) => void;
  logout: () => void;
}

class Account implements IAccount {
  user: IUser;
  inProgress = false;
  error: string | undefined = undefined;
  rememberMe = true;
  rootStore: RootStore;
  disposers: Array<IReactionDisposer> | IReactionDisposer;

  constructor(rootStore: IRootStore) {
    makeAutoObservable(this, {rootStore: false});
    this.rootStore = rootStore;
    this.user = new User(rootStore);

    this.disposers = [
      autorun(() => {
        if (this.user.token === undefined) {
          this.loadToken();
        } else {
          this.saveToken();
        }
      }),
    ];
  }

  loadToken() {
    const local = window.localStorage.getItem('token');
    const session = window.sessionStorage.getItem('token');
    this.user.setToken(local ? local : session ? session : undefined);
  }

  saveToken() {
    if (this.user.token) {
      if (this.rememberMe) {
        window.localStorage.setItem('token', this.user.token);
      } else {
        window.sessionStorage.setItem('token', this.user.token);
      }
    }
  }

  deleteToken() {
    window.localStorage.removeItem('token');
    window.sessionStorage.removeItem('token');
  }

  get token() {
    return this.user.token;
  }

  setInProgress(progress: boolean) {
    this.inProgress = progress;
  }

  setError(error: string | undefined) {
    this.error = error;
  }

  setRememberMe(value: boolean) {
    this.rememberMe = value;
  }

  reset() {
    this.user.reset();
    this.inProgress = false;
    this.error = undefined;
    this.rememberMe = true;
  }

  get isAuthenticated() {
    return this.user.token !== undefined;
  }

  // API calls

  async login(username: string, password: string) {
    try {
      this.setInProgress(true);

      const response = await Api.login(username, password);

      if (response.ok) {
        const data = await response;

        this.user.setToken(data.body.token);
      }
    } catch (error: any) {
      if (error.status === StatusCodes.BAD_REQUEST) {
        this.setError(i18n.t('common:invalid_login_error') as string);
      } else {
        this.setError(i18n.t('common:server_error') as string);
      }
    } finally {
      this.setInProgress(false);
    }
  }

  async logout() {
    if (this.isAuthenticated && this.user.token) {
      try {
        this.setInProgress(true);

        await Api.logout(this.user.token);

        this.deleteToken();
        this.reset();
      } catch (error: any) {
        console.log('error logging out', error.message);
      } finally {
        this.setInProgress(false);
      }
    }
  }
}

export default Account;
