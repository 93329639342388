import React from 'react';
import {observer} from 'mobx-react-lite';
import PageTitle from '../components/PageTitle';
import '../styles/main.css';
import {Container} from '@mui/system';
import SelectHotel from '../components/Navbar/SelectHotel';
import {useTranslation} from 'react-i18next';

const Overview = observer(() => {
  const {t} = useTranslation();

  return (
    <Container>
      <PageTitle>{t('common:overview')}</PageTitle>
      <SelectHotel disabled={false} />
    </Container>
  );
});

export default Overview;
