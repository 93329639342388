import {StatusCodes} from 'http-status-codes';
import {action, makeObservable, observable, toJS} from 'mobx';
import {toast} from 'react-toastify';
import type {IBrand, IPurchaseResponse, IStorageResponseData, IUnit} from '../../../api/common';
import Api, {IOrderProductsPayload} from '../../../api/inventory/orders';
import i18n from '../../../i18n';
import {handleUnauthorizedResponse} from '../../../libs/utils';
import type RootStore from '../../rootStore';
import type {IRootStore} from '../../rootStore';

// CHECKED INTERFACE
export interface ISessionStorageProduct {
  amenity: {
    id: number;
    brand: IBrand;
    unit: IUnit;
    purchase: IPurchaseResponse;
    storage: IStorageResponseData;
    stock: number;
    est_stock: number;
    est_demand: number;
    suggested_stock: number;
    buffer: number;
    status: string;
    est_box: number;
    since_count: number;
    daily_usage: number;
    sku: string;
    name: string;
    consumption_tax: number;
    category: number;
    hotel: Array<number>;
  };
  quantity: number;
}

interface ICart {
  rootStore: IRootStore;
  product: ISessionStorageProduct | null;
  isSaving: boolean;
  saveSuccess: boolean;
  products: Array<ISessionStorageProduct>;
  getProduct: (id: number) => ISessionStorageProduct | undefined;
  setProduct: (product: ISessionStorageProduct) => void;
  setIsSaving: (isSaving: boolean) => void;
  setSaveSuccess: (saveSuccess: boolean) => void;
  updateProducts: (updatedProductData: ISessionStorageProduct) => void;
  loadProduct: () => void;
  loadProducts: () => void;
  reset: () => void;
}

class Cart implements ICart {
  rootStore: RootStore;
  product: ISessionStorageProduct | null = null;
  isSaving = false;
  saveSuccess = false;
  products: Array<ISessionStorageProduct> = [];

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      product: observable,
      isSaving: observable,
      saveSuccess: observable,
      products: observable,
      getProduct: action,
      setProduct: action,
      setIsSaving: action,
      setSaveSuccess: action,
      updateProducts: action,
      loadProduct: action,
      loadProducts: action,
      reset: action,
    });
  }

  reset() {
    window.sessionStorage.removeItem('order-products');
    window.sessionStorage.removeItem('order-product');
    this.products = [];
    this.product = null;
    this.isSaving = false;
    this.saveSuccess = false;
  }

  setProduct(product: ISessionStorageProduct) {
    window.sessionStorage.setItem('order-product', JSON.stringify(product));
    this.product = product;
  }

  setIsSaving(isSaving: boolean) {
    this.isSaving = isSaving;
  }

  setSaveSuccess(saveSuccess: boolean) {
    this.saveSuccess = saveSuccess;
  }

  loadProduct() {
    const product = window.sessionStorage.getItem('order-product');
    if (product) {
      this.product = JSON.parse(product);
    } else {
      this.product = null;
    }
  }

  getProduct(id: number) {
    return this.products.find((product) => {
      return product.amenity.id === id;
    });
  }

  loadProducts() {
    const products = window.sessionStorage.getItem('order-products');
    if (products !== null) {
      this.products = JSON.parse(products);
    } else {
      this.products = [];
    }
  }

  updateProducts(updatedProductData: ISessionStorageProduct) {
    // Product is getting deleted
    if (updatedProductData.quantity === 0) {
      this.products = this.products.filter((product) => {
        return product.amenity.id !== updatedProductData.amenity.id;
      });
    } else {
      // Product quantity changed
      const product = this.products.find((product) => {
        return product.amenity.id === updatedProductData.amenity.id;
      });
      if (product !== undefined) {
        this.products = this.products.map((product) => {
          if (product.amenity.id === updatedProductData.amenity.id) {
            product.quantity = updatedProductData.quantity;
            return product;
          }
          return product;
        });
      } else {
        // New product added
        const products = toJS(this.products);
        products.push(updatedProductData);
        this.products = products;
      }
    }
    window.sessionStorage.setItem('order-products', JSON.stringify(toJS(this.products)));
    window.sessionStorage.removeItem('order-product');
    this.product = null;
  }

  async saveOrder(products: IOrderProductsPayload) {
    const token = this.rootStore.accountStore.user.token;
    const currentHotelId = this.rootStore.hotelStore.currentHotel?.id;

    if (token && currentHotelId) {
      try {
        this.setIsSaving(true);

        const response = await Api.saveOrder(token, currentHotelId, products);

        if (response.ok) {
          toast.success(i18n.t('Order Saved.') as string);
          // TODO CHECK THIS EXECUTION
          products.items.forEach((item) => {
            const foundItem = this.getProduct(item.amenity);

            if (foundItem) {
              const toBeDeletedItem = {...foundItem, quantity: 0};
              this.updateProducts(toBeDeletedItem);
            }
          });
          this.setSaveSuccess(true);
        }
      } catch (error: any) {
        this.setSaveSuccess(false);

        if (error.status === StatusCodes.UNAUTHORIZED) {
          handleUnauthorizedResponse();
          return;
        }
        toast.error(error.message);
      } finally {
        this.setIsSaving(false);
      }
    }
  }
}

export default Cart;
