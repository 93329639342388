import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import SuccessModal from './SuccessModal';
import orderStyles from '../../styles/order';
import {useRootStore} from '../../context';
import {
  Button,
  ButtonGroup,
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import TotalCreateOrder from './TotalCreateOrder';
import {useTranslation} from 'react-i18next';
import type {IPrepareOrderResponseResult} from '../../api/order';

interface IRowItemProps {
  linen: IPrepareOrderResponseResult;
}

const RowItem: React.FC<IRowItemProps> = observer(({linen}) => {
  const {orderStore} = useRootStore();
  const classes = orderStyles();
  const [surplus, setSurplus] = useState(linen.surplus);
  const [toOrder, setToOrder] = useState(linen.to_order);

  const handleAddBundle = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setToOrder(toOrder + linen.bundle);
    setSurplus(surplus + linen.bundle);
  };

  const handleRemoveBundle = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (toOrder - linen.bundle >= 0) {
      setToOrder(toOrder - linen.bundle);
      setSurplus(surplus - linen.bundle);
    }
  };

  useEffect(() => {
    orderStore.updateOrder(linen.linen_id, toOrder, surplus);
  }, [toOrder, surplus]);

  return (
    <TableRow sx={{'& td': {fontSize: '1rem'}}}>
      <TableCell sx={{paddingLeft: 0}}>{linen.item}</TableCell>
      <TableCell align="center">{linen.bundle}</TableCell>
      <TableCell align="center">{linen.demand}</TableCell>
      <TableCell align="center">{linen.stock}</TableCell>
      <TableCell align="center" sx={{color: 'primary.main', fontWeight: 'bold'}}>
        {toOrder}
      </TableCell>
      <TableCell align="center">
        <ButtonGroup>
          <Button size="small" color="inherit" onClick={handleRemoveBundle}>
            <RemoveIcon fontSize="small" />
          </Button>
          <Button size="small" color="inherit" onClick={handleAddBundle}>
            <AddIcon fontSize="small" />
          </Button>
        </ButtonGroup>
      </TableCell>
      <TableCell className={`text-center ${classes.surplus}`}>{surplus}</TableCell>
    </TableRow>
  );
});

const PrepareOrderDesktop = observer(() => {
  const {t} = useTranslation();
  const {orderStore} = useRootStore();

  return (
    <Container>
      <SuccessModal />
      <Typography variant="h4" component="h3" marginBottom={3}>
        {t('common:prepare_order')}
      </Typography>
      <Table>
        <TableHead>
          <TableRow sx={{'& .MuiTableCell-root': {fontSize: '1rem', fontWeight: 'bold'}}}>
            <TableCell sx={{paddingLeft: 0}}>{t('prepareLinenOrder:linen_type')}</TableCell>
            <TableCell align="center">{t('prepareLinenOrder:min_bundle')}</TableCell>
            <TableCell align="center">{t('prepareLinenOrder:fc_demand')}</TableCell>
            <TableCell align="center">{t('prepareLinenOrder:stock')}</TableCell>
            <TableCell align="center">{t('prepareLinenOrder:order')}</TableCell>
            <TableCell align="center">{t('prepareLinenOrder:extra_question')}</TableCell>
            <TableCell align="center">{t('prepareLinenOrder:surplus_deficit')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orderStore.order.map((obj, i) => {
            return <RowItem linen={obj} key={i} />;
          })}
        </TableBody>
      </Table>
      <TotalCreateOrder />
    </Container>
  );
});

export default PrepareOrderDesktop;
