import React, {useEffect, useState} from 'react';
import {toJS} from 'mobx';
import {observer} from 'mobx-react-lite';
import '../../styles/main.css';
import '../../styles/inventory.css';
import ItemSummary from './ItemSummary';
import {
  ADD_ITEM_STEP_1,
  ADD_ITEM_STEP_2,
  ADD_ITEM_STEP_3,
} from '../../stores/inventory/product/product';
import {Button, Chip, Stack, Typography} from '@mui/material';
import AddItemStepLayout from './AddItemStepLayout';
import {useTranslation} from 'react-i18next';
import {useRootStore} from '../../context';

const AddItemStep2 = observer(() => {
  const {productStore, hotelStore} = useRootStore();
  const {t} = useTranslation();
  const [hotels, setHotels] = useState([hotelStore.currentHotel?.id]);

  const getHotelButtonVariant = (id: number) => {
    if (hotels.includes(id)) {
      return 'dark';
    }
    return 'outline-secondary';
  };

  useEffect(() => {
    const amenity = toJS(productStore.amenity);

    amenity.hotel = [...hotels] as Array<number>;
    productStore.setAmenity(amenity);
  }, [hotels]);

  const handleAddHotel = (id: number) => {
    if (id === hotelStore.currentHotel?.id) return;

    const amenity = toJS(productStore.amenity);

    if (hotels.includes(id)) {
      const h = hotels.filter((val) => {
        return Number(id) !== Number(val);
      });
      setHotels(h);
    } else {
      const h = [...hotels];
      h.push(id);
      productStore.setAmenity(amenity);
      setHotels(h);
    }
  };

  const getLevelButtonVariant = (level: 'lv1' | 'lv2' | 'lv3') => {
    if (productStore.levels.includes(level)) {
      return 'dark';
    }
    return 'outline-secondary';
  };

  const handleAddStorage = (level: 'lv1' | 'lv2' | 'lv3') => {
    let s = [...productStore.levels];

    if (s.includes(level)) {
      s = s.filter((item) => item !== level);
      productStore.setLevels(s);
    } else {
      s.push(level);
      productStore.setLevels(s);
    }
  };

  const handleNextStep = () => {
    productStore.setStep(ADD_ITEM_STEP_2, true);
  };

  return (
    <AddItemStepLayout back={ADD_ITEM_STEP_1}>
      <ItemSummary />
      {/* ------------------------- Hotels ----------------------------- */}
      <Stack flexDirection={{lg: 'row'}} gap={{xs: 1.5, lg: 3}} mb={3} alignItems={{lg: 'center'}}>
        <Typography variant="body1">{t('addItemSteps:create_item_for_other_hotels')}</Typography>
        <Stack flexDirection="row" gap={3}>
          {hotelStore.hotels.map((obj, index) => {
            return (
              <Chip
                key={index}
                label={obj.short_name}
                clickable
                onClick={() => handleAddHotel(obj.id)}
                color="gray"
                variant={getHotelButtonVariant(obj.id) === 'dark' ? 'filled' : 'outlined'}
              />
            );
          })}
        </Stack>
      </Stack>
      {/* ------------------------- Hotels ----------------------------- */}
      {/* ------------------------- Levels ----------------------------- */}
      <Stack flexDirection={{lg: 'row'}} gap={{xs: 1.5, lg: 3}} alignItems={{lg: 'center'}}>
        <Typography variant="body1">{t('addItemSteps:create_smaller_storage_levels')}</Typography>
        <Stack flexDirection="row" gap={3}>
          <Chip
            label={t('addItemSteps:level2')}
            clickable
            onClick={() => handleAddStorage('lv2')}
            color="gray"
            variant={getLevelButtonVariant('lv2') === 'dark' ? 'filled' : 'outlined'}
          />
          <Chip
            label={t('addItemSteps:level3')}
            clickable
            onClick={() => handleAddStorage('lv3')}
            color="gray"
            variant={getLevelButtonVariant('lv3') === 'dark' ? 'filled' : 'outlined'}
          />
        </Stack>
      </Stack>
      {/* ------------------------- Levels ----------------------------- */}
      <Stack alignItems={{md: 'flex-end'}} mt={3}>
        {productStore.levels.length > 0 ? (
          <Button
            onClick={handleNextStep}
            href={`/inventory/add-item#${ADD_ITEM_STEP_3}`}
            variant="contained"
            color="gray"
          >
            {t('common:next')}
          </Button>
        ) : (
          <Button variant="contained" color="gray" href="/inventory/confirm">
            {t('common:next')}
          </Button>
        )}
      </Stack>
    </AddItemStepLayout>
  );
});

export default AddItemStep2;
