import React from 'react';
import {Button, Typography} from '@mui/material';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {theme} from '../../config/theme.config';
import {useTranslation} from 'react-i18next';
import {DateTime} from 'luxon';

interface IToggleAutoSendCodeButtonProps {
  onEnableDisable: (
    e: React.MouseEvent,
    groupId: string,
    checkInDate: string,
    autoSendStatus: boolean,
    lock: string | null,
  ) => void;
  groupId: string;
  autoSendStatus: boolean;
  checkInDate: string;
  lock: string | null;
  reservationState: string;
}

const ToggleAutoSendCodeButton: React.FC<IToggleAutoSendCodeButtonProps> = ({
  onEnableDisable,
  groupId,
  autoSendStatus,
  checkInDate,
  lock,
  reservationState,
}) => {
  const {t} = useTranslation();

  const buttonText = autoSendStatus
    ? t('reservations:disable_send_code')
    : t('reservations:enable_send_code');

  const buttonIcon = autoSendStatus ? <UnpublishedIcon /> : <CheckCircleIcon />;

  const buttonColor = autoSendStatus ? theme.palette.gray.light : theme.palette.primary.main;

  const formattedCheckInDate = DateTime.fromJSDate(new Date(checkInDate)).toFormat('yyyy-MM-dd');

  const isDisabled = !lock || !['Confirmed', 'Started'].includes(reservationState);

  return (
    <Button
      variant="contained"
      endIcon={buttonIcon}
      aria-label={'Send Code'}
      sx={{backgroundColor: buttonColor}}
      onClick={(e) => onEnableDisable(e, groupId, formattedCheckInDate, autoSendStatus, lock)}
      disabled={isDisabled}
    >
      <Typography variant={'caption'}>{buttonText}</Typography>
    </Button>
  );
};

export default ToggleAutoSendCodeButton;
