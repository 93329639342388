import React from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import TranslateIcon from '@mui/icons-material/Translate';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PhoneIcon from '@mui/icons-material/Phone';
import {observer} from 'mobx-react-lite';
import {Link} from 'react-router-dom';
import {capitalize} from '../../libs/utils';
import '../../styles/main.css';
import {useTranslation} from 'react-i18next';
import {useRootStore} from '../../context';
import type {IGuestResult} from '../../api/guests';

interface IGuestCardProps {
  guestData: IGuestResult;
}

const GuestCard: React.FC<IGuestCardProps> = observer(({guestData}) => {
  const {id, first_name, last_name, email, phone_number, is_valid_phone_number, language} =
    guestData;
  const {t} = useTranslation();

  const {hotelStore} = useRootStore();

  const capitalizedFirstName = capitalize(first_name);
  const capitalizedLastName = capitalize(last_name);

  const guestDetailsButton = (
    <Link to={`${id}`} style={{textDecoration: 'none'}}>
      <Button
        variant="contained"
        endIcon={<FormatListBulletedIcon />}
        aria-label={'See details'}
        color={'primary'}
      >
        <Typography variant={'caption'}>{t('common:see_details')}</Typography>
      </Button>
    </Link>
  );

  const phoneHasIncorrectFormat = phone_number && !is_valid_phone_number;

  const cardContentStyles = {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  };

  return (
    <>
      <Card sx={{width: {xs: '100%', sm: '49%', md: '30%'}}}>
        <CardContent sx={{...cardContentStyles}}>
          <Grid container gap={{xs: 1}}>
            <Grid item marginBottom={1}>
              <Typography variant="h5">{`${capitalizedFirstName} ${capitalizedLastName}`}</Typography>
            </Grid>

            <Grid item xs={12}>
              <Stack display="flex" flexDirection="row" alignItems={'center'} gap={0.5}>
                <AlternateEmailIcon fontSize="small" />

                <Typography noWrap variant="body2">
                  {email ?? '-'}
                </Typography>
              </Stack>
            </Grid>
            <Grid item>
              <Stack display="flex" flexDirection="row" alignItems={'center'} gap={0.5}>
                <PhoneIcon fontSize="small" color={!is_valid_phone_number ? 'error' : 'inherit'} />
                <Typography variant="body2">{phone_number ?? '-'}</Typography>

                {phoneHasIncorrectFormat && (
                  <Tooltip title={t('common:phone_number_incorrect_format')} color="error">
                    <IconButton color="error" sx={{margin: 0, padding: 0}}>
                      <ErrorOutlineIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
              </Stack>
            </Grid>
            <Grid item display="flex" flexDirection="column" justifyContent={'center'}>
              <Stack display="flex" flexDirection="row" alignItems={'center'} gap={0.5}>
                <TranslateIcon fontSize="small" />

                <Typography textAlign="center" variant="body2">
                  {language.toUpperCase()}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Stack
            alignItems="center"
            marginTop={2}
            flexDirection="row"
            justifyContent={'space-evenly'}
            gap={1}
          >
            {guestDetailsButton}
          </Stack>
        </CardContent>
      </Card>
    </>
  );
});

export default GuestCard;
