import {MenuItem, Stack, SxProps, TextField, Theme, useMediaQuery} from '@mui/material';
import type {FormikProps} from 'formik';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useRootStore} from '../../../context';
import type {IFormikInitValues} from '../AddItemStep1';
import SectionTitle from './SectionTitle';

interface IItemInfoSectionProps {
  formik: FormikProps<IFormikInitValues>;
  selectStyles: SxProps<Theme> | undefined;
  handleUnitChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
}

const ItemInfoSection: React.FC<IItemInfoSectionProps> = observer(
  ({formik, selectStyles, handleUnitChange}) => {
    const isMobile = useMediaQuery('(max-width: 900px)');

    const {inventoryStore} = useRootStore();
    const {t} = useTranslation();

    return (
      <>
        {/* --------------------- Product Category ------------------------- */}
        <Stack alignItems="start" gap={3} marginBottom={3} width={{xs: '100%', md: 'auto'}}>
          <SectionTitle>{t('common:item_info')}</SectionTitle>
          <TextField
            select
            name="category"
            onChange={formik.handleChange}
            value={formik.values.category}
            required
            fullWidth
            label={t('common:product_category')}
            error={formik.touched.category && Boolean(formik.errors.category)}
            helperText={formik.touched.category && formik.errors.category}
            sx={selectStyles}
          >
            {inventoryStore.categories.map((obj, index) => {
              return (
                <MenuItem key={index} value={obj.id}>
                  {obj.name}
                </MenuItem>
              );
            })}
          </TextField>
          {/* --------------------- Product Category ------------------------- */}
          {/* --------------------------- SKU -------------------------------- */}
          <Stack gap={3} flexDirection={{md: 'row'}} width="100%">
            <TextField
              name="sku"
              label={t('common:sku')}
              value={formik.values.sku}
              onChange={formik.handleChange}
              required
              fullWidth={isMobile}
              error={formik.touched.sku && Boolean(formik.errors.sku)}
              helperText={formik.touched.sku && formik.errors.sku}
              inputProps={{maxLength: 16}}
            />
            {/* --------------------------- SKU ---------------------------- */}
            {/* --------------------------- Name --------------------------- */}
            <TextField
              name="name"
              label={t('common:item_name')}
              value={formik.values.name}
              onChange={formik.handleChange}
              required
              fullWidth={isMobile}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Stack>
          {/* --------------------------- Name ------------------------------- */}
          {/* --------------------- Unit of measurement ---------------------- */}
          <TextField
            select
            name="unit"
            onChange={handleUnitChange}
            value={formik.values.unit}
            label={t('common:uom')}
            sx={selectStyles}
            fullWidth
            required
            error={formik.touched.unit && Boolean(formik.errors.unit)}
            helperText={formik.touched.unit && formik.errors.unit}
          >
            {inventoryStore.units.map((obj, index) => (
              <MenuItem key={index} value={obj.id}>
                {obj.name}
              </MenuItem>
            ))}
          </TextField>
          {/* --------------------- Unit of measurement ---------------------- */}
        </Stack>
      </>
    );
  },
);

export default ItemInfoSection;
