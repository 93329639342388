import React from 'react';
import {observer} from 'mobx-react-lite';
import {toast} from 'react-toastify';

import {useNavigate} from 'react-router-dom';

import '../../styles/main.css';
import '../../styles/inventory.css';
import ItemSummary from './ItemSummary';
import StorageLV from './StorageLV';
import {ADD_ITEM_STEP_2} from '../../stores/inventory/product/product';
import {Button, Stack} from '@mui/material';
import AddItemStepLayout from './AddItemStepLayout';
import {useRootStore} from '../../context';
import {useTranslation} from 'react-i18next';

const AddItemStep3 = observer(() => {
  const {productStore} = useRootStore();
  const {t} = useTranslation();
  const navigate = useNavigate();

  const handleNext = () => {
    if (
      productStore.levels.includes('lv2') &&
      (productStore.lv2.storage === 0 || productStore.lv2.quantity === 0)
    ) {
      toast.error(t('addItemSteps:please_save_level2_storage'));
      return;
    }
    if (
      productStore.levels.includes('lv3') &&
      (productStore.lv3.storage === 0 || productStore.lv3.quantity === 0)
    ) {
      toast.error(t('please_save_level3_storage'));
      return;
    }
    navigate('/inventory/confirm');
  };

  return (
    <AddItemStepLayout back={ADD_ITEM_STEP_2}>
      <ItemSummary />
      {productStore.levels.includes('lv2') && <StorageLV lv="lv2" />}
      {productStore.levels.includes('lv3') && <StorageLV lv="lv3" />}
      <Stack alignItems="end">
        <Button variant="contained" color="gray" size="large" onClick={handleNext}>
          {t('common:next')}
        </Button>
      </Stack>
    </AddItemStepLayout>
  );
});

export default AddItemStep3;
