import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import {useTranslation} from 'react-i18next';
import SuccessModal from './SuccessModal';
import {useRootStore} from '../../context';
import {ButtonGroup, List, ListItem, Stack, Typography, Button, Card} from '@mui/material';
import TotalCreateOrder from './TotalCreateOrder';
import type {IPrepareOrderResponseResult} from '../../api/order';

interface ILinenItemProps {
  linen: IPrepareOrderResponseResult;
}

const LinenItem: React.FC<ILinenItemProps> = observer(({linen}) => {
  const {t} = useTranslation();
  const {orderStore} = useRootStore();
  const [surplus, setSurplus] = useState(linen.surplus);
  const [toOrder, setToOrder] = useState(linen.to_order);

  const handleAddBundle = (e: React.MouseEvent) => {
    e.preventDefault();
    setToOrder(toOrder + linen.bundle);
    setSurplus(surplus + linen.bundle);
  };

  const handleRemoveBundle = (e: React.MouseEvent) => {
    e.preventDefault();
    if (toOrder - linen.bundle >= 0) {
      setToOrder(toOrder - linen.bundle);
      setSurplus(surplus - linen.bundle);
    }
  };

  useEffect(() => {
    orderStore.updateOrder(linen.linen_id, toOrder, surplus);
  }, [toOrder, surplus]);

  return (
    <Card variant="outlined">
      <List>
        {/* --------------------------- Item ------------------------------- */}
        <ListItem divider>
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Typography fontWeight="600">{linen.item}</Typography>
            <div>
              <Typography variant="body2" color="GrayText" component="span" marginRight={1}>
                {t('prepareLinenOrder:stock')}
              </Typography>
              <Typography variant="body1" component="span" fontWeight="600">
                {linen.stock}
              </Typography>
            </div>
          </Stack>
        </ListItem>
        {/* ---------------------------- Item ------------------------------ */}

        {/* ------------------------- Min Bundle --------------------------- */}
        <ListItem divider>
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Typography variant="body2" color="GrayText">
              {t('prepareLinenOrder:min_bundle')}
            </Typography>
            <Typography variant="body1" component="span">
              {linen.bundle}
            </Typography>
          </Stack>
        </ListItem>
        {/* ------------------------- Min Bundle --------------------------- */}

        {/* ------------------------- F/C Demand --------------------------- */}
        <ListItem divider>
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Typography variant="body2" color="GrayText">
              {t('prepareLinenOrder:fc_demand')}
            </Typography>
            <Typography variant="body1" component="span">
              {linen.demand}
            </Typography>
          </Stack>
        </ListItem>
        {/* ------------------------- F/C Demand --------------------------- */}

        {/* ---------------------- Surplus/(Deficit) ----------------------- */}
        <ListItem divider>
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Typography variant="body2" color="GrayText">
              {t('prepareLinenOrder:surplus_deficit')}
            </Typography>
            <Typography variant="body1" component="span" color="orange">
              ({linen.surplus})
            </Typography>
          </Stack>
        </ListItem>
        {/* --------------------- Surplus/(Deficit) ------------------------ */}

        {/* --------------------------- Bottom ----------------------------- */}
        <ListItem>
          <Stack flexDirection="row" justifyContent="space-between" width="100%">
            {/* ------------------------ To Order -------------------------- */}
            <Stack alignItems="center">
              <Typography variant="h6" color="GrayText">
                {t('prepareLinenOrder:stock')}
              </Typography>
              <Typography variant="h4" fontWeight="700" color="primary">
                {toOrder}
              </Typography>
            </Stack>
            {/* ------------------------ To Order -------------------------- */}

            {/* -------------------------- Extra --------------------------- */}
            <Stack alignItems="center" justifyContent="space-between">
              <Typography variant="h6" color="GrayText">
                {t('prepareLinenOrder:extra_question')}
              </Typography>
              <ButtonGroup>
                <Button
                  key="remove"
                  variant="contained"
                  color="inherit"
                  sx={{backgroundColor: '#FFF'}}
                  onClick={handleRemoveBundle}
                  size="small"
                >
                  <RemoveIcon fontSize="small" />
                </Button>
                <Button
                  key="add"
                  variant="contained"
                  color="inherit"
                  sx={{backgroundColor: '#FFF'}}
                  onClick={handleAddBundle}
                  size="small"
                >
                  <AddIcon fontSize="small" />
                </Button>
              </ButtonGroup>
            </Stack>
            {/* -------------------------- Extra --------------------------- */}
          </Stack>
        </ListItem>
        {/* --------------------------- Bottom ----------------------------- */}
      </List>
    </Card>
  );
});

const PrepareOrderMobile = observer(() => {
  const {t} = useTranslation();
  const {orderStore} = useRootStore();

  return (
    <>
      <SuccessModal />
      {/* ---------------------------- Title ------------------------------- */}
      <Typography variant="h4" component="h3" marginBottom={4}>
        {t('common:prepare_order')}
      </Typography>
      {/* ---------------------------- Title ------------------------------- */}
      {/* ---------------------------- Items ------------------------------- */}
      <Stack gap={3}>
        {orderStore.order.map((obj, i) => {
          return <LinenItem linen={obj} key={i} />;
        })}
      </Stack>
      {/* ---------------------------- Items ------------------------------- */}
      <TotalCreateOrder />
    </>
  );
});

export default PrepareOrderMobile;
