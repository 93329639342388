import {StatusCodes} from 'http-status-codes';
import {makeObservable, action, observable} from 'mobx';
import {toast} from 'react-toastify';
import Api, {
  TGetBrandsResponse,
  TGetCategoriesResponse,
  TGetStorageResponse,
  TGetUnitResponse,
} from '../../api/inventory/inventory';
import {handleUnauthorizedResponse} from '../../libs/utils';
import type RootStore from '../rootStore';

interface IInventory {
  rootStore: RootStore;
  brands: TGetBrandsResponse;
  categories: TGetCategoriesResponse;
  storages: TGetStorageResponse;
  units: TGetUnitResponse;
  latestCount: string;
  getBrands: () => void;
  getCategories: () => void;
  getStorages: () => void;
  getUnits: () => void;
  getLatestCount: () => void;
  setBrands: (brands: TGetBrandsResponse) => void;
  setCategories: (categories: TGetCategoriesResponse) => void;
  setStorages: (storages: TGetStorageResponse) => void;
  setUnits: (units: TGetUnitResponse) => void;
  setLatestCount: (latestCount: string) => void;
}

class Inventory implements IInventory {
  rootStore: RootStore;
  brands: TGetBrandsResponse = [];
  categories: TGetCategoriesResponse = [];
  storages: TGetStorageResponse = [];
  units: TGetUnitResponse = [];
  latestCount = '';

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      brands: observable,
      categories: observable,
      storages: observable,
      units: observable,
      latestCount: observable,
      getBrands: action,
      getCategories: action,
      getStorages: action,
      getUnits: action,
      getLatestCount: action,
      setBrands: action,
      setCategories: action,
      setStorages: action,
      setUnits: action,
      setLatestCount: action,
    });
  }

  setBrands(brands: TGetBrandsResponse) {
    this.brands = brands;
  }

  setCategories(categories: TGetCategoriesResponse) {
    this.categories = categories;
  }

  setStorages(storages: TGetStorageResponse) {
    this.storages = storages;
  }

  setUnits(units: TGetUnitResponse) {
    this.units = units;
  }

  setLatestCount(latestCount: string) {
    this.latestCount = latestCount;
  }

  // api calls
  async getBrands() {
    const token = this.rootStore.accountStore.user.token;

    if (token) {
      try {
        const response = await Api.getBrands(token);

        if (response.ok) {
          const data = await response;

          this.setBrands(data.body);
        }
      } catch (error: any) {
        if (error.status === StatusCodes.UNAUTHORIZED) {
          handleUnauthorizedResponse();
          return;
        }
        toast.error(error.message);
      }
    }
  }

  async getCategories() {
    const token = this.rootStore.accountStore.user.token;

    if (token) {
      try {
        const response = await Api.getCategories(token);

        if (response.ok) {
          const data = await response;

          this.setCategories(data.body);
        }
      } catch (error: any) {
        if (error.status === StatusCodes.UNAUTHORIZED) {
          handleUnauthorizedResponse();
          return;
        }
        toast.error(error.message);
      }
    }
  }

  async getStorages() {
    const token = this.rootStore.accountStore.user.token;

    if (token) {
      try {
        const response = await Api.getStorages(token);

        if (response.ok) {
          const data = await response;

          this.setStorages(data.body);
        }
      } catch (error: any) {
        if (error.status === StatusCodes.UNAUTHORIZED) {
          handleUnauthorizedResponse();
          return;
        }
        toast.error(error.message);
      }
    }
  }

  async getUnits() {
    const token = this.rootStore.accountStore.user.token;

    if (token) {
      try {
        const response = await Api.getUnits(token);

        if (response.ok) {
          const data = await response;

          this.setUnits(data.body);
        }
      } catch (error: any) {
        if (error.status === StatusCodes.UNAUTHORIZED) {
          handleUnauthorizedResponse();
          return;
        }
        toast.error(error.message);
      }
    }
  }

  async getLatestCount() {
    const token = this.rootStore.accountStore.user.token;
    const hotelId = this.rootStore.hotelStore.currentHotel?.id;

    if (token && hotelId) {
      try {
        const response = await Api.getLatestCount(token, hotelId);

        if (response.ok) {
          const data = await response;

          this.setLatestCount(data.body.latest_count);
        }
      } catch (error: any) {
        if (error.status === StatusCodes.UNAUTHORIZED) {
          handleUnauthorizedResponse();
          return;
        }
        // if 404 a stock count was not taken yet.
        if (error.status !== StatusCodes.NOT_FOUND) {
          toast.error(error.message);
        }
      }
    }
  }
}

export default Inventory;
