import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {LoadingButton} from '@mui/lab';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import {useFormik} from 'formik';
import {toJS} from 'mobx';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import type {IGetLinenInventoryResponse, TUpdateLinenInventoryParams} from '../../api/linen';
import {useRootStore} from '../../context';
import NoResultsFound from '../general/NoResultsFound';
import SkeletonLoader from '../general/SkeletonLoader';
import LinenStockItem from './LinenStockItem';

const InventoryAccordion: React.FC = observer(() => {
  const {t} = useTranslation();
  const {hotelStore, accountStore, linenStore} = useRootStore();
  const [inventory, setInventory] = useState<IGetLinenInventoryResponse>({});
  const [currentLocation, setCurrentLocation] = useState('');
  const [validationSchema, setValidationSchema] = useState({});

  useEffect(() => {
    if (hotelStore.currentHotel !== undefined && accountStore.isAuthenticated) {
      linenStore.getInventory();
      formik.resetForm();
    }
  }, [hotelStore.currentHotel, accountStore.isAuthenticated]);

  useEffect(() => {
    if (linenStore.inventory !== undefined) {
      setInventory(toJS(linenStore.inventory));
    }
  }, [linenStore.inventory]);

  useEffect(() => {
    if (currentLocation === '') {
      const curLoc = sessionStorage.getItem('currentLocation');
      if (curLoc !== null) {
        setCurrentLocation(curLoc);
      }
    } else if (inventory !== undefined) {
      Object.entries(inventory).map(([key, stock]) => {
        if (key === currentLocation) {
          const validationSchema: Record<string, any> = {};

          stock.map((obj) => {
            formik.setFieldValue(`total_${obj.id}`, obj.total, false);

            validationSchema[`total_${obj.id}`] = Yup.number()
              .required(t('linenInventory:stock_count_required'))
              .min(0, t('linenInventory:stock_count_greater_0'))
              .integer(t('linenInventory:stock_count_must_number'));
          });

          setValidationSchema(validationSchema);
        }
      });
    }
  }, [currentLocation, inventory]);

  const handleLocation = (currentLocation: React.SyntheticEvent<Element, Event>) => {
    if (!currentLocation.currentTarget.textContent) return;

    setCurrentLocation(currentLocation.currentTarget.textContent);
    sessionStorage.setItem('currentLocation', currentLocation.currentTarget.textContent);
  };

  const StockSchema = Yup.object().shape(validationSchema);

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,
    validationSchema: StockSchema,
    onSubmit: (values: Record<string, number>) => {
      const params: TUpdateLinenInventoryParams = [];

      Object.entries(values).forEach(([key, val]) => {
        if (key.startsWith('total_')) {
          const id = key.replace(/[^0-9]/g, '');

          params.push({
            id: Number(id),
            total: val,
          });
        }
      });

      linenStore.updateInventory(params);
    },
  });

  return (
    <>
      {linenStore.isLoading && <SkeletonLoader skeletonShape="table" />}

      {inventory !== undefined && !linenStore.isLoading && (
        <Box>
          {Object.entries(inventory).map(([key, stock]) => {
            return (
              <Accordion
                key={key}
                sx={{paddingInline: 2, backgroundColor: '#FFF'}}
                onChange={handleLocation}
              >
                {/* -------------------------- Title --------------------------- */}
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h6" component="h2" fontWeight="600">
                    {key}
                  </Typography>
                </AccordionSummary>
                {/* -------------------------- Title --------------------------- */}

                {/* -------------------------- Stock --------------------------- */}
                <form noValidate onSubmit={formik.handleSubmit}>
                  {stock.map((obj) => {
                    const {id, linen_name, total} = obj;

                    return (
                      <AccordionDetails key={id}>
                        <LinenStockItem
                          id={id}
                          linenName={linen_name}
                          total={total}
                          formik={formik}
                        />
                      </AccordionDetails>
                    );
                  })}
                  {/* ---------------------- Update Button --------------------- */}
                  <Stack flexDirection="row" justifyContent="end" padding={3} component="div">
                    <LoadingButton
                      loading={linenStore.isSubmitting}
                      variant="contained"
                      type="submit"
                      size="large"
                      color="gray"
                    >
                      <Typography variant="body1">{t('common:update')}</Typography>
                    </LoadingButton>
                  </Stack>
                  {/* ---------------------- Update Button --------------------- */}
                </form>
                {/* -------------------------- Stock --------------------------- */}
              </Accordion>
            );
          })}
        </Box>
      )}

      {inventory === undefined && !linenStore.isLoading && (
        <NoResultsFound message={t('linenInventory:no_inventory_to_show')} />
      )}
    </>
  );
});

const Inventory = observer(() => {
  return (
    <Grid item>
      <InventoryAccordion />
    </Grid>
  );
});

export default Inventory;
