export default {
  defaultLocale: 'en',
  fallbackLocale: 'ja',
  locales: ['en', 'ja'],
};

export const namespaces = [
  'common',
  'vendor',
  'storageDetailsSection',
  'addAmenity',
  'addItemSteps',
  'confirmStorageDetails',
  'createOrder',
  'listOrders',
  'saveOrder',
  'storageLV',
  'linenInventory',
  'orderDetails',
  'prepareLinenOrder',
  'stockCount',
  'items',
  'lockDevices',
  'reservations',
  'guests',
  'login',
  'settings',
];
