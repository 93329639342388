import React, {useEffect, useState} from 'react';
import {toJS} from 'mobx';
import {toast} from 'react-toastify';
import {observer} from 'mobx-react-lite';
import {useNavigate, useParams} from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import * as Yup from 'yup';
import {FormikProps, useFormik} from 'formik';
import PageTitle from '../../components/PageTitle';
import HelpIcon from '@mui/icons-material/Help';
import {useRootStore} from '../../context';
import SelectHotel from '../../components/Navbar/SelectHotel';
import {
  Button,
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  MenuItem,
  Select,
  Stack,
  Typography,
  FormControl,
  InputLabel,
  FormHelperText,
  TextField,
  Grid,
  Chip,
  Tooltip,
  InputAdornment,
  IconButton,
  SxProps,
  Theme,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteIcon from '@mui/icons-material/Delete';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import {useTranslation} from 'react-i18next';
import type {IBrand, ICategory, IUnit, IVendor} from '../../api/common';
import type {ILevelResponse} from '../../api/inventory/product';

interface IFormikInitValues {
  sku: string;
  consumption_tax: string | number;
  name: string;
  brand: string | number;
  category: string | number;
  unit: string | number;
  hotel: Array<number>;
  vendor: string | number;
  price: string;
  reference: string;
  delivery: string | number;
  info: string;
  lv1_storage: number;
  lv1_quantity: number;
  lv1_image: string | null;
  lv2_storage: number;
  lv2_quantity: number;
  lv2_image: string | null;
  lv3_storage: number;
  lv3_quantity: number;
  lv3_image: string | null;
}

const EditItem = observer(() => {
  const {t} = useTranslation();
  const {productStore, inventoryStore, vendorStore} = useRootStore();

  const {itemId} = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (itemId) {
      productStore.edit.getProduct(Number(itemId));
    }
  }, [itemId]);

  useEffect(() => {
    const purchase = toJS(productStore.edit.purchase);
    if (purchase.id > 0) {
      formik.setFieldValue('vendor', purchase.vendor?.id);
    }
  }, [productStore.edit.purchase]);

  useEffect(() => {
    if (inventoryStore.brands.length === 0) {
      inventoryStore.getBrands();
    }
    if (inventoryStore.categories.length === 0) {
      inventoryStore.getCategories();
    }
    if (inventoryStore.storages.length === 0) {
      inventoryStore.getStorages();
    }
    if (inventoryStore.units.length === 0) {
      inventoryStore.getUnits();
    }
  }, [
    inventoryStore.brands,
    inventoryStore.categories,
    inventoryStore.storages,
    inventoryStore.units,
  ]);

  useEffect(() => {
    if (productStore.edit.levels.lv1 !== null) {
      formik.setFieldValue('lv1_storage', productStore.edit.levels.lv1.storage.id);
      formik.setFieldValue('lv1_quantity', productStore.edit.levels.lv1.quantity);
      formik.setFieldValue('lv1_image', productStore.edit.levels.lv1.image);
    }
    if (productStore.edit.levels.lv2 !== null) {
      formik.setFieldValue('lv2_storage', productStore.edit.levels.lv2.storage.id);
      formik.setFieldValue('lv2_quantity', productStore.edit.levels.lv2.quantity);
      formik.setFieldValue('lv2_image', productStore.edit.levels.lv2.image);
    }
    if (productStore.edit.levels.lv3 !== null) {
      formik.setFieldValue('lv3_storage', productStore.edit.levels.lv3.storage.id);
      formik.setFieldValue('lv3_quantity', productStore.edit.levels.lv3.quantity);
      formik.setFieldValue('lv3_image', productStore.edit.levels.lv3.image);
    }
  }, [productStore.edit.levels.lv1, productStore.edit.levels.lv2, productStore.edit.levels.lv3]);

  useEffect(() => {
    if (productStore.edit.success) {
      navigate('/inventory/items', {replace: true});
      productStore.edit.reset();
    }
  }, [productStore.edit.success]);

  const handleBack = () => {
    navigate('/inventory/items');
  };

  const YupSchema = Yup.object().shape(
    {
      category: Yup.number().required(t('addItemSteps:category_required')),
      sku: Yup.string().ensure().trim().required(t('addItemSteps:sku_required')),
      name: Yup.string().ensure().trim().required(t('addItemSteps:product_name_required')),
      unit: Yup.number().required(t('addItemSteps:uom_required')),
      hotel: Yup.array()
        .of(Yup.number().positive().integer())
        .min(1, t('addItemSteps:select_at_least_one_hotel')),
      brand: Yup.number().required(t('addItemSteps:brand_name_required')),
      vendor: Yup.number().positive().required(t('addItemSteps:vendor_required')),
      reference: Yup.string().ensure().trim().required(t('addItemSteps:reference_code_required')),
      price: Yup.number()
        .min(0, t('addItemSteps:price_cannot_be_less_than_0'))
        .required(t('addItemSteps:product_price_required')),
      delivery: Yup.number().integer().min(0).notRequired().default(0),
      info: Yup.string().ensure().trim().notRequired(),
      lv1_storage: Yup.number()
        .positive()
        .integer()
        .required(t('addItemSteps:lv1_storage_required')),
      lv1_quantity: Yup.number()
        .positive()
        .integer()
        .required(t('addItemSteps:lv1_units_required')),
      lv1_image: Yup.mixed().nullable().notRequired(),
      lv2_storage: Yup.number()
        .integer()
        .when('lv2_quantity', {
          is: (val: number) => Number.isInteger(val) && val > 0,
          then: (YupSchema) =>
            YupSchema.positive(t('addItemSteps:lv2_storage_required')).required(
              t('addItemSteps:lv2_storage_required'),
            ),
          otherwise: (YupSchema) => YupSchema.notRequired(),
        }),
      lv2_quantity: Yup.number()
        .integer()
        .when('lv2_storage', {
          is: (val: number) => Number.isInteger(val) && val > 0,
          then: (YupSchema) =>
            YupSchema.positive(t('addItemSteps:lv2_units_required')).required(
              t('addItemSteps:lv2_units_required'),
            ),
          otherwise: (YupSchema) => YupSchema.notRequired(),
        }),
      lv2_image: Yup.mixed().nullable().notRequired(),
      lv3_storage: Yup.number()
        .integer()
        .when('lv3_quantity', {
          is: (val: number) => Number.isInteger(val) && val > 0,
          then: (YupSchema) =>
            YupSchema.positive(t('addItemSteps:lv3_storage_required')).required(
              t('addItemSteps:lv3_storage_required'),
            ),
          otherwise: (YupSchema) => YupSchema.notRequired(),
        }),
      lv3_quantity: Yup.number()
        .integer()
        .when('lv3_storage', {
          is: (val: number) => Number.isInteger(val) && val > 0,
          then: (YupSchema) =>
            YupSchema.positive(t('addItemSteps:lv3_units_required')).required(
              t('addItemSteps:lv3_units_required'),
            ),
          otherwise: (YupSchema) => YupSchema.notRequired(),
        }),
      lv3_image: Yup.mixed().nullable().notRequired(),
    },
    [
      ['lv2_storage', 'lv2_quantity'],
      ['lv3_storage', 'lv3_quantity'],
    ],
  );

  const formik = useFormik({
    initialValues: {
      sku: productStore.edit.amenity.sku,
      consumption_tax: productStore.edit.amenity.consumption_tax || '',
      name: productStore.edit.amenity.name,
      brand: productStore.edit.amenity.brand.id || '',
      category: productStore.edit.amenity.category.id || '',
      unit: productStore.edit.amenity.unit.id || '',
      hotel: productStore.edit.amenity.hotel,
      vendor: productStore.edit.purchase.vendor?.id || '',
      price: productStore.edit.purchase.price || '',
      reference: productStore.edit.purchase.reference,
      delivery: productStore.edit.purchase.delivery || '',
      info: productStore.edit.purchase.info,
      lv1_storage:
        productStore.edit.levels.lv1 === null ? 0 : productStore.edit.levels.lv1.storage.id,
      lv1_quantity:
        productStore.edit.levels.lv1 === null ? 0 : productStore.edit.levels.lv1.quantity,
      lv1_image: productStore.edit.levels.lv1?.image ?? null,
      lv2_storage:
        productStore.edit.levels.lv2 === null ? 0 : productStore.edit.levels.lv2.storage.id,
      lv2_quantity:
        productStore.edit.levels.lv2 === null ? 0 : productStore.edit.levels.lv2.quantity,
      lv2_image: productStore.edit.levels.lv2?.image ?? null,
      lv3_storage:
        productStore.edit.levels.lv3 === null ? 0 : productStore.edit.levels.lv3.storage.id,
      lv3_quantity:
        productStore.edit.levels.lv3 === null ? 0 : productStore.edit.levels.lv3.quantity,
      lv3_image: productStore.edit.levels.lv3?.image ?? null,
    } as IFormikInitValues,
    enableReinitialize: true,
    validationSchema: YupSchema,
    onSubmit: (values) => {
      const brand: IBrand = inventoryStore.brands.find((brand) => brand.id === values.brand)!;
      const category: ICategory = inventoryStore.categories.find(
        (category) => category.id === values.category,
      )!;
      const unit: IUnit = inventoryStore.units.find((unit) => unit.id === values.unit)!;
      const purchaseVendor: IVendor = vendorStore.vendors.find(
        (vendor) => vendor.id === Number(values.vendor),
      )!;

      productStore.edit.setAmenity({
        id: productStore.edit.amenity.id,
        sku: values.sku,
        consumption_tax: Number(values.consumption_tax),
        name: values.name,
        brand: brand,
        category: category,
        unit: unit,
        hotel: values.hotel,
      });
      productStore.edit.setPurchase({
        id: productStore.edit.purchase.id,
        price_currency: 'JPY',
        price: values.price,
        unit_price_currency: 'JPY',
        reference: values.reference,
        delivery: Number(values.delivery) || 0,
        info: values.info,
        amenity: productStore.edit.amenity.id,
        vendor: purchaseVendor,
      });
      const levels: Array<ILevelResponse> = [
        {
          level: 'LV1',
          amenity: productStore.edit.amenity.id,
          storage: {id: values.lv1_storage, name: ''},
          quantity: values.lv1_quantity,
          image: values.lv1_image ?? null,
        },
      ];
      if (values.lv2_storage && values.lv2_quantity) {
        levels.push({
          level: 'LV2',
          amenity: productStore.edit.amenity.id,
          storage: {id: values.lv2_storage, name: ''},
          quantity: values.lv2_quantity,
          image: values.lv2_image ?? null,
        });
      }
      if (values.lv3_storage && values.lv3_quantity) {
        levels.push({
          level: 'LV3',
          amenity: productStore.edit.amenity.id,
          storage: {id: values.lv3_storage, name: ''},
          quantity: values.lv3_quantity,
          image: values.lv3_image ?? null,
        });
      }

      productStore.edit.setLevels(levels);
      productStore.edit.updateProduct();
    },
  });

  const selectStyles = {
    minWidth: '215px',
  };

  return (
    <Container>
      <PageTitle>{t('common:inventory_and_supplies')}</PageTitle>
      <SelectHotel disabled={true} />
      <Card sx={{marginTop: 5}}>
        <CardContent>
          <form noValidate onSubmit={formik.handleSubmit} style={{width: '100%'}}>
            <Stack gap={3} sx={{overflow: 'hidden'}} alignItems="start">
              <Button
                variant="text"
                color="inherit"
                startIcon={<ChevronLeftIcon fontSize="large" />}
                size="large"
                onClick={() => navigate('/inventory/items')}
              >
                {t('common:all_items')}
              </Button>
              <ItemInfoSection formik={formik} selectStyles={selectStyles} />
              <PurchaseInfoSection formik={formik} selectStyles={selectStyles} />
              <StorageDetailsSection
                formik={formik}
                lv="LV1"
                tooltip={t('addItemSteps:number_of_units_per_lv1')}
              />
              <StorageDetailsSection
                formik={formik}
                lv="LV2"
                tooltip={t('addItemSteps:number_of_units_per_lv2')}
              />
              <StorageDetailsSection
                formik={formik}
                lv="LV3"
                tooltip={t('addItemSteps:number_of_units_per_lv3')}
              />
              <Stack flexDirection="row" alignSelf="end" gap={2}>
                <Button variant="contained" color="gray" onClick={handleBack}>
                  {t('common:back')}
                </Button>
                <LoadingButton
                  loading={productStore.edit.isSubmitting}
                  variant="contained"
                  type="submit"
                >
                  {t('common:save')}
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </CardContent>
      </Card>
    </Container>
  );
});

export default EditItem;

interface ISectionTitleProps {
  children: React.ReactNode;
  color?: string;
}

const SectionTitle: React.FC<ISectionTitleProps> = ({color, children}) => {
  return (
    <Stack flexDirection="row" alignItems="center" gap={3} marginBottom={1} width="100%">
      <Typography
        variant="h6"
        color={color || 'black'}
        component="h2"
        fontWeight={600}
        whiteSpace="pre"
      >
        {children}
      </Typography>
      <Divider sx={{width: '100%'}} />
    </Stack>
  );
};

interface IItemInfoSectionProps {
  formik: FormikProps<IFormikInitValues>;
  selectStyles: SxProps<Theme> | undefined;
}

const ItemInfoSection: React.FC<IItemInfoSectionProps> = observer(({formik, selectStyles}) => {
  const {t} = useTranslation();
  const isDesktop = useMediaQuery('(min-width: 1200)');
  const {inventoryStore} = useRootStore();

  return (
    <>
      <SectionTitle>{t('common:item_info')}</SectionTitle>
      {/* --------------------- Product Category ------------------------- */}
      <Stack alignItems="start" gap={3} marginBottom={3} width={{xs: '100%', md: 'auto'}}>
        <FormControl
          fullWidth={!isDesktop}
          required
          error={formik.touched.category && Boolean(formik.errors.category)}
        >
          <InputLabel id="product-category-label">{t('common:product_category')}</InputLabel>
          <Select
            name="category"
            onChange={formik.handleChange}
            value={formik.values.category as any}
            id="product-category-select"
            label={t('common:product_category')}
            labelId="product-category-label"
            sx={selectStyles}
          >
            {inventoryStore.categories.map((obj, index) => {
              return (
                <MenuItem key={index} value={obj.id}>
                  {obj.name}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText error={formik.touched.category && Boolean(formik.errors.category)}>
            {formik.touched.category && formik.errors.category}
          </FormHelperText>
        </FormControl>
        {/* --------------------- Product Category ------------------------- */}
        {/* --------------------------- SKU -------------------------------- */}
        <Stack gap={3} flexDirection={{md: 'row'}}>
          <TextField
            name="sku"
            label={t('common:sku')}
            value={formik.values.sku}
            onChange={formik.handleChange}
            required
            fullWidth
            error={formik.touched.sku && Boolean(formik.errors.sku)}
            helperText={formik.touched.sku && formik.errors.sku}
          />
          {/* --------------------------- SKU ---------------------------- */}
          {/* --------------------------- Name --------------------------- */}
          <TextField
            name="name"
            label={t('common:item_name')}
            value={formik.values.name}
            onChange={formik.handleChange}
            required
            fullWidth
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </Stack>
        {/* --------------------------- Name ------------------------------- */}
        {/* --------------------- Unit of measurement ---------------------- */}
        <FormControl required error={formik.touched.unit && Boolean(formik.errors.unit)}>
          <InputLabel id="product-category-label">{t('common:uom')}</InputLabel>
          <Select
            name="unit"
            onChange={formik.handleChange}
            value={formik.values.unit as any}
            id="product-unit-select"
            label={t('common:uom')}
            labelId="product-unit-label"
            sx={selectStyles}
          >
            {inventoryStore.units.map((obj, index) => (
              <MenuItem key={index} value={obj.id}>
                {obj.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText error={formik.touched.unit && Boolean(formik.errors.unit)}>
            {formik.touched.unit && formik.errors.unit}
          </FormHelperText>
        </FormControl>
        {/* --------------------- Unit of measurement ---------------------- */}
      </Stack>
    </>
  );
});

interface IPurchaseInfoSectionProps {
  formik: FormikProps<IFormikInitValues>;
  selectStyles: SxProps<Theme> | undefined;
}

const PurchaseInfoSection: React.FC<IPurchaseInfoSectionProps> = observer(
  ({formik, selectStyles}) => {
    const {t} = useTranslation();
    const {productStore, inventoryStore, vendorStore, hotelStore} = useRootStore();
    const [hotels, setHotels] = useState([...productStore.edit.amenity.hotel]);

    const getHotelButtonVariant = (id: number) => {
      if (hotels.includes(id)) {
        return 'dark';
      }
      return 'outline-secondary';
    };

    useEffect(() => {
      if (productStore.edit.amenity.hotel.length > 0) {
        const amenity = toJS(productStore.edit.amenity);
        setHotels([...amenity.hotel]);
      }
    }, [productStore.edit.amenity.hotel]);

    const handleAddHotel = (id: number) => {
      const index = hotels.indexOf(id);
      if (index > -1) {
        if (hotels.length === 1) {
          toast.warning(t('addItemSteps:at_least_one_hotel_required'));
        } else {
          hotels.splice(index, 1);
        }
      } else {
        hotels.push(id);
      }
      setHotels([...hotels]);
      formik.setFieldValue('hotel', [...hotels]);
    };

    return (
      <Stack gap={3} alignItems="start">
        <SectionTitle>{t('common:purchase_info')}</SectionTitle>
        <Stack flexDirection={{xs: 'column', md: 'row'}} gap={3} marginBottom={{xs: 3, md: 0}}>
          {/* ----------------------------- Brand ------------------------------ */}
          <FormControl required error={formik.touched.brand && Boolean(formik.errors.brand)}>
            <InputLabel id="brand-select-label">{t('common:brand')}</InputLabel>
            <Select
              name="brand"
              value={formik.values.brand as any}
              onChange={formik.handleChange}
              id="brand-select"
              labelId="brand-select-label"
              label={t('common:brand')}
              sx={selectStyles}
            >
              {inventoryStore.brands.map((obj, index) => (
                <MenuItem key={index} value={obj.id}>
                  {obj.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error={formik.touched.brand && Boolean(formik.errors.brand)}>
              {formik.touched.brand && formik.errors.brand}
            </FormHelperText>
          </FormControl>
          {/* ---------------------------- Brand ----------------------------- */}
          {/* ---------------------------- Vendor ---------------------------- */}
          <FormControl required error={formik.touched.vendor && Boolean(formik.errors.vendor)}>
            <InputLabel id="vendor-select-label">{t('common:vendor')}</InputLabel>
            <Select
              name="vendor"
              value={formik.values.vendor as any}
              onChange={formik.handleChange}
              id="vendor-select"
              labelId="vendor-select-label"
              label={t('common:vendor')}
              sx={selectStyles}
            >
              {vendorStore.vendors.map((obj) => (
                <MenuItem key={obj.id} value={obj.id}>
                  {obj.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error={formik.touched.vendor && Boolean(formik.errors.vendor)}>
              {formik.touched.vendor && formik.errors.vendor}
            </FormHelperText>
          </FormControl>
          {/* ---------------------------- Vendor ---------------------------- */}
          {/* -------------------------- Vendor Ref -------------------------- */}
          <TextField
            name="reference"
            required
            label={t('common:vendor_ref')}
            onChange={formik.handleChange}
            value={formik.values.reference}
            error={formik.touched.reference && Boolean(formik.errors.reference)}
            helperText={formik.touched.reference && formik.errors.reference}
          />
          {/* -------------------------- Vendor Ref -------------------------- */}
        </Stack>
        {/* ---------------------------- Item URL ---------------------------- */}
        <TextField
          name="info"
          label={t('common:item_url_comment')}
          onChange={formik.handleChange}
          value={formik.values.info}
          error={formik.touched.info && Boolean(formik.errors.info)}
          helperText={formik.touched.info && formik.errors.info}
        />
        {/* ---------------------------- Item URL ---------------------------- */}
        <Stack flexDirection={{xs: 'column', md: 'row'}} gap={3}>
          {/* ------------------------- Price p/ LV1 ------------------------- */}
          <TextField
            name="price"
            type="number"
            label={t('common:price_per_lv1')}
            onChange={formik.handleChange}
            value={formik.values.price}
            error={formik.touched.price && Boolean(formik.errors.price)}
            helperText={formik.touched.price && formik.errors.price}
            InputProps={{
              endAdornment: <InputAdornment position="end">{'\u00A5'}</InputAdornment>,
            }}
            inputProps={{min: 0}}
          />
          {/* ------------------------- Price p/ LV1 ------------------------- */}
          {/* ----------------------------- Tax ------------------------------ */}
          <FormControl>
            <InputLabel id="tax-select-label">{t('common:consumption_tax')}</InputLabel>
            <Select
              name="consumption_tax"
              value={formik.values.consumption_tax as any}
              onChange={formik.handleChange}
              id="consumption_tax-select"
              labelId="consumption_tax-select-label"
              label={t('common:consumption_tax')}
              sx={selectStyles}
              defaultValue={10}
            >
              <MenuItem value={8}>8%</MenuItem>
              <MenuItem value={10}>10%</MenuItem>
            </Select>
          </FormControl>
          {/* ---------------------------- Brand ----------------------------- */}
          {/* ------------------------- Delivery Est ------------------------- */}
          <TextField
            name="delivery"
            type="number"
            label={t('common:delivery_estimate')}
            onChange={formik.handleChange}
            value={formik.values.delivery}
            error={formik.touched.delivery && Boolean(formik.errors.delivery)}
            helperText={formik.touched.delivery && formik.errors.delivery}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">{t('common:days')}</InputAdornment>,
            }}
            inputProps={{min: 0}}
          />
        </Stack>
        {/* -------------------------- Delivery Est -------------------------- */}
        {/* ----------------------------- Hotels ----------------------------- */}
        <Stack flexDirection="row" alignItems="center" gap={5}>
          <Typography variant="body1" component="label" textTransform="uppercase" color="GrayText">
            {t('common:currently_in')}
          </Typography>
          <Stack flexDirection="row" gap={2}>
            {hotelStore.hotels.map((obj, index) => (
              <Chip
                key={index}
                label={obj.short_name}
                clickable
                onClick={() => handleAddHotel(obj.id)}
                variant={getHotelButtonVariant(obj.id) === 'dark' ? 'filled' : 'outlined'}
                color="gray"
              />
            ))}
          </Stack>
          <TextField
            name="hotel"
            onChange={formik.handleChange}
            value={formik.values.hotel}
            error={formik.touched.hotel && Boolean(formik.errors.hotel)}
            type="hidden"
            helperText={formik.touched.hotel && formik.errors.hotel}
            sx={{display: 'none'}}
          />
        </Stack>
        {/* ----------------------------- Hotels ----------------------------- */}
      </Stack>
    );
  },
);

interface IStorageDetailsSectionProps {
  formik: FormikProps<IFormikInitValues>;
  lv: string;
  tooltip: string;
}

const StorageDetailsSection: React.FC<IStorageDetailsSectionProps> = observer(
  ({formik, lv, tooltip}) => {
    const {t} = useTranslation();
    const {productStore, inventoryStore} = useRootStore();
    const [open, setOpen] = useState(false);
    let titleColor;
    switch (lv) {
      case 'LV1':
        titleColor = 'primary';
        break;
      case 'LV2':
        titleColor = 'warning.main';
        break;
      case 'LV3':
        titleColor = 'success.dark';
        break;
      default:
        titleColor = '#000';
    }

    const handleImageChange = (event: any, field: string) => {
      if (event.currentTarget.files.length > 0) {
        const reader = new FileReader();
        reader.readAsDataURL(event.currentTarget.files[0]);
        reader.onloadend = function () {
          formik.setFieldValue(field, reader.result);
        };
      } else {
        formik.setFieldValue(field, '');
      }
      formik.handleChange(event);
    };

    const storageFieldName = `${lv.toLowerCase()}_storage` as keyof typeof formik.values;
    const storageFieldValue = formik.values[storageFieldName];

    const quantityFieldName = `${lv.toLowerCase()}_quantity` as keyof typeof formik.values;
    const quantityFieldValue = formik.values[quantityFieldName];

    const imageFieldName = `${lv.toLowerCase()}_image` as keyof typeof formik.values;

    return (
      <>
        <SectionTitle color={titleColor}>
          {t('addItemSteps:storage_level_details', {lv: lv})}
        </SectionTitle>
        <Grid container gap={3}>
          {/* -------------------------- LV Storage -------------------------- */}
          <Grid item xs={12} md={4}>
            <FormControl
              required
              fullWidth
              error={formik.touched[storageFieldName] && Boolean(formik.errors[storageFieldName])}
            >
              <InputLabel id={`${lv}-select-label`}>
                {t('addItemSteps:storage_box_type', {
                  lv: lv,
                  typeOfBox: lv === 'LV1' ? ` ${t('addItemSteps:normally_box')}` : '',
                })}
              </InputLabel>
              <Select
                name={storageFieldName}
                value={formik.values[storageFieldName] as any}
                label={t('addItemSteps:storage_box_type', {
                  lv: lv,
                  typeOfBox: lv === 'LV1' ? ` ${t('addItemSteps:normally_box')}` : '',
                })}
                labelId={`${lv}-select-label`}
                id={`${lv}-select`}
                onChange={formik.handleChange}
              >
                {inventoryStore.storages.map((obj, index) => (
                  <MenuItem key={index} value={obj.id}>
                    {obj.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText
                error={formik.touched[storageFieldName] && Boolean(formik.errors[storageFieldName])}
              >
                {formik.touched[storageFieldName] && formik.errors[storageFieldName]}
              </FormHelperText>
            </FormControl>
          </Grid>
          {/* -------------------------- LV Storage -------------------------- */}
          {/* -------------------------- Units p/ LV ------------------------- */}
          <Grid item xs={12} md={6}>
            <TextField
              name={quantityFieldName}
              label={t('addItemSteps:units_per_level', {lv: lv})}
              value={formik.values[quantityFieldName]}
              onChange={formik.handleChange}
              type="number"
              required
              helperText={formik.touched[quantityFieldName] && formik.errors[quantityFieldName]}
              error={formik.touched[quantityFieldName] && Boolean(formik.errors[quantityFieldName])}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {productStore.edit.amenity.unit.name}
                  </InputAdornment>
                ),
              }}
              inputProps={{min: 0}}
            />
            <Tooltip title={tooltip} placement="top">
              <HelpIcon />
            </Tooltip>
          </Grid>
          {/* -------------------------- Units p/ LV ------------------------- */}
          <Grid item>
            {typeof formik.values[imageFieldName] === 'string' &&
            formik.values[imageFieldName] !== '' ? (
              <Box position="relative">
                <IconButton
                  sx={{
                    position: 'absolute',
                    top: '0.5em',
                    right: '0.5em',
                    bgcolor: '#535050',
                    '&:hover': {bgcolor: '#adaaaa', '*': {color: 'error.main'}},
                  }}
                  onClick={() => setOpen(true)}
                >
                  <DeleteIcon sx={{color: '#FFF', transition: 'all 0.35s'}} />
                </IconButton>
                <img
                  src={formik.values[imageFieldName] as string}
                  alt={t('common:product_image')}
                />
              </Box>
            ) : (
              <FormControl
                error={formik.touched[imageFieldName] && Boolean(formik.errors[imageFieldName])}
              >
                <Typography variant="body2" color="GrayText" marginBottom={1}>
                  {t('common:product_image_optional')}
                </Typography>
                <input
                  type="file"
                  name={imageFieldName}
                  onChange={(event) => handleImageChange(event, imageFieldName)}
                  value={formik.values[imageFieldName] as string}
                  disabled={!storageFieldValue || !quantityFieldValue}
                />
                <FormHelperText
                  error={formik.touched[imageFieldName] && Boolean(formik.errors[imageFieldName])}
                >
                  {formik.touched[imageFieldName] && formik.errors[imageFieldName]}
                </FormHelperText>
              </FormControl>
            )}
          </Grid>
        </Grid>
        <Dialog
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {t('addItemSteps:delete_image_question')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t('addItemSteps:press_save_after_delete')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant={'outlined'} color={'gray'} onClick={() => setOpen(false)}>
              {t('common:cancel')}
            </Button>
            <Button
              variant={'outlined'}
              color={'error'}
              onClick={() => {
                setOpen(false);
                formik.setFieldValue(imageFieldName, '');
              }}
              autoFocus
            >
              {t('common:delete')}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  },
);
