import React from 'react';
import {Button, Stack, Typography, Box} from '@mui/material';
import Modal from '@mui/material/Modal';
import {observer} from 'mobx-react-lite';
import '../../styles/main.css';
import {useTranslation} from 'react-i18next';

interface ISendCodeModalProps {
  onSendCode: () => void;
  isOpen: boolean;
  onClose: () => void;
}

const SendCodeModal: React.FC<ISendCodeModalProps> = observer(({onSendCode, isOpen, onClose}) => {
  const {t} = useTranslation();

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.default',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <Modal
        open={isOpen}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} width={{xs: '70%', sm: 'auto'}}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {t('reservations:are_you_sure_send_code')}
          </Typography>
          <Typography id="modal-modal-description" sx={{mt: 2}}>
            {t('reservations:remember_complete_prevous_registration_steps')}
          </Typography>
          <Stack flexDirection="row" gap={1} justifyContent="flex-end" marginTop={3}>
            <Button variant="contained" color="primary" type="button" onClick={onSendCode}>
              {t('common:yes')}
            </Button>
            <Button variant="contained" color="error" type="button" onClick={onClose}>
              {t('common:no')}
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
});

export default SendCodeModal;
