import {Divider, Stack, Typography} from '@mui/material';
import React from 'react';

interface ISectionTitleProps {
  color?: string;
  children: React.ReactNode;
}

const SectionTitle: React.FC<ISectionTitleProps> = ({color, children}) => {
  return (
    <Stack flexDirection="row" alignItems="center" gap={3} marginBottom={1} width="100%">
      <Typography
        variant="h6"
        color={color || 'black'}
        component="h2"
        fontWeight={600}
        whiteSpace="pre"
      >
        {children}
      </Typography>
      <Divider sx={{width: '100%'}} />
    </Stack>
  );
};

export default SectionTitle;
