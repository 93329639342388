import React, {useState, useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {useLocation, useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {useTranslation} from 'react-i18next';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Container,
  Stack,
  Card,
  CardMedia,
  CardContent,
  Typography,
  TextField,
  InputAdornment,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {useRootStore} from '../context';

const Login = observer(() => {
  const {t} = useTranslation();
  const {accountStore} = useRootStore();

  const location = useLocation();
  const navigate = useNavigate();
  const [timeoutID, setTimeoutID] = useState<NodeJS.Timeout>();
  const [togglePassword, setTogglePassword] = useState({
    type: 'password',
    icon: <VisibilityIcon />,
  });

  useEffect(() => {
    formik.setSubmitting(accountStore.inProgress);
  }, [accountStore.inProgress]);

  useEffect(() => {
    if (accountStore.error) {
      setTimeoutID(
        setTimeout(() => {
          accountStore.setError(undefined);
        }, 5000),
      );
    }
  }, [accountStore.error]);

  useEffect(() => {
    if (accountStore.isAuthenticated) {
      let path = location.pathname;

      if (path === undefined || path === '/') {
        path = '/linens-towels';
      }
      navigate(path, {replace: true});
    }
  }, [accountStore.isAuthenticated]);

  const LoginSchema = Yup.object().shape({
    username: Yup.string().required(t('login:username_email_required')),
    password: Yup.string().required(t('login:password_required')),
  });

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      if (timeoutID !== undefined) {
        clearTimeout(timeoutID);
        setTimeoutID(undefined);
      }
      accountStore.setError(undefined);
      accountStore.login(values.username, values.password);
    },
  });

  const handleViewPassword = () => {
    if (togglePassword.type === 'password') {
      setTogglePassword({
        type: 'text',
        icon: <VisibilityOffIcon />,
      });
    } else {
      setTogglePassword({
        type: 'password',
        icon: <VisibilityIcon />,
      });
    }
  };

  const handleRememberMe = (event: React.ChangeEvent<HTMLInputElement>) => {
    accountStore.setRememberMe(event.target.checked);
  };

  return (
    <Container maxWidth="xs">
      <Stack minHeight="100vh" justifyContent="center" alignItems="center">
        <Card>
          <CardMedia component="img" image="images/wayfarer-logo.png" />
          <CardContent>
            <Typography
              variant="h5"
              component="h1"
              fontWeight={600}
              marginBottom={5}
              textAlign="center"
            >
              {t('login:log_in_to_ops_dashboard')}
            </Typography>
            <form onSubmit={formik.handleSubmit}>
              <Stack gap={2}>
                {accountStore.error && (
                  <Typography variant="body1" component="p" color="error">
                    {accountStore.error}
                  </Typography>
                )}
                <TextField
                  label={t('login:user_id')}
                  name="username"
                  required
                  onChange={formik.handleChange}
                  onClick={() => accountStore.setError(undefined)}
                  value={formik.values.username}
                  fullWidth
                  error={formik.touched.username && Boolean(formik.errors.username)}
                  placeholder={t('login:id')}
                  helperText={formik.touched.username && formik.errors.username}
                />
                <TextField
                  label={t('login:password')}
                  name="password"
                  required
                  onChange={formik.handleChange}
                  onClick={() => accountStore.setError(undefined)}
                  value={formik.values.password}
                  fullWidth
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  type={togglePassword.type}
                  placeholder="*******"
                  helperText={formik.touched.password && formik.errors.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={handleViewPassword}
                        sx={{cursor: 'pointer'}}
                      >
                        {togglePassword.icon}
                      </InputAdornment>
                    ),
                  }}
                />
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox defaultChecked onChange={handleRememberMe} />}
                    label={t('login:remember_me')}
                  />
                </FormGroup>
                <LoadingButton
                  loading={accountStore.inProgress}
                  disabled={accountStore.inProgress}
                  variant="contained"
                  size="large"
                  type="submit"
                >
                  {t('login:log_in')}
                </LoadingButton>
              </Stack>
            </form>
          </CardContent>
        </Card>
      </Stack>
    </Container>
  );
});

export default Login;
