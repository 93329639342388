import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import request from 'superagent';
import {toast} from 'react-toastify';
import {
  Card,
  Grid,
  Stack,
  Modal,
  Paper,
  Button,
  Divider,
  Container,
  Typography,
  CardContent,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import {saveAs} from 'file-saver';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import DeleteIcon from '@mui/icons-material/Delete';
import Config from '../../config';
import Common from '../../api/common';
import {useRootStore} from '../../context';
import PageTitle from '../../components/PageTitle';
import Header from '../../components/table/Header';
import CardRowItem from '../../components/table/CardRowItem';
import SelectHotel from '../../components/Navbar/SelectHotel';
import DatePicker from '../../components/DatePicker';
import {DateTime} from 'luxon';
import {LoadingButton} from '@mui/lab';
import {useTranslation} from 'react-i18next';
import type {IGetStockHistoryResult} from '../../api/inventory/stock';
import NoResultsFound from '../../components/general/NoResultsFound';
import SkeletonLoader from '../../components/general/SkeletonLoader';
import ListPagination from '../../components/ListPagination';

const PreviousStockCounts = observer(() => {
  const {t} = useTranslation();
  const {stockCountStore, hotelStore} = useRootStore();

  useEffect(() => {
    if (hotelStore.currentHotel !== undefined) {
      stockCountStore.getPreviousStockCounts();
    }
  }, [hotelStore.currentHotel, stockCountStore.dateTo, stockCountStore.dateFrom]);

  useEffect(() => {
    if (stockCountStore.previousStockCounts.length > 0) {
      stockCountStore.orderStockCounts();
    }
  }, [stockCountStore.order]);

  useEffect(() => {
    if (stockCountStore.exportBlob !== undefined) {
      const todayDate = DateTime.now().toFormat('yyyy-MM-dd');
      const filename = `${hotelStore.currentHotel?.short_name} previous-stock-counts-${todayDate}.xlsx`;
      saveAs(stockCountStore.exportBlob, filename);
      stockCountStore.setExportBlob(undefined);
    }
  }, [stockCountStore.exportBlob]);

  const handleClearDates = (event: React.SyntheticEvent) => {
    event.preventDefault();
    stockCountStore.setDateFrom(null);
    stockCountStore.setDateTo(null);
  };

  const handleExport = (event: React.SyntheticEvent) => {
    event.preventDefault();
    stockCountStore.exportPreviousStockCounts();
  };

  const handleSetAscendingOrder = (event: React.SyntheticEvent) => {
    event.preventDefault();
    stockCountStore.setOrder('asc');
    toast.success(t('common:updated_list_ascending_order'));
  };

  const handleSetDescendingOrder = (event: React.SyntheticEvent) => {
    event.preventDefault();
    stockCountStore.setOrder('desc');
    toast.success(t('common:updated_list_descending_order'));
  };

  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <PageTitle>{t('stockCount:previous_stock_counts')}</PageTitle>
        </Grid>
        <Grid item xs={12} md={4}>
          <SelectHotel />
        </Grid>
      </Grid>
      <Card sx={{marginY: 5}}>
        <CardContent>
          <Stack gap={3} sx={{overflow: 'hidden'}} alignItems="start" marginY={2}>
            <Link to={'/inventory/stock'} style={{textDecoration: 'none'}}>
              <Button
                variant="text"
                color="inherit"
                startIcon={<ChevronLeftIcon fontSize="large" />}
                size="large"
              >
                {t('stockCount:back_to_stock')}
              </Button>
            </Link>
          </Stack>

          {stockCountStore.isLoading && <SkeletonLoader skeletonShape="table" />}
          {stockCountStore.count > 0 && !stockCountStore.isLoading && (
            <>
              <Grid
                container
                spacing={2}
                justifyContent={{xs: 'center'}}
                direction={{xs: 'row'}}
                alignItems="center"
              >
                <Grid item xs={12} lg={8}>
                  <Stack
                    flexDirection={{xs: 'column', md: 'row'}}
                    alignItems={{xs: 'center'}}
                    justifyContent="center"
                    spacing={1}
                    gap={1}
                  >
                    <Typography textAlign={'center'}>{t('common:filter_between_dates')}</Typography>
                    <DatePicker
                      label={t('common:date_from')}
                      value={stockCountStore.dateFrom}
                      onChange={(date) => {
                        stockCountStore.setDateFrom(date);
                      }}
                      fullWidth={false}
                      disableFuture
                    />
                    <DatePicker
                      label={t('common:date_to')}
                      value={stockCountStore.dateTo}
                      onChange={(date) => {
                        stockCountStore.setDateTo(date);
                      }}
                      fullWidth={false}
                      margin={{marginLeft: 2}}
                      minDate={stockCountStore.dateFrom}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Stack
                    flexDirection={{xs: 'row', lg: 'row-reverse'}}
                    alignItems={{xs: 'center'}}
                    justifyContent={{
                      xs: 'space-between',
                      lg: 'space-evenly',
                    }}
                  >
                    <Stack
                      flexDirection={'column'}
                      alignItems={{xs: 'center'}}
                      justifyContent={{xs: 'flex-start'}}
                    >
                      <Typography>{t('common:date_sort')}</Typography>
                      <Stack flexDirection={'row'}>
                        <Tooltip title={t('common:descending_order')}>
                          <IconButton
                            aria-label={t('common:descending_order')}
                            color="primary"
                            onClick={handleSetDescendingOrder}
                          >
                            <ArrowDownwardIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={t('common:ascending_order')}>
                          <IconButton
                            aria-label={t('common:ascending_order')}
                            color="primary"
                            onClick={handleSetAscendingOrder}
                          >
                            <ArrowUpwardIcon />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </Stack>
                    <Stack
                      flexDirection={'row'}
                      alignItems={{xs: 'center'}}
                      justifyContent={{xs: 'flex-end'}}
                      spacing={1}
                    >
                      <LoadingButton
                        sx={{marginLeft: 1}}
                        onClick={handleExport}
                        variant="contained"
                        loading={stockCountStore.isExporting}
                        disabled={stockCountStore.count === 0}
                      >
                        {t('common:export')}
                      </LoadingButton>
                      <Button sx={{marginLeft: 1}} onClick={handleClearDates}>
                        {t('common:clear_dates')}
                      </Button>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
              <Grid container display={{xs: 'none', lg: 'flex'}} mt={3}>
                <Header lg={2} justifyContent="start">
                  {t('common:date')}
                </Header>
                <Header lg={2} justifyContent={'start'}>
                  {t('common:author')}
                </Header>
                <Header lg={8} justifyContent={'end'}>
                  {t('common:actions')}
                </Header>
              </Grid>
              <Grid container gap={{xs: 5, lg: 0}}>
                {stockCountStore.previousStockCounts.length > 0 &&
                  stockCountStore.previousStockCounts.map((item, index) => {
                    const info = [
                      {
                        label: t('common:date'),
                        value: item.ctime,
                        lg: 2,
                        alignItems: 'start',
                      },
                      {
                        label: t('common:author'),
                        value: item.creator,
                        lg: 2,
                        alignItems: 'start',
                      },
                      {
                        label: t('common:actions'),
                        value: <ActionButtons obj={item} />,
                        lg: 8,
                        alignItems: 'end',
                      },
                    ];
                    return <CardRowItem key={index} item={info} />;
                  })}
              </Grid>
            </>
          )}

          {stockCountStore.count > 0 && (
            <Stack
              display="flex"
              flexDirection={'row'}
              width="100%"
              justifyContent={'center'}
              marginTop={3}
            >
              <ListPagination
                getter={stockCountStore.getPreviousStockCounts.bind(stockCountStore)}
                store={stockCountStore}
                watchers={[stockCountStore.dateFrom, stockCountStore.dateTo]}
              />
            </Stack>
          )}

          {stockCountStore.count === 0 && !stockCountStore.isLoading && (
            <NoResultsFound message={t('stockCount:no_stock_counts_to_show')} />
          )}
        </CardContent>
      </Card>
    </Container>
  );
});

export default PreviousStockCounts;

interface IActionButtonsProps {
  obj: IGetStockHistoryResult;
}

const ActionButtons: React.FC<IActionButtonsProps> = observer(({obj}) => {
  const {t} = useTranslation();
  const {hotelStore, accountStore, stockCountStore} = useRootStore();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpen = () => setIsModalOpen(true);
  const handleClose = () => setIsModalOpen(false);

  const handleDelete = async () => {
    const token = accountStore.user.token;
    const currentHotelId = hotelStore.currentHotel?.id;

    if (token && currentHotelId) {
      const url = `${Config.API_URL}/inventory/stock/${obj.ctime}/${currentHotelId}/`;

      setIsModalOpen(false);

      try {
        const response = await request
          .delete(url)
          .auth(token, {type: 'bearer'})
          .accept(Common.MEDIA_TYPE)
          .send();

        if (response.ok) {
          stockCountStore.getPreviousStockCounts();

          toast.success(t('stockCount:stock_count_deleted'));
        }
      } catch (error: any) {
        toast.error(error.message);
      }
    }
  };

  const modalStyles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <Stack flexDirection="row">
        <Link to={`/inventory/edit-count/${obj.ctime}`} style={{textDecoration: 'none'}}>
          <Tooltip title={t('common:see_details')}>
            <IconButton aria-label={t('common:see_details')} color="primary">
              <FormatListBulletedIcon />
            </IconButton>
          </Tooltip>
        </Link>
        <Tooltip title={t('common:delete')}>
          <IconButton aria-label="delete" color="error" onClick={handleOpen}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Stack>
      <Modal open={isModalOpen} onClose={handleClose}>
        <Paper sx={modalStyles}>
          <Typography variant="h4" component="h3" fontWeight={600} mb={1}>
            {t('stockCount:delete_stock_count_date', {stockCountDate: obj.ctime})}
          </Typography>
          <Divider />
          <Typography variant="body1" fontWeight={600} gutterBottom color="GrayText" mt={2}>
            {t('stockCount:cannot_be_undone')}
          </Typography>
          <Divider />
          <Stack flexDirection="row" mt={2} justifyContent="end" gap={2}>
            <Button variant="outlined" onClick={handleClose} color="gray">
              {t('common:close')}
            </Button>
            <Button variant="contained" onClick={handleDelete} color="error">
              {t('common:delete')}
            </Button>
          </Stack>
        </Paper>
      </Modal>
    </>
  );
});
