import * as punycode from 'punycode';

class EmailValidator {
  private userRegex: RegExp;
  private domainRegex: RegExp;

  constructor() {
    // dot-atom
    this.userRegex = /^[-!#$%&'*+/=?^_`{}|~0-9A-Z]+(\.[-!#$%&'*+/=?^_`{}|~0-9A-Z]+)*$/i;

    // max length for domain name labels is 63 characters per RFC 1034
    this.domainRegex = /^((?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\.)+)(?:[A-Z0-9-]{2,63}(?!-))$/i;
  }

  validateEmail(email: string): boolean {
    if (!email || !email.includes('@')) {
      return false;
    }

    const [userPart, domainPart] = email.split('@');

    if (!this.userRegex.test(userPart)) {
      return false;
    }

    if (!this.domainRegex.test(domainPart)) {
      // Try for possible IDN domain-part
      try {
        const punycodeDomainPart = punycode.toASCII(domainPart);
        if (this.domainRegex.test(punycodeDomainPart)) {
          return true;
        }
      } catch (error) {
        // Ignore punycode conversion error
      }

      return false;
    }

    return true;
  }
}

export default function validateEmail(email: string): boolean {
  const validator = new EmailValidator();
  return validator.validateEmail(email);
}
