import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {useLocation, useNavigate} from 'react-router-dom';

import {useRootStore} from '../../context';
import AddItemStep1 from '../../components/inventory/AddItemStep1';
import AddItemStep2 from '../../components/inventory/AddItemStep2';
import AddItemStep3 from '../../components/inventory/AddItemStep3';
import {
  ADD_ITEM_STEP_1,
  ADD_ITEM_STEP_2,
  ADD_ITEM_STEP_3,
} from '../../stores/inventory/product/product';

const AddItem = observer(() => {
  const {productStore} = useRootStore();
  const location = useLocation();
  const navigate = useNavigate();
  const [step, setStep] = useState(ADD_ITEM_STEP_1);

  useEffect(() => {
    if (location.hash.length === 0) {
      navigate(`/inventory/add-item#${productStore.currentStep()}`, {replace: true});
    }
    if (location.hash.startsWith('#')) {
      const s = location.hash.slice(1);
      if (productStore.enableStep(s)) {
        setStep(s);
      } else {
        navigate(`/inventory/add-item#${productStore.currentStep()}`, {replace: true});
      }
    }
  }, [location.hash]);

  if (step === ADD_ITEM_STEP_1) {
    return <AddItemStep1 />;
  }
  if (step === ADD_ITEM_STEP_2) {
    return <AddItemStep2 />;
  }
  if (step === ADD_ITEM_STEP_3) {
    return <AddItemStep3 />;
  }

  return <></>;
});

export default AddItem;
