import React from 'react';
import {observer} from 'mobx-react-lite';
import {Box, Card, CardContent, Container, Stack, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';

const NotFound = observer(() => {
  const {t} = useTranslation();

  return (
    <Container maxWidth="sm">
      <Stack minHeight="100vh" justifyContent="center" alignItems="center">
        <Card>
          <CardContent sx={{textAlign: 'center'}}>
            <Box overflow="hidden" borderRadius={2} mb={3}>
              <img src="/images/not-found.jpg" />
            </Box>
            <Typography variant="h4" component="span" fontWeight={600}>
              {t('common:not_found')}
            </Typography>
            <Typography variant="h6" component="h1">
              {t('common:sorry_not_found')}
            </Typography>
          </CardContent>
        </Card>
      </Stack>
    </Container>
  );
});

export default NotFound;
