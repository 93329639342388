import {makeObservable, action, observable} from 'mobx';
import {StatusCodes} from 'http-status-codes';
import Api, {IGetStockHistoryResponse, IGetStockHistoryResult} from '../../api/inventory/stock';
import {DateTime} from 'luxon';
import {toJS} from 'mobx';
import {toast} from 'react-toastify';
import i18n from '../../i18n';
import type RootStore from '../rootStore';
import {handleUnauthorizedResponse} from '../../libs/utils';

export type FilterDate = string | null;

interface IStockCount {
  rootStore: RootStore;
  previousStockCounts: Array<IGetStockHistoryResult>;
  dateFrom: FilterDate;
  dateTo: FilterDate;
  isLoading: boolean;
  isExporting: boolean;
  next: string | null;
  previous: string | null;
  count: number;
  order: 'asc' | 'desc';
  exportBlob: any | undefined;

  setPreviousStockCounts: (previousStockCounts: Array<IGetStockHistoryResult>) => void;
  setDateFrom: (dateFrom: string | null | Date) => void;
  setDateTo: (dateTo: string | null | Date) => void;
  getPreviousStockCounts: (nextPreviousUrl?: string | null) => Promise<void>;
  setNext: (next: string | null) => void;
  setPrevious: (previous: string | null) => void;
  setCount: (count: number) => void;
  setOrder: (orderDirection: 'asc' | 'desc') => void;
  setIsLoading: (isLoading: boolean) => void;
  orderStockCounts: () => void;
  exportPreviousStockCounts: () => Promise<void>;
}

class StockCount implements IStockCount {
  rootStore;
  previousStockCounts: Array<IGetStockHistoryResult> = [];
  dateFrom: FilterDate = null;
  dateTo: FilterDate = null;
  isLoading = false;
  isExporting = false;
  next: string | null = null;
  previous: string | null = null;
  count = 0;
  order: 'asc' | 'desc' = 'desc';

  // TODO CHECK IF THIS FILE TYPE WORKS OK
  exportBlob: any | undefined = undefined;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      previousStockCounts: observable,
      dateFrom: observable,
      dateTo: observable,
      isLoading: observable,
      isExporting: observable,
      next: observable,
      previous: observable,
      count: observable,
      order: observable,
      exportBlob: observable,
      setPreviousStockCounts: action,
      setDateFrom: action,
      setDateTo: action,
      getPreviousStockCounts: action,
      setNext: action,
      setPrevious: action,
      setCount: action,
      setOrder: action,
      setIsLoading: action,
      orderStockCounts: action,
      exportPreviousStockCounts: action,
    });
  }

  setPreviousStockCounts(previousStockCounts: Array<IGetStockHistoryResult>) {
    this.previousStockCounts = previousStockCounts;
  }

  setDateFrom(dateFrom: string | null | Date) {
    const date =
      dateFrom instanceof Date
        ? DateTime.fromJSDate(dateFrom).toFormat('yyyy-MM-dd 06:00:00')
        : dateFrom;

    this.dateFrom = date;

    const message =
      dateFrom === null ? i18n.t('common:date_from_cleared') : i18n.t('common:date_from_updated');

    toast.success(i18n.t(message) as string);
  }

  setDateTo(dateTo: string | null | Date) {
    const date =
      dateTo instanceof Date ? DateTime.fromJSDate(dateTo).toFormat('yyyy-MM-dd 06:00:00') : dateTo;

    this.dateTo = date;

    const message =
      dateTo === null ? i18n.t('common:date_from_cleared') : i18n.t('common:date_from_updated');

    toast.success(i18n.t(message) as string);
  }

  async getPreviousStockCounts(nextPreviousUrl: string | null = null) {
    const token = this.rootStore.accountStore.user.token;
    const currentHotel = this.rootStore.hotelStore.currentHotel?.id;

    let formattedDateFrom = null;
    let formattedDateTo = null;

    if (this.dateFrom !== null) {
      formattedDateFrom = DateTime.fromFormat(this.dateFrom, 'yyyy-MM-dd 06:00:00').toFormat(
        'yyyy-MM-dd',
      );
    }

    if (this.dateTo !== null) {
      formattedDateTo = DateTime.fromFormat(this.dateTo, 'yyyy-MM-dd 06:00:00').toFormat(
        'yyyy-MM-dd',
      );
    }

    if (token && currentHotel) {
      try {
        this.setIsLoading(true);

        const response = await Api.getStockHistory(
          token,
          currentHotel,
          formattedDateFrom,
          formattedDateTo,
          nextPreviousUrl,
        );

        if (response.ok) {
          const data: IGetStockHistoryResponse = await response.body;

          this.setPreviousStockCounts(data.results);
          this.setCount(data.count);
          this.setNext(data.next);
          this.setPrevious(data.previous);
          this.orderStockCounts();
        }
      } catch (error: any) {
        toast.error(error.message);
        if (error.status === StatusCodes.UNAUTHORIZED) {
          handleUnauthorizedResponse();
          return;
        }
      } finally {
        this.setIsLoading(false);
      }
    }
  }

  async exportPreviousStockCounts() {
    const token = this.rootStore.accountStore.user.token;
    const currentHotel = this.rootStore.hotelStore.currentHotel?.id;

    let formattedDateTo = null;
    let formattedDateFrom = null;

    if (this.dateTo !== null) {
      formattedDateTo = DateTime.fromFormat(this.dateTo, 'yyyy-MM-dd 06:00:00').toFormat(
        'yyyy-MM-dd',
      );
    }

    if (this.dateFrom !== null) {
      formattedDateFrom = DateTime.fromFormat(this.dateFrom, 'yyyy-MM-dd 06:00:00').toFormat(
        'yyyy-MM-dd',
      );
    }

    if (token && currentHotel) {
      try {
        this.setIsExporting(true);

        const response = await Api.exportStockHistory(
          token,
          currentHotel,
          formattedDateFrom,
          formattedDateTo,
        );

        if (response.ok) {
          const data = await response.body;
          this.setExportBlob(data);
        }
      } catch (error: any) {
        toast.error(error.message);

        if (error.status === StatusCodes.UNAUTHORIZED) {
          handleUnauthorizedResponse();
          return;
        }
      } finally {
        this.setIsExporting(false);
      }
    }
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setIsExporting(isExporting: boolean) {
    this.isExporting = isExporting;
  }

  setNext(next: string | null) {
    this.next = next;
  }

  setPrevious(previous: string | null) {
    this.previous = previous;
  }

  setCount(count: number) {
    this.count = count;
  }

  setOrder(orderDirection: 'asc' | 'desc') {
    this.order = orderDirection;
  }

  setExportBlob(blob: any | undefined) {
    this.exportBlob = blob;
  }

  orderStockCounts() {
    const stockCounts = toJS(this.previousStockCounts);

    const order = this.order;

    stockCounts.sort(function (a: IGetStockHistoryResult, b: IGetStockHistoryResult) {
      const dateA = new Date(a.ctime).getTime();
      const dateB = new Date(b.ctime).getTime();
      if (order === 'asc') {
        return dateA - dateB;
      }
      return dateB - dateA;
    });

    this.setPreviousStockCounts(stockCounts);
  }
}

export default StockCount;
