import React from 'react';
import {observer} from 'mobx-react-lite';
import SelectHotel from '../components/Navbar/SelectHotel';
import {Container} from '@mui/material';
import PageTitle from '../components/PageTitle';
import {useTranslation} from 'react-i18next';

const Maintenance = observer(() => {
  const {t} = useTranslation();

  return (
    <Container>
      <PageTitle>{t('navbar_maintenance')}</PageTitle>
      <SelectHotel disabled={false} />
    </Container>
  );
});

export default Maintenance;
