import {initReactI18next} from 'react-i18next';
import i18n from 'i18next';
import i18nConfig, {namespaces} from './i18n.config';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const ns = namespaces;
const supportedLngs = i18nConfig.locales;
const resources = ns.reduce((acc, n) => {
  supportedLngs.forEach((lng) => {
    if (!acc[lng]) acc[lng] = {};
    acc[lng] = {
      ...acc[lng],
      [n]: require(`../src/translations/${lng}/${n}.json?internal`),
    };
  });
  return acc;
}, {});

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(Backend)
  .init({
    fallbackLng: i18nConfig.fallbackLocale,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    ns,
    supportedLngs,
    resources,
    load: 'languageOnly',
  });

export default i18n;
