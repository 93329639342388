import React from 'react';
import {observer} from 'mobx-react-lite';
import {useRootStore} from '../context';
import DeleteIcon from '@mui/icons-material/Delete';
import {Avatar, Badge, IconButton, Stack, Tooltip, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import SkeletonLoader from './general/SkeletonLoader';

interface IProfileImageProps {
  sx?: Record<string, string | number>;
  textColor?: 'black' | 'white';
  size?: 'small' | 'medium' | 'large';
  canManage?: boolean;
  onDelete?: (e: React.MouseEvent) => void;
}

const ProfileImage: React.FC<IProfileImageProps> = observer(
  ({sx, textColor = 'black', size = 'small', onDelete, canManage = false}) => {
    const {t} = useTranslation();
    const {accountStore, settingsStore} = useRootStore();
    const sizes = {
      small: {height: 40, width: 40},
      medium: {height: 52, width: 52},
      large: {height: 80, width: 80},
    };

    const imageSize = sizes[size];
    const deleteIcon = (
      <Tooltip title={t('common:delete')}>
        <IconButton
          aria-label="delete"
          color="error"
          onClick={onDelete}
          sx={{backgroundColor: 'black', padding: 0.4, '&:hover': {backgroundColor: 'grey'}}}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    );
    const hasProfileImage = accountStore.user.profile_image;
    const badgeContent = canManage && hasProfileImage ? deleteIcon : <></>;

    return (
      <>
        <Stack flexDirection="row" alignItems="center" gap={1}>
          {settingsStore.inProgress && <SkeletonLoader skeletonShape="avatar" amount={1} />}

          {!settingsStore.inProgress && (
            <Stack flexDirection={canManage ? 'column' : 'row'} alignItems="center" gap={1}>
              <Badge
                overlap="circular"
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                badgeContent={badgeContent}
              >
                <Avatar
                  src={accountStore?.user?.profile_image}
                  title={t('common:profile_image')}
                  sx={{...sx, ...imageSize}}
                />
              </Badge>
              <Typography color={textColor} variant={canManage ? 'h6' : 'body1'}>
                {accountStore.user?.username}
              </Typography>
            </Stack>
          )}
        </Stack>
      </>
    );
  },
);

export default ProfileImage;
