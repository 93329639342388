import {Stack, Box, Typography} from '@mui/material';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {useTranslation} from 'react-i18next';
import ProfileImage from '../../components/ProfileImage';

interface IUploadDeleteProfileImageProps {
  handleUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleDelete: (e: React.MouseEvent) => void;
}

const ManageProfileImage: React.FC<IUploadDeleteProfileImageProps> = observer(
  ({handleUpload, handleDelete}) => {
    const {t} = useTranslation();

    return (
      <>
        <Stack
          alignItems="center"
          flexDirection={'row'}
          gap={2}
          justifyContent={{
            xs: 'center',
            md: 'flex-start',
          }}
        >
          <ProfileImage size="large" onDelete={handleDelete} canManage={true} />
        </Stack>
        <Box display={'flex'} flexDirection="column" marginTop={3}>
          <Stack flexDirection="column" gap={1} alignItems="flex-start">
            <Typography variant="h6">{t('settings:update_profile_photo')}</Typography>
            <input name="profile_image" onChange={handleUpload} type="file" />
          </Stack>
        </Box>
      </>
    );
  },
);

export default ManageProfileImage;
