import request from 'superagent';
import Config from '../../config';
import Common from '../common';

// CHECKED INTERFACE
export interface IAddVendorResponse {
  id: number;
  name: string;
  type: string; // email phone url
  info: string;
}
// CHECKED INTERFACE
export interface IVendorResponse {
  id: number;
  name: string;
  type: string; // email phone url
  info: string;
}

// CHECKED TYPE
export type TGetVendorResponse = Array<IVendorResponse>;

export default {
  addVendor: function (token: string, name: string, type: string, info: string) {
    const url = `${Config.API_URL}/inventory/vendor/`;

    const params = {
      name: name,
      type: type,
      info: info,
    };
    return request
      .post(url)
      .auth(token, {type: 'bearer'})
      .type(Common.MEDIA_TYPE)
      .accept(Common.MEDIA_TYPE)
      .send(JSON.stringify(params));
  },

  deleteVendor: function (token: string, vendorId: number) {
    const url = `${Config.API_URL}/inventory/vendor/${vendorId}/`;

    return request.delete(url).auth(token, {type: 'bearer'}).accept(Common.MEDIA_TYPE).send();
  },

  getVendors: function (token: string) {
    const url = `${Config.API_URL}/inventory/vendor/`;

    return request.get(url).auth(token, {type: 'bearer'}).accept(Common.MEDIA_TYPE).send();
  },
};
