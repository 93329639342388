import request from 'superagent';
import Config from '../../config';
import Common, {
  IAmenityResponse,
  IBrand,
  ICategory,
  IPurchaseResponse,
  IStorageIDName,
  IStorageResponseData,
  IUnit,
} from '../common';

// CHECKED INTERFACE
export interface ILevelResponse {
  id?: number;
  image?: string | null;
  storage: IStorageIDName;
  level: string; // lv1 lv2 lv3
  quantity: number;
  amenity: number;
}

export type TGetLevelsResponse = Array<'LV1' | 'LV2' | 'LV3'>;

// CHECKED INTERFACE
export interface ICreateProductPayload {
  amenity: {
    sku: string;
    consumption_tax: number;
    name: string;
    brand: number;
    category: number;
    unit: string;
    hotel: Array<number>;
  };

  purchase: {
    vendor: number;
    price: number;
    reference: string;
    delivery: number;
    info: string;
  };

  lv1: {
    level: string;
    amenity_id: number;
    storage: number;
    quantity: number;
    image?: string;
  };
  lv2?: {
    level: string;
    amenity_id: number;
    storage: number;
    quantity: number;
    image?: string;
  };
  lv3?: {
    level: string;
    amenity_id: number;
    storage: number;
    quantity: number;
    image?: string;
  };
}

// CHECKED INTERFACE
export interface ICreateProductResponse {
  id: number;
  brand: IBrand;
  unit: IUnit;
  category: ICategory;
  sku: string;
  name: string;
  consumption_tax: number;
  hotel: Array<number>;
}

// CHECKED INTERFACE
export interface IUpdateProductPayload {
  amenity: {
    id: number;
    sku: string;
    consumption_tax: number;
    name: string;
    brand: number;
    category: number;
    unit: number;
    hotel: Array<number>;
  };
  purchase: {
    id: number;
    price_currency: string;
    // TODO CHECK WHY PRICE IS SOMETIMES A STRING
    price: number;
    unit_price_currency: string;
    reference: string;
    delivery: number;
    info: string;
    amenity: number;
    vendor: number;
  };

  lv1: {
    level: string;
    amenity: number;
    storage: number;
    quantity: number;
    image?: string | null;
  };
  lv2?: {
    level: string;
    amenity: number;
    storage: number;
    quantity: number;
    image?: string | null;
  };
  lv3?: {
    level: string;
    amenity: number;
    storage: number;
    quantity: number;
    image?: string | null;
  };
}

// CHECKED INTERFACE
export interface IUpdateProductResponse {
  amenity: IAmenityResponse;
  purchase: IPurchaseResponse;
  lv1: IStorageResponseData;
  lv2?: IStorageResponseData;
  lv3?: IStorageResponseData;
}

// CHECKED INTERFACE
export interface IGetProductResponse {
  amenity: IAmenityResponse;
  levels: Array<IStorageResponseData>;
  purchase: IPurchaseResponse;
}

// CHECKED INTERFACE
export interface IListProductResult {
  id: number;
  brand: IBrand;
  unit: IUnit;
  category: ICategory;
  purchase: IPurchaseResponse;
  storage: IStorageResponseData;
  stock: number;
  est_stock: number;
  status: string; // ok or low
  est_box: number;
  since_count: number;
  sku: string;
  name: string;
  consumption_tax: number;
  hotel: Array<number>;
}

// CHECKED INTERFACE
export interface IListProductsResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: Array<IListProductResult>;
}

export default {
  getLevels(token: string, hotel: number) {
    const url = `${Config.API_URL}/inventory/levels/${hotel}/`;

    return request.get(url).auth(token, {type: 'bearer'}).accept(Common.MEDIA_TYPE).send();
  },

  createProduct(token: string, product: ICreateProductPayload) {
    const url = `${Config.API_URL}/inventory/product/`;

    return request
      .post(url)
      .auth(token, {type: 'bearer'})
      .type(Common.MEDIA_TYPE)
      .accept(Common.MEDIA_TYPE)
      .send(JSON.stringify(product));
  },

  updateProduct(token: string, product: IUpdateProductPayload) {
    const url = `${Config.API_URL}/inventory/product/`;

    return request
      .put(url)
      .auth(token, {type: 'bearer'})
      .type(Common.MEDIA_TYPE)
      .accept(Common.MEDIA_TYPE)
      .send(JSON.stringify(product));
  },

  getProduct(token: string, amenity_id: number) {
    const url = `${Config.API_URL}/inventory/product/${amenity_id}/`;

    return request.get(url).auth(token, {type: 'bearer'}).accept(Common.MEDIA_TYPE).send();
  },

  deleteProduct(token: string, level: string, amenity_id: number, hotel_id: number) {
    const url = `${Config.API_URL}/inventory/product/${level}/${amenity_id}/${hotel_id}/`;

    return request.delete(url).auth(token, {type: 'bearer'}).accept(Common.MEDIA_TYPE).send();
  },

  listProducts(token: string, hotelId: number, level: string, nextPreviousUrl: string | null) {
    const url = nextPreviousUrl || `${Config.API_URL}/inventory/product/${level}/${hotelId}/`;

    return request.get(url).auth(token, {type: 'bearer'}).accept(Common.MEDIA_TYPE).send();
  },

  // UNUSED
  deleteAmenity(token: string, amenity_id: number) {
    const url = `${Config.API_URL}/inventory/product/${amenity_id}/`;

    return request.delete(url).auth(token, {type: 'bearer'}).accept(Common.MEDIA_TYPE).send();
  },

  // UNUSED
  createPurchaseInfo(token: string, purchase: object) {
    const url = `${Config.API_URL}/inventory/purchase-info/`;

    return request
      .post(url)
      .auth(token, {type: 'bearer'})
      .type(Common.MEDIA_TYPE)
      .accept(Common.MEDIA_TYPE)
      .send(JSON.stringify(purchase));
  },
  // UNUSED
  createStorageLevel(token: string, level: string) {
    const url = `${Config.API_URL}/inventory/storage-level/`;

    return request
      .post(url)
      .auth(token, {type: 'bearer'})
      .type(Common.MEDIA_TYPE)
      .accept(Common.MEDIA_TYPE)
      .send(JSON.stringify(level));
  },
};
