import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {toJS} from 'mobx';
import {yellow} from '@mui/material/colors';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import InfoIcon from '@mui/icons-material/Info';
import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';
import {
  Card,
  Grid,
  Stack,
  Button,
  Divider,
  Container,
  Typography,
  CardContent,
  CardHeader,
} from '@mui/material';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import Header from '../table/Header';
import CardRowItem from '../table/CardRowItem';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DeleteIcon from '@mui/icons-material/Delete';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import {getPrice} from '../../libs/utils';
import {useRootStore} from '../../context';
import {PAGE_CREATE_ORDER, PAGE_LIST_ORDERS} from '../../stores/inventory/orders/orders';
import SelectHotel from '../Navbar/SelectHotel';
import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';
import type {IOrderProductsPayload} from '../../api/inventory/orders';
import type {IVendor} from '../../api/common';
import type {ISessionStorageProduct} from '../../stores/inventory/orders/cart';
import {DateTime} from 'luxon';

interface IVendorProducts {
  products: Array<ISessionStorageProduct>;
  vendor: IVendor;
}

type TProducts = Record<string, IVendorProducts>;

const SaveOrder = observer(() => {
  const {t} = useTranslation();
  const {hotelStore, ordersStore} = useRootStore();
  const [products, setProducts] = useState<TProducts>({});

  useEffect(() => {
    if (ordersStore.cart.products.length === 0 && ordersStore.cart.saveSuccess) {
      ordersStore.setPage(PAGE_LIST_ORDERS);
    } else if (ordersStore.cart.products.length === 0) {
      ordersStore.setPage(PAGE_CREATE_ORDER);
    }
  }, [ordersStore.cart.products, ordersStore.cart.saveSuccess]);

  useEffect(() => {
    if (ordersStore.cart.products.length > 0) {
      const p: TProducts = {};
      ordersStore.cart.products.forEach((obj) => {
        if (obj.amenity.purchase.vendor.name in p) {
          p[obj.amenity.purchase.vendor.name].products.push(toJS(obj));
        } else {
          p[obj.amenity.purchase.vendor.name] = {
            vendor: toJS(obj.amenity.purchase.vendor),
            products: [toJS(obj)],
          };
        }
      });
      setProducts(p);
    }
  }, [ordersStore.cart.products]);

  const handleSaveOrder = (e: React.SyntheticEvent, vendor: string, finalPrice: number) => {
    e.preventDefault();
    const payload: IOrderProductsPayload = {
      vendor: products[vendor].vendor.id,
      price: finalPrice,
      items: [],
    };

    const currentHotel = hotelStore.currentHotel?.id;

    if (currentHotel) {
      payload.items = products[vendor].products.map((obj) => {
        const totalAmount = obj.quantity * Number(obj.amenity.purchase.price);
        const tax = (obj.amenity.consumption_tax / 100) * totalAmount;

        return {
          amenity: obj.amenity.id,
          hotel: currentHotel,
          total: obj.quantity,
          // price: totalAmount + tax,
          price: totalAmount,
        };
      });
      ordersStore.cart.saveOrder(payload);
    }
  };

  const handleClearProduct = (
    e: React.SyntheticEvent,
    vendor: string,
    deleteProduct?: ISessionStorageProduct,
  ) => {
    e.preventDefault();

    // Delete a single product
    if (deleteProduct) {
      products[vendor].products.forEach((product) => {
        if (product.amenity.id === deleteProduct.amenity.id) {
          const toDeleteProduct = {...deleteProduct, quantity: 0};
          ordersStore.cart.updateProducts(toDeleteProduct);
        }
      });

      toast.success(t('saveOrder:product_cleared_from_sheet'));
      return;
    }
    // Delete all products from that vendor
    products[vendor].products.forEach((product) => {
      const toDeleteProduct = {...product, quantity: 0};
      ordersStore.cart.updateProducts(toDeleteProduct);
    });

    toast.success(t('saveOrder:products_cleared_from_sheet'));
  };

  const getDate = () => {
    return DateTime.now().toFormat('dd/MM/yyyy');
  };

  const getTotal = (product: IVendorProducts) => {
    let price = 0;
    product.products.forEach((obj) => {
      const totalAmount = Number(obj.amenity.purchase.price) * obj.quantity;

      price += totalAmount;
    });
    return price;
  };

  const getTax = (vendor: IVendorProducts, tax: number, productList: Array<string>) => {
    let totalTax = 0;

    vendor.products.forEach((product) => {
      const productPrice = Number(product.amenity.purchase.price) * product.quantity;
      const productTax = product.amenity.consumption_tax;

      if (productTax === tax) {
        const taxAmount = productPrice * (tax / 100);
        totalTax += taxAmount;
        productList.push(product.amenity.sku);
      }
    });
    return totalTax;
  };

  const getFinalPrice = (vendor: IVendorProducts, totalTax8: number, totalTax10: number) => {
    let totalPrice = 0;
    vendor.products.forEach((product) => {
      const totalAmount = Number(product.amenity.purchase.price) * product.quantity;
      totalPrice += totalAmount;
    });

    return totalPrice + totalTax8 + totalTax10;
  };

  interface IInfoProps {
    infoContent: string;
  }

  const Info: React.FC<IInfoProps> = ({infoContent}) => {
    if (infoContent && infoContent.includes('www.') === true) {
      if (!infoContent.startsWith('http')) {
        infoContent = `http://${infoContent}`;
      }

      return (
        <Tooltip title={infoContent} placement="right">
          <Link href={infoContent} target="_blank" rel="noreferrer" underline="none">
            <IconButton>
              <OpenInBrowserIcon color="primary" />
            </IconButton>
          </Link>
        </Tooltip>
      );
    }

    return (
      <Tooltip title={infoContent || t('saveOrder:no_info')} placement="right">
        <Link href={infoContent} target="_blank" rel="noreferrer" underline="none">
          <IconButton>
            <InfoIcon color="primary" />
          </IconButton>
        </Link>
      </Tooltip>
    );
  };

  if (Object.keys(products).length === 0) {
    return (
      <Stack alignItems="center">
        <CircularProgress color="primary" />
        <Typography marginY={2}>Loading...</Typography>
      </Stack>
    );
  }

  return (
    <>
      <Container>
        <Grid container>
          <Grid item xs={12} md={4}>
            <SelectHotel disabled={true} />
          </Grid>
        </Grid>
        <Card sx={{marginTop: 5}} variant="outlined">
          <CardContent>
            <Stack gap={3} sx={{overflow: 'hidden'}} alignItems="start">
              <Button
                variant="text"
                color="inherit"
                startIcon={<ChevronLeftIcon fontSize="large" />}
                size="large"
                onClick={() => {
                  ordersStore.setPage(PAGE_CREATE_ORDER);
                }}
              >
                {t('saveOrder:order_sheet')}
              </Button>
            </Stack>
            {Object.keys(products).map((vendor, index) => {
              const tax8Products: Array<string> = [];
              const tax10Products: Array<string> = [];
              const totalTax8 = getTax(products[vendor], 8, tax8Products);
              const totalTax10 = getTax(products[vendor], 10, tax10Products);
              const finalPrice = getFinalPrice(products[vendor], totalTax8, totalTax10);

              return (
                <Card key={index} variant="outlined" sx={{marginY: 3}} component={'div'}>
                  <CardContent>
                    <Card variant="outlined" sx={{marginY: 2}} component="div">
                      <CardHeader
                        title={vendor}
                        sx={{
                          '& .MuiTypography-root': {
                            fontWeight: '700',
                          },
                        }}
                      />
                      <CardContent>
                        <Grid container spacing={2} alignItems="center">
                          <Grid
                            item
                            xs={12}
                            md={4}
                            display="flex"
                            justifyContent="space-evenly"
                            alignItems="center"
                          >
                            <Typography sx={{fontWeight: 'bold'}}>
                              {t('common:order_date')}
                            </Typography>
                            <Typography color="GrayText" fontWeight="bold" variant="body2">
                              {getDate()}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={4}
                            display="flex"
                            justifyContent="space-evenly"
                            alignItems="center"
                          >
                            <Typography sx={{fontWeight: 'bold'}}>
                              {t('common:order_method')}
                            </Typography>
                            <Typography
                              color="primary"
                              fontWeight="bold"
                              variant="body2"
                              sx={{textTransform: 'uppercase'}}
                            >
                              {products[vendor].vendor.type}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={4}
                            display="flex"
                            justifyContent="space-evenly"
                            alignItems="center"
                          >
                            <Typography color="primary">{products[vendor].vendor.info}</Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>

                    <Grid container component={'div'}>
                      {products[vendor].products.map((vendorProduct, index) => {
                        const info = [
                          {
                            label: t('common:code'),
                            value: vendorProduct.amenity.sku,
                            lg: 2,
                            alignItems: 'center',
                          },
                          {
                            label: t('common:brand'),
                            value: vendorProduct.amenity.brand.name,
                            lg: 2,
                            alignItems: 'center',
                          },
                          {
                            label: t('common:vendor_ref'),
                            value: vendorProduct.amenity.purchase.reference,
                            lg: 2,
                            alignItems: 'center',
                          },
                          {
                            label: t('common:quantity'),
                            value: vendorProduct.quantity,
                            lg: 1,
                            alignItems: 'center',
                          },
                          {
                            label: t('common:cost'),
                            value: getPrice(Number(vendorProduct.amenity.purchase.price)),
                            lg: 2,
                            alignItems: 'center',
                          },
                          {
                            label: t('saveOrder:info'),
                            value: (
                              <Grid item xs={12} alignItems="center">
                                <Info infoContent={vendorProduct.amenity.purchase.info} />
                              </Grid>
                            ),
                            lg: 2,
                            alignItems: 'center',
                          },
                          {
                            label: t('saveOrder:clear_product'),
                            value: (
                              <Tooltip title={t('saveOrder:clear_product')}>
                                <IconButton
                                  aria-label="delete"
                                  color="error"
                                  onClick={(e) => {
                                    handleClearProduct(e, vendor, vendorProduct);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            ),
                            lg: 1,
                            alignItems: 'start',
                          },
                        ];
                        return (
                          <React.Fragment key={index}>
                            <Grid container display={{xs: 'none', lg: 'flex'}} marginY={3}>
                              <Header lg={2} justifyContent="center">
                                {t('common:code')}
                              </Header>
                              <Header lg={2} justifyContent="center">
                                {t('common:brand')}
                              </Header>
                              <Header lg={2} justifyContent={'center'}>
                                {t('common:vendor_ref')}
                              </Header>
                              <Header lg={1} justifyContent={'center'}>
                                {t('common:quantity')}
                              </Header>
                              <Header lg={2} justifyContent={'center'}>
                                {t('common:cost')}
                              </Header>
                              <Header lg={2} justifyContent={'center'}>
                                {t('saveOrder:info')}
                              </Header>
                            </Grid>
                            <Divider light />

                            <Grid container gap={{xs: 5, lg: 0}}>
                              <CardRowItem key={index} item={info} />
                              <Divider light />
                            </Grid>
                          </React.Fragment>
                        );
                      })}
                    </Grid>

                    <Grid container direction="column" alignItems="flex-end" component={'div'}>
                      <Grid container marginTop={2} display="flex">
                        <Grid item xs={12} display="flex" justifyContent="space-between">
                          <Typography fontWeight="bold">{t('common:total')}</Typography>
                          <Typography fontWeight="bold">
                            {getPrice(getTotal(products[vendor]))}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          display="flex"
                          justifyContent="space-between"
                          marginY={1}
                        >
                          {tax8Products.length > 0 && (
                            <>
                              <Typography>{`${t(
                                'saveOrder:tax_8',
                              )} (${tax8Products.toString()})`}</Typography>
                              <Typography>{getPrice(totalTax8)}</Typography>
                            </>
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          display="flex"
                          justifyContent="space-between"
                          marginY={1}
                        >
                          {tax10Products.length > 0 && (
                            <>
                              <Typography>{`${t(
                                'saveOrder:tax_10',
                              )} (${tax10Products.toString()})`}</Typography>
                              <Typography>{getPrice(totalTax10)}</Typography>
                            </>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <Divider></Divider>
                        </Grid>
                      </Grid>

                      <Grid container marginY={1}>
                        <Grid item xs={12} textAlign="right">
                          <Typography variant="h4" fontWeight="700" sx={{color: 'success.main'}}>
                            {getPrice(finalPrice)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      width="100%"
                      component="div"
                    >
                      <Grid item display="flex" alignItems="center">
                        <WarningRoundedIcon sx={{color: yellow[500]}} />
                        <Typography textAlign="center">
                          {t('saveOrder:before_saving_make_order')}
                        </Typography>
                      </Grid>
                      <Stack
                        marginTop={2}
                        width="80%"
                        direction="column"
                        spacing={2}
                        component="div"
                      >
                        <LoadingButton
                          loading={ordersStore.cart.isSaving}
                          loadingPosition="start"
                          startIcon={<SaveIcon />}
                          variant="contained"
                          fullWidth
                          disabled={ordersStore.cart.isSaving}
                          onClick={(e) => {
                            handleSaveOrder(e, vendor, finalPrice);
                          }}
                        >
                          {t('saveOrder:save_order')}
                        </LoadingButton>

                        <Button
                          startIcon={<HighlightOffIcon />}
                          variant="contained"
                          fullWidth
                          disabled={ordersStore.cart.isSaving}
                          color="error"
                          onClick={(e) => {
                            handleClearProduct(e, vendor);
                          }}
                        >
                          {t('saveOrder:clear_products')}
                        </Button>
                      </Stack>
                    </Grid>
                  </CardContent>
                </Card>
              );
            })}
          </CardContent>
        </Card>
      </Container>
    </>
  );
});

export default SaveOrder;
