import React, {useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {useRootStore} from '../../context';
import {Grid, Button, Stack, Paper, Box} from '@mui/material';
import Header from '../table/Header';
import CardRowItem from '../table/CardRowItem';
import {useTranslation} from 'react-i18next';
import NoResultsFound from '../general/NoResultsFound';
import SkeletonLoader from '../general/SkeletonLoader';
import ListPagination from '../ListPagination';

const Orders = observer(() => {
  const {t} = useTranslation();
  const {orderStore, hotelStore} = useRootStore();

  useEffect(() => {
    if (hotelStore.currentHotel !== undefined) {
      orderStore.getOrders();
    }
  }, [hotelStore.currentHotel]);

  const getPrice = (value: number | bigint) => {
    return new Intl.NumberFormat('ja-JP', {
      style: 'currency',
      currency: 'JPY',
    }).format(value);
  };

  return (
    <>
      {orderStore.count > 0 && (
        <Box>
          <Paper
            sx={{
              backgroundColor: {xs: 'transparent', lg: '#FFF'},
              boxShadow: {xs: 0, lg: 5},
              padding: {lg: 5},
            }}
          >
            {orderStore.count > 0 && !orderStore.isLoading && (
              <>
                <Grid container display={{xs: 'none', lg: 'flex'}}>
                  <Grid item lg={2}>
                    <Header>{t('common:hotel')}</Header>
                  </Grid>
                  <Grid item lg={2}>
                    <Header>{t('common:author')}</Header>
                  </Grid>
                  <Grid item lg={2}>
                    <Header>{t('common:order_date')}</Header>
                  </Grid>
                  <Grid item lg={2}>
                    <Header>{t('common:delivery_date')}</Header>
                  </Grid>
                  <Grid item lg={2}>
                    <Header>{t('common:price')}</Header>
                  </Grid>
                </Grid>

                <Grid container gap={{xs: 5, lg: 0}}>
                  {orderStore.orders.map((obj, i) => {
                    const info = [
                      {label: t('common:hotel'), value: obj.hotel_name, lg: 2},
                      {label: t('common:author'), value: obj.name, lg: 2},
                      {
                        label: t('common:order_date'),
                        value: new Date(obj.created).toDateString(),
                        lg: 2,
                      },
                      {
                        label: t('common:delivery_date'),
                        value: new Date(obj.delivery_date).toDateString(),
                        lg: 2,
                      },
                      {label: t('common:price'), value: getPrice(parseInt(obj.price)), lg: 2},
                      {
                        label: '',
                        value: (
                          <Button
                            href={obj.download_url}
                            target="_blank"
                            variant="text"
                            sx={{textDecoration: 'underline'}}
                          >
                            {t('common:download')}
                          </Button>
                        ),
                        lg: 2,
                      },
                    ];

                    return <CardRowItem key={i} item={info} />;
                  })}
                </Grid>
              </>
            )}

            {orderStore.isLoading && <SkeletonLoader skeletonShape="table" />}

            {orderStore.count > 0 && (
              <Stack
                display="flex"
                flexDirection={'row'}
                width="100%"
                justifyContent={'center'}
                marginTop={3}
              >
                <ListPagination
                  getter={orderStore.getOrders.bind(orderStore)}
                  store={orderStore}
                  watchers={[orderStore.startDate, orderStore.endDate]}
                />
              </Stack>
            )}
          </Paper>
        </Box>
      )}
      {orderStore.count === 0 && !orderStore.isLoading && (
        <NoResultsFound message={t('common:there_are_currently_no_orders')} />
      )}
    </>
  );
});

export default Orders;
