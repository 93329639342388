import React from 'react';
import Switch from '@mui/material/Switch';
import {Box, Stack, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';

const ChangeLanguageSwitch: React.FC = () => {
  const {t, i18n} = useTranslation();
  const [checked, setChecked] = React.useState(() => {
    const currentLanguage = i18n.language;

    return currentLanguage === 'en' || currentLanguage === 'en-US';
  });

  const handleChange = () => {
    const newStatus = !checked;
    setChecked(newStatus);

    const language = newStatus ? 'en' : 'ja';

    i18n.changeLanguage(language);
  };

  return (
    <Box display={'flex'} flexDirection="column">
      <Typography variant="h6">{t('common:change_language')}</Typography>
      <Stack direction="row" spacing={0.5} alignItems="center">
        <Typography variant="body2">JA</Typography>
        <Switch checked={checked} onClick={handleChange} />
        <Typography variant="body2">EN</Typography>
      </Stack>
    </Box>
  );
};

export default ChangeLanguageSwitch;
