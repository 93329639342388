import {StatusCodes} from 'http-status-codes';
import {makeObservable, action, observable, toJS, IReactionDisposer} from 'mobx';
import {toast} from 'react-toastify';
import i18n from '../../../i18n';
import Api, {ILevelResponse, IUpdateProductPayload} from '../../../api/inventory/product';
import type RootStore from '../../rootStore';
import type {IAmenityResponse, IPurchaseResponse} from '../../../api/common';
import {handleUnauthorizedResponse} from '../../../libs/utils';

interface ILevels {
  lv1: ILevelResponse | null;
  lv2: ILevelResponse | null;
  lv3: ILevelResponse | null;
}

interface IEdit {
  rootStore: RootStore;
  isSubmitting: boolean;
  success: boolean;
  amenity: IAmenityResponse;
  purchase: IPurchaseResponse;
  levels: ILevels;
  disposers: Array<IReactionDisposer>;
  setSubmitting: (isSubmitting: boolean) => void;
  setSuccess: (isSuccess: boolean) => void;
  setAmenity: (amenity: IAmenityResponse) => void;
  setPurchase: (purchase: IPurchaseResponse) => void;
  setLevels: (levels: Array<ILevelResponse>) => void;
  getProduct: (amenityId: number) => void;
  updateProduct: () => void;
  resetAmenity: () => void;
  resetPurchase: () => void;
  resetLevels: () => void;
  reset: () => void;
}

class Edit implements IEdit {
  rootStore: RootStore;
  isSubmitting = false;
  success = false;
  amenity: IAmenityResponse = {
    id: 0,
    sku: '',
    consumption_tax: 10,
    name: '',
    brand: {id: 0, name: ''},
    category: {id: 0, name: ''},
    unit: {id: 0, name: ''},
    hotel: [],
  };
  purchase: IPurchaseResponse = {
    id: 0,
    price_currency: 'JPY',
    price: '',
    unit_price_currency: 'JPY',
    unit_price: '',
    reference: '',
    delivery: 0,
    info: '',
    amenity: 0,
    vendor: {id: 0, name: '', type: '', info: ''},
  };
  levels: ILevels = {lv1: null, lv2: null, lv3: null};
  disposers: Array<IReactionDisposer>;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      isSubmitting: observable,
      success: observable,
      amenity: observable,
      purchase: observable,
      levels: observable,
      setSubmitting: action,
      setSuccess: action,
      setAmenity: action,
      setPurchase: action,
      setLevels: action,
      getProduct: action,
      updateProduct: action,
      resetAmenity: action,
      resetPurchase: action,
      resetLevels: action,
      reset: action,
    });

    this.disposers = [];
  }

  setSubmitting(isSubmitting: boolean) {
    this.isSubmitting = isSubmitting;
  }

  setSuccess(isSuccess: boolean) {
    this.success = isSuccess;
  }

  setAmenity(amenity: IAmenityResponse) {
    this.amenity = amenity;
  }

  setPurchase(purchase: IPurchaseResponse) {
    this.purchase = purchase;
  }

  setLevels(levels: Array<ILevelResponse>) {
    const lv1 = levels.find((levelItem) => {
      return levelItem.level === 'LV1';
    });
    this.levels['lv1'] = lv1 !== undefined ? lv1 : null;

    const lv2 = levels.find((levelItem) => {
      return levelItem.level === 'LV2';
    });
    this.levels['lv2'] = lv2 !== undefined ? lv2 : null;

    const lv3 = levels.find((levelItem) => {
      return levelItem.level === 'LV3';
    });
    this.levels['lv3'] = lv3 !== undefined ? lv3 : null;
  }

  resetAmenity() {
    this.setAmenity({
      id: 0,
      sku: '',
      consumption_tax: 10,
      name: '',
      brand: {id: 0, name: ''},
      category: {id: 0, name: ''},
      unit: {id: 0, name: ''},
      hotel: [],
    });
  }

  resetPurchase() {
    this.setPurchase({
      id: 0,
      price_currency: 'JPY',
      price: '',
      unit_price_currency: 'JPY',
      unit_price: '',
      reference: '',
      delivery: 0,
      info: '',
      amenity: 0,
      vendor: {
        id: 0,
        name: '',
        type: '',
        info: '',
      },
    });
  }

  resetLevels() {
    this.levels = {lv1: null, lv2: null, lv3: null};
  }

  reset() {
    this.resetAmenity();
    this.resetPurchase();
    this.resetLevels();
    this.setSuccess(false);
  }

  async updateProduct() {
    const isURL = function (url: string | null | undefined) {
      if (typeof url !== 'string') return false;

      const pattern = /^((https):\/\/)/;

      return pattern.test(url);
    };

    const lv1 = {
      ...toJS(this.levels.lv1),
    };

    const product: IUpdateProductPayload = {
      amenity: {
        ...toJS(this.amenity),
        brand: this.amenity.brand.id,
        category: this.amenity.category.id,
        unit: this.amenity.unit.id,
      },
      purchase: {
        ...toJS(this.purchase),
        price: parseInt(this.purchase.price),
        vendor: this.purchase.vendor?.id,
      },
      lv1: {
        amenity: lv1.amenity!,
        level: lv1.level!,
        quantity: lv1.quantity!,
        storage: lv1.storage!.id,
        image: lv1.image,
      },
    };

    if (typeof product['lv1'].image === 'string') {
      if (isURL(product['lv1'].image)) delete product['lv1']['image'];

      if (product['lv1'].image === '') {
        product['lv1']['image'] = null;
      }
    }

    if (this.levels.lv2) {
      product['lv2'] = {
        ...toJS(this.levels.lv2),
        storage: this.levels.lv2.storage.id,
      };

      if (isURL(product['lv2'].image)) {
        delete product['lv2']['image'];
      }

      if (product['lv2'].image === '') {
        product['lv2']['image'] = null;
      }
    }

    if (this.levels.lv3) {
      product['lv3'] = {
        ...toJS(this.levels['lv3']),
        storage: this.levels.lv3.storage.id,
      };

      if (isURL(product['lv3'].image)) {
        delete product['lv3']['image'];
      }

      if (product['lv3'].image === '') {
        product['lv3']['image'] = null;
      }
    }

    const token = this.rootStore.accountStore.user.token;

    if (token) {
      try {
        this.setSubmitting(true);

        const response = await Api.updateProduct(token, product);

        if (response.ok) {
          this.setSuccess(true);
          toast.success(i18n.t('Product successfully updated') as string);
        }
      } catch (error: any) {
        if (error.status === StatusCodes.UNAUTHORIZED) {
          handleUnauthorizedResponse();
          return;
        }
        toast.error(error.message);
      } finally {
        this.setSubmitting(false);
      }
    }
  }

  async getProduct(amenityId: number) {
    const token = this.rootStore.accountStore.user.token;

    if (token) {
      try {
        const response = await Api.getProduct(token, amenityId);

        if (response.ok) {
          const data = await response;
          this.setAmenity(data.body.amenity);
          this.setPurchase(data.body.purchase);
          this.setLevels(data.body.levels);
        }
      } catch (error: any) {
        if (error.status === StatusCodes.UNAUTHORIZED) {
          handleUnauthorizedResponse();
          return;
        }
        toast.error(error.message);
      }
    }
  }
}

export default Edit;
