import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import pluralize from 'pluralize';
import {getML} from '../../libs/utils';
import {useRootStore} from '../../context';
import {Box, Grid, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import type {TProductLevel} from '../../stores/inventory/product/product';

interface IConfirmStorageDetailsProps {
  lv: TProductLevel;
}

const ConfirmStorageDetails: React.FC<IConfirmStorageDetailsProps> = observer(({lv}) => {
  const {inventoryStore, productStore} = useRootStore();
  const {t} = useTranslation();
  const [storage, setStorage] = useState({name: ''});
  const [unit, setUnit] = useState({name: ''});

  useEffect(() => {
    if (inventoryStore.storages.length === 0) {
      inventoryStore.getStorages();
    } else {
      const s = inventoryStore.storages.find((obj) => {
        return obj.id === Number(productStore.lv2.storage);
      });
      if (s) {
        setStorage(s);
      }
    }
  }, [inventoryStore.storages]);

  useEffect(() => {
    if (inventoryStore.units.length === 0) {
      inventoryStore.getUnits();
    } else {
      const u = inventoryStore.units.find((obj) => {
        return obj.id === parseInt(productStore.amenity.unit);
      });
      if (u) {
        setUnit(u);
      }
    }
  }, [inventoryStore.units]);

  const color = lv.includes('2') ? 'primary.light' : 'warning.light';

  return (
    <Box>
      <Typography variant="h6" component="h2" fontWeight={600} sx={{color}}>
        {t('confirmStorageDetails:level_storage_details', {lv: lv[2]})}
      </Typography>
      {/* ---------------------------- Storage has ------------------------- */}
      <Grid container columnSpacing={3} rowSpacing={1} marginTop={1} width={{md: '50%'}}>
        <Grid item xs={12} md={6}>
          <Typography variant="body1">
            {t('confirmStorageDetails:each_storage_has', {lv: lv.toLocaleUpperCase()})}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <Typography variant="body1" component="span" textTransform="uppercase" fontWeight={600}>
              {getML(productStore.getLevel(lv).quantity)}
            </Typography>
            <Typography variant="body1" component="span">
              {unit.name}
            </Typography>
          </Box>
        </Grid>
        {/* ---------------------------- Storage has ------------------------*/}
        {/* ---------------------------- Order equals -----------------------*/}
        <Grid item xs={12} md={6}>
          <Typography variant="body1">{t('confirmStorageDetails:each_order_equals')}</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="body1" component="span" fontWeight={600}>
            {getML(productStore.lv1.quantity / productStore.getLevel(lv).quantity)}
          </Typography>
          <Typography variant="body1" component="span">
            {' '}
            {pluralize(storage.name)}
          </Typography>
        </Grid>
        {/* ---------------------------- Order equals -----------------------*/}
        {/* ------------------------------ Image ----------------------------*/}
        <Grid item>
          {productStore.getLevel(lv).image && (
            <img
              width="200rem"
              src={productStore.getLevel(lv).image}
              alt={t('common:product_image')}
            />
          )}
        </Grid>
        {/* ------------------------------ Image ----------------------------*/}
      </Grid>
    </Box>
  );
});

export default ConfirmStorageDetails;
