import {DateTime} from 'luxon';
import type {IReservationData, IReservationResult} from '../api/reservations';

export const getML = (number: number | bigint, decimal: number | null = null) => {
  if (decimal) {
    return new Intl.NumberFormat('en-US', {
      maximumFractionDigits: decimal,
    }).format(number);
  }
  return new Intl.NumberFormat('en-US', {}).format(number);
};

export const getPrice = (number: number | bigint, currency = 'JPY', locale = 'ja-JP') => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    maximumFractionDigits: 2,
  }).format(number);
};

export const dataURLtoBlob = (dataURL: string) => {
  // convert base64 to raw binary data held in a string, removing the mimetype
  const byteString = atob(dataURL.split(',')[1]);
  // retrieve the mimetype
  const mimetype = dataURL.split(',')[0].split(':')[1].split(';')[0];
  // create a buffer array
  const buffer = new ArrayBuffer(byteString.length);
  // create an uint8 container
  const container = new Uint8Array(buffer);
  // correctly encode buffer
  for (let i = 0; i < byteString.length; i++) {
    container[i] = byteString.charCodeAt(i);
  }
  // return the blob
  return new Blob([buffer], {type: mimetype});
};

export function capitalize(text: string) {
  if (text) {
    const lowerCaseText = text.toLowerCase();
    return lowerCaseText[0].toUpperCase() + lowerCaseText.substring(1);
  }
  return '-';
}

export function localizedDate(date: string) {
  return DateTime.fromSQL(date, {zone: 'utc'}).toLocal().toFormat('DD t');
}

export const areConsecutiveDates = (checkOutDate: string, checkInDate: string) => {
  const checkOut = DateTime.fromSQL(checkOutDate).toUTC().toLocal().toFormat('dd-MM');
  const checkIn = DateTime.fromSQL(checkInDate).toUTC().toLocal().toFormat('dd-MM');

  if (checkOut !== checkIn) return false;
  return true;
};

export type ReservationsByRoom = Record<string, Array<IReservationData>>;

export const separateByRoom = (reservationsArray: IReservationResult) => {
  const roomReservations: ReservationsByRoom = {};

  reservationsArray.reservations.forEach((reservationData) => {
    const room = reservationData.room_number;
    const roomsRegistered = Object.keys(roomReservations).filter((key) => key.includes(room));

    if (roomsRegistered.length > 0) {
      // If registered, check if for any it has a consecutive date
      const consecutiveIndex = roomsRegistered.findIndex((roomName) => {
        // If dates are consecutive
        const lastRoom = roomReservations[roomName].length - 1;
        const lastRoomCheckout = roomReservations[roomName][lastRoom].check_out;

        const consecutive = areConsecutiveDates(lastRoomCheckout, reservationData.check_in);

        if (consecutive) return true;
        return false;
      });

      if (consecutiveIndex >= 0) {
        const foundRoomName = roomsRegistered[consecutiveIndex];
        roomReservations[foundRoomName].push(reservationData);
      } else {
        const key = `${room} ${reservationData.id}`;
        roomReservations[key] = [];
        roomReservations[key].push(reservationData);
      }
    } else {
      roomReservations[room] = [];
      roomReservations[room].push(reservationData);
    }
  });

  return roomReservations;
};

export function handleUnauthorizedResponse() {
  window.localStorage.removeItem('token');
  window.location.reload();
}
