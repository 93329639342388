import {Container, Grid, SelectChangeEvent, Stack} from '@mui/material';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useState} from 'react';

import Inventory from '../components/linen/Inventory';
import LinenOrder from '../components/linen/LinenOrder';
import Orders from '../components/linen/Orders';
import SelectAction from '../components/linen/SelectAction';
import SelectHotel from '../components/Navbar/SelectHotel';
import PageTitle from '../components/PageTitle';
import LinenAction from '../libs/linenAction';
import {useRootStore} from '../context';
import OrderFilter from '../components/linen/OrderFilter';
import {useTranslation} from 'react-i18next';
import LinenInfoCards from '../components/linen/LinenInfoCards';
import InfoText from '../components/general/InfoText';

const LinenContent = observer(() => {
  const {linenStore} = useRootStore();

  switch (linenStore.currentAction) {
    case LinenAction.VIEW_ORDERS:
      return <Orders />;
    case LinenAction.INVENTORY:
      return <Inventory />;
    case LinenAction.PREPARE_ORDER:
      return <LinenOrder />;
    default:
      return null;
  }
});

const LinensTowels = observer(() => {
  const {t} = useTranslation();
  const {linenStore, hotelStore, orderStore} = useRootStore();

  const [currentAction, setCurrentAction] = useState(LinenAction.VIEW_ORDERS);
  const [hotelSelectDisabled, setHotelSelectDisabled] = useState(false);
  const [isCurrentMonthCosts, setIsCurrentMonthCosts] = useState(false);

  useEffect(() => {
    if (hotelStore.hotels.length === 0) {
      hotelStore.loadHotels();
    }
  }, [hotelStore.hotels]);

  useEffect(() => {
    setCurrentAction(linenStore.currentAction);
    setHotelSelectDisabled(
      orderStore.currentStep === 1 && linenStore.currentAction === 'prepare_order',
    );
  }, [linenStore.currentAction, orderStore.currentStep]);

  useEffect(() => {
    setIsCurrentMonthCosts(isCurrentMonth());
  }, [orderStore.startDate]);

  const handleCurrentAction = (event: SelectChangeEvent<string>) => {
    event.preventDefault();
    linenStore.setCurrentAction(event.target.value);
  };

  const isCurrentMonth = () => {
    if (!orderStore.startDate) return true;

    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    const setDate = new Date(orderStore.startDate);
    const setMonth = setDate.getMonth();
    const setYear = setDate.getFullYear();

    return currentMonth === setMonth && currentYear === setYear;
  };

  return (
    <Container>
      <PageTitle>{t('common:navbar_linens_and_towels')}</PageTitle>
      <Stack flexDirection={{md: 'row'}} gap={3} mb={5}>
        {/* ------------------------ Select Filters ------------------------ */}
        <Stack flexDirection={{md: 'row'}} gap={3} width={{xs: '100%', md: '50%'}}>
          <SelectHotel disabled={hotelSelectDisabled} />
          <SelectAction handleCurrentAction={handleCurrentAction} currentAction={currentAction} />
        </Stack>
        {currentAction === LinenAction.VIEW_ORDERS && <OrderFilter />}
        {/* ------------------------ Select Filters ------------------------ */}
      </Stack>
      {!isCurrentMonthCosts && (
        <InfoText text="The costs shown below are not from the current month" />
      )}

      {/* -------------------------- Info Cards -------------------------- */}
      <LinenInfoCards />
      {/* -------------------------- Info Cards -------------------------- */}
      <Grid item xs={12} marginTop={5}>
        <LinenContent />
      </Grid>
    </Container>
  );
});

export default LinensTowels;
