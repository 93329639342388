import React from 'react';
import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from '@mui/material';
import LinenAction, {readableAction} from '../../libs/linenAction';
import {useTranslation} from 'react-i18next';

interface ISelectActionProps {
  currentAction: string;
  handleCurrentAction:
    | ((event: SelectChangeEvent<any>, child: React.ReactNode) => void)
    | undefined;
}

const SelectAction: React.FC<ISelectActionProps> = ({currentAction, handleCurrentAction}) => {
  const {t} = useTranslation();

  return (
    <FormControl fullWidth>
      <InputLabel id="linen-action-select-label">{t('common:select_action')}</InputLabel>
      <Select
        labelId="linen-action-select-label"
        id="linen-action-select"
        label={t('common:select_action')}
        value={currentAction}
        onChange={handleCurrentAction}
        MenuProps={{
          sx: {
            border: '1px solid primary',
            '& .MuiPaper-root': {
              border: '1px solid #3751FF',
            },
          },
        }}
      >
        {LinenAction.action.map((action, index) => {
          return (
            <MenuItem key={index} value={action}>
              {readableAction(action)}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
export default SelectAction;
