import {ListItem, ListItemButton, ListItemIcon, ListItemText} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link, useLocation} from 'react-router-dom';

interface INavLinkProps {
  link: {
    text: string;
    icon?: React.ReactNode;
    link: string | undefined;
  };
}

const NavLink: React.FC<INavLinkProps> = ({link}) => {
  const {t} = useTranslation();
  const {text, icon, link: path} = link;

  const location = useLocation();
  const pathname = location.pathname;

  const isCurrentPage = pathname === path;

  const itemStyles = {
    '&:before': {
      content: '""',
      width: '2px',
      position: 'absolute',
      top: isCurrentPage ? 0 : '50%',
      left: 0,
      height: isCurrentPage ? '100%' : '0%',
      transition: 'all 0.35s ease-in-out',
      backgroundColor: '#FFF',
    },

    // Icon styles
    '.MuiSvgIcon-root': {
      color: isCurrentPage ? '#FFF' : '#FFFFFF40',
    },
  };

  const textStyles = {
    color: isCurrentPage ? '#FFF' : '#FFFFFF40',
    letterSpacing: '0.25px',
    paddingBlock: 1,
  };

  const isExternalUrl = (url: string) => {
    const regexp = /^https?.*/;

    return regexp.test(url);
  };
  return path ? (
    <ListItem disablePadding sx={itemStyles}>
      {!isExternalUrl(path) && (
        <Link to={path} style={{color: 'inherit', textDecoration: 'none', width: '100%'}}>
          <ListItemButton sx={{width: '100%'}}>
            {icon && <ListItemIcon>{icon}</ListItemIcon>}
            <ListItemText primary={text} sx={textStyles} />
          </ListItemButton>
        </Link>
      )}
      {isExternalUrl(path) && (
        <a
          href={path}
          target="_blank"
          rel="noreferrer"
          style={{textDecoration: 'none', width: '100%'}}
        >
          <ListItemButton sx={{width: '100%'}}>
            {icon && <ListItemIcon>{icon}</ListItemIcon>}
            <ListItemText primary={text} sx={textStyles} />
          </ListItemButton>
        </a>
      )}
    </ListItem>
  ) : (
    <></>
  );
};

export default NavLink;
