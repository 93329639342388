import {LoadingButton} from '@mui/lab';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useRootStore} from '../../context';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SaveIcon from '@mui/icons-material/Save';

const SubmitButton = observer(() => {
  const {t} = useTranslation();
  const {settingsStore} = useRootStore();
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (settingsStore.success) {
      setSuccess(true);
      const timerId = setTimeout(() => {
        settingsStore.setSuccess(undefined);
        settingsStore.setChanged(false);
        setSuccess(false);
      }, 1500);
      return () => clearTimeout(timerId);
    }
    setSuccess(false);
  }, [settingsStore.success]);

  return (
    <>
      <LoadingButton
        loading={settingsStore.inProgress}
        loadingPosition="end"
        endIcon={success ? <CheckCircleIcon color="success" /> : <SaveIcon />}
        variant="contained"
        color="gray"
        type="submit"
        disabled={!settingsStore.changed || settingsStore.success}
      >
        {t('common:update')}
      </LoadingButton>
    </>
  );
});

export default SubmitButton;
