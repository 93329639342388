import request from 'superagent';
import Config from '../../config';
import Common, {IBrand, ICategory, IStorageIDName, IUnit} from '../common';

export type TGetBrandsResponse = Array<IBrand>;

export type TGetCategoriesResponse = Array<ICategory>;

export type TGetStorageResponse = Array<IStorageIDName>;

export type TGetUnitResponse = Array<IUnit>;

export interface ILatestCountResponse {
  latest_count: string;
}

export default {
  getBrands: function (token: string) {
    const url = `${Config.API_URL}/inventory/brand/`;

    return request.get(url).auth(token, {type: 'bearer'}).accept(Common.MEDIA_TYPE).send();
  },

  getCategories: function (token: string) {
    const url = `${Config.API_URL}/inventory/category/`;

    return request.get(url).auth(token, {type: 'bearer'}).accept(Common.MEDIA_TYPE).send();
  },

  getStorages: function (token: string) {
    const url = `${Config.API_URL}/inventory/storage/`;

    return request.get(url).auth(token, {type: 'bearer'}).accept(Common.MEDIA_TYPE).send();
  },

  getUnits: function (token: string) {
    const url = `${Config.API_URL}/inventory/unit/`;

    return request.get(url).auth(token, {type: 'bearer'}).accept(Common.MEDIA_TYPE).send();
  },

  getLatestCount(token: string, hotel: number) {
    const url = `${Config.API_URL}/inventory/stock/latest/${hotel}/`;

    return request
      .get(url)
      .auth(token, {type: 'bearer'})
      .type(Common.MEDIA_TYPE)
      .accept(Common.MEDIA_TYPE)
      .send();
  },
};
